import gql from "graphql-tag";

export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    GetCurrentUser {
      ok
      error
      user {
        id
        email
        firstName
        lastName
        fullName
        profileColor
        profileImage
        googleCalendarId
        subscribe
        lastWorkspaceId
        startDate
        endDate
        createdAt
        updatedAt
        googleToken {
          access_token
          refresh_token
          expiry_date
        }
        workspaces {
          id
          name
          pricing
          workspaceColor
          workspaceImage
          updatedAt
          createdAt
        }
      }
    }
  }
`;
export const GET_USER_GOOGLE_TOKEN = gql`
  query GetCurrentUserGoogleToken {
    GetCurrentUser {
      ok
      error
      user {
        googleToken {
          access_token
          refresh_token
          expiry_date
        }
      }
    }
  }
`;

export const UPDATE_CURRENT_USER = gql`
  mutation UpdateCurrentUser(
    $firstName: String
    $lastName: String
    $profileColor: String
    $profileImage: String
    $googleCalendarId: String
    $subscribe: Boolean
    $lastWorkspaceId: String
  ) {
    UpdateCurrentUser(
      firstName: $firstName
      lastName: $lastName
      profileColor: $profileColor
      profileImage: $profileImage
      googleCalendarId: $googleCalendarId
      subscribe: $subscribe
      lastWorkspaceId: $lastWorkspaceId
    ) {
      ok
      error
    }
  }
`;

export const FIND_USERS_BY_EMAIL = gql`
  query FindUsersByEmail($email: String!) {
    FindUsersByEmail(email: $email) {
      ok
      error
      users {
        id
        email
        firstName
        lastName
        fullName
        profileImage
        profileColor
      }
    }
  }
`;
export const DELETE_USER_GOOGLE_TOKEN = gql`
  mutation DeleteUserGoogleTokens($userId: String!) {
    DeleteUserGoogleTokens(userId: $userId) {
      error
      ok
    }
  }
`;

import { StringIdItem } from "src/types/SharedTypes";

export const getStoredLastWorkspace = (): StringIdItem | null => {
  let lastWorkspace: StringIdItem | null = null;

  try {
    const storedLastWorkspace: string | null = window.localStorage.getItem(
      "lastWorkspace",
    );

    if (storedLastWorkspace) {
      lastWorkspace = JSON.parse(storedLastWorkspace);
    }
  } catch (err) {
    console.error(err);
  }

  return lastWorkspace;
};

import React from "react";
import type { FC } from "react";
import { useTheme, useMediaQuery, Box, Typography, Button, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import DetailModal from "../DetailModal";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

interface DeleteAlertProps {
  open: boolean;
  title: string;
  children?: React.ReactNode;
  onSubmit: () => void;
  onCancel: () => void;
  [key: string]: any;
}

const DeleteAlert: FC<DeleteAlertProps> = ({ open, title, children, onSubmit, onCancel, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <DetailModal header={<Typography>{title}</Typography>} onClose={onCancel} open={open} {...rest}>
      <Box display="flex" flexDirection="column">
        <Box flexGrow={1} pt={2} pb={2}>
          {children}
        </Box>
        <Box display="flex">
          <Box flexGrow={1} />
          <Button onClick={onCancel}>Cancel</Button>
          <Box p={1} />
          <Button onClick={onSubmit} style={{ backgroundColor: theme.palette.error.main, color: "white" }}>
            Delete
          </Button>
        </Box>
      </Box>
    </DetailModal>
  );
};

export default DeleteAlert;

import { gql } from "@apollo/client";

export const GET_PROJECT_INFO_PROPERTIES = gql`
  query ProjectInfoProperties {
    ProjectInfoProperties {
      ok
      error
      complexityLevels {
        id
        name
      }
      paymentTerms {
        id
        name
      }
      programs {
        id
        name
      }
      risks {
        id
        name
      }
      serviceTypes {
        id
        name
      }
      tools {
        id
        name
      }
    }
  }
`;

import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import { GET_CURRENT_USER, UPDATE_CURRENT_USER } from "src/Api/User/User.queries";
import { UpdateCurrentUserMutation, UpdateCurrentUserMutationVariables } from "../../../generated/graphql";

export function useProfileQueries() {
  const { enqueueSnackbar } = useSnackbar();

  const [updateCurrentUser] = useMutation<UpdateCurrentUserMutation, UpdateCurrentUserMutationVariables>(
    UPDATE_CURRENT_USER,
    {
      refetchQueries: [{ query: GET_CURRENT_USER }],
      onCompleted: (data) => {
        if (data.UpdateCurrentUser.ok) {
          enqueueSnackbar("Your profile is successfully updated.", {
            variant: "success",
          });
        } else {
          enqueueSnackbar(data.UpdateCurrentUser.error || "Server error", {
            variant: "error",
          });
        }
      },
    },
  );

  return { updateCurrentUser };
}

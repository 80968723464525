import { BigNumber, ethers } from "ethers";
import { timesheetAbi } from "./abi";
import dotenv from "dotenv";
import moment from "moment";
dotenv.config();

//Settings

//블록체인으로 들어가는 RPC
const avaxWyzrsRpcProvider = new ethers.providers.JsonRpcProvider(
  "https://subnets.avacloud.io/fb021a82-f4bc-4895-b8d9-e4726a81a61e", //이것도 공
);
//회사 지갑 개인키 서명을 하기위함
const privateKey: string = "8399ce13d0e9441b875e61a3ff7107fd97ee2c17a9cb7d23706247a3d75ba153"; // 숨겨야함
//월렛을 정의를
const wallet = new ethers.Wallet(privateKey, avaxWyzrsRpcProvider);
//TimehseetContract address
const avaxContractAddress = "0x49589ef890EFcA885B7BCcC0724303A9a593c70D";
//ABI(Application Binary Interface)
const contractABI = timesheetAbi;
//contract에 있는 함수를 사용하기 위해서 새로운 클래스로 정의
const avaxContract = new ethers.Contract(avaxContractAddress, contractABI, wallet);
export async function ExportCsvRecordToBlockchain(currentWorkspace: string, projectName: string, currentUser: string) {
  const CurrentDate = moment().format("YYYY-MM-DD");
  const description = `${currentWorkspace}: ${projectName} (${CurrentDate}) `;

  //FIXME :유저의 지갑으로 트랜잭션을 보내야함

  //Wallet
  const wallet = new ethers.Wallet(privateKey, avaxWyzrsRpcProvider);
  const avaxContract = new ethers.Contract(avaxContractAddress, contractABI, wallet);

  try {
    // 컨트랙트의 함수 호출 (이 부분은 실제 컨트랙트 함수에 맞게 수정해야 함)
    const tx = await avaxContract.addTimesheetWithWorkerName(currentUser, description);

    // 트랜잭션이 채굴될 때까지 기다립니다
    const receipt = await tx.wait();

    console.log(`avax Transaction successful with hash: ${receipt.transactionHash}`);

    console.log(receipt, "receipt");
  } catch (error) {
    console.error(`Failed to call contract method: ${error}`);
  }
}
export async function ExportFilteredCsvRecordToBlockchain(
  currentWorkspace: string,
  projectName: string,
  currentUser: string,
  date: string,
) {
  const description = `${currentWorkspace}: ${projectName} ${date}`;

  //FIXME :유저의 지갑으로 트랜잭션을 보내야함

  //Wallet
  const wallet = new ethers.Wallet(privateKey, avaxWyzrsRpcProvider);
  const avaxContract = new ethers.Contract(avaxContractAddress, contractABI, wallet);

  try {
    // 컨트랙트의 함수 호출 (이 부분은 실제 컨트랙트 함수에 맞게 수정해야 함)
    const tx = await avaxContract.addTimesheetWithWorkerName(currentUser, description);

    // 트랜잭션이 채굴될 때까지 기다립니다
    const receipt = await tx.wait();

    console.log(`avax Transaction successful with hash: ${receipt.transactionHash}`);

    console.log(receipt, "receipt");
  } catch (error) {
    console.error(`Failed to call contract method: ${error}`);
  }
}

export async function GetListOfCsvRecordsInBlockchain() {
  //FIXME :유저의 지갑으로 트랜잭션을 보내야함

  const wallet = new ethers.Wallet(privateKey, avaxWyzrsRpcProvider);

  const contractABI = timesheetAbi;
  const avaxContract = new ethers.Contract(avaxContractAddress, contractABI, wallet);

  try {
    const result = await avaxContract.Count();
    const result2 = await avaxContract.getTimesheetWithWorkerName(1);
    //index 0에서부터 가지고온다.
    //해당 블록체인 서브넷의 블록정보를 가지고올려면 어떻게해야할까

    // const sagaResult = await sagaContract.Count();
    // const result3 = await contract.getTimesheetWithWorkerName(index);
    // console.log(`Worker Name: ${result[1]}, Description: ${result[2]} - ${result}`);
    if (result2.length > 0) {
      let timestamp = BigNumber.from(result2[0]); // This would be a BigNumber in ethers.js v5

      // Convert the BigNumber to a number (be cautious about large numbers, but timestamps are safe)
      let timestampNumber = timestamp.toNumber();

      // Convert the timestamp to a JavaScript Date object
      let date = new Date(timestampNumber * 1000); // Convert to milliseconds by multiplying by 1000
      console.log(`Timesheet: ${result2} ${date}`);
      console.log(`Timesheet: ${result}`);
    }

    // console.log(`Saga Timesheet: ${sagaResult}`);
    // console.log(`Timesheet2: ${result3}`);
  } catch (error) {
    console.error(`Failed to call contract method: ${error}`);
  }
}

//특정 index 로 RecordData 가지고 오기
export async function getRecordTimesheet(index: number) {
  return await avaxContract.getTimesheetWithWorkerName(index);
}

//전체 길이에서 마지막 데이터 10개 가지고오기
// Get the timesheets for all records
export async function getRecordTimesheets() {
  const totalTimehseetLength = await avaxContract.getTimesheetWithWorkerNameCount();
  //입력값으로 받은 숫자 중 가장 큰값을 반환
  const startIndex = Math.max(totalTimehseetLength - 10, 0);

  const timeSheetArr = [];
  for (let i = startIndex; i < totalTimehseetLength; i++) {
    const record = await avaxContract.getTimesheetWithWorkerName(i);

    timeSheetArr.push(record);
  }
  return timeSheetArr;
}

export async function getLastBlocks(numOfBlocks: number) {
  const latest = await avaxWyzrsRpcProvider.getBlockNumber();
  const blocks = [];

  for (let i = 0; i < numOfBlocks; i++) {
    const block = await avaxWyzrsRpcProvider.getBlock(latest - i);
    blocks.push(block);
  }

  return blocks;
}

// 블록의 상세 정보 가져오기
export async function getBlockDetails(blockNumber: any) {
  return await avaxWyzrsRpcProvider.getBlock(blockNumber);
}

// 현재 가스비 가져오기
export async function getCurrentGasPrice() {
  return await avaxWyzrsRpcProvider.getGasPrice();
}

// 특정 트랜잭션 정보 가져오기 (트랜잭션 해시 필요)
export async function getTransactionDetails(txHash: any) {
  return await avaxWyzrsRpcProvider.getTransaction(txHash);
}
export async function getNetworkStatus() {
  return await avaxWyzrsRpcProvider.getGasPrice();
}
export async function getTotalRecordCount() {
  return await avaxContract.getTimesheetWithWorkerNameCount();
}

export async function getTotalBlockCount() {
  getLastBlocks(5).then((blocks) => {
    console.log(blocks); // 최근 5개의 블록 정보 출력
  });
}

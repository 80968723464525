import makeStyles from '@mui/styles/makeStyles';

export const DIALOG_ROW_WIDTH_MAX = 505;
export const DIALOG_ROW_WIDTH_XL = (505 / 6) * 5;
export const DIALOG_ROW_WIDTH_L = (505 / 6) * 4;
export const DIALOG_ROW_WIDTH_M = (505 / 6) * 3;
export const DIALOG_ROW_WIDTH_S = (505 / 6) * 2;
export const DIALOG_ROW_WIDTH_XS = 505 / 6;
export const ITEM_HEIGHT = 48;
export const ITEM_PADDING_TOP = 8;

export const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            minWidth: 400
        }
    }
};

export default makeStyles(theme => ({
    paper: {
        maxHeight: "80vh",
        minWidth: "40vh"
    },
    dialog: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4),
        minHeight: 200,
        minWidth: "300px"
    },
    form: {
        display: "flex",
        flexDirection: "column",
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3)
    },
    inputField: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2)
    },
    createButton: {
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(2)
    },
    root: {
        width: "100%"
    },
    button: {
        marginRight: theme.spacing(0.5)
    },
    buttonGroup: {
        bottom: 0,
        marginBottom: "15px"
    },
    completed: {
        display: "inline-block",
        marginLeft: "10px"
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    stepper: {
        padding: "20px 15px"
    },
    content: {
        padding: "10px 30px"
    },
    row: {
        margin: "6px 0px"
    },
    dateInputField: {
        width: "150px"
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 12
        }
    },
    assigneesPaper: {
        boxShadow: "none",
        margin: 0,
        color: "#586069",
        fontSize: 13
    },
    assigneesOption: {
        minHeight: "auto",
        alignItems: "flex-center",
        '&[aria-selected="true"]': {
            backgroundColor: "#ffeead"
        },
        '&[data-focus="true"]': {
            backgroundColor: theme.palette.action.hover
        }
    },
    assigneesPopperDisablePortal: {
        position: "relative"
    },
    assigneesText: {
        flexGrow: 1
    },
    assigneesClose: {
        opacity: 0.6,
        width: 18,
        height: 18
    }
}));

import type { FC } from "react";
import { Box, Typography } from "@mui/material";
import { UserAvatar } from "src/Components/Atoms";
import { WorkspaceUserBase } from "../../../generated/graphql";

interface UserAvatarAndNameProps {
  member: Omit<WorkspaceUserBase, "__typename">;
  size?: "xsmall" | "small" | "medium" | "large";
}

const UserAvatarAndName: FC<UserAvatarAndNameProps> = ({ member, size }) => {
  return (
    <Box display="flex" alignItems="center">
      <UserAvatar user={member} size={size || "xsmall"} />
      <Box pr={1} />
      <Typography variant="subtitle1" color="textPrimary">
        {member.fullName}
      </Typography>
    </Box>
  );
};

export default UserAvatarAndName;

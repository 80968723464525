import { ApexOptions } from "apexcharts";
// material
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme, Theme } from "@mui/material/styles";
import { COLOR_CODES } from "../theme/colors";

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    "@global": {
      // Tooltip
      ".apexcharts-tooltip,.apexcharts-xaxistooltip": {
        border: "0 !important",
        color: `${theme.palette.text.primary} !important`,
        borderRadius: `${theme.shape.borderRadius}px !important`,
        backgroundColor: `${theme.palette.background.default} !important`,
      },
      ".apexcharts-tooltip-title": {
        border: "0 !important",
        fontWeight: theme.typography.fontWeightBold,
        backgroundColor: `${theme.palette.grey[500_16]} !important`,
        color: theme.palette.text.primary,
      },
      ".apexcharts-xaxistooltip-bottom": {
        "&:before": {
          borderBottomColor: "transparent !important",
        },
        "&:after": {
          borderBottomColor: `${theme.palette.background.paper} !important`,
        },
      },

      // Legend
      ".apexcharts-legend": {
        padding: "0 !important",
      },
      ".apexcharts-legend-series": {
        alignItems: "center",
        display: "flex !important",
      },
      ".apexcharts-legend-marker": {
        marginTop: "-2px !important",
        marginRight: "8px !important",
      },

      ".apexcharts-legend-text": {
        lineHeight: "18px",
        textTransform: "capitalize",
      },
    },
  }),
);

export default function BaseOptionChart(): ApexOptions {
  useStyles();
  const theme = useTheme();

  const LABEL_TOTAL = {
    show: true,
    label: "Total",
    color: theme.palette.text.secondary,
    fontSize: theme.typography.subtitle2.fontSize as string,
    fontWeight: theme.typography.subtitle2.fontWeight,
    lineHeight: theme.typography.subtitle2.lineHeight,
  };

  const LABEL_VALUE = {
    offsetY: 8,
    color: theme.palette.text.primary,
    fontSize: theme.typography.h3.fontSize as string,
    fontWeight: theme.typography.h3.fontWeight,
    lineHeight: theme.typography.h3.lineHeight,
  };

  return {
    // Colors
    colors: [
      theme.palette.primary.main,
      COLOR_CODES[1],
      COLOR_CODES[10],
      COLOR_CODES[2],
      COLOR_CODES[9],
      COLOR_CODES[3],
      COLOR_CODES[11],
      COLOR_CODES[4],
      COLOR_CODES[15],
      COLOR_CODES[5],
      COLOR_CODES[7],
      COLOR_CODES[6],
      COLOR_CODES[12],
      COLOR_CODES[14],
      COLOR_CODES[8],
    ],

    // Chart
    chart: {
      toolbar: { show: false },
      zoom: { enabled: false },
      // animations: { enabled: false },
      foreColor: theme.palette.text.disabled,
      fontFamily: theme.typography.fontFamily,
    },

    // States
    states: {
      hover: {
        filter: {
          type: "lighten",
          value: 0.04,
        },
      },
      active: {
        filter: {
          type: "darken",
          value: 0.88,
        },
      },
    },

    // Fill
    fill: {
      opacity: 1,
      gradient: {
        type: "vertical",
        shadeIntensity: 0,
        opacityFrom: 0.4,
        opacityTo: 0,
        stops: [0, 100],
      },
    },

    // Datalabels
    dataLabels: { enabled: false },

    // Stroke
    stroke: {
      width: 3,
      curve: "smooth",
      lineCap: "round",
    },

    // Grid
    grid: {
      strokeDashArray: 3,
      borderColor: theme.palette.divider,
    },

    // Xaxis
    xaxis: {
      axisBorder: { show: false },
      axisTicks: { show: false },
    },

    // Markers
    markers: {
      size: 0,
      strokeColors: theme.palette.background.paper,
    },

    // Tooltip
    tooltip: {
      x: {
        show: false,
      },
    },

    // Legend
    legend: {
      show: true,
      fontSize: String(13),
      position: "top",
      horizontalAlign: "right",
      markers: {
        radius: 12,
      },
      fontWeight: 500,
      itemMargin: { horizontal: 12 },
      labels: {
        colors: theme.palette.text.primary,
      },
    },

    // plotOptions
    plotOptions: {
      // Bar
      bar: {
        columnWidth: "28%",
        borderRadius: 4,
      },
      // Pie + Donut
      pie: {
        donut: {
          labels: {
            show: true,
            value: LABEL_VALUE,
            total: LABEL_TOTAL,
          },
        },
      },
      // Radialbar
      radialBar: {
        track: {
          strokeWidth: "100%",
          background: theme.palette.grey[300],
        },
        dataLabels: {
          value: LABEL_VALUE,
          total: LABEL_TOTAL,
        },
      },
      // Radar
      radar: {
        polygons: {
          fill: { colors: ["transparent"] },
          strokeColors: theme.palette.divider,
          connectorColors: theme.palette.divider,
        },
      },
      // polarArea
      polarArea: {
        rings: {
          strokeColor: theme.palette.divider,
        },
        spokes: {
          connectorColors: theme.palette.divider,
        },
      },
    },

    // Responsive
    responsive: [
      {
        // sm
        breakpoint: theme.breakpoints.values.sm,
        options: {
          plotOptions: { bar: { columnWidth: "40%" } },
        },
      },
      {
        // md
        breakpoint: theme.breakpoints.values.md,
        options: {
          plotOptions: { bar: { columnWidth: "32%" } },
        },
      },
    ],
  };
}

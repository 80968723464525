import { FC, ReactNode } from "react";
import { Outlet } from "react-router-dom";

import ProjectGuard from "./ProjectGuard";

interface ProjectGuardProps {
  children?: ReactNode;
}

const ProjectGuardWrapper: FC<ProjectGuardProps> = ({ children, ...rest }) => {
  return (
    <ProjectGuard>
      <Outlet />
    </ProjectGuard>
  );
};

export default ProjectGuardWrapper;

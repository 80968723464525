import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { objFromArray } from "src/utils/utils";
import _ from "lodash";
import { WorkspaceUser, WorkspaceUserMeta } from "../../generated/graphql";

export interface MemberState {
  isLoaded: boolean;
  result: {
    ok: boolean | null;
    message: string;
  };
  members: {
    byId: Record<string, WorkspaceUser>;
    allIds: string[];
  };
}

const initialState: MemberState = {
  isLoaded: false,
  result: {
    ok: null,
    message: "",
  },
  members: {
    byId: {},
    allIds: [],
  },
};

export const WorkspaceMemberSlice = createSlice({
  name: "workspaceMembers",
  initialState,
  reducers: {
    reset(state: MemberState) {
      state = initialState;
    },
    startToLoad(state: MemberState) {
      state.isLoaded = true;
    },
    getMembers(state: MemberState, action: PayloadAction<{ members: WorkspaceUser[] }>) {
      const { members } = action.payload;

      state.members.byId = objFromArray(members);
      state.members.allIds = members.map((v) => v.id);
      state.isLoaded = true;
    },

    addWorkspaceMember(state: MemberState, action: PayloadAction<{ member: WorkspaceUser }>) {
      const { member } = action.payload;

      state.members.byId[member.id] = member;
      state.members.allIds.push(member.id);
    },
    changeResult(state: MemberState, action: PayloadAction<{ ok: boolean; message: string }>) {
      const { ok, message } = action.payload;
      state.result.ok = ok;
      state.result.message = message;
    },
    updateMember(state: MemberState, action: PayloadAction<{ member: WorkspaceUser }>) {
      const { member } = action.payload;

      state.members.byId[member.id] = member;
    },
    deleteMember(state: MemberState, action: PayloadAction<{ id: string }>) {
      const { id } = action.payload;
      state.members.byId = _.omit(state.members.byId, id);
      _.pull(state.members.allIds, id);
    },
  },
});

export const reducer = WorkspaceMemberSlice.reducer;

import { DELETE_WORKSPACE, UPDATE_WORKSPACE } from "src/Api/Workspace/Workspace.queries";
import client from "src/apollo";
import { Workspace } from "src/types";
import {
  DeleteWorkspaceMutation,
  DeleteWorkspaceMutationVariables,
  UpdateWorkspaceMutation,
  UpdateWorkspaceMutationVariables,
} from "../../generated/graphql";
import { resetStoresWhenWorkspaceIsChanged } from "../reducers/resetReducer";
import { slice } from "../reducers/workspaceReducer";
import { AppThunk } from "../store";

export const updateWorkspace = (workspace: Workspace): AppThunk => async (dispatch) => {
  const res = await client.mutate<UpdateWorkspaceMutation, UpdateWorkspaceMutationVariables>({
    mutation: UPDATE_WORKSPACE,
    variables: { ...workspace },
  });

  if (res.data?.UpdateWorkspace.ok) {
    dispatch(slice.actions.updateWorkspace({ workspace }));
  }
};

export const deleteWorkspace = (workspace: Workspace): AppThunk => async (dispatch) => {
  const res = await client.mutate<DeleteWorkspaceMutation, DeleteWorkspaceMutationVariables>({
    mutation: DELETE_WORKSPACE,
    variables: { id: workspace.id },
  });

  if (res.data?.DeleteWorkspace.ok) {
    await client.resetStore();
    await dispatch(resetStoresWhenWorkspaceIsChanged());

    dispatch(slice.actions.deleteWorkspace({ id: workspace.id }));
  }
};

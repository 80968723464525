import { gql } from "@apollo/client";

export const F_TASK_CATEGORY_PROPERTIES = gql`
  fragment TaskCategoryProperties on ProjectTaskCategory {
    id
    name
    order
    color
    projectId
    createdAt
    updatedAt
    taskIds
  }
`;

export const GET_PROJECT_TASK_CATEGORIES = gql`
  query GetProjectTaskCategories($projectId: String) {
    GetProjectTaskCategories(projectId: $projectId) {
      ok
      error
      categories {
        ...TaskCategoryProperties
      }
    }
  }
  ${F_TASK_CATEGORY_PROPERTIES}
`;

export const CREATE_PROJECT_TASK_CATEGORY = gql`
  mutation CreateProjectTaskCategory($projectId: String!, $name: String!, $color: String) {
    CreateProjectTaskCategory(projectId: $projectId, name: $name, color: $color) {
      ok
      error
      result {
        ...TaskCategoryProperties
      }
    }
  }
  ${F_TASK_CATEGORY_PROPERTIES}
`;

export const UPDATE_PROJECT_TASK_CATEGORY = gql`
  mutation UpdateProjectTaskCategory($id: Int!, $name: String, $color: String) {
    UpdateProjectTaskCategory(id: $id, name: $name, color: $color) {
      ok
      error
    }
  }
`;

export const UPDATE_PROJECT_TASK_CATEGORY_ORDER = gql`
  mutation UpdateProjectTaskCategoryOrder($id: Int!, $position: Int!) {
    UpdateProjectTaskCategoryOrder(id: $id, position: $position) {
      ok
      error
    }
  }
`;

export const DELETE_PROJECT_TASK_CATEGORY = gql`
  mutation DeleteProjectTaskCategory($input: DeleteProjectTaskPropertyInput!) {
    DeleteProjectTaskCategory(input: $input) {
      ok
      error
    }
  }
`;

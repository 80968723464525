import React from "react";
import { Box, Typography, Container, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { Copyright } from "src/Components/Atoms";

var useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  header: {
    fontWeight: theme.typography.fontWeightBold,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  content: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}));

export default function WorkspaceNone() {
  const classes = useStyles();
  return (
    <>
      <Container component="main">
        <div className={classes.paper}>
          <Typography variant="h1" className={classes.header}></Typography>
          <Typography variant="h5" className={classes.content}>
            Check if the URL is correct.
          </Typography>
          <WarningRoundedIcon style={{ fontSize: "200px" }} />
        </div>
        <Box mt={5}>
          <Copyright />
        </Box>
      </Container>
    </>
  );
}

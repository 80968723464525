import React from "react";

import { useQuery } from "@apollo/client";
import { Box, CircularProgress, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { GET_TOTAL_TASK_COUNTS } from "../../../Api/Dashboard/dashborad.queries";
import { GetTotalTaskCountsQuery, GetTotalTaskCountsQueryVariables } from "../../../generated/graphql";
import { useProject } from "../../../Store/hooks";
import BaseCard from "./BaseCard";

import type { FC } from "react";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

interface TotalTasksProps {}

const TotalTasks: FC<TotalTasksProps> = () => {
  const classes = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down("md"));

  const { project } = useProject();

  const { data, loading } = useQuery<GetTotalTaskCountsQuery, GetTotalTaskCountsQueryVariables>(GET_TOTAL_TASK_COUNTS, {
    fetchPolicy: "cache-and-network",
    variables: { projectId: project!.id },
  });

  return (
    <BaseCard name="Total Tasks">
      {loading && (
        <Box display="flex" flexGrow={1} justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {!loading && <Typography variant="h2">{data?.GetTotalTaskCounts}</Typography>}
    </BaseCard>
  );
};

export default TotalTasks;

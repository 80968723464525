import apollo from "src/apollo";
import { projectInfoEnum } from "src/enum/projectInfoEnum";
import { convertDatetimeForm } from "../date";
import { TaskInfoEnum } from "src/enum/taskInfoEnum";
import { GET_CURRENT_USER } from "src/Api/User/User.queries";
import { Task } from "src/types/TaskTypes";
import moment from "moment";
import { GetCurrentUserQuery, Project } from "../../generated/graphql";

import { ethers } from "ethers";
import { ExportCsvRecordToBlockchain, ExportFilteredCsvRecordToBlockchain } from "../avaxBlockchainWyzrs";

// order
const ProjectInfoOrder = [
  projectInfoEnum.name,
  projectInfoEnum.serviceTypeId,
  projectInfoEnum.publishedDate,
  projectInfoEnum.publisherId,
  projectInfoEnum.description,
  projectInfoEnum.projectName,
  projectInfoEnum.country,
  projectInfoEnum.state,
  projectInfoEnum.city,
  projectInfoEnum.programId,
  projectInfoEnum.size,
  projectInfoEnum.publishedCost,
  projectInfoEnum.ownerId,
  projectInfoEnum.architectId,
  projectInfoEnum.generalContractorId,
  projectInfoEnum.overallStartDate,
  projectInfoEnum.overallEndDate,
  projectInfoEnum.website,
  projectInfoEnum.teamSize,
  projectInfoEnum.leadMemberId,
  projectInfoEnum.projectAssigneesIds,
  projectInfoEnum.phaseId,
  projectInfoEnum.startDate,
  projectInfoEnum.endDate,
  projectInfoEnum.clientId,
  projectInfoEnum.clientContactsIds,
  projectInfoEnum.mainToolId,
  projectInfoEnum.subToolId,
  projectInfoEnum.remark,
  projectInfoEnum.contractValue,
  projectInfoEnum.paymentTermId,
  projectInfoEnum.complexityLevelId,
  projectInfoEnum.riskId,
  projectInfoEnum.riskDescription,
  projectInfoEnum.otherComments,
  projectInfoEnum.strategy,
];

/**
 * Converting row data to string that is concatenated with comma(,)
 * @param rows All Row Data(Task data filtered by table)
 */
function convertProjectsInfoToString(rows: Project[]) {
  return rows
    .map((v) =>
      [
        // v.name,
        // v.serviceType?.name || "",
        // getDate(DateOrNull(v.publishedDate)),
        // v.publisher?.fullName || "",
        // v.description,
        // v.projectInfo.projectName,
        // v.country,
        // v.state,
        // v.city,
        // v.projectInfo.projectName,
        // v.projectInfo.size,
        // v.projectInfo.publishedCost,
        // v.projectInfo.owner?.name || "",
        // v.projectInfo.architect?.name || "",
        // v.projectInfo.generalContractor?.name || "",
        // getDate(DateOrNull(v.projectInfo.overallStartDate)),
        // getDate(DateOrNull(v.projectInfo.overallEndDate)),
        // v.projectInfo.website,
        // v.teamSize,
        // v.leadMember?.fullName || "",
        // `"${v.projectAssignees?.map((v) => v.fullName).join(",")}"`,
        // v.phase?.name || "",
        // getDate(DateOrNull(v.startDate)),
        // getDate(DateOrNull(v.endDate)),
        // v.client?.name || "",
        // `"${v.clientContacts?.map((v) => v.name).join(",")}"`,
        // v.mainTool?.name || "",
        // v.subTool?.name || "",
        // v.remark,
        // v.contractValue,
        // v.paymentTerm?.name || "",
        // v.complexityLevel?.name || "",
        // v.risk?.name || "",
        // v.riskDescription,
        // v.otherComments,
        // v.strategy,
      ].join(","),
    )
    .join("\n");
}

/**
 * Genertaing headers for csv file
 */
function renderProjectInfoHeader() {
  return ProjectInfoOrder.join(",") + "\n";
}

export function allProjectInfoExportCsv(data: Project[]) {
  const date = new Date();
  var fileName =
    "All project information_" +
    date.getFullYear().toString() +
    (date.getMonth() + 1).toString() +
    date.getDate().toString();

  var blob = new Blob(["\uFEFF" + renderProjectInfoHeader() + convertProjectsInfoToString(data)], {
    type: "text/csv; charset=utf-8",
  });
  var encodedUri = URL.createObjectURL(blob);
  var link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", fileName + ".csv");
  document.body.appendChild(link);
  link.click(); // execute to download
}

// order
const order = [
  TaskInfoEnum.projectId,
  TaskInfoEnum.assignees,
  TaskInfoEnum.name,
  TaskInfoEnum.categoryId,
  TaskInfoEnum.duration,
  TaskInfoEnum.endDate,
  TaskInfoEnum.description,
  TaskInfoEnum.statusId,
  TaskInfoEnum.plannedStartDate,
  TaskInfoEnum.plannedEndDate,
  TaskInfoEnum.createdAt,
  TaskInfoEnum.createdBy,
];

/**
 * Legacy - 2020-04-11
 * Get all information(user, task-status, task-category, projects) from apollo cache data
 * and return dictionary type for each information
 */
// function getIdValuePairs() {
//     const users = apollo.readQuery<GetUsers>({ query: GET_USERS });
//     const taskCategories = apollo.readQuery<GetTaskCategoryTypes>({
//         query: GET_TASK_CATEGORY_TYPES,
//     });
//     const projects = apollo.readQuery<GetProjects>({ query: GET_PROJECTS });

//     const taskStatuses = apollo.readQuery<GetAllTaskStatusTypes>({
//         query: GET_ALL_TASK_TYPES,
//     });

//     var userDict = {};
//     var statusDict = {};
//     var categoryDict = {};
//     var projectDict = {};

//     if (users && users.GetUsers.users) {
//         users.GetUsers.users.forEach((v) => (userDict[v.id] = v.fullName));
//     }
//     if (taskStatuses && taskStatuses.GetAllTaskStatusTypes.taskStatusTypes) {
//         taskStatuses.GetAllTaskStatusTypes.taskStatusTypes.forEach(
//             (v) => (statusDict[v.id] = v.name)
//         );
//     }
//     if (taskCategories && taskCategories.GetTaskCategoryTypes.taskCategoryTypes) {
//         taskCategories.GetTaskCategoryTypes.taskCategoryTypes.forEach(
//             (v) => (categoryDict[v.id] = v.name)
//         );
//     }
//     if (projects && projects.GetProjects.projects) {
//         projects.GetProjects.projects.forEach((v) => (projectDict[v.id] = v.name));
//     }

//     return { userDict, statusDict, categoryDict, projectDict };
// }

/**
 * Get current user full name from apollo cache data
 */
export function getCurrentUserName() {
  const currentUser = apollo.readQuery<GetCurrentUserQuery>({
    query: GET_CURRENT_USER,
  });
  var currentUserName = "";
  if (currentUser && currentUser.GetCurrentUser.user) {
    currentUserName = currentUser.GetCurrentUser.user.fullName;
  }
  return currentUserName;
}

/**
 * Converting row data to string that is concatenated with comma(,)
 * @param rows All Row Data(Task data filtered by table)
 */
function convertRowsToString(rows: Task[]) {
  return rows
    .map((v) =>
      [
        `"${v.project?.name}"`,
        `"${v.assignees?.map((v) => v.fullName).join(",")}"`,
        `"${v.name}"`,
        `"${v.category?.name}"`,
        v.duration || 0,
        v.endDate ? convertDatetimeForm(v.endDate) : "",
        v.description ? `"${v.description}"` : "",
        `"${v.status?.name}"`,
        v.plannedStartDate ? convertDatetimeForm(v.plannedStartDate) : "",
        v.plannedEndDate ? convertDatetimeForm(v.plannedEndDate) : "",
        convertDatetimeForm(v.createdAt),
        `"${v.createdBy?.fullName}"`,
      ].join(","),
    )
    .join("\n");
}

/**
 * Genertaing headers for csv file
 */
function renderHeader() {
  return order.join(",") + "\n";
}

export const HEADER_KEYS = [
  "ID",
  "Task",
  "Assignee",
  "Planned Start Date",
  "Planned End Date",
  "Description",
  "Status",
  "Project",
  "Category",
  "Phase",
  "Milestone",
  "Completed",
  "Time Spent(m)",
  "CreatedBy",
];

interface ITaskByFilter {
  assigneesIds: string[];
  categoryId: number;
  createdAt: string;
  createdById: string;
  description: null | string;
  duration: number;
  endDate: string;
  id: number;
  milestoneId: null | number;
  name: string;
  phaseId: null | number;
  plannedEndDate: string;
  plannedStartDate: string;
  projectId: string;
  startDate: null | string;
  statusId: number;
  timesheets: null | any; // timesheets에 대한 타입이 명확하지 않으면 any로 지정
  updatedAt: string;
}

interface ITaskResult {
  ID: number;
  Task: string;
  Assignee: string[];
  PlannedStartDate: string;
  PlannedEndDate: string;
  Description: string | null;
  Status: string;
  Project: string;
  Category: string;
  Phase: string | null;
  Milestone: string | null;
  Completed: string;
  TimeSpent: number;
  CreatedBy: string;
}

const processRow = (v: any) => {
  return HEADER_KEYS.map((key) => {
    const isStringType = !["ID", "Planned Start Date", "Planned End Date", "Completed", "Time Spent(m)"].includes(key);
    return isStringType ? `"${v[key]}"` : v[key];
  }).join(",");
};

/**
 * Export CSV File(download)
 * @param rows All task data to export
 * @param currentProjectName current project name(default:null)
 */
export function exportCsv(rows: Task[], type: "project" | "myTasks" | "allTasks", projectName?: string) {
  // set file name
  var fileName = "";

  switch (type) {
    case "project":
      fileName = projectName!.match(/\S+/g)!.join("_");
      break;
    case "myTasks":
      fileName = getCurrentUserName().match(/\S+/g)!.join("_");
      break;
    default:
      break;
  }

  var blob = new Blob(["\uFEFF" + renderHeader() + convertRowsToString(rows)], {
    type: "text/csv; charset=utf-8",
  });
  var encodedUri = URL.createObjectURL(blob);
  var link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", fileName + ".csv");
  document.body.appendChild(link);
  link.click(); // execute to download
}

//현재 프로젝트의 Task의 데이터를 CSV로 다운로드하는 function
export function exportProjectTasksInCSV(
  header: string,
  rows: string[],
  projectName: string,
  currentUser: string,
  currentWorkspace: string,
) {
  var fileName = currentWorkspace + ":" + projectName;
  var blob = new Blob(["\uFEFF" + header + "\n" + rows.join("\n")], {
    type: "text/csv; charset=utf-8",
  });
  var encodedUri = URL.createObjectURL(blob);
  var link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", fileName + ".csv");
  document.body.appendChild(link);
  link.click(); // execute to download

  ExportCsvRecordToBlockchain(currentWorkspace, projectName, currentUser);
}

//assignees Ids를 받아서 assignees의 이름을 반환하는 function

//현재 프로젝트의 Task중 Filering한 데이터를 CSV로 다운로드하는 function
export function exportProjectFilteringTaskInCSV(
  header: string,
  rows: string[],
  projectName: string,
  currentUser: string,
  currentWorkspace: string,
  assignee: string[],
  filterStartDate: string,
  filterEndDate: string,
) {
  const momentStartDate = moment(filterStartDate);
  const momentEndDate = moment(filterEndDate);

  // Validate the dates using moment's isValid function
  const validFilterStartDate = momentStartDate.isValid() ? momentStartDate.format("YYYY-MM-DD") : "";
  const validFilterEndDate = momentEndDate.isValid()
    ? momentEndDate.format("YYYY-MM-DD")
    : moment().format("YYYY-MM-DD");

  const validDate = `(${validFilterStartDate}~${validFilterEndDate})`;

  var blob = new Blob(["\uFEFF" + header + "\n" + rows.join("\n")], {
    type: "text/csv; charset=utf-8",
  });

  var encodedUri = URL.createObjectURL(blob);
  var link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", `${currentWorkspace} : ${projectName} [${assignee}] ${validDate}.csv`);
  document.body.appendChild(link);
  link.click(); // execute to download
  ExportFilteredCsvRecordToBlockchain(currentWorkspace, projectName, currentUser, validDate);
}

import React from "react";
import { Paper, List, ListItem, ListItemText, Typography } from "@mui/material";
import useStyles from "./styles";
import { useLocales } from "../../../hooks";

type Props<T extends string | symbol | number> = {
  navigationList: T[];
  contentList?: { [key in T]: any };
  currentSelected: T;
  setSelected: React.Dispatch<React.SetStateAction<T>>;
};

export default function SettingNavigator<T extends string | symbol | number>(props: Props<T>) {
  const { translate } = useLocales();
  const classes = useStyles();

  return (
    <div style={{ display: "flex" }}>
      <div className={classes.leftSide}>
        <Paper className={classes.navigation}>
          <List className={classes.navigationList}>
            {/* List map function */}
            {Object.values(props.navigationList).map((v, i) => (
              <ListItem
                button
                divider
                className={classes.navigationListItem}
                onClick={() => props.setSelected(v)}
                key={i}
              >
                {/* Selected indicator */}
                {props.currentSelected === v ? <div className={classes.selectedItem}></div> : null}

                {/* ListItem Text */}
                <ListItemText disableTypography>
                  <Typography color="textPrimary" variant="subtitle1">
                    {translate(v as string)}
                  </Typography>
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </Paper>
      </div>
      {props.contentList && (
        <div className={classes.rightSide}>
          <Paper className={classes.contentPaper}>
            {props.contentList && props.contentList![props.currentSelected as string | symbol | number]}
          </Paper>
        </div>
      )}
    </div>
  );
}

import { useState } from "react";
import {
  Milestone,
  Project,
  ProjectAssignee,
  ProjectPhase,
  ProjectTaskCategory,
  ProjectTaskStatus,
  Task,
  TaskStatus,
  WorkspaceUser,
} from "../../../generated/graphql";
import { useTypedSelector } from "../../../hooks";
import { getById, getListById, getListByIds } from "../../helper/projection";
import { closeTaskDetailModal, deleteTask, updateTask, updateTask_ } from "../../../Store/reducers/taskReducer";
import { timespentNumberToString, timespentStringToNumber } from "../../../utils";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import moment from "moment";

type FocusField =
  | "project"
  | "category"
  | "phase"
  | "milestone"
  | "assignee"
  | "start-date"
  | "due-date"
  | "completed"
  | "time-spent"
  | "none";

export function useTaskDetail() {
  const [loading, setLoading] = useState(true);
  const [focus, setFocus] = useState<FocusField>("none");

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const {
    tasks: tasksState,
    isTaskDetailModalOpen,
    selectedTask: { taskId },
  } = useTypedSelector((state) => state.tasks);
  const { projects: projectsState } = useTypedSelector((state) => state.projects);
  const { projectMembers: projectMembersState } = useTypedSelector((state) => state.projectMembers);
  const { members: membersState } = useTypedSelector((state) => state.members);
  const { projectPhases: projectPhasesState, projectMilestones: projectMilestonesState } = useTypedSelector(
    (state) => state.projectTimeline,
  );
  const { taskCategories: taskCategoriesState } = useTypedSelector((state) => state.projectTaskCategories);
  const { taskStatuses: taskStatusesState } = useTypedSelector((state) => state.projectTaskStatuses);

  const getProject = (projectId?: string) => getById<Project>(projectsState, projectId, "byId");
  const getPhase = (phaseId?: number | null) => getById<ProjectPhase>(projectPhasesState, phaseId, "byId");
  const getMilestone = (milestoneId?: number | null) => getById<Milestone>(projectMilestonesState, milestoneId, "byId");
  const getCategory = (categoryId?: number) => getById<ProjectTaskCategory>(taskCategoriesState, categoryId, "byId");
  const getStatus = (statusId?: number) => getById<ProjectTaskStatus>(taskStatusesState, statusId, "byId");

  const getMembers = (projectId?: string) =>
    getListByIds<WorkspaceUser>(
      membersState,
      "byId",
      (getListById<ProjectAssignee>(projectMembersState, "byProject", "byId", projectId) || []).map((v) => v.userId!),
    );
  const getPhases = (projectId?: string) =>
    getListById<ProjectPhase>(projectPhasesState, "byProject", "byId", projectId) || [];
  const getMilestones = (input: { projectId?: string; phaseId?: number | null }) => {
    if (input.phaseId) {
      return getListById<Milestone>(projectMilestonesState, "byPhase", "byId", input.phaseId) || [];
    }
    return getListById<Milestone>(projectMilestonesState, "byProject", "byId", input.projectId) || [];
  };
  const getCategories = (projectId?: string) =>
    getListById<ProjectTaskCategory>(taskCategoriesState, "byProject", "byId", projectId) || [];
  const getStatuses = (projectId?: string) =>
    getListById<ProjectTaskStatus>(taskStatusesState, "byProject", "byId", projectId) || [];

  /**
   * States of task
   */
  const task = getById<Task>(tasksState, taskId, "byId");

  const [name, setName] = useState(task?.name);
  const [description, setDescription] = useState(task?.description);
  const [project, setProject] = useState(getProject(task?.projectId));
  const [phase, setPhase] = useState(getPhase(task?.phaseId));
  const [milestone, setMilestone] = useState(getMilestone(task?.milestoneId));
  const [category, setCategory] = useState(getCategory(task?.categoryId));
  const [status, setStatus] = useState(getStatus(task?.statusId));
  const [assignees, setAssignees] = useState(
    getListByIds<WorkspaceUser>(membersState, "byId", task?.assigneesIds || []),
  );
  const [startDate, setStartDate] = useState(task?.plannedStartDate);
  const [dueDate, setDueDate] = useState(task?.plannedEndDate);
  const [completed, setCompleted] = useState(task?.endDate);
  const [timespent, setTimespent] = useState(timespentNumberToString(task?.duration));

  /**
   * Errors
   */
  const [nameError, setNameError] = useState("");
  const [startDateError, setStartDateError] = useState("");
  const [dueDateError, setDueDateError] = useState("");
  const [timespentError, setTimeSpentError] = useState("");

  /**
   * Options
   */
  const [projects] = useState(getListById<Project>(projectsState, "allIds", "byId") || []);
  const [members, setMembers] = useState(getMembers(task?.projectId));
  const [phases, setPhases] = useState(getPhases(task?.projectId));
  const [milestones, setMilestones] = useState(getMilestones({ projectId: task?.projectId, phaseId: task?.phaseId }));
  const [statuses, setStatuses] = useState(getStatuses(task?.projectId));
  const [categories, setCategories] = useState(getCategories(task?.projectId));

  /**
   * Actions
   */
  const updateName = async (name: string) => {
    try {
      setName(name);
      unsetFocus();
      await dispatch(updateTask_({ id: task!.id, name }));
    } catch (error) {
      if (error instanceof Error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } else {
        enqueueSnackbar("updateTask Error", { variant: "error" });
      }
    }
  };
  const updateDescription = async (description: string) => {
    try {
      setDescription(description);
      unsetFocus();
      await dispatch(updateTask_({ id: task!.id, description }));
    } catch (error) {
      if (error instanceof Error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } else {
        enqueueSnackbar("updateTask Error", { variant: "error" });
      }
    }
  };
  const updateProject = async (projectId: string) => {
    try {
      const _status = getStatuses(projectId)[0];
      const _category = getCategories(projectId)[0];

      setProject(getProject(projectId));
      setPhase(null);
      setMilestone(null);
      setAssignees([]);
      setStatus(_status);
      setCategory(_category);

      setPhases(getPhases(projectId));
      setMilestones(getMilestones({ projectId }));
      setMembers(getMembers(projectId));
      setStatuses(getStatuses(projectId));
      setCategories(getCategories(projectId));

      unsetFocus();
      await dispatch(
        updateTask_({
          id: task!.id,
          projectId,
          phaseId: null,
          milestoneId: null,
          assigneesIds: [],
          statusId: _status.id,
          categoryId: _category.id,
        }),
      );
    } catch (error) {
      enqueueSnackbar((error as Error).message, { variant: "error" });
    }
  };
  const updatePhase = async (phaseId: number | null) => {
    try {
      setPhase(getPhase(phaseId));
      setMilestone(null);

      setMilestones(getMilestones({ phaseId }));

      unsetFocus();
      await dispatch(updateTask_({ id: task!.id, phaseId: phaseId, milestoneId: null }));
    } catch (error) {
      enqueueSnackbar((error as Error).message, { variant: "error" });
    }
  };
  const updateMilestone = async (milestoneId: number | null) => {
    try {
      setMilestone(getMilestone(milestoneId));

      unsetFocus();
      await dispatch(updateTask_({ id: task!.id, milestoneId }));
    } catch (error) {
      enqueueSnackbar((error as Error).message, { variant: "error" });
    }
  };
  const updateCategory = async (categoryId: number) => {
    try {
      setCategory(getCategory(categoryId));

      unsetFocus();
      await dispatch(updateTask_({ id: task!.id, categoryId }));
    } catch (error) {
      enqueueSnackbar((error as Error).message, { variant: "error" });
    }
  };
  const updateStatus = async (status: ProjectTaskStatus) => {
    try {
      setStatus(getStatus(status.id));

      unsetFocus();
      await dispatch(updateTask_({ id: task!.id, statusId: status.id }));

      // Status 가 done으로 바뀌면 complete 시간을 자동으로 입력하고, focus
      if (status.keyStatus === TaskStatus.Done) {
        setCompleted(new Date());
        setFocus("completed");
      }
    } catch (error) {
      enqueueSnackbar((error as Error).message, { variant: "error" });
    }
  };
  const updateAssignees = async (ids: string[]) => {
    try {
      setAssignees(getListByIds<WorkspaceUser>(membersState, "byId", ids));

      unsetFocus();
      await dispatch(updateTask_({ id: task!.id, assigneesIds: ids }));
    } catch (error) {
      enqueueSnackbar((error as Error).message, { variant: "error" });
    }
  };
  const changeStartDate = (startDate: Date) => {
    setStartDate(startDate);
  };
  const updateStartDate = async () => {
    try {
      unsetFocus();
      await dispatch(updateTask_({ id: task!.id, plannedStartDate: startDate }));

      // 기존 시간 간격으로 그대로 늘려서 end date 저장
      if (task?.plannedStartDate && task?.plannedEndDate) {
        const _start = moment(task.plannedStartDate);
        const _end = moment(task.plannedEndDate);

        const _duration = moment.duration(_end.diff(_start));
        const _newEnd = moment(startDate).add(_duration).toDate();

        setDueDate(_newEnd);
        await dispatch(updateTask_({ id: task!.id, plannedEndDate: _newEnd }));
      } else {
        if (startDate) {
          const _newEnd = moment(startDate).add(30, "m").toDate();
          setDueDate(_newEnd);
          await dispatch(updateTask_({ id: task!.id, plannedEndDate: _newEnd }));
        }
      }
    } catch (error) {
      enqueueSnackbar((error as Error).message, { variant: "error" });
    }
  };
  const changeDueDate = async (dueDate: Date) => {
    setDueDate(dueDate);
  };
  const updateDueDate = async () => {
    try {
      unsetFocus();
      await dispatch(updateTask_({ id: task!.id, plannedEndDate: dueDate }));
    } catch (error) {
      enqueueSnackbar((error as Error).message, { variant: "error" });
    }
  };
  const changeCompleted = async (completedDate: Date) => {
    setCompleted(completedDate);
  };
  const updateCompleted = async () => {
    try {
      unsetFocus();
      await dispatch(updateTask_({ id: task!.id, endDate: completed }));
    } catch (error) {
      enqueueSnackbar((error as Error).message, { variant: "error" });
    }
  };
  const changeTimespent = (timespent: string) => {
    try {
      timespentStringToNumber(timespent);
      setTimeSpentError("");
    } catch (error) {
      setTimeSpentError("Time spent must be in the format '3h 35m'");
    }

    setTimespent(timespent);
  };
  const updateTimespent = async () => {
    try {
      if (!Boolean(timespentError)) {
        const _ = timespentStringToNumber(timespent);
        setTimespent(timespent);

        unsetFocus();
        await dispatch(updateTask_({ id: task!.id, duration: _ }));
      }
    } catch (error) {
      enqueueSnackbar((error as Error).message, { variant: "error" });
    }
  };

  const checkNecessaryTypeIsNull = (types: any[]) => {
    return types.some((v) => v === null);
  };

  const unsetFocus = () => setTimeout(() => setFocus("none"), 0);

  const deleteThis = () => {
    try {
      dispatch(closeTaskDetailModal());
      dispatch(deleteTask(task!.id, task!.projectId));
    } catch (error) {
      if (error instanceof Error) {
        enqueueSnackbar(error.message, { variant: "error" });
      } else {
        enqueueSnackbar("delete task error", { variant: "error" });
      }
    }
  };

  return {
    loading: checkNecessaryTypeIsNull([project, category, status]),
    open: isTaskDetailModalOpen,
    close: () => {
      dispatch(closeTaskDetailModal());
    },
    focus,
    setFocus: (value: FocusField) => setFocus(value),
    unsetFocus: () => setTimeout(() => setFocus("none"), 0),
    error: {
      nameError,
      startDateError,
      dueDateError,
      timespentError,
    },
    task: {
      id: task!.id,
      name: name!,
      description,
      project: project!,
      phase,
      milestone,
      category: category!,
      status: status!,
      assignees,
      startDate,
      dueDate,
      completed,
      timespent,
    },
    options: {
      projects,
      phases,
      milestones,
      statuses,
      categories,
      members,
    },
    actions: {
      changeStartDate,
      changeDueDate,
      changeCompleted,
      changeTimespent,
      updateName,
      updateDescription,
      updateProject,
      updatePhase,
      updateMilestone,
      updateCategory,
      updateStatus,
      updateAssignees,
      updateStartDate,
      updateDueDate,
      updateCompleted,
      updateTimespent,
      deleteTask: deleteThis,
    },
  };
}

import React from "react";

import { Box, Theme, useMediaQuery, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import type { FC } from "react";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

interface DetailRowProps {
  header?: React.ReactNode;
  element?: React.ReactNode;
}

const DetailRow: FC<DetailRowProps> = ({ header, element, children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      display="flex"
      alignItems={mobileDevice ? "start" : "center"}
      flexDirection={mobileDevice ? "column" : "row"}
      mt={mobileDevice ? 1 : 0}
    >
      {children}
    </Box>
  );
};

export default DetailRow;

export type CurrencyCode = {
  currency: string;
  code: string;
  symbol: string;
  countryCode: string;
};

export let currencyMap: { [code: string]: CurrencyCode } = {
  USD: {
    currency: "United States dollar",
    code: "USD",
    symbol: "$",
    countryCode: "US",
  },
  EUR: {
    currency: "Euro",
    code: "EUR",
    symbol: "€",
    countryCode: "EU",
  },
  JPY: {
    currency: "Japanese yen",
    code: "JPY",
    symbol: "¥",
    countryCode: "JP",
  },
  GBP: {
    currency: "Pound sterling",
    code: "GBP",
    symbol: "£",
    countryCode: "GB",
  },
  AUD: {
    currency: "Australian dollar",
    code: "AUD",
    symbol: "A$",
    countryCode: "AU",
  },
  CAD: {
    currency: "Canadian dollar",
    code: "CAD",
    symbol: "C$",
    countryCode: "CA",
  },
  CHF: {
    currency: "Swiss franc",
    code: "CHF",
    symbol: "CHF",
    countryCode: "CH",
  },
  CNY: {
    currency: "Renminbi",
    code: "CNY",
    symbol: "元",
    countryCode: "CN",
  },
  HKD: {
    currency: "Hong Kong dollar",
    code: "HKD",
    symbol: "HK$",
    countryCode: "HK",
  },
  NZD: {
    currency: "New Zealand dollar",
    code: "NZD",
    symbol: "NZ$",
    countryCode: "NZ",
  },
  SEK: {
    currency: "Swedish krona",
    code: "SEK",
    symbol: "kr",
    countryCode: "SE",
  },
  KRW: {
    currency: "South Korean won",
    code: "KRW",
    symbol: "₩",
    countryCode: "KR",
  },
  SGD: {
    currency: "Singapore dollar",
    code: "SGD",
    symbol: "S$",
    countryCode: "SG",
  },
  NOK: {
    currency: "Norwegian krone",
    code: "NOK",
    symbol: "kr",
    countryCode: "NO",
  },
  MXN: {
    currency: "Mexican peso",
    code: "MXN",
    symbol: "$",
    countryCode: "MX",
  },
  INR: {
    currency: "Indian rupee",
    code: "INR",
    symbol: "₹",
    countryCode: "IN",
  },
  RUB: {
    currency: "Russian ruble",
    code: "RUB",
    symbol: "₽",
    countryCode: "RU",
  },
  ZAR: {
    currency: "South African rand",
    code: "ZAR",
    symbol: "R",
    countryCode: "ZA",
  },
  TRY: {
    currency: "Turkish lira",
    code: "TRY",
    symbol: "₺",
    countryCode: "TR",
  },
  BRL: {
    currency: "Brazilian real",
    code: "BRL",
    symbol: "R$",
    countryCode: "BR",
  },
  TWD: {
    currency: "New Taiwan dollar",
    code: "TWD",
    symbol: "NT$",
    countryCode: "TW",
  },
  DKK: {
    currency: "Danish krone",
    code: "DKK",
    symbol: "kr",
    countryCode: "DK",
  },
  PLN: {
    currency: "Polish złoty",
    code: "PLN",
    symbol: "zł",
    countryCode: "PL",
  },
  THB: {
    currency: "Thai baht",
    code: "THB",
    symbol: "฿",
    countryCode: "TH",
  },
  IDR: {
    currency: "Indonesian rupiah",
    code: "IDR",
    symbol: "Rp",
    countryCode: "ID",
  },
  HUF: {
    currency: "Hungarian forint",
    code: "HUF",
    symbol: "Ft",
    countryCode: "HU",
  },
  CZK: {
    currency: "Czech koruna",
    code: "CZK",
    symbol: "Kč",
    countryCode: "CZ",
  },
  ILS: {
    currency: "Israeli new shekel",
    code: "ILS",
    symbol: "₪",
    countryCode: "IL",
  },
  CLP: {
    currency: "Chilean peso",
    code: "CLP",
    symbol: "CLP$",
    countryCode: "CL",
  },
  PHP: {
    currency: "Philippine peso",
    code: "PHP",
    symbol: "₱",
    countryCode: "PH",
  },
  AED: {
    currency: "UAE dirham",
    code: "AED",
    symbol: "د.إ",
    countryCode: "AE",
  },
  COP: {
    currency: "Colombian peso",
    code: "COP",
    symbol: "COL$",
    countryCode: "CO",
  },
};

export let currencies: CurrencyCode[] = [
  {
    currency: "United States dollar",
    code: "USD",
    symbol: "$",
    countryCode: "US",
  },
  {
    currency: "Euro",
    code: "EUR",
    symbol: "€",
    countryCode: "EU",
  },
  {
    currency: "Japanese yen",
    code: "JPY",
    symbol: "¥",
    countryCode: "JP",
  },
  {
    currency: "Pound sterling",
    code: "GBP",
    symbol: "£",
    countryCode: "GB",
  },
  {
    currency: "Australian dollar",
    code: "AUD",
    symbol: "A$",
    countryCode: "AU",
  },
  {
    currency: "Canadian dollar",
    code: "CAD",
    symbol: "C$",
    countryCode: "CA",
  },
  {
    currency: "Swiss franc",
    code: "CHF",
    symbol: "CHF",
    countryCode: "CH",
  },
  {
    currency: "Renminbi",
    code: "CNY",
    symbol: "元",
    countryCode: "CN",
  },
  {
    currency: "Hong Kong dollar",
    code: "HKD",
    symbol: "HK$",
    countryCode: "HK",
  },
  {
    currency: "New Zealand dollar",
    code: "NZD",
    symbol: "NZ$",
    countryCode: "NZ",
  },
  {
    currency: "Swedish krona",
    code: "SEK",
    symbol: "kr",
    countryCode: "SE",
  },
  {
    currency: "South Korean won",
    code: "KRW",
    symbol: "₩",
    countryCode: "KR",
  },
  {
    currency: "Singapore dollar",
    code: "SGD",
    symbol: "S$",
    countryCode: "SG",
  },
  {
    currency: "Norwegian krone",
    code: "NOK",
    symbol: "kr",
    countryCode: "NO",
  },
  {
    currency: "Mexican peso",
    code: "MXN",
    symbol: "$",
    countryCode: "MX",
  },
  {
    currency: "Indian rupee",
    code: "INR",
    symbol: "₹",
    countryCode: "IN",
  },
  {
    currency: "Russian ruble",
    code: "RUB",
    symbol: "₽",
    countryCode: "RU",
  },
  {
    currency: "South African rand",
    code: "ZAR",
    symbol: "R",
    countryCode: "ZA",
  },
  {
    currency: "Turkish lira",
    code: "TRY",
    symbol: "₺",
    countryCode: "TR",
  },
  {
    currency: "Brazilian real",
    code: "BRL",
    symbol: "R$",
    countryCode: "BR",
  },
  {
    currency: "New Taiwan dollar",
    code: "TWD",
    symbol: "NT$",
    countryCode: "TW",
  },
  {
    currency: "Danish krone",
    code: "DKK",
    symbol: "kr",
    countryCode: "DK",
  },
  {
    currency: "Polish złoty",
    code: "PLN",
    symbol: "zł",
    countryCode: "PL",
  },
  {
    currency: "Thai baht",
    code: "THB",
    symbol: "฿",
    countryCode: "TH",
  },
  {
    currency: "Indonesian rupiah",
    code: "IDR",
    symbol: "Rp",
    countryCode: "ID",
  },
  {
    currency: "Hungarian forint",
    code: "HUF",
    symbol: "Ft",
    countryCode: "HU",
  },
  {
    currency: "Czech koruna",
    code: "CZK",
    symbol: "Kč",
    countryCode: "CZ",
  },
  {
    currency: "Israeli new shekel",
    code: "ILS",
    symbol: "₪",
    countryCode: "IL",
  },
  {
    currency: "Chilean peso",
    code: "CLP",
    symbol: "CLP$",
    countryCode: "CL",
  },
  {
    currency: "Philippine peso",
    code: "PHP",
    symbol: "₱",
    countryCode: "PH",
  },
  {
    currency: "UAE dirham",
    code: "AED",
    symbol: "د.إ",
    countryCode: "AE",
  },
  {
    currency: "Colombian peso",
    code: "COP",
    symbol: "COL$",
    countryCode: "CO",
  },
];

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY || "AIzaSyBj3D_19x10qCuDTxanDUMbzotDgk97vMk",
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN || "emerald-cacao-282602.firebaseapp.com",
  // databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || "emerald-cacao-282602",
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || "emerald-cacao-282602.appspot.com",
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID || "38647807880",
  appId: process.env.REACT_APP_FIREBASE_APP_ID || "1:38647807880:web:69ad30ec318024ffdb443d",
  // measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// var firebaseConfig = {
// 	apiKey: "AIzaSyCM5zxO9mfK5YRjpU3H18AD1FYKq_tFX1Y",
// 	authDomain: "wyzrs-dev.firebaseapp.com",
// 	projectId: "wyzrs-dev",
// 	storageBucket: "wyzrs-dev.appspot.com",
// 	messagingSenderId: "300176078437",
// 	appId: "1:300176078437:web:6c0657ce51897a2da8b88c"
// };

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export default firebase;

import React from "react";
import type { FC } from "react";
import {
  Box,
  FormControl,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Theme,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { currencies } from "src/types/CurrencyTypes";

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: 300,
    padding: 0,
  },
}));

interface CurrencySelectProps {
  currency: string;
  open?: boolean;
  onChange: (event: SelectChangeEvent) => void;
  onOpen?: () => void;
  onClose?: () => void;
}

const CurrencySelect: FC<CurrencySelectProps> = ({ currency, onChange, open, onOpen, onClose }) => {
  const classes = useStyles();

  return (
    <FormControl size="small" margin="dense" variant="outlined" className={classes.form}>
      <Select
        labelId="default-currency"
        id="default-currency-select-outlined"
        open={open}
        value={currency}
        onOpen={onOpen}
        onClose={onClose}
        onChange={onChange}
        style={{ padding: 0 }}
        fullWidth
        variant="standard"
      >
        {currencies.map((c) => (
          <MenuItem key={c.code} value={c.code} style={{ width: 300 }}>
            <Box
              display="flex"
              justifyContent="space-between"
              style={{ width: "100%", height: 20 }}
              alignItems="center"
            >
              <ListItemIcon style={{ paddingRight: 10 }}>
                <img
                  alt={c.code}
                  // className={classes.image}
                  style={{ width: 20 }}
                  src={`/static/countries/flags_svg/${c.countryCode.toLowerCase()}.svg`}
                />
              </ListItemIcon>
              <ListItemText>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body2">{c.currency}</Typography>
                  <Typography variant="body2">{c.symbol}</Typography>
                </Box>
              </ListItemText>
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CurrencySelect;

import React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from '@mui/material/Alert';
import { Theme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import { MessageType } from "src/types";

type Props = {
  message: string | null;
  open: boolean;
  handleClose: () => void;
  messageType: MessageType;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function CustomSnackbar(props: Props) {
  const classes = useStyles();
  const { message, open, handleClose, messageType } = props;

  return (
    <Snackbar
      open={open}
      className={classes.root}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert onClose={handleClose} severity={messageType}>
        {message}
      </Alert>
    </Snackbar>
  );
}

import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "react-perfect-scrollbar/dist/css/styles.css";

import "simplebar/src/simplebar.css";

import "react-quill/dist/quill.snow.css";
import "nprogress/nprogress.css";

import ReactDOM from "react-dom";
// React DnD
// import { DndProvider } from "react-dnd";
// import Backend from "react-dnd-html5-backend";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { ApolloProvider } from "@apollo/client";

import client from "./apollo";
import App from "./App";
import { CollapseDrawerProvider } from "./Context/CollapseDrawerContext";
import { SettingsProvider } from "./Context/SettingsContext";
import { store } from "./Store";
ReactDOM.render(
  <Provider store={store}>
    <ApolloProvider client={client}>
      <SettingsProvider>
        <CollapseDrawerProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </CollapseDrawerProvider>
      </SettingsProvider>
    </ApolloProvider>
  </Provider>,
  document.getElementById("root"),
);

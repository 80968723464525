import { FC, useState } from "react";
import { Icon } from "@iconify/react";
import { Box, Button, Paper, PaperProps, Stack, Theme, Typography, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import TimesheetRow from "./components/TimesheetRow";
import TimesheetHeader from "./components/TimesheetHeader";
import { Timesheet, TimesheetStatus } from "../../../../generated/graphql";
import { useProperties } from "../../../../Store/hooks";
import { DetailModal, UserAvatarAndName } from "../../../../Components/Molecules";
import { timespentNumberToString } from "../../../../utils";
import { Label, Scrollbar } from "../../../../Components/Atoms";
import TimesheetUserDatail from "../../TimesheetUserDetail";

interface TimesheetsListProps extends PaperProps {
  date: Date;
  timesheets: Timesheet[] | null;
  onReject: (id: number) => void;
  onAccept: (id: number) => void;
}

const TimesheetsList: FC<TimesheetsListProps> = ({ date, timesheets, onAccept, onReject, ...others }) => {
  const theme = useTheme();
  const { getUserById } = useProperties();

  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState("");

  const userComponent = (id?: string | null) => {
    const _user = getUserById(id);

    if (id == null || _user == null) {
      return (
        <Typography variant="subtitle2" color="textPrimary" textAlign="center">
          {" "}
          Error{" "}
        </Typography>
      );
    }

    return (
      <Stack sx={{ pl: 1 }}>
        <UserAvatarAndName member={_user} size="small" />
      </Stack>
    );
  };

  const timespentComponent = (value?: number | null) => {
    if (value == null) {
      return (
        <Typography variant="subtitle2" textAlign="center" sx={{ color: theme.palette.primary.main }}>
          -
        </Typography>
      );
    }

    return (
      <Typography variant="subtitle2" textAlign="center" sx={{ color: theme.palette.text.primary }}>
        {timespentNumberToString(value)}
      </Typography>
    );
  };

  const statusComponent = (id: number, value: TimesheetStatus) => {
    var _color: "default" | "primary" | "secondary" | "info" | "success" | "warning" | "error" = "default";

    if (value === TimesheetStatus.Pending) {
      return (
        <Stack direction={"row"} justifyContent="center" alignItems="center" sx={{ width: "100%" }} spacing={2}>
          <Label sx={{ maxWidth: "80px" }} color={_color}>
            {value}
          </Label>
          <Button variant="outlined" size="small" onClick={() => onAccept(id)}>
            Accept
          </Button>
          <Button variant="outlined" size="small" color="error" onClick={() => onReject(id)}>
            Reject
          </Button>
        </Stack>
      );
    }

    if (value === TimesheetStatus.Accepted) _color = "success";
    if (value === TimesheetStatus.Canceled) _color = "warning";
    if (value === TimesheetStatus.Rejected) _color = "error";

    return (
      <Stack direction={"row"} justifyContent="center" alignItems="center" sx={{ width: "100%" }} spacing={2}>
        <Label sx={{ maxWidth: "80px" }} color={_color}>
          {value}
        </Label>
      </Stack>
    );
  };

  const tasksButton = (userId?: string | null) => {
    return (
      <Stack direction={"row"} justifyContent="center" alignItems="center" sx={{ width: "100%" }}>
        <IconButton size="small" sx={{ width: "30px", height: "30px" }} onClick={() => handleClickDetailButton(userId)}>
          <Icon icon={"bi:list-ul"} width={24} height={24} />
        </IconButton>
      </Stack>
    );
  };

  const handleClickDetailButton = (userId?: string | null) => {
    if (userId) {
      setUserId(userId);
      setOpen(true);
    }
  };

  return (
    <>
      <Paper sx={{ p: 1, width: "100%" }} {...others}>
        <TimesheetHeader date={date} />
        {timesheets?.map((v) => {
          return (
            <TimesheetRow
              key={v.id}
              values={[
                userComponent(v.user?.id),
                timespentComponent(v.sunday),
                timespentComponent(v.monday),
                timespentComponent(v.tuesday),
                timespentComponent(v.wednesday),
                timespentComponent(v.thursday),
                timespentComponent(v.friday),
                timespentComponent(v.saturday),
                timespentComponent(v.totalTimeSpent),
                statusComponent(v.id, v.status),
                tasksButton(v.user?.id),
              ]}
            />
          );
        })}
      </Paper>
      {open && (
        <DetailModal fullWidth maxWidth="lg" open={open} header={"Tasks"} onClose={() => setOpen(false)} width={"100%"}>
          <Box sx={{ minWidth: "800px" }}>
            <Scrollbar>
              <TimesheetUserDatail userId={userId} date={date} />
            </Scrollbar>
          </Box>
        </DetailModal>
      )}
    </>
  );
};

export default TimesheetsList;

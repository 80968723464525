import React, { useRef, useState } from "react";
import { Check as CheckIcon, ChevronDown as ChevronDownIcon } from "react-feather";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { LoadingScreen } from "src/Components/Molecules";
import useAuth from "src/hooks/useAuth";
import { useTypedSelector } from "src/hooks/useTypedSelector";
import { changeWorkspace } from "src/Store/reducers/workspaceReducer";
import { Box, Button, ButtonBase, Divider, Popover, SvgIcon, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useLocales } from "../../../../../hooks";
import WorkspaceListItem from "./WorkspaceListItem";
import type { FC } from "react";

const useStyles = makeStyles((theme: Theme) => ({
  buttonBase: {
    width: "100%",
    height: "100%",
    display: "block",
  },
  popOverHeader: {
    backgroundColor: theme.palette.background.paper,
  },
  popOverContent: {
    width: 240,
    height: "100%",
  },
  workspaceList: {
    width: "100%",
    maxHeight: 300,
  },
  workspaceListItem: {
    width: "100%",
    cursor: "pointer",
  },
  workspacesButton: {
    width: "100%",
  },
}));

interface WorkspaceConvertingItemProps {
  className?: string;
}

const WorkspaceConvertingItem: FC<WorkspaceConvertingItemProps> = ({ className }) => {
  const { translate } = useLocales();
  const navigate = useNavigate();
  const classes = useStyles();
  const ref = useRef<any>(null);
  const { user } = useAuth();
  const { workspace } = useTypedSelector((state) => state.workspace);
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState<boolean>(false);

  if (!workspace) {
    return <LoadingScreen />;
  }

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <ButtonBase className={classes.buttonBase} onClick={handleOpen} ref={ref}>
        <Box display={"flex"} alignItems="center">
          <WorkspaceListItem workspaceItem={workspace!} />
          <Box flexGrow={1} />
          <SvgIcon fontSize="small">
            <ChevronDownIcon />
          </SvgIcon>
        </Box>
      </ButtonBase>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        style={{ zIndex: 1400 }}
      >
        <Box p={1} pl={2} className={classes.popOverHeader}>
          <Typography variant="body2">{translate("workspace-change-popup.select-a-workspace")}</Typography>
        </Box>
        <Divider />
        <Box p={1} className={classes.popOverContent}>
          <PerfectScrollbar options={{ suppressScrollX: true }}>
            <Box p={1} display="flex" flexDirection="column" alignItems="flex-start" className={classes.workspaceList}>
              {user?.workspaces.map((w) => (
                <ButtonBase
                  style={{
                    width: "100%",
                    marginBottom: 5,
                  }}
                  key={w.id}
                  onClick={async () => {
                    const to = await dispatch(changeWorkspace(undefined, w.id));
                    navigate(String(to));
                  }}
                >
                  <Box
                    display="flex"
                    alignSelf="stretch"
                    justifyContent="flex-start"
                    alignItems="center"
                    className={classes.workspaceListItem}
                  >
                    <WorkspaceListItem workspaceItem={w} size="small" />
                    <Box flexGrow={1} />
                    {w.id === workspace.id && (
                      <SvgIcon fontSize="small">
                        <CheckIcon />
                      </SvgIcon>
                    )}
                  </Box>
                </ButtonBase>
              ))}
            </Box>
          </PerfectScrollbar>
          <Divider />
          <Box p={1}>
            <Button
              variant="outlined"
              className={classes.workspacesButton}
              color="primary"
              component={RouterLink}
              to={"/user-workspaces"}
            >
              {translate("workspace-change-popup.go-to-workspaces")}
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default WorkspaceConvertingItem;

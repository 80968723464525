import React from "react";
import type { FC } from "react";
import { Box, Typography } from "@mui/material";
import { Emoji } from "src/Components/Atoms";

interface UserStatusOptionProps {
  emoji: string;
  text: string;
  className?: string;
}
const UserStatusOption: FC<UserStatusOptionProps> = ({ emoji, text, className }) => {
  return (
    <Box display="flex" alignItems="center" style={{ lineHeight: "30px", width: "100%" }} className={className}>
      <Box pl={1} pr={1} style={{ marginBottom: -3 }}>
        <Emoji symbol={emoji} />
      </Box>
      <Box pl={2} pr={1}>
        <Typography variant="body1">{text}</Typography>
      </Box>
    </Box>
  );
};

export default UserStatusOption;

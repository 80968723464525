import React from "react";
import { Codepen, Flag, Hexagon, Pocket } from "react-feather";

import { Box, Breadcrumbs, SvgIcon, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { Milestone, ProjectPhase } from "../../../../../generated/graphql";
import { useTypedSelector } from "../../../../../hooks";
import { getById } from "../../../../../Store/helper/projection";
import { getDefaultColorCodes } from "../../../../../theme/colors";
import { Project, Task } from "../../../../../types";

import type { FC } from "react";
const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

interface DetailBreadcrumbHeaderProps {
  projectId: string;
  phaseId?: number | null;
  milestoneId?: number | null;
  taskId?: number | null;
}

interface ItemProps {
  value: any;
  category: "project" | "phase" | "milestone" | "task";
  color?: string | null;
}

const Item: FC<ItemProps> = ({ category, color, value }) => {
  return (
    <Box display="flex" alignItems="center">
      <Box
        display="flex"
        style={{ backgroundColor: color || getDefaultColorCodes() }}
        borderRadius="2px"
        width="15px"
        height="15px"
        alignItems="center"
        justifyContent="center"
        mr={0.5}
      >
        {category === "project" && <Codepen size="12px" strokeWidth="2px" color="white" />}
        {category === "phase" && <Hexagon size="12px" strokeWidth="2px" color="white" />}
        {category === "milestone" && <Flag size="12px" strokeWidth="2px" color="white" />}
        {category === "task" && <Pocket size="12px" strokeWidth="2px" color="white" />}
      </Box>

      <div style={{ width: "100px", whiteSpace: "nowrap" }}>
        <Box component="div" textOverflow="ellipsis" overflow="hidden">
          <Typography variant="caption">{value}</Typography>
        </Box>
      </div>
    </Box>
  );
};

const DetailBreadcrumbHeader: FC<DetailBreadcrumbHeaderProps> = ({ projectId, phaseId, milestoneId, taskId }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down("md"));

  const { projects } = useTypedSelector((state) => state.projects);
  const { projectPhases, projectMilestones } = useTypedSelector((state) => state.projectTimeline);
  const { tasks } = useTypedSelector((state) => state.tasks);

  const project = getById<Project>(projects, projectId);
  const phase = getById<ProjectPhase>(projectPhases, phaseId);
  const milestone = getById<Milestone>(projectMilestones, milestoneId);
  const task = getById<Task>(tasks, taskId);

  if (project === null) {
    console.error("DetailBreadcrumbHeader can't find project");
  }
  if (phase === null && phaseId) {
    console.error("DetailBreadcrumbHeader can't find phase");
  }
  if (milestone === null && milestoneId) {
    console.error("DetailBreadcrumbHeader can't find milestone");
  }
  if (task === null && taskId) {
    console.error("DetailBreadcrumbHeader can't find task");
  }

  return (
    <Breadcrumbs>
      {project && <Item value={project.name} category="project" color={project.projectColor} />}
      {phase && <Item value={phase.name} category="phase" color={phase.color} />}
      {milestone && <Item value={milestone.name} category="milestone" color={milestone.color} />}
      {task && <Item value={task.id} category="task" color={getDefaultColorCodes()} />}
    </Breadcrumbs>
  );
};

export default DetailBreadcrumbHeader;

import { Icon } from "@iconify/react";
import { FC } from "react";
import searchFill from "@iconify/icons-eva/search-fill";
// material
import { styled } from "@mui/material/styles";
import { Box, InputAdornment, OutlinedInput } from "@mui/material";
// components
// ----------------------------------------------------------------------

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: "100%",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": { boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

interface SearchbarProp {
  value: string;
  placeholder: string;
  onChange: (value: string) => void;
}

const Searchbar: FC<SearchbarProp> = ({ value, placeholder, onChange }) => {
  return (
    <SearchStyle
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder || "Search"}
      startAdornment={
        <InputAdornment position="start">
          <Box component={Icon} icon={searchFill} sx={{ color: "text.disabled" }} />
        </InputAdornment>
      }
    />
  );
};

export default Searchbar;

import React from "react";

import { Tab, Tabs, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";

interface StyledTabsProps {
  value: number;
  onChange: (event: React.ChangeEvent<{}>, newValue: any) => void;
  [key: string]: any;
}

export const StyledTabs = withStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: "33px",
    },
    indicator: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
      "& > span": {
        maxWidth: 40,
        width: "100%",
        backgroundColor: theme.palette.primary.main,
      },
    },
  }),
)((props: StyledTabsProps) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const useStyles = makeStyles({
  root: {
    minWidth: "none",
  },
});

interface StyledTabProps {
  label: string;
}

export const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: "none",
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(15),
      marginRight: theme.spacing(8),
      padding: "6px 0px",
      minWidth: "30px",
      minHeight: "30px",
      "&:hover": {
        backgroundColor: "transparent",
      },
      "&:focus": {
        opacity: 1,
      },
    },
  }),
)((props: StyledTabProps) => (
  <Tab
    disableRipple
    {...props}
    label={
      <Typography variant="subtitle2" color="textPrimary">
        {props.label}
      </Typography>
    }
  />
));

import React, { useState } from "react";
import type { FC, ReactNode } from "react";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import { Header } from "src/Components/Organisms";
import { Theme } from "@mui/material";

interface DefaultLayoutProps {
  children?: ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: 50,
    transition: "0.2s all ease",
  },
  wrapperPadding: {
    paddingLeft: 256,
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    overflow: "auto",
  },
}));

const DefaultLayout: FC<DefaultLayoutProps> = ({ children }) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState<boolean>(false);

  return (
    <div className={classes.root}>
      <Header hideWorkspaceItems onNavOpen={() => setMobileNavOpen(true)} />
      <div
        className={clsx({
          [classes.wrapper]: true,
          [classes.wrapperPadding]: isMobileNavOpen,
        })}
      >
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default DefaultLayout;

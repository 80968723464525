import React from "react";

import { Box, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import type { FC } from "react";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

interface DetailHeaderProps {
  name: string;
}

const DetailHeader: FC<DetailHeaderProps> = ({ name }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box width="150px">
      <Typography variant="subtitle2" color={theme.palette.text.secondary}>
        {name}
      </Typography>
    </Box>
  );
};

export default DetailHeader;

import { gql } from "@apollo/client";

export const F_MILESTONE = gql`
  fragment MilestoneProps on Milestone {
    id
    name
    description
    start
    end
    order
    color
    deleted
    projectId
    phaseId
    progress {
      total
      done
      inProgress
      others
    }
  }
`;

export const GET_MILESTONE = gql`
  query GetMilestone($milestoneId: Int) {
    GetMilestone(milestoneId: $milestoneId) {
      ok
      error
      milestone {
        ...MilestoneProps
      }
    }
  }
  ${F_MILESTONE}
`;

export const GET_MILESTONES = gql`
  query GetMilestones($projectId: String, $phaseId: Int) {
    GetMilestones(projectId: $projectId, phaseId: $phaseId) {
      ok
      error
      milestones {
        ...MilestoneProps
      }
    }
  }
  ${F_MILESTONE}
`;

export const CREATE_MILESTONE = gql`
  mutation CreateMilestone($name: String!, $start: DateTime, $end: DateTime!, $projectId: String!, $phaseId: Int) {
    CreateMilestone(name: $name, start: $start, end: $end, projectId: $projectId, phaseId: $phaseId) {
      ok
      error
      milestone {
        ...MilestoneProps
      }
    }
  }
  ${F_MILESTONE}
`;

export const UPDATE_MILESTONE = gql`
  mutation UpdateMilestone(
    $id: Int!
    $name: String
    $description: String
    $start: DateTime
    $end: DateTime
    $phaseId: Int
    $color: String
  ) {
    UpdateMilestone(
      id: $id
      name: $name
      description: $description
      start: $start
      end: $end
      phaseId: $phaseId
      color: $color
    ) {
      ok
      error
    }
  }
`;

export const UPDATE_MILESTONE_ORDER = gql`
  mutation UpdateMilestoneOrder($id: Int!, $position: Int!) {
    UpdateMilestoneOrder(id: $id, position: $position) {
      ok
      error
    }
  }
`;

export const DELETE_MILESTONE = gql`
  mutation DeleteMilestone($id: Int!, $removeChilds: Boolean!) {
    DeleteMilestone(id: $id, removeChilds: $removeChilds) {
      ok
      error
    }
  }
`;

import {
  CREATE_PROJECT_TASK_CATEGORY,
  DELETE_PROJECT_TASK_CATEGORY,
  GET_PROJECT_TASK_CATEGORIES,
  UPDATE_PROJECT_TASK_CATEGORY,
  UPDATE_PROJECT_TASK_CATEGORY_ORDER,
} from "src/Api/Project/ProjectTaskCategory.queries";
import client from "src/apollo";
import {
  CreateProjectTaskCategoryMutation,
  CreateProjectTaskCategoryMutationVariables,
  DeleteProjectTaskCategoryMutation,
  DeleteProjectTaskCategoryMutationVariables,
  DeleteProjectTaskPropertyInput,
  GetProjectTaskCategoriesQuery,
  GetProjectTaskCategoriesQueryVariables,
  ProjectTaskCategory,
  UpdateProjectTaskCategoryMutation,
  UpdateProjectTaskCategoryMutationVariables,
  UpdateProjectTaskCategoryOrderMutation,
  UpdateProjectTaskCategoryOrderMutationVariables,
} from "../../generated/graphql";
import { projectTaskCategorySlice as slice } from "../reducers/projectTaskCategoryReducer";
import { AppThunk } from "../store";

export const getProjectTaskCategories = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setLoaded({ loaded: false }));

  const res = await client.query<GetProjectTaskCategoriesQuery, GetProjectTaskCategoriesQueryVariables>({
    query: GET_PROJECT_TASK_CATEGORIES,
    variables: { projectId: undefined },
  });

  if (res.data.GetProjectTaskCategories.ok) {
    dispatch(slice.actions.getTaskCategories({ taskCategories: res.data.GetProjectTaskCategories.categories }));
  } else {
    console.error(res.data.GetProjectTaskCategories.error);
  }

  dispatch(slice.actions.setLoaded({ loaded: true }));
};

export const getProjectTaskCategoriesByProject = (projectId: string | null): AppThunk => async (dispatch) => {
  if (projectId) {
    const res = await client.query<GetProjectTaskCategoriesQuery, GetProjectTaskCategoriesQueryVariables>({
      query: GET_PROJECT_TASK_CATEGORIES,
      variables: {
        projectId,
      },
    });

    if (res.data.GetProjectTaskCategories.ok) {
      dispatch(slice.actions.getTaskCategories({ taskCategories: res.data.GetProjectTaskCategories.categories }));
    }
  }
};

export const createProjectTaskCategory = (projectId: string, name: string, color?: string): AppThunk => async (
  dispatch,
) => {
  const res = await client.mutate<CreateProjectTaskCategoryMutation, CreateProjectTaskCategoryMutationVariables>({
    mutation: CREATE_PROJECT_TASK_CATEGORY,
    refetchQueries: [{ query: GET_PROJECT_TASK_CATEGORIES, variables: { projectId } }],
    variables: {
      name,
      projectId,
      color,
    },
  });

  if (res.data?.CreateProjectTaskCategory.ok && res.data.CreateProjectTaskCategory.result) {
    dispatch(slice.actions.createProjectTaskCategory({ taskCategory: res.data.CreateProjectTaskCategory.result }));
  }
};

export const updateProjectTaskCategory = (taskCategory: ProjectTaskCategory): AppThunk => async (dispatch) => {
  const res = await client.mutate<UpdateProjectTaskCategoryMutation, UpdateProjectTaskCategoryMutationVariables>({
    mutation: UPDATE_PROJECT_TASK_CATEGORY,
    refetchQueries: [{ query: GET_PROJECT_TASK_CATEGORIES, variables: { projectId: taskCategory.projectId } }],
    variables: { ...taskCategory },
  });

  if (res.data?.UpdateProjectTaskCategory.ok) {
    dispatch(slice.actions.updateProjectTaskCategory({ taskCategory }));
  }
};

export const updateProjectTaskCategoryOrder = (id: number, position: number, projectId: string): AppThunk => async (
  dispatch,
) => {
  dispatch(slice.actions.updateProjectTaskCategoryOrder({ id, position }));
  client.mutate<UpdateProjectTaskCategoryOrderMutation, UpdateProjectTaskCategoryOrderMutationVariables>({
    mutation: UPDATE_PROJECT_TASK_CATEGORY_ORDER,
    refetchQueries: [{ query: GET_PROJECT_TASK_CATEGORIES, variables: { projectId } }],

    variables: {
      id,
      position,
    },
  });
};

// TODO: Key Status 외에 다른 status를 지울 경우,
// 해당 status로 할당된 task들을 다른 status로 변경해야함.
export const deleteProjectTaskCategory = (input: DeleteProjectTaskPropertyInput): AppThunk => async (dispatch) => {
  const res = await client.mutate<DeleteProjectTaskCategoryMutation, DeleteProjectTaskCategoryMutationVariables>({
    mutation: DELETE_PROJECT_TASK_CATEGORY,
    variables: {
      input,
    },
  });

  if (res.data?.DeleteProjectTaskCategory.ok) {
    dispatch(slice.actions.deleteProjectTaskCategory({ id: input.id }));
  }
};

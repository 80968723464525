import React from "react";
import { Info as InfoIcon } from "react-feather";

import { Box, Card, Grid, Paper, Theme, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import type { FC } from "react";
import Header from "../header";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    zIndex: 1,
    flexGrow: 1,
    boxShadow: "0 0 2px 0 rgba(145, 158, 171, 0.24), 0 16px 14px -4px rgba(145, 158, 171, 0.24)",
    marginTop: "20px",
  },
  helper: {
    cursor: "pointer",
  },
  helperIcon: {
    backgroundColor: "white",
  },
}));
interface BlockItem {
  parentHash: string;
  hash: string;
  timestamp: number;
  number: number;
}

interface BaseCardProps {
  name: string;
  arr: BlockItem[];
  direction?: "row" | "column";
  helperText?: string;
}

const BlockCard: FC<BaseCardProps> = ({ name, arr, direction = "row", helperText, children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down("md"));
  const itemsToRender = [...arr];
  //block hash
  //block timestamp
  //block number

  //arr를 받아서 뿌려줄것 이때 해당 배열의 순서의 역순으로 보여줄것. 최신화하기 위함
  //
  return (
    <Card className={classes.paper}>
      <Header name={name} />
      {itemsToRender.map((item, index) => (
        <Box key={index} flexGrow={1} my={1}>
          <Grid
            container
            alignItems="center"
            spacing={2}
            style={{
              borderBottom: index === itemsToRender.length - 1 ? "none" : "1px solid",
              borderColor: theme.palette.divider,
              maxHeight: "480px",
              overflow: "auto", // 스크롤바 필요시 나타나도록 설정
            }}
          >
            <Grid item xs={4}>
              <Typography variant="body1" style={{ color: theme.palette.grey[500] }}>
                ParentHash:{" "}
              </Typography>
              <Tooltip placement="top" title={item.parentHash} className={classes.helper}>
                <Typography variant="body1">{item.parentHash.slice(0, 16) + "..."}</Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1" style={{ color: theme.palette.grey[500] }}>
                Hash:{" "}
              </Typography>
              <Tooltip placement="top" title={item.hash} className={classes.helper}>
                <Typography variant="body1">{item.hash.slice(0, 40) + "..."}</Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={4} sx={{ marginBottom: "10px" }}>
              <Typography variant="body1" style={{ color: theme.palette.grey[500] }}>
                Timestamp:{" "}
              </Typography>
              <Tooltip
                placement="top"
                title="A Timestamp refers to a small piece of data stored in every block in a Blockchain as a unique serial"
                className={classes.helper}
              >
                <Typography variant="body1" style={{ color: theme.palette.primary.main }}>
                  {item.timestamp}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={4} sx={{ marginBottom: "10px" }}>
              <Typography variant="body1" style={{ color: theme.palette.grey[500] }}>
                Number:{" "}
              </Typography>
              <Typography variant="body1">{item.number}</Typography>
            </Grid>
          </Grid>
        </Box>
      ))}
    </Card>
  );
};

export default BlockCard;

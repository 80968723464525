import React, { FC } from "react";
import { Avatar, Tooltip, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import clsx from "clsx";
import { stringToColor } from "src/utils";
import { WorkspaceUserBase } from "../../../generated/graphql";

export interface UserAvatarProps {
  user: Omit<WorkspaceUserBase, "__typename">;
  size?: "xsmall" | "small" | "medium" | "large";
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: (props: UserAvatarProps) => {
      if (props.user) {
        return {
          backgroundColor: stringToColor(props.user.id),
          color: "#FFFFFF",
        };
      } else {
        return {
          backgroundColor: "#FFFFFF",
        };
      }
    },
  }),
);

function exportTwoChar(name: string) {
  if (name) {
    const arr = name.split(" ", 2);

    if (arr.length >= 2) {
      return (arr[0].substring(0, 1) + arr[1].substring(0, 1)).toUpperCase();
    } else {
      return arr[0].substring(0, 2).toUpperCase();
    }
  } else {
    return "";
  }
}

const UserAvatar: FC<UserAvatarProps> = ({ user, className, size, ...rest }) => {
  const classes = useStyles({ user });
  const twoChars = exportTwoChar(user.fullName);

  const avatarSize = (size: "xsmall" | "small" | "medium" | "large" | undefined) => {
    switch (size) {
      case "xsmall":
        return "20px";
      case "small":
        return "30px";
      case "medium":
        return "40px";
      case "large":
        return "50px";
      default:
        return undefined;
    }
  };

  const fontSize = (size: "xsmall" | "small" | "medium" | "large" | undefined) => {
    switch (size) {
      case "xsmall":
        return "0.6rem";
      case "small":
        return "0.75rem";
      case "medium":
        return "0.9rem";
      case "large":
        return "1.1rem";
      default:
        return undefined;
    }
  };

  if (!user) {
    return <div></div>;
  }

  return (
    <Tooltip title={user.fullName}>
      <Avatar
        variant={"circular"}
        src={user.profileImage || ""}
        {...rest}
        className={clsx(classes.avatar, className)}
        style={{ width: avatarSize(size), height: avatarSize(size), fontSize: fontSize(size), color: "#FFFFFF" }}
      >
        {!user.profileImage && twoChars}
      </Avatar>
    </Tooltip>
  );
};

export default UserAvatar;

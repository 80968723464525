import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { Task, TaskStatusType } from "src/types";
import { objFromArray } from "src/utils/utils";
import { AppThunk } from "../store";
import client from "src/apollo";
import { CREATE_TASK, DELETE_TASK, GET_PROJECT_TASKS, UPDATE_TASK } from "src/Api/Task/Task.queries";
import {
  CreateTaskMutation,
  CreateTaskMutationVariables,
  DeleteTaskMutation,
  DeleteTaskMutationVariables,
  UpdateTaskMutation,
  UpdateTaskMutationVariables,
} from "../../generated/graphql";

export interface TaskState {
  totalCount: number;
  isCreateTaskDialogOpen: boolean;
  isTaskDetailModalOpen: boolean;
  isTableModalOpen: boolean;
  isSyncCalendarDialogOpen: boolean;
  isLoaded: { byProject: Record<string, boolean> };
  statuses: {
    byId: Record<number, TaskStatusType & { allIds: number[] }>;
    byProject: Record<string, number[]>;
  };
  tasks: {
    byId: Record<number, Task>;
    byProject: Record<string, number[]>;
    byTimesheet: Record<number, number[]>;
  };
  userTaskIds: number[];
  timesheetTaskIds: number[];
  projectTimesheetTaskIds: number[];
  selectedTask: { taskId: number | null };
  deletedTaskIds: number[];
  selectedRange: {
    start: Date;
    end: Date;
  } | null;
  isSyncing: boolean;
}

const initialState: TaskState = {
  totalCount: 0,
  isCreateTaskDialogOpen: false,
  isTaskDetailModalOpen: false,
  isTableModalOpen: false,
  isSyncCalendarDialogOpen: false,
  isLoaded: {
    byProject: {},
  },
  statuses: {
    byId: {},
    byProject: {},
  },
  tasks: {
    byId: {},
    byProject: {},
    byTimesheet: {},
  },
  userTaskIds: [],
  timesheetTaskIds: [],
  projectTimesheetTaskIds: [],
  deletedTaskIds: [],
  selectedTask: {
    taskId: null,
  },
  selectedRange: null,
  isSyncing: false,
};

export const slice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    reset(state: TaskState) {
      state = initialState;
    },
    getCount(state: TaskState, action: PayloadAction<{ count: number }>) {
      const { count } = action.payload;

      state.totalCount = count;
    },
    // FIXME:
    // phase, milestone 하위 task들을 받아왔을 때, 임시 저장
    // 모든 페이지에서 task들의 사용법이 정해졌을 때 수정할 필요가 있음
    getOnlyTasks(state: TaskState, action: PayloadAction<{ tasks: Task[] }>) {
      const { tasks } = action.payload;

      state.tasks.byId = _.merge(state.tasks.byId, objFromArray(tasks));
    },
    getTasks(state: TaskState, action: PayloadAction<{ tasks: Task[]; projectId: string }>) {
      const { tasks, projectId } = action.payload;

      state.tasks.byId = _.merge(state.tasks.byId, objFromArray(tasks));
      state.tasks.byProject[projectId] = tasks.map((v) => v.id);
      state.isLoaded.byProject[projectId] = true;
    },
    getTimesheetTasks(
      state: TaskState,
      action: PayloadAction<{ tasks: Task[]; timesheetId?: number; projectId?: string }>,
    ) {
      const { tasks, timesheetId, projectId } = action.payload;

      state.tasks.byId = _.merge(state.tasks.byId, objFromArray(tasks));

      if (timesheetId) {
        state.tasks.byTimesheet[timesheetId] = tasks.map((v) => v.id);
      }

      const _groupByProjectId = _.groupBy(tasks, "projectId");

      _.forEach(_groupByProjectId, (val, projectId) => {
        state.tasks.byProject[projectId] = val.map((v) => v.id);
      });

      if (projectId) {
        state.projectTimesheetTaskIds = tasks.map((v) => v.id);
      } else {
        state.timesheetTaskIds = tasks.map((v) => v.id);
      }
    },
    getUserTasks(state: TaskState, action: PayloadAction<{ tasks: Task[] }>) {
      const { tasks } = action.payload;
      state.tasks.byId = _.merge(state.tasks.byId, objFromArray(tasks));

      const _groupByProjectId = _.groupBy(tasks, "projectId");
      _.forEach(_groupByProjectId, (val, projectId) => {
        state.tasks.byProject[projectId] = val.map((v) => v.id);
      });

      //test
      state.userTaskIds = tasks.map((v) => v.id);
    },

    createTask(state: TaskState, action: PayloadAction<{ task: Task; userSelf?: boolean }>) {
      const { task, userSelf } = action.payload;

      state.tasks.byId[task.id] = task;
      state.totalCount++;

      if (state.tasks.byProject[task.projectId] && state.tasks.byProject[task.projectId].length > 0) {
        state.tasks.byProject[task.projectId].unshift(task.id);
      } else {
        state.tasks.byProject[task.projectId] = [task.id];
      }

      if (userSelf) {
        state.userTaskIds.unshift(task.id);
      }
    },
    updateTask(state: TaskState, action: PayloadAction<{ task: Task }>) {
      const { task } = action.payload;

      state.tasks.byId[task.id] = { ...task };
    },
    deleteTask(state: TaskState, action: PayloadAction<{ id: number; projectId: string }>) {
      const { id, projectId } = action.payload;
      state.totalCount--;

      state.tasks.byId = _.omit(state.tasks.byId, id);
      _.pull(state.tasks.byProject[projectId], id);

      // userTaskIds 에서 지워주어야 MyTaskTable에서 에러가 안남.
      _.pull(state.userTaskIds, id);
    },
    openModal(state: TaskState) {
      state.isTaskDetailModalOpen = true;
    },
    closeModal(state: TaskState) {
      state.isTaskDetailModalOpen = false;
      state.selectedTask = {
        taskId: null,
      };
      state.selectedRange = null;
    },
    openTableModal(state: TaskState) {
      state.isTableModalOpen = true;
    },
    closeTableModal(state: TaskState) {
      state.isTableModalOpen = false;
      state.selectedTask = {
        taskId: null,
      };
      state.selectedRange = null;
    },
    openCreateTaskDialog(state: TaskState) {
      state.isCreateTaskDialogOpen = true;
    },
    closeCreateTaskDialog(state: TaskState) {
      state.isCreateTaskDialogOpen = false;
      state.selectedRange = null;
    },
    openSyncCalendarDialog(state: TaskState) {
      state.isSyncCalendarDialogOpen = true;
    },
    closeSyncCalendarDialog(state: TaskState) {
      state.isSyncCalendarDialogOpen = false;
    },
    syncStarted(state: TaskState) {
      // Update the state to indicate that syncing has started
      state.isSyncing = true;
    },
    syncFailed(state: TaskState) {
      // Update the state to indicate that syncing has failed
      state.isSyncing = false;
    },
    selectTask(state: TaskState, action: PayloadAction<{ taskId: number }>) {
      const { taskId } = action.payload;

      state.selectedTask = {
        taskId,
      };
    },
    selectRange(state: TaskState, action: PayloadAction<{ start: Date; end: Date }>) {
      const { start, end } = action.payload;

      state.selectedRange = {
        start,
        end,
      };
    },
  },
});

export const reducer = slice.reducer;

export const getTasksCount = (count: number): AppThunk => (dispatch) => {
  dispatch(
    slice.actions.getCount({
      count: count || 0,
    }),
  );
};

export const getTasksByProject = (projectId: string, tasks: Task[]): AppThunk => async (dispatch) => {
  dispatch(
    slice.actions.getTasks({
      tasks: tasks || [],
      projectId,
    }),
  );
};

export const getTasksByTimesheet = (tasks: Task[], timesheetId?: number, projectId?: string): AppThunk => async (
  dispatch,
) => {
  dispatch(
    slice.actions.getTimesheetTasks({
      tasks: tasks || [],
      timesheetId: timesheetId,
      projectId: projectId,
    }),
  );
};

export const getTasksByUser = (tasks: Task[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.getUserTasks({ tasks: tasks || [] }));
};

// TODO: createTask => calendar pagination 하면서 로직 바꿔야 함
export const createTask = (
  task: Task,
  userSelf: boolean = false,
  pageSize: number = 10,
  setSelectedTask: boolean = false,
): AppThunk => async (dispatch) => {
  const res = await client.mutate<CreateTaskMutation, CreateTaskMutationVariables>({
    mutation: CREATE_TASK,
    variables: { ...task },
  });

  if (res.data?.CreateTask.ok && res.data.CreateTask.task) {
    dispatch(
      slice.actions.createTask({
        task: { ...res.data.CreateTask.task! },
        userSelf,
      }),
    );
    if (setSelectedTask) {
      dispatch(slice.actions.selectTask({ taskId: res.data.CreateTask.task.id }));
    }
  }

  return res.data?.CreateTask.task;
};

export const updateTask = (task: Task): AppThunk => async (dispatch, getState) => {
  const res = await client.mutate<UpdateTaskMutation, UpdateTaskMutationVariables>({
    mutation: UPDATE_TASK,
    variables: { ...task },
  });

  if (res.data?.UpdateTask.ok) {
    dispatch(slice.actions.updateTask({ task }));
  } else {
    throw new Error(res.data?.UpdateTask.error || "Fail to update task");
  }
};

export const updateTask_ = (task: UpdateTaskMutationVariables): AppThunk => async (dispatch, getState) => {
  const res = await client.mutate<UpdateTaskMutation, UpdateTaskMutationVariables>({
    mutation: UPDATE_TASK,
    variables: task,
  });

  if (res.data?.UpdateTask.ok && res.data.UpdateTask.result) {
    dispatch(slice.actions.updateTask({ task: res.data.UpdateTask.result }));
  } else {
    throw new Error(res.data?.UpdateTask.error || "Fail to update task");
  }
};

export const updateTaskPlannedTime = (taskId: number, start: Date, end: Date): AppThunk => async (dispatch) => {
  const _taskId = Number(taskId);

  const res = await client.mutate<UpdateTaskMutation, UpdateTaskMutationVariables>({
    mutation: UPDATE_TASK,
    variables: {
      id: _taskId,
      plannedStartDate: start,
      plannedEndDate: end,
    },
  });

  if (res.data?.UpdateTask.ok && res.data.UpdateTask.result) {
    dispatch(
      slice.actions.updateTask({
        task: res.data.UpdateTask.result,
      }),
    );
  }
};

export const deleteTask = (taskId: number, projectId: string): AppThunk => async (dispatch) => {
  const res = await client.mutate<DeleteTaskMutation, DeleteTaskMutationVariables>({
    mutation: DELETE_TASK,
    refetchQueries: [{ query: GET_PROJECT_TASKS, variables: { projectId, offset: 0, limit: -1 } }],
    variables: {
      taskIds: [taskId],
    },
  });

  if (res.data?.DeleteTask.ok) {
    dispatch(slice.actions.deleteTask({ id: taskId, projectId }));
  }
};

export const selectTask = (taskId: number): AppThunk => (dispatch) => {
  dispatch(slice.actions.selectTask({ taskId }));
};

export const selectTaskPlannedRange = (start: Date, end: Date): AppThunk => (dispatch) => {
  dispatch(
    slice.actions.selectRange({
      start: start,
      end: end,
    }),
  );
};

export const openTaskDetailModal = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.openModal());
};

export const closeTaskDetailModal = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.closeModal());
};

export const openTaskTableModal = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.openTableModal());
};

export const closeTaskTableModal = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.closeTableModal());
};

export const openCreateTaskDialog = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.openCreateTaskDialog());
};

export const closeCreateTaskDialog = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.closeCreateTaskDialog());
};
export const openSyncCalendarDialog = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.openSyncCalendarDialog());
};
export const closeSyncCalendarDialog = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.closeSyncCalendarDialog());
};

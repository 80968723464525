import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  Milestone,
  Project,
  ProjectAssignee,
  ProjectInfo,
  ProjectPhase,
  ProjectTaskCategory,
  ProjectTaskStatus,
  WorkspaceUser,
  WorkspaceUserBase,
} from "../../generated/graphql";
import { useTypedSelector } from "../../hooks";
import { getProjectPhases as getProjectPhasesAction } from "../action-creators";
import { getById, getListById, getListByIds } from "../helper/projection";

//TODO: property가 없을 경우, fetch 해오기
export function useProperties() {
  const dispatch = useDispatch();

  const { projects } = useTypedSelector((state) => state.projects);
  const { projectInformations } = useTypedSelector((state) => state.projectInfoProperties);
  const { projectMembers } = useTypedSelector((state) => state.projectMembers);
  const { projectPhases, projectMilestones } = useTypedSelector((state) => state.projectTimeline);
  const { taskCategories } = useTypedSelector((state) => state.projectTaskCategories);
  const { taskStatuses } = useTypedSelector((state) => state.projectTaskStatuses);
  const { members } = useTypedSelector((state) => state.members);

  const getUserById = (id: string | undefined | null) => {
    if (id == null) {
      return null;
    }

    return getById<WorkspaceUserBase>(members, id, "byId");
  };

  const getUsersByIds = (ids: string[] | undefined | null) => {
    if (ids == null) {
      return [];
    }

    return getListByIds<WorkspaceUserBase>(members, "byId", ids);
  };

  const getProject = (id: string | undefined | null, whenNotExistedGetDefault: boolean = false) => {
    const _result = getById<Project>(projects, id, "byId");

    if (_result == null && whenNotExistedGetDefault) {
      const _ = getProjects();

      return _.length > 0 ? _[0] : null;
    }

    return _result;
  };

  const getProjectPhase = (id: number | undefined | null) => {
    return getById<ProjectPhase>(projectPhases, id, "byId");
  };

  const getProjectMilestone = (id: number | undefined | null) => {
    return getById<Milestone>(projectMilestones, id, "byId");
  };

  const getProjectTaskCategory = (id: number | undefined | null) => {
    return getById<ProjectTaskCategory>(taskCategories, id, "byId");
  };

  const getProjectTaskStatus = (id: number | undefined | null) => {
    return getById<ProjectTaskStatus>(taskStatuses, id, "byId");
  };

  const getProjectTaskCategoryDefault = (projectId: string | undefined | null) => {
    const _ = getProjectTaskCategories(projectId);
    return _.length > 0 ? _[0] : null;
  };

  const getProjectTaskStatusDefault = (projectId: string | undefined | null) => {
    const _ = getProjectTaskStatuses(projectId);
    return _.length > 0 ? _[0] : null;
  };

  const getProjects = () => {
    return getListById<Project>(projects, "allIds", "byId") || [];
  };

  const getProjectPhases = (projectId?: string) => {
    const _result = getListById<ProjectPhase>(projectPhases, "byProject", "byId", projectId);

    return _result || [];
  };

  const getProjectMilestones = (input: { projectId?: string; phaseId?: number | null }) => {
    if (input.phaseId) {
      return getListById<Milestone>(projectMilestones, "byPhase", "byId", input.phaseId) || [];
    }
    return getListById<Milestone>(projectMilestones, "byProject", "byId", input.projectId) || [];
  };

  const getProjectTaskCategories = (projectId?: string | null) =>
    getListById<ProjectTaskCategory>(taskCategories, "byProject", "byId", projectId) || [];

  const getProjectTaskStatuses = (projectId?: string | null) =>
    getListById<ProjectTaskStatus>(taskStatuses, "byProject", "byId", projectId) || [];

  const getProjectMembers = (projectId?: string | null) =>
    getListByIds<WorkspaceUser>(
      members,
      "byId",
      (getListById<ProjectAssignee>(projectMembers, "byProject", "byId", projectId) || []).map((v) => v.userId!),
    );

  const getProjectInfo = useCallback(
    (projectId: string | undefined | null) => {
      return getById<ProjectInfo>(projectInformations, projectId, "byId");
    },
    [projectInformations],
  );

  return {
    getUserById,
    getUsersByIds,
    getProject,
    getProjectPhase,
    getProjectMilestone,
    getProjectTaskCategory,
    getProjectTaskStatus,
    getProjects,
    getProjectPhases,
    getProjectMilestones,
    getProjectTaskCategories,
    getProjectTaskStatuses,
    getProjectMembers,
    getProjectTaskCategoryDefault,
    getProjectTaskStatusDefault,
    getProjectInfo,
  };
}

import React, { useEffect, useRef, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { Autocomplete, Box, ClickAwayListener, Theme, useMediaQuery, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { WorkspaceUserMeta } from "../../../../../generated/graphql";
import { UserAvatarAndName } from "../../../../Molecules";
import DetailInputBase from "../DetailInputBase";

import type { FC } from "react";
const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  iconSelected: {
    width: 17,
    height: 17,
    marginRight: 5,
    marginLeft: -2,
    color: theme.palette.primary.main,
  },
  paper: {
    boxShadow: "none",
    margin: 0,
    color: theme.palette.background.paper,
    fontSize: 13,
  },
  option: {
    minHeight: "auto",
    alignItems: "flex-start",
    padding: 8,
    '&[aria-selected="true"]': {
      backgroundColor: "transparent",
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  popperDisablePortal: {
    position: "relative",
  },
  close: {
    opacity: 0.6,
    width: 18,
    height: 18,
    color: theme.palette.primary.main,
  },
}));

interface AssigneesRowProps {
  open?: boolean;
  options: Omit<WorkspaceUserMeta, "__typename">[];
  value: undefined | Omit<WorkspaceUserMeta, "__typename">[];
  onUpdate?: (value: undefined | Omit<WorkspaceUserMeta, "__typename">[]) => Promise<void>;
  onChange?: (values: undefined | WorkspaceUserMeta[]) => void;
}

const AssigneesRow: FC<AssigneesRowProps> = ({ open, options, value, onUpdate, onChange }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down("md"));
  const [pendingValues, setPendingValues] = useState(value);
  const [disabled, setDisabled] = useState(false);

  const handleChange = async () => {
    setDisabled(true);

    onUpdate && (await onUpdate(pendingValues));

    setDisabled(false);
  };

  return (
    <ClickAwayListener onClickAway={handleChange}>
      <Box display="flex" flexDirection="column" width="100%">
        <Autocomplete
          open={open}
          disabled={disabled}
          multiple
          classes={{
            paper: classes.paper,
            option: classes.option,
            popperDisablePortal: classes.popperDisablePortal,
          }}
          value={pendingValues}
          onChange={(event, newValue) => {
            setPendingValues(newValue);
            onChange && onChange(newValue);
          }}
          disablePortal
          renderTags={() => null}
          noOptionsText="No labels"
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Box display="flex" width="100%">
                <DoneIcon className={classes.iconSelected} style={{ visibility: selected ? "visible" : "hidden" }} />
                <Box flexGrow={1}>
                  <UserAvatarAndName member={option} size="xsmall" />
                </Box>
                <CloseIcon className={classes.close} style={{ visibility: selected ? "visible" : "hidden" }} />
              </Box>
            </li>
          )}
          options={options}
          getOptionLabel={(option) => option.fullName}
          renderInput={(params) => (
            <DetailInputBase ref={params.InputProps.ref} inputProps={params.inputProps} fullWidth disabled={disabled} />
          )}
        />
      </Box>
    </ClickAwayListener>
  );
};

export default AssigneesRow;

import makeStyles from "@mui/styles/makeStyles";

export default makeStyles((theme) => ({
  leftSide: {
    marginRight: "10px",
  },
  rightSide: {
    marginRight: "10px",
  },
  navigation: {
    minWidth: "240px",
    width: "25vw",
    maxWidth: "310px",
  },
  navigationList: {
    padding: "0px 0px",
  },
  navigationListItem: {
    padding: "8px 20px",
    "&:hover": {
      backgroundColor: theme.palette.background.paper,
    },
    "&:focus": {
      backgroundColor: theme.palette.background.default,
    },
  },
  selectedItem: {
    width: "3px",
    height: "23.5px",
    background: theme.palette.primary.main,
    position: "absolute",
    top: "25%",
    left: 0,
  },
  contentPaper: {
    width: "70vw",
    minHeight: "100%",
  },
}));

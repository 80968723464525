import { useMutation } from "@apollo/client";
import {
  UPDATE_PROJECT_INFO,
  GET_PROJECTS_BY_USER,
  GET_PROJECT_LIST_BY_USER,
  DELETE_PROJECT,
  CREATE_PROJECT,
} from "src/Api/Project/Project/Project.queries";
import { ALL_OPTIONS_FROM_WORKSPACE } from "src/Api/Options/Options.queries";
import { useSnackbar } from "notistack";
import {
  CreateProjectMutation,
  CreateProjectMutationVariables,
  DeleteProjectMutation,
  DeleteProjectMutationVariables,
  UpdateProjectInfoMutation,
  UpdateProjectInfoMutationVariables,
} from "../../../generated/graphql";

export function useProjectQueries() {
  const { enqueueSnackbar } = useSnackbar();

  const [CreateProjectMutation] = useMutation<CreateProjectMutation, CreateProjectMutationVariables>(CREATE_PROJECT, {
    refetchQueries: [`GetProjectListByUser`, { query: ALL_OPTIONS_FROM_WORKSPACE }],
    awaitRefetchQueries: true,
    onCompleted(data) {
      if (!data || !data.CreateProject.ok) {
        alert(data.CreateProject.error);
      }
    },
  });

  const [updateProjectMutation] = useMutation<UpdateProjectInfoMutation, UpdateProjectInfoMutationVariables>(
    UPDATE_PROJECT_INFO,
    {
      refetchQueries: [{ query: GET_PROJECTS_BY_USER }, { query: GET_PROJECT_LIST_BY_USER }],
      awaitRefetchQueries: true,
      onCompleted(data) {
        if (!data.UpdateProjectInfo.ok) {
          const error = data.UpdateProjectInfo.error ? data.UpdateProjectInfo.error : "updateProjectMutation error";
          enqueueSnackbar(error, { variant: "error" });
        }
      },
    },
  );

  const [deleteProjectMutation] = useMutation<DeleteProjectMutation, DeleteProjectMutationVariables>(DELETE_PROJECT, {
    refetchQueries: [
      { query: GET_PROJECTS_BY_USER },
      { query: GET_PROJECT_LIST_BY_USER },
      { query: ALL_OPTIONS_FROM_WORKSPACE },
    ],
    awaitRefetchQueries: true,
    onCompleted(data) {
      if (!data.DeleteProject.ok) {
        enqueueSnackbar(data.DeleteProject.error || "Server Error", {
          variant: "error",
        });
      }
    },
  });

  return {
    CreateProjectMutation,
    updateProjectMutation,
    deleteProjectMutation,
  };
}

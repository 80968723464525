import type { FC, ReactNode } from "react";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import useAuth from "src/hooks/useAuth";

interface AuthGuardProps {
  children?: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  const { isAuthenticated, isEmailVerified, user } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/sign-in" />;
  }

  if (user == null) {
    return <Navigate to="/404" />;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;

import { alpha, Theme } from "@mui/material/styles";

// ----------------------------------------------------------------------

export default function Backdrop(theme: Theme) {
  const varLow = alpha(theme.palette.primary.main, 0.2);
  const varHigh = alpha(theme.palette.primary.main, 0.3);

  return {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          background: [
            // theme.palette.primary.main,
            `-moz-linear-gradient(75deg, ${varLow} 0%, ${varHigh} 100%)`,
            `-webkit-linear-gradient(75deg, ${varLow} 0%, ${varHigh} 100%)`,
            `linear-gradient(75deg, ${varLow} 0%, ${varHigh} 100%)`,
          ],
          "&.MuiBackdrop-invisible": {
            background: "transparent",
          },
        },
      },
    },
  };
}

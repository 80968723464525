import firebase from "src/Firebase/firebase";
import { ApolloClient, HttpLink, InMemoryCache, ApolloLink, split } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { setCookie } from "./utils";

const defaults = {
  isLoggedIn: Boolean(localStorage.getItem("jwt")) || false,
};

const resolvers = {
  Mutation: {
    logUserIn: (_: any, { token }: any, { cache }: any) => {
      // cookie for 1month
      setCookie("STKN", token, 30);
      cache.writeData({
        data: {
          isLoggedIn: true,
        },
      });
      return null;
    },
    logUserOut: (_: any, __: any, { cache }: any) => {
      setCookie("STKN", "", -1);
      cache.writeData({
        data: {
          isLoggedIn: false,
        },
      });
      window.location.pathname = "/";
      return null;
    },
  },
};
let inFlightQueries = 0;

const queryTrackingLink = new ApolloLink((operation, forward) => {
  inFlightQueries++;
  return forward(operation).map((response) => {
    inFlightQueries--;
    return response;
  });
});

const areAllQueriesCompleted = () => inFlightQueries === 0;

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
    });
  }
  if (networkError) console.log(`[Network error]: ${networkError}`);

  if (areAllQueriesCompleted()) {
    client.resetStore();
  }
});
const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_SERVER_HOST || "/api/graphql",
});

const workspaceLink = setContext((_, { headers }) => {
  let lastWorkspace: { id: string; name: string } | null = null;

  try {
    const storedLastWorkspace: string | null = window.localStorage.getItem("lastWorkspace");

    if (storedLastWorkspace) {
      lastWorkspace = JSON.parse(storedLastWorkspace);
    }
  } catch (err) {
    console.error(err);
  }

  return {
    headers: {
      ...headers,
      workspace: lastWorkspace?.id || "null",
    },
  };
});

const authLink = setContext(async (_, { headers }) => {
  return await firebase
    .auth()
    .currentUser?.getIdToken()
    .then((token) => {
      return {
        headers: {
          ...headers,
          authorization: token ? `${token}` : "",
        },
      };
    });
});

//https://github.com/apollographql/apollo-client/issues/6850
const client = new ApolloClient({
  link: ApolloLink.from([queryTrackingLink, errorLink, authLink, workspaceLink, httpLink]),
  cache: new InMemoryCache(),
  resolvers,
});

export default client;

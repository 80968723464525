import { replace } from "lodash";
import numeral from "numeral";

// ----------------------------------------------------------------------

export function fCurrency(number: string | number) {
  return numeral(number).format(Number.isInteger(number) ? "$0,0" : "$0,0.00");
}

export function fPercent(number: number) {
  return numeral(number / 100).format("0.0%");
}

export function fNumber(number: string | number) {
  return numeral(number).format();
}

export function fShortenNumber(number: string | number) {
  return replace(numeral(number).format("0.00a"), ".00", "");
}

export function fData(number: string | number) {
  return numeral(number).format("0.0 b");
}

export function fTimeDuration(number: number | undefined | null) {
  if (number == null) return "";

  if (number <= 0) return "";

  const hours = Math.floor(number / 60);
  const minutes = number % 60;

  return (
    ((hours !== 0 && hours.toString() + "h") || "") +
    ((hours !== 0 && minutes !== 0 && " ") || "") +
    ((minutes !== 0 && minutes.toString() + "m") || "")
  );
}

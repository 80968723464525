import { gql } from "@apollo/client";

export const F_TASK_STATUS_PROPERTIES = gql`
  fragment TaskStatusProperties on ProjectTaskStatus {
    id
    name
    order
    keyStatus
    color
    isHidden
    isHiddenFromCalendar
    projectId
    createdAt
    updatedAt
    taskIds
  }
`;

export const GET_PROJECT_TASK_STATUSES = gql`
  query GetProjectTaskStatuses($projectId: String) {
    GetProjectTaskStatuses(projectId: $projectId) {
      ok
      error
      statuses {
        ...TaskStatusProperties
      }
    }
  }
  ${F_TASK_STATUS_PROPERTIES}
`;

export const CREATE_PROJECT_TASK_STATUS = gql`
  mutation CreateProjectTaskStatus($projectId: String!, $name: String!, $color: String) {
    CreateProjectTaskStatus(projectId: $projectId, name: $name, color: $color) {
      ok
      error
      result {
        ...TaskStatusProperties
      }
    }
  }
  ${F_TASK_STATUS_PROPERTIES}
`;

export const UPDATE_PROJECT_TASK_STATUS = gql`
  mutation UpdateProjectTaskStatus(
    $id: Int!
    $name: String
    $color: String
    $isHidden: Boolean
    $isHiddenFromCalendar: Boolean
  ) {
    UpdateProjectTaskStatus(
      id: $id
      name: $name
      color: $color
      isHidden: $isHidden
      isHiddenFromCalendar: $isHiddenFromCalendar
    ) {
      ok
      error
    }
  }
`;

export const UPDATE_PROJECT_TASK_STATUS_ORDER = gql`
  mutation UpdateProjectTaskStatusOrder($id: Int!, $position: Int!) {
    UpdateProjectTaskStatusOrder(id: $id, position: $position) {
      ok
      error
    }
  }
`;

export const DELETE_PROJECT_TASK_STATUS = gql`
  mutation DeleteProjectTaskStatus($input: DeleteProjectTaskPropertyInput!) {
    DeleteProjectTaskStatus(input: $input) {
      ok
      error
    }
  }
`;

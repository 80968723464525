import gql from "graphql-tag";

export const GET_PROJECT_ASSIGNEE = gql`
  query GetProjectAssignees($projectId: String) {
    GetProjectAssignees(projectId: $projectId) {
      ok
      error
      projectAssignees {
        id
        lead
        userId
        projectId
        position {
          id
          name
          order
        }
      }
    }
  }
`;

export const GET_PROJECT_ASSIGNEE_GOOGLE_CALENDARID = gql`
  query GetProjectAssigneesGoogleCalendarId($projectId: String) {
    GetProjectAssignees(projectId: $projectId) {
      ok
      error
      projectAssignees {
        id
        lead
        userId
        projectId
        position {
          id
          name
          order
        }
      }
    }
  }
`;

export const GET_PROJECT_ASSIGNEE_BY_USERID = gql`
  mutation GetProjectAssigneeById($userId: String, $projectId: String) {
    GetProjectAssigneeById(userId: $userId, projectId: $projectId) {
      error
      ok
      projectAssignee {
        id
        lead
        googleCalendarId
        userId
        projectId
        createdAt
        updatedAt
      }
    }
  }
`;
export const GET_GOOGLECALENDAR_IDS_BY_USERID = gql`
  query GetGoogleCalendarIdsByUserId($userId: String!) {
    GetGoogleCalendarIdsByUserId(userId: $userId) {
      ok
      error
      googleCalendars {
        projectId
        googleCalendarId
      }
    }
  }
`;
export const CREATE_PROJECT_ASSIGNEE = gql`
  mutation CreateProjectAssignee($workspaceUserId: String!, $projectId: String!) {
    CreateProjectAssignee(workspaceUserId: $workspaceUserId, projectId: $projectId) {
      ok
      error
      result {
        id
        lead
        userId
        googleCalendarId
        projectId
        position {
          id
          name
          order
        }
      }
    }
  }
`;

export const UPDATE_PROJECT_ASSIGNEE = gql`
  mutation UpdateProjectAssignee($id: Int!, $lead: Boolean) {
    UpdateProjectAssignee(id: $id, lead: $lead) {
      ok
      error
    }
  }
`;

export const DELETE_PROJECT_ASSIGNEE = gql`
  mutation DeleteProjectAssignee($id: Int!) {
    DeleteProjectAssignee(id: $id) {
      ok
      error
    }
  }
`;

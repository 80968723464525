import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { objFromArray } from "src/utils/utils";
import { ProjectTaskCategory } from "../../generated/graphql";

export interface ProjectTaskCategoryState {
  isAllLoaded: boolean;
  isLoaded: { byProject: Record<string, boolean> };
  taskCategories: {
    byId: Record<number, ProjectTaskCategory>;
    byProject: Record<string, number[]>;
  };
}

const initialState: ProjectTaskCategoryState = {
  isAllLoaded: false,
  isLoaded: {
    byProject: {},
  },
  taskCategories: {
    byId: {},
    byProject: {},
  },
};

export const projectTaskCategorySlice = createSlice({
  name: "projectTaskCategory",
  initialState,
  reducers: {
    reset(state: ProjectTaskCategoryState) {
      state = initialState;
    },
    setLoaded(state: ProjectTaskCategoryState, action: PayloadAction<{ loaded: boolean }>) {
      state.isAllLoaded = action.payload.loaded;
    },
    getTaskCategories(
      state: ProjectTaskCategoryState,
      action: PayloadAction<{ taskCategories: ProjectTaskCategory[] }>,
    ) {
      const { taskCategories } = action.payload;
      state.taskCategories.byId = _.merge(state.taskCategories.byId, objFromArray(taskCategories));

      const _groupByProjectId = _.groupBy(taskCategories, "projectId");
      _.forEach(_groupByProjectId, (val, projectId) => {
        state.taskCategories.byProject[projectId] = val.sort((a, b) => a.order || 0 - (b.order || 0)).map((v) => v.id);
        state.isLoaded.byProject[projectId] = true;
      });
    },
    createProjectTaskCategory(
      state: ProjectTaskCategoryState,
      action: PayloadAction<{ taskCategory: ProjectTaskCategory }>,
    ) {
      const { taskCategory } = action.payload;

      state.taskCategories.byId[taskCategory.id] = taskCategory;
      state.taskCategories.byProject[taskCategory.projectId].push(taskCategory.id);
    },
    updateProjectTaskCategory(
      state: ProjectTaskCategoryState,
      action: PayloadAction<{ taskCategory: ProjectTaskCategory }>,
    ) {
      const { taskCategory } = action.payload;

      _.merge(state.taskCategories.byId[taskCategory.id], taskCategory);
    },
    updateProjectTaskCategoryOrder(
      state: ProjectTaskCategoryState,
      action: PayloadAction<{ id: number; position: number }>,
    ) {
      const { id, position } = action.payload;
      const projectId = state.taskCategories.byId[id].projectId;

      _.pull(state.taskCategories.byProject[projectId], id);
      state.taskCategories.byProject[projectId].splice(position, 0, id);
    },
    deleteProjectTaskCategory(state: ProjectTaskCategoryState, action: PayloadAction<{ id: number }>) {
      const { id } = action.payload;

      const projectId = state.taskCategories.byId[id].projectId;

      state.taskCategories.byId = _.omit(state.taskCategories.byId, id);
      _.pull(state.taskCategories.byProject[projectId], id);
    },
  },
});

export const reducer = projectTaskCategorySlice.reducer;

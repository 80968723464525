import type { FC, PropsWithChildren, ReactElement, ReactNode } from "react";
import { Paper, Box, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DragDropContext, Droppable, DropResult, ResponderProvided } from "react-beautiful-dnd";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    display: "flex",
    overflow: "auto",
    height: "100%",
  },
  inner: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "100%",
    overflowY: "unset",
    overflowX: "unset",
    width: "100%",
  },
  droppableArea: {
    minHeight: 80,
    flexGrow: 1,
    overflowY: "unset",
  },
  cardRoot: {
    outline: "none",
  },
}));

interface DragDropPaperProps {
  droppableId: string;
  onDragEnd: (result: DropResult, provided: ResponderProvided) => void;
}

function DragDropContainer(props: PropsWithChildren<DragDropPaperProps>): ReactElement {
  const { droppableId, onDragEnd, children } = props;
  const classes = useStyles();

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Box className={classes.inner}>
        <Droppable droppableId={droppableId} type="card">
          {(provided) => (
            <div ref={provided.innerRef} className={classes.droppableArea}>
              {children}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </Box>
    </DragDropContext>
  );
}

export default DragDropContainer;

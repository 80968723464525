type NomalizedState<T> = { byId: Record<string | number, T>; allIds: string[] | number[] };
type NomalizedStateByProject<T> = { byId: Record<string | number, T>; byProject: Record<string, string[] | number[]> };

export function mapAllStates<T>(nomalizedState: NomalizedState<T>) {
  return nomalizedState.allIds.map((id) => nomalizedState.byId[id]);
}

export function mapAllStatesNotDeleted<T extends { deleted: boolean }>(nomalizedState: NomalizedState<T>) {
  const _ = nomalizedState.allIds.map((id) => nomalizedState.byId[id]);
  return _.filter((v) => !v.deleted);
}

export function mapAllStatesByProjectNotDeleted<T extends { deleted: boolean }>(
  nomalizedState: NomalizedStateByProject<T>,
  projectId: string,
) {
  if (projectId in nomalizedState.byProject) {
    const _ = nomalizedState.byProject[projectId].map((id) => nomalizedState.byId[id]);
    return _.filter((v) => !v.deleted);
  }

  console.error("key-value error");
  return [];
}

export function getFirstState<T>(nomalizedState: NomalizedState<T>) {
  if (nomalizedState.allIds) {
    return nomalizedState.byId[nomalizedState.allIds[0]];
  } else {
    return null;
  }
}

export function getById<T = any>(
  nomalizedState: any,
  id: string | number | null | undefined,
  valueKey: NomalizedStateValueKeyType = "byId",
): T | null {
  if (!id) return null;

  if (!(valueKey in nomalizedState)) {
    return null;
  }

  if (id in nomalizedState[valueKey]) {
    return nomalizedState[valueKey][id];
  }

  return null;
}

type NomalizedStateListKeyType = "byProject" | "byPhase" | "byMilestone" | "allIds";
type NomalizedStateValueKeyType = "byId" | "byProject";

export function getListById<T = any>(
  nomalizedState: any,
  listKey: NomalizedStateListKeyType,
  valueKey: NomalizedStateValueKeyType,
  listKeyId?: string | number | null,
): T[] | null {
  if (!(listKey in nomalizedState)) {
    console.error("getListById : can't find the key in state");
    return null;
  }

  if (!(valueKey in nomalizedState)) {
    console.error("getListById : can't find the key in state");
    return null;
  }

  if (listKeyId && listKey in nomalizedState) {
    if (listKeyId in nomalizedState[listKey]) {
      return nomalizedState[listKey][listKeyId].map((id: any) => nomalizedState[valueKey][id]);
    }

    return null;
  }

  if (listKey in nomalizedState && Array.isArray(nomalizedState[listKey])) {
    return nomalizedState[listKey].map((id: any) => nomalizedState[valueKey][id]);
  }

  return null;
}

export function getListByIds<T = any>(
  nomalizedState: any,
  valueKey: NomalizedStateValueKeyType,
  valueKeyIds: string[] | number[] | undefined,
) {
  if (!valueKeyIds) {
    return [];
  }

  if (!(valueKey in nomalizedState)) {
    console.error("getListByIds : can't find the key in state");
    return [];
  }

  const _mapped = valueKeyIds.map((id) => getById<T>(nomalizedState, id));
  const _result = _mapped.filter((v) => v !== null) as T[];
  return _result;
}

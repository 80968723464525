import gql from "graphql-tag";
import {
  F_COMPANY_PROPERTIES,
  F_OPTIONS,
} from "../Project/Project/Project.queries";

export const ALL_OPTIONS_FROM_WORKSPACE = gql`
  query AllOptionsFromWorkspace {
    GetCompanies {
      ok
      error
      companies {
        id
        name
        members {
          id
          name
          title
          email
          phone
        }
      }
    }
    GetCompanyMembers {
      ok
      error
      companyMembers {
        id
        name
        title
        email
        phone
        company {
          id
          name
        }
      }
    }
    GetComplexityLevels {
      ok
      error
      complexityLevels {
        id
        name
      }
    }
    GetPaymentTerms {
      ok
      error
      paymentTerms {
        id
        name
      }
    }
    GetPrograms {
      ok
      error
      programs {
        id
        name
      }
    }
    GetRisks {
      ok
      error
      risks {
        id
        name
      }
    }
    GetServiceTypes {
      ok
      error
      serviceTypes {
        id
        name
      }
    }
    GetTools {
      ok
      error
      tools {
        id
        name
      }
    }
    GetWorkspaceUsers {
      ok
      error
      users {
        id
        email
        firstName
        lastName
        fullName
        profileColor
        profileImage
        activate
        role
      }
    }
    GetProjectsByUser {
      ok
      error
      projects {
        id
        name
        description
        projectColor
        projectImage
        state
        createdAt
        updatedAt
        assignees {
          id
          lead
          user {
            id
            firstName
            lastName
            fullName
            profileColor
            profileImage
          }
          position {
            id
            name
          }
          createdAt
          updatedAt
        }
        projectInfo {
          id
          arch_projectName
          arch_startDate
          arch_endDate
          arch_size
          arch_publishedCost
          arch_website
          arch_location
          proj_startDate
          proj_endDate
          proj_publishedDate
          proj_contractValue
          strategy
          remark
          riskDescription
          otherComments
          clientMembers
          ...Options
          architect {
            ...CompanyProperties
          }
          client {
            ...CompanyProperties
          }
          generalContractor {
            ...CompanyProperties
          }
          owner {
            ...CompanyProperties
          }
        }
        taskStatuses {
          id
          name
          order
          keyStatus
          color
          isHidden
        }
        taskCategories {
          id
          name
          order
          color
        }
      }
    }
  }
  ${F_COMPANY_PROPERTIES}
  ${F_OPTIONS}
`;

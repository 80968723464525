import React from "react";
import { Theme } from "@mui/material/styles";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";
import Dialog from "@mui/material/Dialog";
import MuiDialogTitle from "@mui/material/DialogTitle";
import { IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const useStyles = makeStyles((theme) => ({
  paper: {
    maxHeight: "80vh",
    minWidth: "30vh",
  },
  dialog: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    minHeight: 150,
    minWidth: "300px",
  },
}));

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>

      <IconButton aria-label="close" className={classes.closeButton} onClick={onClose} size="large">
        <CloseIcon fontSize="small" />
      </IconButton>
    </MuiDialogTitle>
  );
});

export default function CustomDialogWithCloseBtn(props: any) {
  const { open, onClose, children, title, ...rest } = props;
  const classes = useStyles();

  return (
    <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open} {...rest}>
      <div className={classes.dialog}>
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
          {title}
        </DialogTitle>
        {children}
      </div>
    </Dialog>
  );
}

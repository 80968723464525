import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { objFromArray } from "src/utils/utils";
import _ from "lodash";
import { PhaseType } from "src/types";

export interface PhaseState {
  isLoaded: boolean;
  phases: {
    byId: Record<number, PhaseType>;
    allIds: number[];
  };
}

const initialState: PhaseState = {
  isLoaded: false,
  phases: {
    byId: {},
    allIds: [],
  },
};

export const PhaseSlice = createSlice({
  name: "phases",
  initialState,
  reducers: {
    reset(state: PhaseState) {
      state = initialState;
    },
    changeLoadState(state: PhaseState, action: PayloadAction<{ isLoaded: boolean }>) {
      state.isLoaded = action.payload.isLoaded;
    },
    getPhases(state: PhaseState, action: PayloadAction<{ phases: PhaseType[] }>) {
      const { phases } = action.payload;

      state.phases.byId = objFromArray(phases);
      state.phases.allIds = phases.map((v) => v.id);
      state.isLoaded = true;
    },
    createPhase(state: PhaseState, action: PayloadAction<{ phase: PhaseType }>) {
      const { phase } = action.payload;

      state.phases.byId[phase.id] = phase;
      state.phases.allIds.push(phase.id);
    },
    updatePhase(state: PhaseState, action: PayloadAction<{ phase: PhaseType }>) {
      const { phase } = action.payload;

      state.phases.byId[phase.id] = phase;
    },
    deletePhase(state: PhaseState, action: PayloadAction<{ id: number }>) {
      const { id } = action.payload;

      state.phases.byId = _.omit(state.phases.byId, id);
      _.pull(state.phases.allIds, id);
    },
    movePhase(state: PhaseState, action: PayloadAction<{ id: number; position: number }>) {
      const { id, position } = action.payload;

      _.pull(state.phases.allIds, id);
      state.phases.allIds.splice(position, 0, id);
    },
  },
});

export const reducer = PhaseSlice.reducer;

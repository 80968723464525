import React, { useState } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import { Theme } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

type unit = "px" | "%" | undefined;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    input: {
      display: "none",
    },
  }),
);

type Props = {
  imageUrl?: any;
  croppedImageUrl: string;
  setCroppedImageUrl: React.Dispatch<React.SetStateAction<string>>;
  src: any;
  setSrc: React.Dispatch<React.SetStateAction<any>>;
};

export default function ProfileUploadCrop(props: Props) {
  const classes = useStyles();

  const { setCroppedImageUrl, src } = props;

  const [imageRef, setImageRef] = useState<any>();
  const [crop, setCrop] = useState<ReactCrop.Crop>({ aspect: 1, unit: "%", x: 0, y: 0 });

  const onCropChange = (crop: ReactCrop.Crop) => {
    setCrop(crop);
  };

  const onImageLoaded = (image: HTMLImageElement) => {
    setImageRef(image);
    makeClientCrop(crop, image);
  };

  const onCropComplete = (crop: any) => {
    makeClientCrop(crop, imageRef);
  };

  const getCroppedImg = (image: any, crop: any): Promise<string> => {
    const canvas: HTMLCanvasElement = document.createElement("canvas");
    const scaleX: number = image.naturalWidth / image.width;
    const scaleY: number = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    if (ctx)
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height,
      );

    return new Promise((resolve, reject) => {
      //크롭된 이미지를 그린 캔버스를 data Url로 변환
      const dataurl = canvas.toDataURL("image/jpeg", 0.5);
      resolve(dataurl);
    });
  };

  const makeClientCrop = async (crop: ReactCrop.Crop, imageRef: HTMLImageElement) => {
    if (imageRef && crop.width && crop.height) {
      await getCroppedImg(imageRef, crop).then((url) => {
        setCroppedImageUrl(url);
      });
    }
  };

  return (
    <div>
      <div className={classes.root}>
        {src && (
          <ReactCrop
            src={src}
            crop={crop}
            ruleOfThirds
            circularCrop
            onChange={onCropChange}
            onImageLoaded={onImageLoaded}
            onComplete={onCropComplete}
          />
        )}
      </div>
    </div>
  );
}

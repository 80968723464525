import { Button, Theme } from "@mui/material";

import withStyles from "@mui/styles/withStyles";

const DeleteButton = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.getContrastText(theme.palette.error.main),
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
    "&:disabled": {
      backgroundColor: theme.palette.error.lighter,
    },
    textTransform: "lowercase",
  },
}))(Button);

export default DeleteButton;

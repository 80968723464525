import type { ReactNode } from "react";
import {
  UserCheck as UserCheckIcon,
  Codepen as CodePenIcon,
  Calendar as CalendarIcon,
  Crosshair as CrosshairIcon,
  CreditCard as CreditCardIcon,
  Columns as ColumnsIcon,
  MessageCircle as MessageCircleIcon,
  PieChart as PieChartIcon,
  Users as UsersIcon,
  ShoppingBag as ShoppingBagIcon,
  Settings as SettingsIcon,
  Trello as TrelloIcon,
  BarChart2 as BarChart2Icon,
  List as ListIcon,
  DollarSign as DollarSignIcon,
} from "react-feather";
import ContactPhoneOutlinedIcon from "@mui/icons-material/ContactPhoneOutlined";
import { Timeline } from "@mui/icons-material";

export interface Item {
  href?: string;
  icon?: ReactNode;
  items?: Item[];
  title: string;
}

export interface Section {
  items: Item[];
  subheader: string;
}

const defaultWorkspaceSections: Section[] = [
  {
    subheader: "PRIVATE",
    items: [
      {
        title: "My Tasks",
        icon: UserCheckIcon,
        href: "/my-tasks",
      },
      {
        title: "My Analysis",
        icon: PieChartIcon,
        href: "/my-analysis",
      },
      {
        title: "My Timesheet",
        icon: ListIcon,
        href: "/my-timesheet",
      },
    ],
  },
  {
    subheader: "TEAM",
    items: [
      {
        title: "Projects",
        icon: CodePenIcon,
        href: "/projects",
      },
      {
        title: "Calendar",
        icon: CalendarIcon,
        href: "/calendar",
      },
      {
        title: "Members",
        icon: UsersIcon,
        href: "/members",
      },
    ],
  },
  {
    subheader: "EXTRA",
    items: [
      {
        title: "Clients",
        icon: ContactPhoneOutlinedIcon,
        href: "/clients",
      },
    ],
  },
  {
    subheader: "BLOCKCHAIN",
    items: [
      {
        title: "My Tasks",
        icon: UserCheckIcon,
        href: "/my-tasks",
      },
    ],
  },
];

const adminWorkspaceSections: Section[] = defaultWorkspaceSections.concat([
  {
    subheader: "ADMIN",
    items: [
      //TODO:
      // {
      //   title: "Working Status",
      //   icon: BarChart2Icon,
      //   href: "/working-status",
      // },
      {
        title: "Workspace Setting",
        icon: SettingsIcon,
        href: "/workspace-setting",
      },
      {
        title: "Workspace Timesheet",
        icon: ListIcon,
        href: "/workspace-timesheet",
      },
    ],
  },
]);

const defaultProjectSections: Section[] = [
  {
    subheader: "PROJECT",
    items: [
      {
        title: "Dashboard",
        icon: PieChartIcon,
        href: "/dashboard",
      },
      {
        title: "Planner",
        icon: Timeline,
        href: "/planner",
      },
      {
        title: "Table",
        icon: CreditCardIcon,
        href: "/table",
      },
      {
        title: "Calendar",
        icon: CalendarIcon,
        href: "/calendar",
      },
      {
        title: "Information",
        icon: BarChart2Icon,
        href: "/information",
      },
      {
        title: "Storage",
        icon: ShoppingBagIcon,
        href: "/storage",
      },
      {
        title: "Setting",
        icon: SettingsIcon,
        href: "/setting",
      },
    ],
  },
  {
    subheader: "TBC",
    items: [
      {
        title: "Kanban",
        icon: TrelloIcon,
        href: "/kanban",
      },
      {
        title: "Feed",
        icon: ColumnsIcon,
        href: "/feed",
      },
      {
        title: "Chat",
        icon: MessageCircleIcon,
        href: "/chat",
      },
    ],
  },
];

export const workspaceSectionsForAll = (workspaceName: string): Section[] => {
  return defaultWorkspaceSections.map((section) => ({
    ...section,
    items: section.items.map((item) => ({
      ...item,
      href: (item.href && `/w/${workspaceName}`.concat(item.href)) || item.href,
    })),
  }));
};

export const workspaceSectionsForAdmin = (workspaceName: string): Section[] => {
  return adminWorkspaceSections.map((section) => ({
    ...section,
    items: section.items.map((item) => ({
      ...item,
      href: (item.href && `/w/${workspaceName}`.concat(item.href)) || item.href,
    })),
  }));
};

export const projectSectionsForAll = (workspaceName: string, projectName: string): Section[] => {
  return defaultProjectSections.map((section) => ({
    ...section,
    items: section.items.map((item) => ({
      ...item,
      href: (item.href && `/w/${workspaceName}/project/${projectName}`.concat(item.href)) || item.href,
    })),
  }));
};

export const projectSectionsForAdmin = (workspaceName: string, projectName: string): Section[] => {
  return defaultProjectSections.map((section) => ({
    ...section,
    items: section.items.map((item) => ({
      ...item,
      href: (item.href && `/w/${workspaceName}/project/${projectName}`.concat(item.href)) || item.href,
    })),
  }));
};

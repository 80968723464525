import gql from "graphql-tag";

export const CREATE_COMPANY_MEMBER = gql`
	mutation CreateCompanyMember($name: String!, $title: String, $email: String, $phone: String, $companyId: Int!) {
		CreateCompanyMember(name: $name, title: $title, email: $email, phone: $phone, companyId: $companyId) {
			ok
			error
		}
	}
`;

export const DELETE_COMPANY_MEMBERS = gql`
	mutation DeleteCompanyMembers($ids: [Int!]!) {
		DeleteCompanyMembers(ids: $ids) {
			ok
			error
		}
	}
`;

export const GET_COMPANY_MEMBERS = gql`
	query GetCompanyMembers {
		GetCompanyMembers {
			ok
			error
			companyMembers {
				id
				name
				title
				email
				phone
				company {
					id
					name
				}
			}
		}
	}
`;

export const UPDATE_COMPANY_MEMBER = gql`
	mutation UpdateCompanyMember($id: Int!, $name: String!, $title: String, $email: String, $phone: String) {
		UpdateCompanyMember(id: $id, name: $name, title: $title, email: $email, phone: $phone) {
			ok
			error
		}
	}
`;

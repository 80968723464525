import _ from "lodash";
import { objFromArray } from "src/utils/utils";

import { createSlice } from "@reduxjs/toolkit";

import { Project, ProjectInfo } from "../../generated/graphql";

import type { PayloadAction } from "@reduxjs/toolkit";

export interface ProjectsState {
  isLoaded: boolean;
  isModalOpen: boolean;
  projects: {
    byId: Record<string, Project>;
    allIds: string[];
  };
  currentProjectId: string | null;
}

const initialState: ProjectsState = {
  isLoaded: false,
  isModalOpen: false,
  projects: {
    byId: {},
    allIds: [],
  },
  currentProjectId: null,
};

export const slice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    reset(state: ProjectsState) {
      state.projects = {
        byId: {},
        allIds: [],
      };
      state.currentProjectId = null;
    },
    startLoad(state: ProjectsState) {
      state.isLoaded = false;
    },
    getProjects(state: ProjectsState, action: PayloadAction<{ projects: Project[] }>) {
      const { projects } = action.payload;

      state.projects.byId = objFromArray(projects);
      state.projects.allIds = projects.map((v) => v.id);

      state.isLoaded = true;
    },
    createProject(state: ProjectsState, action: PayloadAction<{ project: Project }>) {
      const { project } = action.payload;

      state.projects.byId[project.id] = project;
      state.projects.allIds.unshift(project.id);
    },
    updateProject(state: ProjectsState, action: PayloadAction<{ project: Project }>) {
      const { project } = action.payload;

      state.projects.byId[project.id] = _.merge(state.projects.byId[project.id], project);
    },
    updateProjectInfo(
      state: ProjectsState,
      action: PayloadAction<{
        projectId: string;
        projectInfo: ProjectInfo;
      }>,
    ) {
      const { projectId, projectInfo } = action.payload;

      state.projects.byId[projectId].projectInfo = _.merge(state.projects.byId[projectId].projectInfo, projectInfo);
    },
    deleteProject(state: ProjectsState, action: PayloadAction<{ projectId: string }>) {
      const { projectId } = action.payload;

      state.projects.byId = _.omit(state.projects.byId, projectId);
      _.pull(state.projects.allIds, projectId);
    },
    openModal(state: ProjectsState) {
      state.isModalOpen = true;
    },
    closeModal(state: ProjectsState) {
      state.isModalOpen = false;
    },
    selectProjectById(state: ProjectsState, action: PayloadAction<{ projectId: string }>) {
      state.currentProjectId = action.payload.projectId;
    },
    selectProjectByName(state: ProjectsState, action: PayloadAction<{ projectName: string }>) {
      const { projectName } = action.payload;
      const id = state.projects.allIds.find((id) => state.projects.byId[id].name === projectName);

      if (id) {
        state.currentProjectId = id;
      } else {
        state.currentProjectId = null;
      }
    },
    deselectProject(state: ProjectsState) {
      state.currentProjectId = null;
    },
  },
});

export const reducer = slice.reducer;

import { gql } from "@apollo/client";

export const F_PROJECT_PHASE_PROPERTIES = gql`
  fragment ProjectPhaseProperties on ProjectPhase {
    id
    name
    code
    order
    color
    start
    end
    phaseBudget
    projectId
    workspacePhaseId
    deleted
    deletedById
  }
`;

export const GET_PROJECT_PHASES = gql`
  query GetProjectPhases($projectId: String) {
    GetProjectPhases(projectId: $projectId) {
      ok
      error
      phases {
        ...ProjectPhaseProperties
      }
    }
  }
  ${F_PROJECT_PHASE_PROPERTIES}
`;

export const CREATE_PROJECT_PHASE = gql`
  mutation CreateProjectPhase($start: DateTime!, $end: DateTime!, $projectId: String!, $phaseId: Int!) {
    CreateProjectPhase(start: $start, end: $end, projectId: $projectId, phaseId: $phaseId) {
      ok
      error
      phase {
        ...ProjectPhaseProperties
      }
    }
  }
  ${F_PROJECT_PHASE_PROPERTIES}
`;

export const DELETE_PROJECT_PHASE = gql`
  mutation DeleteProjectPhase($id: Int!, $removeChilds: Boolean!) {
    DeleteProjectPhase(id: $id, removeChilds: $removeChilds) {
      ok
      error
    }
  }
`;

export const UPDATE_PROJECT_PHASE = gql`
  mutation UpdateProjectPhase($id: Int!, $input: UpdateProjectPhaseInput) {
    UpdateProjectPhase(id: $id, input: $input) {
      ok
      error
    }
  }
`;

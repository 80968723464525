import React, { forwardRef } from "react";
import type { FC, ReactNode } from "react";
import clsx from "clsx";
import { Box, Card, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import DragIndicatorSharpIcon from "@mui/icons-material/DragIndicatorSharp";

interface OptionProps {
  optionId?: number;
  dragging: boolean;
  index?: number;
  style?: {};
  className?: string;
  children?: ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    outline: "none",
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  option: {
    "&:hover": {
      backgroundColor: theme.palette.background.paper,
    },
  },
  dragging: {
    backgroundColor: theme.palette.background.paper,
  },
  cover: {
    height: 200,
  },
  badge: {
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
}));

const DraggableOption: FC<OptionProps> = forwardRef(
  ({ optionId, className, dragging, index, style, children, ...rest }, ref) => {
    const classes = useStyles();

    return (
      <div
        className={clsx(classes.root, className)}
        index={index}
        // @ts-ignore
        ref={ref}
        style={style}
        {...rest}
      >
        <Card
          className={clsx(classes.option, { [classes.dragging]: dragging })}
          raised={dragging}
          variant={dragging ? "elevation" : "outlined"}
        >
          <Box display="flex" alignItems="center">
            <DragIndicatorSharpIcon fontSize="small" color="disabled" />
            {children}
          </Box>
        </Card>
      </div>
    );
  },
);

export default DraggableOption;

import gql from "graphql-tag";

export const CREATE_TASK_STATUS_TYPE = gql`
  mutation CreateTaskStatusType($name: String!, $color: String) {
    CreateTaskStatusType(name: $name, color: $color) {
      ok
      error
      result {
        id
        name
        order
        keyStatus
        color
        isHidden
        isHiddenFromCalendar
      }
    }
  }
`;

export const DELETE_TASK_STATUS_TYPE = gql`
  mutation DeleteTaskStatusType($id: Int!) {
    DeleteTaskStatusType(id: $id) {
      ok
      error
    }
  }
`;

export const GET_TASK_STATUS_TYPES = gql`
  query GetTaskStatusTypes {
    GetTaskStatusTypes {
      ok
      error
      taskStatusTypes {
        id
        name
        order
        keyStatus
        color
        isHidden
        isHiddenFromCalendar
      }
    }
  }
`;

export const UPDATE_TASK_STATUS_TYPE = gql`
  mutation UpdateTaskStatusType(
    $id: Int!
    $name: String
    $color: String
    $isHidden: Boolean
    $isHiddenFromCalendar: Boolean
  ) {
    UpdateTaskStatusType(
      id: $id
      name: $name
      color: $color
      isHidden: $isHidden
      isHiddenFromCalendar: $isHiddenFromCalendar
    ) {
      ok
      error
    }
  }
`;

export const UPDATE_TASK_STATUS_ORDER = gql`
  mutation UpdateOnlyOrderTaskStatusType($id: Int!, $position: Int!) {
    UpdateOnlyOrderTaskStatusType(id: $id, position: $position) {
      ok
      error
    }
  }
`;

export const CREATE_TASK_CATEGORY_TYPE = gql`
  mutation CreateTaskCategoryType($name: String!, $color: String) {
    CreateTaskCategoryType(name: $name, color: $color) {
      ok
      error
      result {
        id
        name
        order
        color
      }
    }
  }
`;

export const DELETE_TASK_CATEGORY_TYPE = gql`
  mutation DeleteTaskCategoryType($id: Int!) {
    DeleteTaskCategoryType(id: $id) {
      ok
      error
    }
  }
`;

export const GET_TASK_CATEGORY_TYPES = gql`
  query GetTaskCategoryTypes {
    GetTaskCategoryTypes {
      ok
      error
      taskCategoryTypes {
        id
        name
        order
        color
      }
    }
  }
`;

export const UPDATE_TASK_CATEGORY_TYPE = gql`
  mutation UpdateTaskCategoryType($id: Int!, $name: String, $color: String) {
    UpdateTaskCategoryType(id: $id, name: $name, color: $color) {
      ok
      error
    }
  }
`;

export const UPDATE_TASK_CATEGORY_ORDER = gql`
  mutation UpdateOnlyOrderTaskCategoryType($id: Int!, $position: Int!) {
    UpdateOnlyOrderTaskCategoryType(id: $id, position: $position) {
      ok
      error
    }
  }
`;

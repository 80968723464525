import React, { ChangeEvent } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton } from "@mui/material";
import useStyles from "./styles";

type Props = {
	// Search input field의 state
	value: string;

	// Search input field의 변경 사항을 handle 하는 함수
	handleChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
};

export default function SearchUserBar(props: Props) {
	const classes = useStyles();

	return (
		<FormControl variant="outlined" margin="dense" style={{ width: "100%" }}>
			<InputLabel>Search User</InputLabel>
			<OutlinedInput
				label={"Search User"}
				placeholder={"Search User"}
				onChange={props.handleChange}
				value={props.value}
				margin="dense"
				endAdornment={
					<InputAdornment position="end">
						<IconButton aria-label={`search user`} edge="end" size="small">
							<SearchIcon className={classes.addButton} />
						</IconButton>
					</InputAdornment>
				}
			/>
		</FormControl>
	);
}

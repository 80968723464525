import { LinearProgress } from "@mui/material";
import { FC, ReactNode, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "src/hooks";
import { getPhases, getUserTitles, getWorkspaceMembers } from "src/Store/action-creators";

interface WorkspaceFetchProps {
  children?: ReactNode;
}

const WorkspaceFetch: FC<WorkspaceFetchProps> = ({ children }) => {
  const dispatch = useDispatch();
  const { isLoaded: membersLoaded } = useTypedSelector((state) => state.members);
  const { isLoaded: userTitlesLoaded } = useTypedSelector((state) => state.userTitles);
  const { isLoaded: phasesLoaded } = useTypedSelector((state) => state.phases);

  useEffect(() => {
    dispatch(getWorkspaceMembers());
    dispatch(getUserTitles());
    dispatch(getPhases());
  }, []);

  if (!membersLoaded || !userTitlesLoaded || !phasesLoaded) {
    return <LinearProgress />;
  }

  return <>{children}</>;
};

export default WorkspaceFetch;

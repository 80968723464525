import React, { useRef, useState } from "react";
import type { FC } from "react";
import { useTheme, useMediaQuery, Box, Popover, Typography, MenuList, MenuItem, Theme, Stack } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { getContrastFontColor, getDefaultColorCodes } from "../../../theme/colors";
import tinycolor from "tinycolor2";
import { ChevronDown } from "react-feather";
import TextLabelChip from "../TextLabelChip";

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    cursor: "pointer",
    color: (props: ButtonValue) => {
      return getContrastFontColor(props.color || getDefaultColorCodes());
    },
    backgroundColor: (props: ButtonValue) => {
      return props.color || getDefaultColorCodes();
    },
    "&:hover": {
      backgroundColor: (props: ButtonValue) => {
        return tinycolor(props.color || getDefaultColorCodes())
          .darken()
          .toHexString();
      },
    },
  },
}));

type ButtonValue = {
  id: string | number;
  name: string;
  color?: string | null;
  [key: string]: any;
};

interface SelectButtonProps {
  value: ButtonValue;
  options: ButtonValue[];
  onChange: (value: ButtonValue) => void;
}

const SelectButton: FC<SelectButtonProps> = ({ value, options, onChange }) => {
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down("md"));
  const buttonRef = useRef<HTMLDivElement>(null);

  const [current, setCurrent] = useState(value);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const classes = useStyles(current);

  const handleClick = (e: any) => {
    setAnchorEl(buttonRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (newValue: ButtonValue) => {
    onChange(newValue);
    setCurrent(newValue);
    handleClose();
  };

  return (
    <>
      <Stack
        direction="row"
        flexWrap="wrap"
        alignItems="center"
        sx={{ p: 1 }}
        borderRadius="3px"
        onClick={handleClick}
        className={classes.button}
        //@ts-ignore
        ref={buttonRef}
      >
        <Typography variant="h6">{current.name}</Typography>
        <Box p={0.4} />
        <ChevronDown size="16px" strokeWidth="3px" />
      </Stack>
      <Popover
        id={"as"}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: -10,
          horizontal: "left",
        }}
      >
        <MenuList>
          {options.map((option) => (
            <MenuItem onClick={() => handleChange(option)} key={option.id}>
              <Box display="flex">
                <TextLabelChip key={option.id} name={option.name} color={option.color} />
                <Box flexGrow={1} />
              </Box>
            </MenuItem>
          ))}
        </MenuList>
      </Popover>
    </>
  );
};

export default SelectButton;

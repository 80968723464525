import clsx from "clsx";
import { format, isSameDay, isValid, isWithinInterval } from "date-fns";
import { Formik } from "formik";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { GET_TIMESHEET_WEEK_TASKS } from "src/Api/Task/Task.queries";
import { GET_WEEK_TIMESHEET, UPDATE_TIMESHEET } from "src/Api/Timesheet/Timesheet.queries";
import { Page, Scrollbar } from "src/Components/Atoms";
import { timesheetCategoryEnum } from "src/enum/timesheetCategoryEnum";
import { useTypedSelector } from "src/hooks";
import { changeProjectIsNull } from "src/Store/action-creators";
import { closeTaskTableModal, getTasksByTimesheet, openTaskTableModal } from "src/Store/reducers/taskReducer";

import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import arrowIosBackFill from "@iconify/icons-eva/arrow-ios-back-fill";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import { Icon } from "@iconify/react";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import {
  Box,
  Button,
  Container,
  Dialog,
  Divider,
  IconButton,
  LinearProgress,
  Paper,
  Stack,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import EmptyContent from "../../Components/Atoms/EmptyContent";
import {
  GetTimesheetWeekTasksQuery,
  GetTimesheetWeekTasksQueryVariables,
  GetWeekTimesheetQuery,
  GetWeekTimesheetQueryVariables,
  Timesheet,
  TimesheetStatus,
  UpdateTimesheetMutation,
  UpdateTimesheetMutationVariables,
} from "../../generated/graphql";
import TaskTableModal from "../TaskTable/TaskTableModal";
import TimesheetsList from "./components/TimesheetsList";
import Header from "./Header";
import TimesheetUserDatail from "./TimesheetUserDetail";
import TimesheetUserTable from "./TimesheetUserTable";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  dayWrapper: {
    position: "relative",
  },
  day: {
    width: 36,
    height: 36,
    fontSize: theme.typography.caption.fontSize,
    margin: "0 2px",
    color: "inherit",
  },
  customDayHighlight: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: "2px",
    right: "2px",
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: "50%",
  },
  nonCurrentMonthDay: {
    color: theme.palette.text.disabled,
  },
  highlightNonCurrentMonthDay: {
    color: "#676767",
  },
  highlight: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  firstHighlight: {
    extend: "highlight",
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  },
  endHighlight: {
    extend: "highlight",
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  },
}));

interface TimesheetsAdminProps {}

const init_data = {
  id: -1,
  monday: null,
  tuesday: null,
  wednesday: null,
  thursday: null,
  friday: null,
  saturday: null,
  sunday: null,
  weekStartDate: moment().startOf("week").toDate(),
  weekEndDate: moment().endOf("week").toDate(),
  status: TimesheetStatus.Pending,
  user: null,
  totalTimeSpent: null,
};

const TimesheetsAdmin: FC<TimesheetsAdminProps> = (props: TimesheetsAdminProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const mobileDevice = useMediaQuery(theme.breakpoints.down("md"));
  const timesheetTasks = useTypedSelector((state) => state.tasks.timesheetTaskIds);
  const memberIds = useTypedSelector((state) => state.members.members.allIds);

  const { tasks, isTableModalOpen } = useTypedSelector((state) => state.tasks);
  const [queries, setQueries] = useState<string[]>([]);
  const [query, setQuery] = useState("");
  const [date, setDate] = useState<Date>(moment().toDate());
  const [isCommentModalOpen, setIsCommentModalOpen] = useState<boolean>(false);
  const [isUserModalOpen, setIsUserModalOpen] = useState<boolean>(false);
  const [data, setData] = useState<Timesheet>(init_data);
  const [currentTimesheets, setCurrentTimesheets] = useState<Timesheet[] | null>([]);
  const { currentProjectId } = useTypedSelector((state) => state.projects);
  const [currentTimesheetId, setCurrentTimesheetId] = useState<number | null>(null);

  const { enqueueSnackbar } = useSnackbar();

  const handleTableOpen = (): void => {
    dispatch(openTaskTableModal());
  };

  const handleOpen = (): void => {
    setIsUserModalOpen(true);
  };

  const handleClose = (): void => {
    dispatch(changeProjectIsNull());
    dispatch(closeTaskTableModal());
  };

  const handleInputQuery = (query: string): void => {
    setQuery(query);
  };

  const handleAddQuery = () => {
    setQueries((queries) => [...queries, query]);
    setQuery("");
  };

  const handleDeleteQuery = (query: string) => {
    setQueries((queries) => queries.filter((v) => v !== query));
  };

  const handleWeekChange = (date: Date) => {
    setDate(moment(date).weekday(1).toDate());
  };

  const formatWeekSelectLabel = (date: Date, invalidLabel: string) => {
    return date && isValid(date) ? `Week of ${format(moment(date).weekday(0).toDate(), "MMM do")}` : invalidLabel;
  };

  const renderWrappedWeekDay = (date: Date, selectedDate: Date | null, dayInCurrentMonth: boolean) => {
    const start = moment(selectedDate).weekday(0).toDate();
    const end = moment(selectedDate).weekday(6).toDate();

    const dayIsBetween = isWithinInterval(date, { start, end });
    const isFirstDay = isSameDay(date, start);
    const isLastDay = isSameDay(date, end);

    const wrapperClassName = clsx({
      [classes.highlight]: dayIsBetween,
      [classes.firstHighlight]: isFirstDay,
      [classes.endHighlight]: isLastDay,
    });

    const dayClassName = clsx(classes.day, {
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
    });

    return (
      <div className={wrapperClassName}>
        <IconButton className={dayClassName} size="large">
          <span> {format(date, "d")} </span>
        </IconButton>
      </div>
    );
  };

  useQuery<GetWeekTimesheetQuery, GetWeekTimesheetQueryVariables>(GET_WEEK_TIMESHEET, {
    variables: {
      userIds: memberIds,
      weekStartDate: moment(date).startOf("week").toDate(),
    },
    fetchPolicy: "network-only",
    pollInterval: 10000,
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data?.GetWeekTimesheet.ok && data?.GetWeekTimesheet.timesheets) {
        setCurrentTimesheets(data.GetWeekTimesheet.timesheets || []);
      } else setCurrentTimesheets([]);
    },
  });

  const [getTimesheetWeekTasks, { loading: TaskLoading }] = useLazyQuery<
    GetTimesheetWeekTasksQuery,
    GetTimesheetWeekTasksQueryVariables
  >(GET_TIMESHEET_WEEK_TASKS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data.GetTimesheetWeekTasks.ok && data.GetTimesheetWeekTasks.tasks) {
        dispatch(getTasksByTimesheet(data.GetTimesheetWeekTasks.tasks));
      }
    },
  });

  const [getWeekTimesheet, { loading }] = useLazyQuery<GetWeekTimesheetQuery, GetWeekTimesheetQueryVariables>(
    GET_WEEK_TIMESHEET,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        if (data?.GetWeekTimesheet.ok && data?.GetWeekTimesheet.timesheets) {
          setCurrentTimesheets(data.GetWeekTimesheet.timesheets || []);
        } else setCurrentTimesheets([]);
      },
    },
  );

  const [updateTimesheet, { loading: updateLoading }] = useMutation<
    UpdateTimesheetMutation,
    UpdateTimesheetMutationVariables
  >(UPDATE_TIMESHEET, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      getWeekTimesheet({
        variables: {
          userIds: memberIds,
          weekStartDate: moment(date).startOf("week").toDate(),
        },
      });
    },
  });

  const handleCreateClick = () => {
    currentTimesheets?.forEach((t) => {
      updateTimesheet({ variables: { id: t.id || -1, status: "accepted" } });
    });
  };

  const handleAccept = (id: number) => {
    updateTimesheet({ variables: { id: id, status: "accepted" } });
  };

  const handleReject = (id: number) => {
    updateTimesheet({ variables: { id: id, status: "rejected" } });
  };

  const handlePrevDate = () => {
    setDate((value) => moment(value).subtract(7, "d").toDate());
  };

  const handleNextDate = () => {
    setDate((value) => moment(value).add(7, "d").toDate());
  };

  const handleToday = () => {
    setDate(new Date());
  };

  useEffect(() => {
    getWeekTimesheet({
      variables: {
        userIds: memberIds,
        weekStartDate: moment(date).startOf("week").toDate(),
      },
    });
  }, [date, getWeekTimesheet]);

  useEffect(() => {
    getTimesheetWeekTasks({
      variables: {
        date: moment(date).subtract(1, "d").toDate(),
        timesheetId: currentTimesheetId || null,
      },
    });
  }, [currentTimesheetId]);

  if (loading) {
    return <LinearProgress style={{ position: "relative", top: "100%", left: "50%" }} />;
  }
  return (
    <>
      <Stack direction="column" sx={{ mt: 1, mb: 3 }}>
        <Typography variant="h6">Timesheet Week {moment(date).weeks().toString()}</Typography>
        <Stack direction="row" spacing={1}>
          <TextField
            sx={{ width: "200px" }}
            size="small"
            variant="outlined"
            value={
              moment(date).weekday(0).format("MM/DD/yyyy").toString() +
              " ~ " +
              moment(date).weekday(6).format("MM/DD/yyyy").toString()
            }
          />
          <IconButton onClick={handlePrevDate}>
            <Icon icon={arrowIosBackFill} width={18} height={18} />
          </IconButton>

          <IconButton onClick={handleNextDate}>
            <Icon icon={arrowIosForwardFill} width={18} height={18} />
          </IconButton>

          <Button size="small" color="primary" variant="contained" onClick={handleToday} sx={{ mx: 0.5 }}>
            Today
          </Button>
        </Stack>
      </Stack>
      <Typography variant="subtitle1">Timesheets</Typography>
      {currentTimesheets && currentTimesheets.length > 0 ? (
        <Paper variant="outlined" sx={{ overflow: "hidden", mt: 1 }}>
          <Scrollbar>
            <TimesheetsList
              date={date}
              timesheets={currentTimesheets}
              onAccept={handleAccept}
              onReject={handleReject}
            />
          </Scrollbar>
        </Paper>
      ) : (
        <EmptyContent title={"Empty Timesheets"} />
      )}
    </>
  );
};

export default TimesheetsAdmin;

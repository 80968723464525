export enum projectInfoEnum {
    name='Project Name for Task Management',
    serviceTypeId = 'Service Type',
    publishedDate = 'Published Date',
    publisherId = 'Name of Publisher',
    description = 'Project Description',
    projectName = 'Project Name',
    country = 'Country',
    city = 'City',
    state ='State',
    programId = 'Program',
    size = 'Size',
    publishedCost = 'Published Cost of the Project',
    ownerId = 'Owner',
    architectId = 'Architect',
    generalContractorId = 'General Contractor',
    overallStartDate = 'Start date',
    overallEndDate = 'End date',
    website = 'Website',
    clientId = 'Client', 
    teamSize = 'Team Size', 
    leadMemberId = 'Project Lead', 
    projectAssigneesIds='Project Members',
    phaseId = 'Phase', 
    startDate = 'Start duration', 
    endDate = 'End duration', 
    clientContactsIds = 'Client Contact', 
    mainToolId = 'Main tool', 
    subToolId = 'Sub tool', 
    remark = 'Remark',
    contractValue='Contract Value', 
    paymentTermId='Payment Term', 
    complexityLevelId='Complexity', 
    riskId='Risk', 
    riskDescription='Risk description', 
    otherComments='Other Comments',
    strategy='Strategies',
    initialModel = 'Initial Model',
    requirementDoc= 'Requirement Doc',
    projectSchedule='Project Schedule',
    organizationChart='Organization Chart',
    bimModelStructure='BIM Model Structure',
}

export enum ProjectTableName {
    basicInfo ="Basic Information",
    projectInfo = "Project Information",
    synteService = "Syntegrate Service",
    synteServiceManager = "Syntegrate Service(Manager Only)",
    projectManagement="Project Management"
}
import gql from "graphql-tag";

export const F_USER_TITLE = gql`
  fragment UserTitle on UserTitle {
    id
    title
    currency
    rate
  }
`;

export const GET_USER_TITLES = gql`
  query GetUserTitles {
    GetUserTitles {
      ...UserTitle
    }
  }
  ${F_USER_TITLE}
`;

export const CREATE_USER_TITLE = gql`
  mutation CreateUserTitle($title: String!, $rate: Float, $currency: String) {
    CreateUserTitle(title: $title, rate: $rate, currency: $currency) {
      ok
      error
      result {
        ...UserTitle
      }
    }
  }
  ${F_USER_TITLE}
`;

export const UPDATE_USER_TITLE = gql`
  mutation UpdateUserTitle($id: Int!, $title: String, $rate: Float, $currency: String) {
    UpdateUserTitle(id: $id, title: $title, rate: $rate, currency: $currency) {
      ok
      error
    }
  }
`;

export const DELETE_USER_TITLE = gql`
  mutation DeleteUserTitle($id: Int!) {
    DeleteUserTitle(id: $id) {
      ok
      error
    }
  }
`;

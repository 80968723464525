import gql from "graphql-tag";

export const GET_TOTAL_TASK_COUNTS = gql`
  query GetTotalTaskCounts($projectId: String!) {
    GetTotalTaskCounts(projectId: $projectId)
  }
`;

export const GET_COMPLETED_TASK_COUNTS = gql`
  query GetCompletedTaskCounts($projectId: String!) {
    GetCompletedTaskCounts(projectId: $projectId)
  }
`;

export const GET_UNCOMPLETED_TASK_COUNTS = gql`
  query GetUncompletedTaskCounts($projectId: String!) {
    GetUncompletedTaskCounts(projectId: $projectId)
  }
`;

export const GET_TOTAL_PROGRESS = gql`
  query GetTotalProgress($projectId: String!) {
    GetTotalProgress(projectId: $projectId) {
      progress
      counts {
        statusId
        count
      }
    }
  }
`;

export const GET_TIME_SPENT = gql`
  query GetTimeSpent($projectId: String!, $input: DateRangeInput) {
    GetTimeSpent(projectId: $projectId, input: $input)
  }
`;

export const GET_TIME_PLANNED = gql`
  query GetTimePlanned($projectId: String!, $input: DateRangeInput) {
    GetTimePlanned(projectId: $projectId, input: $input)
  }
`;

export const GET_TEAM_ACTIVITIES = gql`
  query GetTeamActivities($projectId: String!, $input: DateRangeInput) {
    GetTeamActivities(projectId: $projectId, input: $input) {
      userId
      hours
    }
  }
`;

export const GET_TASK_HOURS_BY_CATEGORY = gql`
  query GetTaskHoursByCategory($projectId: String!, $input: DateRangeInput) {
    GetTaskHoursByCategory(projectId: $projectId, input: $input) {
      categoryId
      hours
    }
  }
`;

export const GET_TEAM_WORKLOAD = gql`
  query GetTeamWorkload($projectId: String!, $input: GetTeamWorkloadInput) {
    GetTeamWorkload(projectId: $projectId, input: $input) {
      date
      hours
    }
  }
`;

export const GET_TASK_COMPLETION_OVERTIME = gql`
  query GetTaskCompletionOverTime($projectId: String!, $input: DateRangeInput) {
    GetTaskCompletionOverTime(projectId: $projectId, input: $input) {
      date
      uncompleted
      completed
    }
  }
`;

export const GET_PROJECT_BUDGET_EXPENSE = gql`
  query GetProjectBudgetExpense($projectId: String!, $input: DateRangeInput) {
    GetProjectBudgetExpense(projectId: $projectId, input: $input)
  }
`;

export const GET_PROJECT_BUDGET_EXPENSE_BY_USER = gql`
  query GetProjectBudgetExpenseByUser($projectId: String!, $input: DateRangeInput) {
    GetProjectBudgetExpenseByUser(projectId: $projectId, input: $input) {
      userId
      expense
    }
  }
`;

export const GET_PROJECT_BUDGET_EXPENSE_BY_CATEGORY = gql`
  query GetProjectBudgetExpenseByCategory($projectId: String!, $input: DateRangeInput) {
    GetProjectBudgetExpenseByCategory(projectId: $projectId, input: $input) {
      categoryId
      expense
    }
  }
`;

export const GET_PROJECT_PHASE_BUDGET_EXPENSE = gql`
  query GetProjectPhaseBudgetExpense($projectId: String!, $input: DateRangeInput) {
    GetProjectPhaseBudgetExpense(projectId: $projectId, input: $input) {
      phaseId
      expense
    }
  }
`;

export const GET_PROJECT_PHASE_BUDGET_EXPENSE_BY_CATEGORY = gql`
  query GetProjectPhaseBudgetExpenseByCategory($projectId: String!, $phaseId: Int!, $input: DateRangeInput) {
    GetProjectPhaseBudgetExpenseByCategory(projectId: $projectId, phaseId: $phaseId, input: $input) {
      categoryId
      expense
    }
  }
`;

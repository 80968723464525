import React from "react";
import type { FC } from "react";
import { useTheme, useMediaQuery, Box, Typography, Tooltip, Theme } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "&.MuiLinearProgress-colorPrimary:not(.MuiLinearProgress-buffer)": {
      backgroundColor: "transparent",
    },
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  inProgress: {
    "&.MuiLinearProgress-colorPrimary:not(.MuiLinearProgress-buffer)": {
      backgroundColor: theme.palette.grey[200],
    },
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#6495ED",
    },
  },
}));

interface CompletionProgressBarProps {
  total: number;
  done: number;
  inProgress: number;
}

interface PBoxProps {
  type: "todo" | "in_progress" | "done";
  value: number;
}

const ProgressBox: FC<PBoxProps> = React.forwardRef(({ type, value }, ref) => {
  function color(params: "todo" | "in_progress" | "done") {
    switch (params) {
      case "todo":
        return "#3AAE9F";
      case "in_progress":
        return "#6558F5";
      case "done":
        return "#D3455B";
    }
  }

  function zIndex(params: "todo" | "in_progress" | "done") {
    switch (params) {
      case "todo":
        return 100;
      case "in_progress":
        return 101;
      case "done":
        return 102;
    }
  }

  return (
    <Box
      borderRadius="5px"
      position="absolute"
      width={value.toString() + "%"}
      height="10px"
      zIndex={zIndex(type)}
      style={{ backgroundColor: color(type) }}
      //@ts-ignore
      ref={ref}
    />
  );
});

const CompletionProgressBar: FC<CompletionProgressBarProps> = ({ total, done, inProgress }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down("md"));

  const _done = total === 0 ? 0 : (done * 100) / total;
  const _inProgress = total === 0 ? 0 : (inProgress * 100) / total;

  return (
    <Box display="flex" width="100%" alignItems="center">
      <Box mr={3} width="100%" height="100%" position="relative" top={"-5px"}>
        <LightTooltip title={`To do or others: ${total - done - inProgress} of ${total} tasks`}>
          <ProgressBox type={"todo"} value={100} />
        </LightTooltip>
        <LightTooltip title={`In Progress: ${inProgress} of ${total} tasks`}>
          <ProgressBox type={"in_progress"} value={_done + _inProgress} />
        </LightTooltip>
        <LightTooltip title={`Done: ${done} of ${total} tasks`}>
          <ProgressBox type={"done"} value={_done} />
        </LightTooltip>
      </Box>
      <Box width="40px">
        <Typography variant="caption" style={{ width: "30px" }}>{`${Math.floor(_done).toString()} %`}</Typography>
      </Box>
    </Box>
  );
};

export default CompletionProgressBar;

import { gql } from "@apollo/client";

export const F_PROJECT_BUDGET = gql`
  fragment ProjectBudgetProperties on ProjectBudget {
    id
    totalFee
    consultantBudget
    projectFee
    profit
    budget
    projectId
  }
`;

export const GET_PROJECT_BUDGET = gql`
  query GetProjectBudget($projectId: String!) {
    GetProjectBudget(projectId: $projectId) {
      ok
      error
      projectBudget {
        ...ProjectBudgetProperties
      }
    }
  }
  ${F_PROJECT_BUDGET}
`;

export const CREATE_PROJECT_BUDGET = gql`
  mutation CreateProjectBudget($projectId: String!) {
    CreateProjectBudget(projectId: $projectId) {
      ok
      error
      projectBudget {
        ...ProjectBudgetProperties
      }
    }
  }
  ${F_PROJECT_BUDGET}
`;

export const UPDATE_PROJECT_BUDGET = gql`
  mutation UpdateProjectBudget($projectId: String!, $values: ProjectBudgetInput!) {
    UpdateProjectBudget(projectId: $projectId, values: $values) {
      ok
      error
      projectBudget {
        ...ProjectBudgetProperties
      }
    }
  }
  ${F_PROJECT_BUDGET}
`;

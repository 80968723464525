import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { objFromArray } from "src/utils/utils";
import _ from "lodash";
import { UserTitle } from "src/types/UserTitleType";

export interface UserTitleState {
  isLoaded: boolean;
  titles: {
    byId: Record<number, UserTitle>;
    allIds: number[];
  };
}

const initialState: UserTitleState = {
  isLoaded: false,
  titles: {
    byId: {},
    allIds: [],
  },
};

export const UserTitleSlice = createSlice({
  name: "userTitles",
  initialState,
  reducers: {
    reset(state: UserTitleState) {
      state = initialState;
    },
    changeLoadState(state: UserTitleState, action: PayloadAction<{ isLoaded: boolean }>) {
      state.isLoaded = action.payload.isLoaded;
    },
    getUserTitles(state: UserTitleState, action: PayloadAction<{ userTitles: UserTitle[] }>) {
      const { userTitles } = action.payload;

      state.titles.byId = objFromArray(userTitles);
      state.titles.allIds = userTitles.map((v) => v.id);
      state.isLoaded = true;
    },
    createUserTitle(state: UserTitleState, action: PayloadAction<{ userTitle: UserTitle }>) {
      const { userTitle } = action.payload;

      state.titles.byId[userTitle.id] = userTitle;
      state.titles.allIds.push(userTitle.id);
    },
    updateUserTitle(state: UserTitleState, action: PayloadAction<{ userTitle: UserTitle }>) {
      const { userTitle } = action.payload;

      state.titles.byId[userTitle.id] = userTitle;
    },
    deleteUserTitle(state: UserTitleState, action: PayloadAction<{ id: number }>) {
      const { id } = action.payload;

      state.titles.byId = _.omit(state.titles.byId, id);
      _.pull(state.titles.allIds, id);
    },
  },
});

export const reducer = UserTitleSlice.reducer;

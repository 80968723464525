import { createBrowserHistory } from "history";
import { createStore, applyMiddleware } from "redux";
import thunk, { ThunkAction } from "redux-thunk";
import { Action, configureStore } from "@reduxjs/toolkit";
import reducers from "./reducers";
import { useDispatch as useReduxDispatch } from "react-redux";

export const history = createBrowserHistory();
export const store = createStore(reducers, {}, applyMiddleware(thunk.withExtraArgument({ history: history })));

// export const store = configureStore({
//   reducer: reducers,
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware().concat(
//       thunk.withExtraArgument({ history: history }),
//     ),
// });

export type RootState = ReturnType<typeof store.getState>;

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
export type AppDispatch = typeof store.dispatch;

import React, { ReactNode } from "react";
import { Theme, Popover } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

type Props = {
    anchor: HTMLElement | null;
    onClose: () => void;
    children: ReactNode;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        popover: {
            pointerEvents: "none",
        },
        paper: {
            backgroundColor: "transparent",
            boxShadow: "none",
        },
    })
);

export default function CustomPopover(props: Props) {
    const classes = useStyles();

    const open = Boolean(props.anchor);

    return (
        <div>
            <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                    paper: classes.paper,
                }}
                open={open}
                anchorEl={props.anchor}
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "center",
                    horizontal: "left",
                }}
                onClose={props.onClose}
                disableRestoreFocus
            >
                {props.children}
            </Popover>
        </div>
    );
}

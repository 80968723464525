import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import useStyles from "src/Style/dialogStyle";

export default function RowStack(props: any) {
	const classes = useStyles();
	const { name, children } = props;

	return (
		<Grid container direction="row">
			{name ? (
				<Box component="div" display="inline" alignSelf="center" className={classes.row}>
					<Typography style={{ display: "inline" }}>{name} : </Typography>
				</Box>
			) : (
				<></>
			)}
			<Box component="div" display="inline" alignSelf="center" style={{ marginLeft: name ? 20 : 0 }} className={classes.row}>
				{children}
			</Box>
		</Grid>
	);
}

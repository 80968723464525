import React, { useState } from "react";
import type { FC, ReactNode } from "react";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import TopBar from "./TopBar";
import NavBar from "./NavBar";
import { Header } from "src/Components/Organisms";
import { styled, Theme, useTheme } from "@mui/material";
import { Outlet } from "react-router-dom";
import SideBar from "./SideBar";
import useCollapseDrawer from "../../hooks/useCollapseDrawer";

interface WorkspaceLayoutProps {
  children?: ReactNode;
}

const APP_BAR_MOBILE = 50;
const APP_BAR_DESKTOP = 50;

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "visible",
});

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "visible",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const WorkspaceLayout: FC<WorkspaceLayoutProps> = ({ children }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const { collapseClick } = useCollapseDrawer();

  const [isMobileNavOpen, setMobileNavOpen] = useState<boolean>(
    window.localStorage.getItem("navIsOpen") === "false" ? false : true,
  );

  const handleMoblieNavOpen = () => {
    window.localStorage.setItem("navIsOpen", "true");
    setMobileNavOpen(true);
  };

  const handleMobileNavClose = () => {
    window.localStorage.setItem("navIsOpen", "false");
    setMobileNavOpen(false);
  };

  return (
    <RootStyle>
      {/* <Header openMobile={isMobileNavOpen} onNavOpen={handleMoblieNavOpen} /> */}
      {/* <NavBar onMobileClose={handleMobileNavClose} openMobile={isMobileNavOpen} /> */}
      <TopBar onOpenSidebar={() => setOpen(true)} />
      <SideBar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <MainStyle
        sx={{
          transition: theme.transitions.create("margin", {
            duration: theme.transitions.duration.complex,
          }),
          ...(collapseClick && {
            ml: "102px",
          }),
        }}
      >
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
};

export default WorkspaceLayout;

import React, { useEffect, useState } from "react";
import type { FC } from "react";
import BaseOptionChart from "src/Style/BaseOptionChart";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { getRecordTimesheets } from "src/utils/avaxBlockchainWyzrs";
import moment from "moment";
import { LinearProgress } from "@mui/material";
import { merge } from "lodash";
import { BaseCard } from "src/Pages/ProjectDashboard/components";

interface RecordItem {
  user: string;
  project: string;
  time: string;
}

interface BaseCardProps {
  direction?: "row" | "column";
  helperText?: string;
}
const RecordChart: FC<BaseCardProps> = ({ direction = "row", helperText }) => {
  const [data, setData] = useState<RecordItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchData = async () => {
    try {
      const rawData = await getRecordTimesheets();
      console.log("rawData:", rawData);

      const refinedData = rawData.map(([bigNumber, workerName, description]) => ({
        user: workerName,
        project: description,
        time: bigNumber._isBigNumber ? moment.unix(bigNumber.toNumber()).format("MM/DD/YYYY HH:mm") : bigNumber,
      }));
      console.log(refinedData, "refined");
      setData(refinedData);
      setLoading(false);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const processData = (items: RecordItem[]) => {
    const counts = items.reduce((acc: { [key: string]: number }, { time }) => {
      const date = moment(time, "MM/DD/YYYY HH:mm").format("YYYY-MM-DD"); // 'moment'로 날짜 파싱
      acc[date] = (acc[date] || 0) + 1;
      return acc;
    }, {});

    return Object.entries(counts)
      .map(([date, count]) => ({
        x: date,
        y: count,
      }))
      .sort((a, b) => new Date(a.x).getTime() - new Date(b.x).getTime()); // 날짜 문자열을 타임스탬프로 변환하여 정렬
  };

  const chartOptions = merge(BaseOptionChart(), {
    // ... 이전 chartOptions 설정 ...
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      min: 0, // y축의 최소값을 0으로 설정
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
      style: "hollow",
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100],
      },
    },
    // 기타 필요한 옵션
  });
  if (loading) {
    return <div></div>;
  }
  return (
    <BaseCard name="Total Record" direction="column">
      {loading ? (
        <div></div>
      ) : (
        <ReactApexChart
          type="area"
          series={[
            {
              name: "Total record",
              data: processData(data),
            },
          ]}
          options={chartOptions}
          height={320}
        />
      )}
    </BaseCard>
  );
};

export default RecordChart;

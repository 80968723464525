import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { objFromArray } from "src/utils/utils";
import { ProjectTaskStatus } from "../../generated/graphql";

export interface ProjectTaskStatusState {
  isAllLoaded: boolean;
  isLoaded: { byProject: Record<string, boolean> };
  taskStatuses: {
    byId: Record<number, ProjectTaskStatus>;
    byProject: Record<string, number[]>;
  };
}

const initialState: ProjectTaskStatusState = {
  isAllLoaded: false,
  isLoaded: {
    byProject: {},
  },
  taskStatuses: {
    byId: {},
    byProject: {},
  },
};

export const projectTaskStatusSlice = createSlice({
  name: "projectTaskStatus",
  initialState,
  reducers: {
    reset(state: ProjectTaskStatusState) {
      state = initialState;
    },
    setLoaded(state: ProjectTaskStatusState, action: PayloadAction<{ loaded: boolean }>) {
      state.isAllLoaded = action.payload.loaded;
    },
    getTaskStatuses(state: ProjectTaskStatusState, action: PayloadAction<{ taskStatuses: ProjectTaskStatus[] }>) {
      const { taskStatuses } = action.payload;
      state.taskStatuses.byId = _.merge(state.taskStatuses.byId, objFromArray(taskStatuses));

      const _groupByProjectId = _.groupBy(taskStatuses, "projectId");
      _.forEach(_groupByProjectId, (val, projectId) => {
        state.taskStatuses.byProject[projectId] = val.sort((a, b) => a.order || 0 - (b.order || 0)).map((v) => v.id);
        state.isLoaded.byProject[projectId] = true;
      });
    },
    createProjectTaskStatus(state: ProjectTaskStatusState, action: PayloadAction<{ taskStatus: ProjectTaskStatus }>) {
      const { taskStatus } = action.payload;

      state.taskStatuses.byId[taskStatus.id] = taskStatus;
      state.taskStatuses.byProject[taskStatus.projectId].push(taskStatus.id);
    },
    updateProjectTaskStatus(state: ProjectTaskStatusState, action: PayloadAction<{ taskStatus: ProjectTaskStatus }>) {
      const { taskStatus } = action.payload;

      _.merge(state.taskStatuses.byId[taskStatus.id], taskStatus);
    },
    updateProjectTaskStatusOrder(
      state: ProjectTaskStatusState,
      action: PayloadAction<{ id: number; position: number }>,
    ) {
      const { id, position } = action.payload;
      const projectId = state.taskStatuses.byId[id].projectId;

      _.pull(state.taskStatuses.byProject[projectId], id);
      state.taskStatuses.byProject[projectId].splice(position, 0, id);
    },
    deleteProjectTaskStatus(state: ProjectTaskStatusState, action: PayloadAction<{ id: number }>) {
      const { id } = action.payload;

      const projectId = state.taskStatuses.byId[id].projectId;

      state.taskStatuses.byId = _.omit(state.taskStatuses.byId, id);
      _.pull(state.taskStatuses.byProject[projectId], id);
    },
  },
});

export const reducer = projectTaskStatusSlice.reducer;

import moment from "moment";
import React, { useRef, useState } from "react";
import ReactDOM from "react-dom";
import ReactQuill from "react-quill";
import { useDispatch } from "react-redux";

import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  DialogActions,
  InputAdornment,
  InputBase,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { WorkspaceUserBase, WorkspaceUserMeta } from "../../../generated/graphql";
import { useTypedSelector } from "../../../hooks";
import { useProperties } from "../../../Store/hooks";
import { closeCreateTaskDialog } from "../../../Store/reducers/taskReducer";
import { getDefaultColorCodes } from "../../../theme/colors";
import { Footer, TextLabelChip, UserAvatar } from "../../Atoms";
import { DetailModal } from "../../Molecules";
import AssigneesRow from "../TaskDetail/components/AssigneesRow";
import DetailInputBase from "../TaskDetail/components/DetailInputBase";
import { useCreateTaskDialog } from "./hooks/useCreateTaskDialog";

import type { FC } from "react";
const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

interface CreateTaskDialogProps {
  projectId?: string;
}

type ColorBoxType = {
  color?: string | null;
};

const ColorBox: FC<ColorBoxType> = ({ color }) => {
  return <Box width="20px" height="20px" style={{ backgroundColor: color || getDefaultColorCodes() }} />;
};

const CreateTaskDialog: FC<CreateTaskDialogProps> = ({ projectId }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();

  const { isCreateTaskDialogOpen } = useTypedSelector((state: { tasks: any }) => state.tasks);
  const {
    state: { project, category, status, name, description, phase, milestone, assignees, startDate, dueDate },
    loading,
    focus,
    options: { projects, categories, statuses, phases, milestones, members },
    action: {
      changeProject,
      changeCategory,
      changeStatus,
      changeName,
      changeDescription,
      changeStartDate,
      changeDueDate,
      changePhase,
      changeMilestone,
      changeAssignees,
      createTask,
    },
  } = useCreateTaskDialog(projectId);
  const quillRef = useRef<any>(null);
  const startDateRef = useRef<any>(null);
  const dueDateRef = useRef<any>(null);
  const setDueDateRefFocus = () => {
    dueDateRef.current && setTimeout(() => dueDateRef.current.focus(), 0);
  };
  const setQuillRefFocus = () => {
    quillRef.current && setTimeout(() => quillRef.current.focus(), 0);
  };

  const handleClose = () => {
    dispatch(closeCreateTaskDialog());
  };

  //TODO: Error Sign Component
  if (project == null) {
    <DetailModal
      open={isCreateTaskDialogOpen}
      onClose={handleClose}
      header={<Typography variant="h6">Create Task</Typography>}
    >
      <Box>Error</Box>
    </DetailModal>;
  }
  return (
    <DetailModal
      open={isCreateTaskDialogOpen}
      onClose={handleClose}
      header={<Typography variant="h6">Create Task</Typography>}
    >
      <Box display="flex" flexDirection={"column"}>
        <Box width="500px" mb={2}>
          <Typography variant="subtitle2" color="textPrimary">
            Project *
          </Typography>
          {projectId ? (
            <TextLabelChip name={project!.name} color={project!.projectColor} height="30px" minHeight="30px" />
          ) : (
            <Autocomplete
              // open={focus === "project"}
              options={projects}
              value={project}
              onChange={async (e: any, v: any) => changeProject(v.id)}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, { id }) => {
                return option.id === id;
              }}
              renderInput={(params) => (
                <DetailInputBase
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  startAdornment={
                    <InputAdornment position="start">
                      <ColorBox color={project?.projectColor} />
                    </InputAdornment>
                  }
                />
              )}
              renderOption={(props, option, state) => (
                <li {...props}>
                  <TextLabelChip name={option.name} color={option.projectColor} />
                </li>
              )}
            />
          )}
        </Box>
        <Box width="300px" mb={2}>
          <Typography variant="subtitle2" color="textPrimary">
            Category *
          </Typography>
          <Autocomplete
            // open={focus === "project"}
            options={categories}
            value={category}
            onChange={async (e: any, v: any) => changeCategory(v)}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, { id }) => {
              return option.id === id;
            }}
            renderInput={(params) => (
              <DetailInputBase
                ref={params.InputProps.ref}
                inputProps={params.inputProps}
                startAdornment={
                  <InputAdornment position="start">
                    <ColorBox color={category?.color} />
                  </InputAdornment>
                }
              />
            )}
            renderOption={(props, option, state) => (
              <li {...props}>
                <TextLabelChip name={option.name} color={option.color} />
              </li>
            )}
          />
        </Box>
        <Box width="300px" mb={2}>
          <Typography variant="subtitle2" color="textPrimary">
            Status *
          </Typography>
          <Autocomplete
            // open={focus === "project"}
            options={statuses}
            value={status}
            onChange={async (e: any, v: any) => changeStatus(v)}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, { id }) => {
              return option.id === id;
            }}
            renderInput={(params) => (
              <DetailInputBase
                ref={params.InputProps.ref}
                inputProps={params.inputProps}
                startAdornment={
                  <InputAdornment position="start">
                    <ColorBox color={status?.color} />
                  </InputAdornment>
                }
              />
            )}
            renderOption={(props, option, state) => (
              <li {...props}>
                <TextLabelChip name={option.name} color={option.color} />
              </li>
            )}
          />
        </Box>
        <Box mb={2}>
          <Typography variant="subtitle2" color="textPrimary">
            Title *
          </Typography>
          <DetailInputBase value={name} onChange={(e: any) => changeName(e.target.value)} />
        </Box>
        <Box width="300px" mb={2}>
          <Typography variant="subtitle2" color="textPrimary">
            Phase
          </Typography>
          <Autocomplete
            // open={focus === "project"}
            options={phases}
            value={phase}
            onChange={async (e: any, v: any) => changePhase(v)}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, { id }) => {
              return option.id === id;
            }}
            renderInput={(params) => (
              <DetailInputBase
                ref={params.InputProps.ref}
                inputProps={params.inputProps}
                startAdornment={
                  <InputAdornment position="start">
                    <ColorBox color={phase?.color} />
                  </InputAdornment>
                }
              />
            )}
            renderOption={(props, option, state) => (
              <li {...props}>
                <TextLabelChip name={option.name} color={option.color} />
              </li>
            )}
          />
        </Box>
        <Box width="300px" mb={2}>
          <Typography variant="subtitle2" color="textPrimary">
            Milestone
          </Typography>
          <Autocomplete
            // open={focus === "project"}
            options={milestones}
            value={milestone}
            onChange={async (e: any, v: any) => changeMilestone(v)}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, { id }) => {
              return option.id === id;
            }}
            renderInput={(params) => (
              <DetailInputBase
                ref={params.InputProps.ref}
                inputProps={params.inputProps}
                startAdornment={
                  <InputAdornment position="start">
                    <ColorBox color={milestone?.color} />
                  </InputAdornment>
                }
              />
            )}
            renderOption={(props, option, state) => (
              <li {...props}>
                <TextLabelChip name={option.name} color={option.color} />
              </li>
            )}
          />
        </Box>
        <Box width="300px" mb={2}>
          <Typography variant="subtitle2" color="textPrimary">
            Start Date
          </Typography>
          <DetailInputBase
            ref={startDateRef}
            type="datetime-local"
            value={startDate && moment(startDate).format("yyyy-MM-DDTHH:mm").toString()}
            onChange={(e: any) => {
              changeStartDate(moment(e.target.value).toDate());
              changeDueDate(moment(e.target.value).toDate());
            }}
            onKeyDown={(e: any) => {
              if (e.key === "t") {
                changeStartDate(moment().toDate());
              }
              if (e.key === "Tab") {
                setDueDateRefFocus();
              }
            }}
          />
        </Box>
        <Box width="300px" mb={2}>
          <Typography variant="subtitle2" color="textPrimary">
            Due Date
          </Typography>
          <DetailInputBase
            inputRef={dueDateRef}
            type="datetime-local"
            value={dueDate && moment(dueDate).format("yyyy-MM-DDTHH:mm").toString()}
            onChange={(e: any) => {
              changeDueDate(moment(e.target.value).toDate());
            }}
            onKeyDown={(e: any) => {
              if (e.key === "Tab") {
                setQuillRefFocus();
              }
            }}
          />
        </Box>
        <Box mb={2}>
          <Typography variant="subtitle2" color="textPrimary">
            Assignees
          </Typography>
          <Box display="flex">
            <Box width="300px" mr={1}>
              <AssigneesRow
                options={members}
                value={assignees}
                onChange={async (v?: WorkspaceUserBase[]) => changeAssignees((v && v.map((v) => v.id)) || [])}
              />
            </Box>
            {assignees.map((v) => (
              <UserAvatar user={v} size="small" key={v.id} />
            ))}
          </Box>
        </Box>

        <Box mb={2}>
          <Typography variant="subtitle2" color="textPrimary">
            Description
          </Typography>
          <ReactQuill ref={quillRef} value={description} onChange={(content) => changeDescription(content)} />
        </Box>
      </Box>
      <DialogActions>
        <Box display="flex" justifyContent="end" alignItems="center">
          {loading && <CircularProgress style={{ width: "20px", height: "20px", marginRight: "5px" }} />}
          <Button
            variant="contained"
            color="primary"
            disabled={!name.replace(/\s/g, "").length || loading}
            onClick={() => createTask()}
          >
            Create
          </Button>
          <Box p={1} />
          <Button onClick={handleClose}>Cancel</Button>
        </Box>
      </DialogActions>
    </DetailModal>
  );
};

export default CreateTaskDialog;

import { WorkspaceUser } from "../../generated/graphql";

export const sortString = <T>(type: "asc" | "desc", key?: string) => (a: T, b: T) => {
  var A = "";
  var B = "";

  if (key) {
    if (typeof a[key] === "string") {
      A = a[key];
    }
    if (typeof b[key] === "string") {
      B = b[key];
    }
  } else {
    if (typeof a === "string") {
      A = a;
    }
    if (typeof b === "string") {
      A = b;
    }
  }
  A = A.toUpperCase(); // ignore upper and lowercase
  B = B.toUpperCase(); // ignore upper and lowercase

  if (A < B) {
    return type === "asc" ? -1 : 1;
  }
  if (A > B) {
    return type === "asc" ? 1 : -1;
  }

  return 0;
};

export function sortAssignees(a: WorkspaceUser, b: WorkspaceUser) {
  if (a.id > b.id) {
    return 1;
  } else if (a.id < b.id) {
    return -1;
  } else {
    return 0;
  }
}

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function descendingNamePropComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (a[orderBy]["name"] === undefined || b[orderBy]["name"] === undefined) {
    console.error("[descendingNamePropComparator] It has not 'name' property.");
    return 0;
  } else {
    if (b[orderBy]["name"] < a[orderBy]["name"]) {
      return -1;
    }
    if (b[orderBy]["name"] > a[orderBy]["name"]) {
      return 1;
    }
    return 0;
  }
}

import React, { useState } from "react";
import { ArrowLeft as ArrowLeftIcon, ArrowRight as ArrowRightIcon, XCircle as CloseIcon } from "react-feather";
import { useDispatch } from "react-redux";
import { Emoji } from "src/Components/Atoms";
import { useTypedSelector } from "src/hooks";
import useFirebaseAuth from "src/hooks/useAuth";
import { RootState } from "src/Store";
import { endWork, startWork } from "src/Store/action-creators/attendance.actionCreator";
import { isToday } from "src/utils/date";

import { Box, Button, Dialog, Divider, IconButton, SvgIcon, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { UserAttendance } from "../../../generated/graphql";
import UserStatusOption from "./UserStatusOption";

import type { FC } from "react";

interface UserStatusDialogProps {
  open: boolean;
  className?: string;
  onClose?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  userStatusOption: {
    cursor: "pointer",
    padding: "2px 0px 2px 0px",
    "&:hover": {
      borderRadius: "10px",
      color: "white",
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

const getTodayAttendance = (state: RootState, userId: string): UserAttendance | undefined => {
  const { attendance } = state.attendance;

  if (userId in attendance.byUser && attendance.byUser[userId].length) {
    return attendance.byId[attendance.byUser[userId][0]];
  }

  return undefined;
};

const UserStatusDialog: FC<UserStatusDialogProps> = ({ open, onClose, className }) => {
  const classes = useStyles();
  const { user } = useFirebaseAuth();
  const { workspace } = useTypedSelector((state) => state.workspace);
  const attendance = useTypedSelector((state) => getTodayAttendance(state, user!.id));
  const dispatch = useDispatch();

  // 여기서 attendnace의 startTime이 today 이고, endTime이 undefined면, 현재 출근 상태를 의미한다.
  const [working, setWorking] = useState(attendance ? isToday(attendance.startTime) && !attendance.endTime : false);

  const handleAttendanceClick = async () => {
    if (working) {
      await dispatch(endWork());
    } else {
      await dispatch(startWork());
    }

    setWorking((status) => !status);
  };

  return (
    <Dialog maxWidth="sm" onClose={onClose} open={open}>
      <Box height="100%" display="flex" flexDirection="column" p={3}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography variant="h4">Set a status</Typography>
          </Box>
          <Box />
          <IconButton onClick={onClose} size="large">
            <SvgIcon fontSize="small">
              <CloseIcon />
            </SvgIcon>
          </IconButton>
        </Box>
        <Box display="flex" flexDirection="column" pt={2} pb={2}>
          <Typography variant="subtitle2" color="textSecondary" style={{ paddingBottom: 5 }}>
            For {workspace!.name}
          </Typography>
          <UserStatusOption emoji={"👍"} text={"Working"} className={classes.userStatusOption} />
          <UserStatusOption emoji={"🗓"} text={"In a meeting"} className={classes.userStatusOption} />
          <UserStatusOption emoji={"😷"} text={"Out Sick"} className={classes.userStatusOption} />
          <UserStatusOption emoji={"🏝"} text={"Vacation"} className={classes.userStatusOption} />
          <UserStatusOption emoji={"⚡️"} text={"Busy"} className={classes.userStatusOption} />
          <UserStatusOption emoji={"🛋"} text={"Away"} className={classes.userStatusOption} />
        </Box>
        <Divider />
        <Box>
          <Typography variant="subtitle2" color="textSecondary">
            Attendance
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2" color="textSecondary">
            Here, you can set up your attendance. <br />
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" style={{ color: "grey" }} justifyContent="space-between">
          <Emoji symbol="🏡" grey={working} />
          <SvgIcon fontSize="small">{working ? <ArrowLeftIcon /> : <ArrowRightIcon />}</SvgIcon>
          <Emoji symbol="🏢" grey={!working} />
          <Button onClick={handleAttendanceClick} style={{ width: "110px" }}>
            {working ? "end work" : "start work"}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default UserStatusDialog;

import React, { useRef, useState } from "react";
import type { FC } from "react";
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";
import { User as UserIcon, LogOut as LogOutIcon } from "react-feather";
import { useSnackbar } from "notistack";
import {
  SvgIcon,
  ListItem,
  ListItemText,
  IconButton,
  Popover,
  List,
  ListItemAvatar,
  ListItemIcon,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import useAuth from "src/hooks/useAuth";
import UserStatusSelection from "./UserStatus";
import { UserAvatar } from "src/Components/Atoms";
import { useDispatch } from "react-redux";
import { resetStoresWhenWorkspaceIsChanged } from "../../../Store/reducers/resetReducer";

const useStyles = makeStyles(() => ({
  avatar: {
    height: 30,
    width: 30,
  },
  avatarButton: {
    "&:hover": {
      background: "transparent",
    },
  },
  popover: {
    width: 280,
  },
}));

const Account: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ref = useRef<any>(null);
  const { user, logout } = useAuth();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await logout();
      await dispatch(resetStoresWhenWorkspaceIsChanged());
      navigate("/");
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Unable to logout", {
        variant: "error",
      });
    }
  };

  return (
    <>
      <IconButton className={classes.avatarButton} ref={ref} onClick={handleOpen} size="large">
        {user && <UserAvatar user={user} className={classes.avatar} size="small" />}
      </IconButton>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <List disablePadding>
          <ListItem divider>
            <ListItemAvatar>
              <>{user && <UserAvatar user={user} />}</>
            </ListItemAvatar>
            <ListItemText
              primary={user!.fullName}
              primaryTypographyProps={{ variant: "subtitle2", color: "textPrimary" }}
              secondary={user!.email}
            />
          </ListItem>
          {"workspace" in params && (
            <ListItem>
              <UserStatusSelection onClose={handleClose} />
            </ListItem>
          )}
          <ListItem component={RouterLink} to="/account">
            <ListItemIcon>
              <SvgIcon fontSize="small">
                <UserIcon />
              </SvgIcon>
            </ListItemIcon>
            <ListItemText primary="Account" primaryTypographyProps={{ variant: "body1", color: "textPrimary" }} />
          </ListItem>
          <ListItem onClick={handleLogout} style={{ cursor: "pointer" }}>
            <ListItemIcon>
              <SvgIcon fontSize="small">
                <LogOutIcon />
              </SvgIcon>
            </ListItemIcon>
            <ListItemText primary="Sign Out" primaryTypographyProps={{ variant: "body1", color: "textPrimary" }} />
          </ListItem>
        </List>
      </Popover>
    </>
  );
};

export default Account;

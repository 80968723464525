import makeStyles from '@mui/styles/makeStyles';
import { Theme } from "@mui/material/styles";

import createStyles from '@mui/styles/createStyles';

export default makeStyles((theme: Theme) =>
	createStyles({
		addButton: {
			color: "#ddd",
			"&:hover": {
				color: "#989898",
			},
		},
	}),
);

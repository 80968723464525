import { Theme } from "@mui/material/styles";

import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme: Theme) => ({
	whole: {},
	appBar: {
		flexGrow: 1,
		backgroundColor: "white",
	},
	toolBar: {
		padding: "0px 10px",
		backgroundColor: "white",
		color: "black",
		[theme.breakpoints.up("sm")]: {
			padding: "0px 25px",
		},
	},
	body: {
		paddingTop: "64px",
		fontFamily: ["'Fira Sans', sans-serif", "'Noto Sans KR', sans-serif"].join(),
	},
	paper: {
		paddingTop: "20px",
		minHeight: "400px",
		backgroundColor: "blue",
	},
	logo: {
		fontFamily: "Roboto Slab",
		fontSize: "24px",
		flexGrow: 1,
		[theme.breakpoints.up("sm")]: {
			fontSize: "28px",
		},
	},
}));

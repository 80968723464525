import { gql } from "@apollo/client";

export const F_USER_ATTENDANCE = gql`
  fragment UserAttendanceProps on UserAttendance {
    id
    startTime
    endTime
    duration
    userId
    updatedAt
    createdAt
  }
`;

export const GET_ATTENDANCE_BY_USER = gql`
  query GetAttendanceByUser($userId: String!, $start: DateTime!, $end: DateTime!) {
    GetAttendanceByUser(userId: $userId, start: $start, end: $end) {
      ok
      error
      results {
        ...UserAttendanceProps
      }
    }
  }
  ${F_USER_ATTENDANCE}
`;

export const GET_ATTENDANCE_ALL_USERS = gql`
  query GetAttendanceAllUsers($start: DateTime!, $end: DateTime!) {
    GetAttendanceAllUsers(start: $start, end: $end) {
      ok
      error
      results {
        ...UserAttendanceProps
      }
    }
  }
  ${F_USER_ATTENDANCE}
`;

export const START_USER_ATTENDANCE = gql`
  mutation StartUserAttendance {
    StartUserAttendance {
      ok
      error
      result {
        ...UserAttendanceProps
      }
    }
  }
  ${F_USER_ATTENDANCE}
`;

export const FINISH_USER_ATTENDANCE = gql`
  mutation FinishUserAttendance {
    FinishUserAttendance {
      ok
      error
      result {
        ...UserAttendanceProps
      }
    }
  }
  ${F_USER_ATTENDANCE}
`;

export const UPDATE_USER_ATTENDANCE = gql`
  mutation UpdateUserAttendance($id: Int!, $startTime: DateTime, $endTime: DateTime) {
    UpdateUserAttendance(id: $id, startTime: $startTime, endTime: $endTime) {
      ok
      error
      result {
        ...UserAttendanceProps
      }
    }
  }
  ${F_USER_ATTENDANCE}
`;

export const DELETE_USER_ATTENDANCE = gql`
  mutation DeleteUserAttendance($id: Int!) {
    DeleteUserAttendance(id: $id) {
      ok
      error
    }
  }
`;

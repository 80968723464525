import type { FC } from "react";
import { Box, Stack, Theme, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import TimesheetRow from "./TimesheetRow";
import moment from "moment";

interface TimesheetHeaderProps {
  date: Date;
}

type DayHeaderCellProps = {
  day: string;
  date: string;
};

const DayHeaderCell = (values: DayHeaderCellProps) => {
  return (
    <Stack direction="column" sx={{ minWidth: "100%" }}>
      <Typography variant="subtitle1" color="textPrimary" textAlign="center">
        {values.day}
      </Typography>
      <Typography variant="caption" color="textSecondary" textAlign="center">
        {values.date}
      </Typography>
    </Stack>
  );
};

const TimesheetHeader: FC<TimesheetHeaderProps> = ({ date }) => {
  const theme = useTheme();

  const expandDatesInAWeek = (date: Date) => {
    return Array.from(Array(7).keys()).map((num) => {
      const _d = moment(date).weekday(num).toDate();

      return <DayHeaderCell date={moment(_d).format("MMM D").toString()} day={moment(_d).format("ddd").toString()} />;
    });
  };

  const headerStart: any[] = [""];
  const headerEnd: any[] = [
    <Typography variant="subtitle1" color="textPrimary" textAlign="center">
      Total
    </Typography>,
    <Typography variant="subtitle1" color="textPrimary" textAlign="center">
      Status
    </Typography>,
    "",
  ];

  return <TimesheetRow values={headerStart.concat(expandDatesInAWeek(date).concat(headerEnd))} />;
};

export default TimesheetHeader;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CREATE_TASK_CATEGORY_TYPE,
  CREATE_TASK_STATUS_TYPE,
  DELETE_TASK_CATEGORY_TYPE,
  DELETE_TASK_STATUS_TYPE,
  GET_TASK_CATEGORY_TYPES,
  GET_TASK_STATUS_TYPES,
  UPDATE_TASK_CATEGORY_ORDER,
  UPDATE_TASK_CATEGORY_TYPE,
  UPDATE_TASK_STATUS_ORDER,
  UPDATE_TASK_STATUS_TYPE,
} from "src/Api/TaskDefaultOptions/TaskDefaultOptions.queries";
import client from "src/apollo";
import { AppThunk } from "..";
import _ from "lodash";
import { TaskCategoryType, TaskStatusType } from "src/types/taskDefaultOptions";
import { objFromArray } from "src/utils/utils";
import { getRandomColorCodes } from "src/theme/colors";
import {
  CreateTaskCategoryTypeMutation,
  CreateTaskCategoryTypeMutationVariables,
  CreateTaskStatusTypeMutation,
  CreateTaskStatusTypeMutationVariables,
  DeleteTaskCategoryTypeMutation,
  DeleteTaskCategoryTypeMutationVariables,
  DeleteTaskStatusTypeMutation,
  DeleteTaskStatusTypeMutationVariables,
  GetTaskCategoryTypesQuery,
  GetTaskStatusTypesQuery,
  UpdateOnlyOrderTaskCategoryTypeMutation,
  UpdateOnlyOrderTaskCategoryTypeMutationVariables,
  UpdateOnlyOrderTaskStatusTypeMutation,
  UpdateOnlyOrderTaskStatusTypeMutationVariables,
  UpdateTaskCategoryTypeMutation,
  UpdateTaskCategoryTypeMutationVariables,
  UpdateTaskStatusTypeMutation,
  UpdateTaskStatusTypeMutationVariables,
} from "../../generated/graphql";

export interface TaskDefaultOptionsState {
  isLoaded: boolean;
  taskStatusOptions: {
    byId: Record<number, TaskStatusType>;
    allIds: number[];
  };
  taskCategoryOptions: {
    byId: Record<number, TaskCategoryType>;
    allIds: number[];
  };
}

const initialState: TaskDefaultOptionsState = {
  isLoaded: false,
  taskStatusOptions: {
    byId: {},
    allIds: [],
  },
  taskCategoryOptions: {
    byId: {},
    allIds: [],
  },
};

export const slice = createSlice({
  name: "taskDefaultOptions",
  initialState,
  reducers: {
    reset(state: TaskDefaultOptionsState) {
      state = initialState;
    },
    getTaskDefaultOptions(
      state: TaskDefaultOptionsState,
      action: PayloadAction<{
        taskStatusOptions: TaskStatusType[];
        taskCategoryOptions: TaskCategoryType[];
      }>,
    ) {
      const { taskStatusOptions, taskCategoryOptions } = action.payload;
      state.taskStatusOptions.byId = objFromArray(taskStatusOptions);
      state.taskStatusOptions.allIds = taskStatusOptions.map((v) => v.id);

      state.taskCategoryOptions.byId = objFromArray(taskCategoryOptions);
      state.taskCategoryOptions.allIds = taskCategoryOptions.map((v) => v.id);
      state.isLoaded = true;
    },
    createTaskStatusOption(state: TaskDefaultOptionsState, action: PayloadAction<{ option: TaskStatusType }>) {
      const { option } = action.payload;

      state.taskStatusOptions.byId[option.id] = option;
      state.taskStatusOptions.allIds.push(option.id);
    },
    updateTaskStatusOption(state: TaskDefaultOptionsState, action: PayloadAction<{ option: TaskStatusType }>) {
      const { option } = action.payload;

      _.merge(state.taskStatusOptions.byId[option.id], option);
    },
    deleteTaskStatusOption(state: TaskDefaultOptionsState, action: PayloadAction<{ id: number }>) {
      const { id } = action.payload;

      state.taskStatusOptions.byId = _.omit(state.taskStatusOptions.byId, id);
      _.pull(state.taskStatusOptions.allIds, id);
    },
    moveTaskStatusOption(state: TaskDefaultOptionsState, action: PayloadAction<{ id: number; position: number }>) {
      const { id, position } = action.payload;

      _.pull(state.taskStatusOptions.allIds, id);
      state.taskStatusOptions.allIds.splice(position, 0, id);
    },
    createTaskCategoryOption(state: TaskDefaultOptionsState, action: PayloadAction<{ option: TaskCategoryType }>) {
      const { option } = action.payload;

      state.taskCategoryOptions.byId[option.id] = option;
      state.taskCategoryOptions.allIds.push(option.id);
    },
    updateTaskCategoryOption(state: TaskDefaultOptionsState, action: PayloadAction<{ option: TaskCategoryType }>) {
      const { option } = action.payload;

      _.merge(state.taskCategoryOptions.byId[option.id], option);
    },
    deleteTaskCategoryOption(state: TaskDefaultOptionsState, action: PayloadAction<{ id: number }>) {
      const { id } = action.payload;

      state.taskCategoryOptions.byId = _.omit(state.taskCategoryOptions.byId, id);
      _.pull(state.taskCategoryOptions.allIds, id);
    },
    moveTaskCategoryOption(state: TaskDefaultOptionsState, action: PayloadAction<{ id: number; position: number }>) {
      const { id, position } = action.payload;

      _.pull(state.taskCategoryOptions.allIds, id);
      state.taskCategoryOptions.allIds.splice(position, 0, id);
    },
  },
});

export const reducer = slice.reducer;

export const getTaskDefaultOptions = (): AppThunk => async (dispatch) => {
  const statusTypes = await client.query<GetTaskStatusTypesQuery>({
    query: GET_TASK_STATUS_TYPES,
  });
  const categoryTypes = await client.query<GetTaskCategoryTypesQuery>({
    query: GET_TASK_CATEGORY_TYPES,
  });

  if (statusTypes.data.GetTaskStatusTypes.ok && categoryTypes.data.GetTaskCategoryTypes.ok) {
    dispatch(
      slice.actions.getTaskDefaultOptions({
        taskStatusOptions: statusTypes.data.GetTaskStatusTypes.taskStatusTypes || [],
        taskCategoryOptions: categoryTypes.data.GetTaskCategoryTypes.taskCategoryTypes || [],
      }),
    );
  }
};

export const createTaskStatusOption = (name: string): AppThunk => async (dispatch) => {
  const response = await client.mutate<CreateTaskStatusTypeMutation, CreateTaskStatusTypeMutationVariables>({
    mutation: CREATE_TASK_STATUS_TYPE,
    refetchQueries: [{ query: GET_TASK_STATUS_TYPES }],
    variables: {
      name: name,
      color: getRandomColorCodes(),
    },
  });

  if (response.data?.CreateTaskStatusType.ok) {
    dispatch(
      slice.actions.createTaskStatusOption({
        option: response.data.CreateTaskStatusType.result!,
      }),
    );
  }
};

export const updateTaskStatusOption = (option: TaskStatusType): AppThunk => async (dispatch) => {
  const response = await client.mutate<UpdateTaskStatusTypeMutation, UpdateTaskStatusTypeMutationVariables>({
    mutation: UPDATE_TASK_STATUS_TYPE,
    refetchQueries: [{ query: GET_TASK_STATUS_TYPES }],
    variables: {
      id: option.id,
      name: option.name,
      color: option.color,
      isHidden: option.isHidden,
      isHiddenFromCalendar: option.isHiddenFromCalendar,
    },
  });

  if (response.data?.UpdateTaskStatusType.ok) {
    dispatch(slice.actions.updateTaskStatusOption({ option: option }));
  }
};

export const deleteTaskStatusOption = (option: TaskStatusType): AppThunk => async (dispatch) => {
  const response = await client.mutate<DeleteTaskStatusTypeMutation, DeleteTaskStatusTypeMutationVariables>({
    mutation: DELETE_TASK_STATUS_TYPE,
    refetchQueries: [{ query: GET_TASK_STATUS_TYPES }],
    variables: {
      id: option.id,
    },
  });

  if (response.data?.DeleteTaskStatusType.ok) {
    dispatch(slice.actions.deleteTaskStatusOption({ id: option.id }));
  }
};

export const moveTaskStatusOption = (id: number, position: number): AppThunk => async (dispatch) => {
  dispatch(slice.actions.moveTaskStatusOption({ id, position }));
  client.mutate<UpdateOnlyOrderTaskStatusTypeMutation, UpdateOnlyOrderTaskStatusTypeMutationVariables>({
    mutation: UPDATE_TASK_STATUS_ORDER,
    refetchQueries: [{ query: GET_TASK_STATUS_TYPES }],
    variables: {
      id,
      position,
    },
  });
};

export const createTaskCategoryOption = (name: string): AppThunk => async (dispatch) => {
  const response = await client.mutate<CreateTaskCategoryTypeMutation, CreateTaskCategoryTypeMutationVariables>({
    mutation: CREATE_TASK_CATEGORY_TYPE,
    refetchQueries: [{ query: GET_TASK_CATEGORY_TYPES }],
    variables: {
      name: name,
      color: getRandomColorCodes(),
    },
  });

  if (response.data?.CreateTaskCategoryType.ok) {
    dispatch(
      slice.actions.createTaskCategoryOption({
        option: response.data.CreateTaskCategoryType.result!,
      }),
    );
  }
};

export const updateTaskCategoryOption = (option: TaskCategoryType): AppThunk => async (dispatch) => {
  const response = await client.mutate<UpdateTaskCategoryTypeMutation, UpdateTaskCategoryTypeMutationVariables>({
    mutation: UPDATE_TASK_CATEGORY_TYPE,
    refetchQueries: [{ query: GET_TASK_CATEGORY_TYPES }],
    variables: {
      id: option.id,
      name: option.name,
      color: option.color,
    },
  });

  if (response.data?.UpdateTaskCategoryType.ok) {
    dispatch(slice.actions.updateTaskCategoryOption({ option: option }));
  }
};

export const deleteTaskCategoryOption = (option: TaskCategoryType): AppThunk => async (dispatch) => {
  const response = await client.mutate<DeleteTaskCategoryTypeMutation, DeleteTaskCategoryTypeMutationVariables>({
    mutation: DELETE_TASK_CATEGORY_TYPE,
    refetchQueries: [{ query: GET_TASK_CATEGORY_TYPES }],

    variables: {
      id: option.id,
    },
  });

  if (response.data?.DeleteTaskCategoryType.ok) {
    dispatch(slice.actions.deleteTaskCategoryOption({ id: option.id }));
  }
};

export const moveTaskCategoryOption = (id: number, position: number): AppThunk => async (dispatch) => {
  dispatch(slice.actions.moveTaskCategoryOption({ id, position }));
  client.mutate<UpdateOnlyOrderTaskCategoryTypeMutation, UpdateOnlyOrderTaskCategoryTypeMutationVariables>({
    mutation: UPDATE_TASK_CATEGORY_ORDER,
    refetchQueries: [{ query: GET_TASK_CATEGORY_TYPES }],
    variables: {
      id,
      position,
    },
  });
};

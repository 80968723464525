// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/w";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  loginUnprotected: path(ROOTS_AUTH, "/login-unprotected"),
  register: path(ROOTS_AUTH, "/register"),
  registerUnprotected: path(ROOTS_AUTH, "/register-unprotected"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
  verify: path(ROOTS_AUTH, "/verify"),
};

export const PATH_PAGE = {
  comingSoon: "/coming-soon",
  maintenance: "/maintenance",
  pricing: "/pricing",
  payment: "/payment",
  about: "/about-us",
  contact: "/contact-us",
  faqs: "/faqs",
  page404: "/404",
  page500: "/500",
  components: "/components",
};

export const PATH_DASHBOARD = (workspaceName: string) => ({
  root: path(ROOTS_DASHBOARD, `/${workspaceName}`),
  private: {
    myTasks: path(ROOTS_DASHBOARD, `/${workspaceName}/my-page`),
    myAnalysis: path(ROOTS_DASHBOARD, `/${workspaceName}/my-analysis`),
    myTimesheet: path(ROOTS_DASHBOARD, `/${workspaceName}/my-timesheet`),
  },
  team: {
    projects: path(ROOTS_DASHBOARD, `/${workspaceName}/projects`),
    calendar: path(ROOTS_DASHBOARD, `/${workspaceName}/calendar`),
    members: path(ROOTS_DASHBOARD, `/${workspaceName}/members`),
    clients: path(ROOTS_DASHBOARD, `/${workspaceName}/clients`),
  },
  blockchain: {
    onChainDashboard: path(ROOTS_DASHBOARD, `/${workspaceName}/on-chain-dashboard`),
  },
  admin: {
    workspaceSetting: path(ROOTS_DASHBOARD, `/${workspaceName}/workspace-setting`),
    workspaceTimesheet: path(ROOTS_DASHBOARD, `/${workspaceName}/workspace-timesheet`),
  },
});

export const PATH_PROJECT = (workspaceName: string, projectName: string) => {
  const ROOT_PROJECT_PATH = path(ROOTS_DASHBOARD, `/${workspaceName}/project/${projectName}`);

  return {
    root: ROOT_PROJECT_PATH,
    project: {
      dashboard: path(ROOT_PROJECT_PATH, `/dashboard`),
      planner: path(ROOT_PROJECT_PATH, `/planner`),
      table: path(ROOT_PROJECT_PATH, `/table`),
      calendar: path(ROOT_PROJECT_PATH, `/calendar`),
      information: path(ROOT_PROJECT_PATH, `/information`),
      storage: path(ROOT_PROJECT_PATH, `/storage`),
      setting: path(ROOT_PROJECT_PATH, `/setting`),
    },
  };
};

export const PATH_DOCS = "https://wyzrstask.xyz";

import { AppThunk } from "../store";
import { WorkspaceMemberSlice as slice } from "../reducers/workspaceMemberReducer";
import client from "src/apollo";
import { GET_WORKSPACE_USERS, UPDATE_WORKSPACE_USER } from "src/Api/User/WorkspaceUser.queries";
import { INVITE_NEW_USER } from "src/Api/Workspace/Workspace.queries";
import {
  GetWorkspaceUsersQuery,
  InviteNewUserMutation,
  InviteNewUserMutationVariables,
  UpdateWorkspaceUserMutation,
  UpdateWorkspaceUserMutationVariables,
  WorkspaceUser,
  WorkspaceUserMeta,
} from "../../generated/graphql";

export const getWorkspaceMembers = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.startToLoad());

  const res = await client.query<GetWorkspaceUsersQuery>({
    query: GET_WORKSPACE_USERS,
  });

  if (res.data.GetWorkspaceUsers.ok && res.data.GetWorkspaceUsers.users) {
    dispatch(slice.actions.getMembers({ members: res.data.GetWorkspaceUsers.users }));
  }
};

export const inviteNewMember = (workspaceId: string, user: WorkspaceUserMeta): AppThunk => async (dispatch) => {
  const res = await client.mutate<InviteNewUserMutation, InviteNewUserMutationVariables>({
    mutation: INVITE_NEW_USER,
    variables: { workspaceId, userId: user.id },
  });

  if (res.data?.InviteNewUser.ok) {
    // dispatch(slice.actions.addPendingMember({ member: user }));
    dispatch(slice.actions.changeResult({ ok: true, message: "Send an invitation" }));
  } else {
    dispatch(slice.actions.changeResult({ ok: false, message: res.data?.InviteNewUser.error || "error" }));
  }
};

export const updateMember = (user: WorkspaceUser): AppThunk => async (dispatch) => {
  const res = await client.mutate<UpdateWorkspaceUserMutation, UpdateWorkspaceUserMutationVariables>({
    mutation: UPDATE_WORKSPACE_USER,
    variables: { ...user },
  });

  if (res.data?.UpdateWorkspaceUser.ok) {
    dispatch(slice.actions.updateMember({ member: user }));
  }
};

export const updateMemberTitleAndRate = (
  user: WorkspaceUser,
  titleId: number | null,
  customRate: number | null,
  customCurrency: string | null,
): AppThunk => async (dispatch) => {
  const res = await client.mutate<UpdateWorkspaceUserMutation, UpdateWorkspaceUserMutationVariables>({
    mutation: UPDATE_WORKSPACE_USER,
    variables: {
      id: user.id,
      titleId,
      customRate,
      customCurrency,
    },
  });

  if (res.data?.UpdateWorkspaceUser.ok) {
    dispatch(slice.actions.updateMember({ member: { ...user, titleId, customRate, customCurrency } }));
  }
};
export const updateMemberActive = (user: WorkspaceUser, activate: boolean): AppThunk => async (dispatch) => {
  const res = await client.mutate<UpdateWorkspaceUserMutation, UpdateWorkspaceUserMutationVariables>({
    mutation: UPDATE_WORKSPACE_USER,
    variables: {
      id: user.id,
      activate,
    },
  });

  if (res.data?.UpdateWorkspaceUser.ok) {
    dispatch(slice.actions.updateMember({ member: { ...user, activate } }));
  }
};
export const updateMemberRole = (user: WorkspaceUser, role: WorkspaceUser["role"]): AppThunk => async (dispatch) => {
  const res = await client.mutate<UpdateWorkspaceUserMutation, UpdateWorkspaceUserMutationVariables>({
    mutation: UPDATE_WORKSPACE_USER,
    variables: {
      id: user.id,
      role,
    },
  });
  if (res.data?.UpdateWorkspaceUser.ok) {
    dispatch(slice.actions.updateMember({ member: { ...user, role } }));
  }
};

import { Button, CircularProgress, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import type { FC } from "react";
import { Page } from "src/Components/Atoms";
import TxTable from "./txTable";
import {
  getLastBlocks,
  getRecordTimesheets,
  getTotalRecordCount,
  getTransactionDetails,
} from "src/utils/avaxBlockchainWyzrs";

import { BaseCard } from "../ProjectDashboard/components";
import { Box } from "@mui/system";
import { PageHeader } from "src/Components/Molecules";
import Header from "./Components/header";
import RecordChart from "./Components/RecordChart";
import moment from "moment";

interface RecordItem {
  user: string;
  project: string;
  time: string;
}

const BlockchainDashboard: FC = () => {
  const [blockHeight, setBlockHeight] = useState<number>();
  const [record, setRecord] = useState<number>();

  const totalRecord = getTotalRecordCount();

  useEffect(() => {
    getLastBlocks(5).then((blocks: { number: any }[]) => {
      let height = blocks[0].number;
      setBlockHeight(height);
    });
    getTotalRecordCount().then((recordCount: { toString: () => any }) => {
      let count = recordCount.toString();
      setRecord(count);
    });
  }, [totalRecord]);

  return (
    <Page title="on-chain dashboard">
      <Container maxWidth="xl">
        <PageHeader name="Wyzrs-subnet" />
        <Typography variant="body1" mt={2} mb={2}>
          Wyzrs-subnet is a platform that uploads timesheet records to the blockchain, enabling the verification of the
          integrity of those records. Future enhancements will include blockchain features related to construction industry.
        </Typography>
        <Header name="Summary" />
        <Grid container spacing={1}>
          <Grid item xs={12} sm={4}>
            <BaseCard name="Chain ID" helperText="This is the chainId of the Wyzrs-subnet created on Avalanche.">
              <Typography variant="h3">1195175</Typography>
            </BaseCard>
          </Grid>
          <Grid item xs={12} sm={4}>
            <BaseCard
              name="Total Records"
              helperText="It represents the total number of Export Timesheet records logged in the smart contract to date."
            >
              {!record && (
                <Box display="flex" flexGrow={1} justifyContent="center">
                  <CircularProgress />
                </Box>
              )}
              {record && <Typography variant="h3">{record}</Typography>}
            </BaseCard>
          </Grid>
          <Grid item xs={12} sm={4} mb={2}>
            <BaseCard
              name="Block height"
              helperText="It represents the total number of blocks generated up to the present."
            >
              {!blockHeight && (
                <Box display="flex" flexGrow={1} justifyContent="center">
                  <CircularProgress />
                </Box>
              )}
              {blockHeight && <Typography variant="h3">{blockHeight}</Typography>}
            </BaseCard>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TxTable />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12}>
          <RecordChart />
        </Grid>
      </Container>
    </Page>
  );
};

export default BlockchainDashboard;
//on-chain-dashboard

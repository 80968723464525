import clsx from "clsx";
import React, { FC } from "react"; // , { useState }
import { Menu as MenuIcon } from "react-feather";

import { AppBar, Box, IconButton, SvgIcon, Theme, Toolbar } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import Account from "./Account";
import HeaderBreadcrumbs from "./Breadcrumbs/HeaderBreadcrumbs";
import Settings from "./Settings";

interface HeaderProps {
  openMobile?: boolean;
  className?: string;
  hideWorkspaceItems?: boolean;
  onNavOpen?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 10,
    backgroundColor: theme.palette.background.default,
  },
  toolbar: {
    minHeight: 50,
    maxHeight: 50,
    paddingRight: 5,
    paddingLeft: 5,
  },
  breadcumbs: {
    transition: "0.2s all ease",
  },
  openNavbarPadding: {
    paddingLeft: 220,
  },
  menuIconButton: {
    "&:hover": {
      background: "transparent",
      color: theme.palette.primary.main,
    },
  },
}));

const Header: FC<HeaderProps> = ({ openMobile, className, hideWorkspaceItems = false, onNavOpen, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        {!hideWorkspaceItems && (
          <IconButton className={classes.menuIconButton} onClick={onNavOpen} disableRipple size="large">
            <SvgIcon>
              <MenuIcon size="24px" strokeWidth="2px" />
            </SvgIcon>
          </IconButton>
        )}
        <Box
          className={clsx({
            [classes.breadcumbs]: true,
            [classes.openNavbarPadding]: openMobile,
          })}
        >
          <HeaderBreadcrumbs />
        </Box>
        <Box ml={2} flexGrow={1} />
        {/* <Search /> */}
        <Account />
        <Settings />
      </Toolbar>
    </AppBar>
  );
};

export default Header;

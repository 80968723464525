import React, { Suspense, lazy } from "react";
import { useLocation, useRoutes, Navigate, RouteObject } from "react-router-dom";
import Landing from "src/Pages/Landing";
import WorkspaceLayout from "../layouts/WorkspaceLayout";
import DefaultLayout from "../layouts/DefaultLayout";
import { AuthGuard, GuestGuard, ProjectGuardWrapper, WorkspaceGuard } from "../Components/Guards";
import { LoadingScreen } from "../Components/Molecules";
import { BreadcrumbType } from "../types";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
import SvgIcon from "../theme/overrides/SvgIcon";
import ManagerTimesheet from "src/Pages/Timesheet/ManagerTimesheet";
import { GoogleOAuthProvider } from "@react-oauth/google";
import BlockchainDashboard from "src/Pages/BlockchainDashboard";
const Loadable = (Component: React.ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes("/dashboard");

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: "fixed",
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

// IMPORT COMPONENTS
// Authentication
const SignIn = Loadable(lazy(() => import("src/Pages/Signin")));
const SignUp = Loadable(lazy(() => import("src/Pages/Signup")));
const ResetPassword = Loadable(lazy(() => import("src/Pages/ResetPassword")));
const VerifyEmail = Loadable(lazy(() => import("src/Pages/VerifyEmail")));
// Dashboard
const WorkspaceMain = Loadable(lazy(() => import("src/Pages/WorkspaceMain")));
const MyPage = Loadable(lazy(() => import("src/Pages/MyPage/MyPages")));
const MyTimesheet = Loadable(lazy(() => import("src/Pages/Timesheet/PersonnelTimesheet")));
const Members = Loadable(lazy(() => import("src/Pages/Members/Members")));
const WorkspaceSetting = Loadable(lazy(() => import("src/Pages/WorkspaceSetting")));
const Projects = Loadable(lazy(() => import("src/Pages/Projects")));
const Clients = Loadable(lazy(() => import("src/Pages/Clients")));
//
const CreateNewWorkspace = Loadable(lazy(() => import("src/Pages/CreateNewWorkspace")));
const WorkspaceSelection = Loadable(lazy(() => import("src/Pages/WorkspaceSelection/WorkspaceSelection")));

const ProjectMain = Loadable(lazy(() => import("src/Pages/ProjectMain")));
const TaskTable = Loadable(lazy(() => import("src/Pages/TaskTable/TaskTable")));
const TaskCalendar = Loadable(lazy(() => import("src/Pages/ProejctCalendar")));
const ProjectDashboard = Loadable(lazy(() => import("src/Pages/ProjectDashboard")));
const ProjectInfo = Loadable(lazy(() => import("src/Pages/ProjectInfo")));
const ProjectSetting = Loadable(lazy(() => import("src/Pages/ProjectSetting")));
const ProjectPlanner = Loadable(lazy(() => import("src/Pages/ProjectPlanner")));
//
const LandingPage = Loadable(lazy(() => import("src/Pages/Landing")));
const ComingSoon = Loadable(lazy(() => import("src/Pages/ComingSoon")));
const Maintenance = Loadable(lazy(() => import("src/Pages/Maintenance")));
const Page500 = Loadable(lazy(() => import("src/Pages/Page500")));
const NotFound = Loadable(lazy(() => import("src/Pages/Page404")));

type RouteObjectWithName = RouteObject & { name?: String; children?: RouteObjectWithName[] };

const __routes: RouteObjectWithName[] = [
  {
    path: "w/:workspace",
    element: (
      <GoogleOAuthProvider clientId="369666441748-tksh2rca8g0lqgh9q3uh0gbu4n9qrcuh.apps.googleusercontent.com">
        <WorkspaceGuard>
          <WorkspaceLayout />
        </WorkspaceGuard>
      </GoogleOAuthProvider>
    ),
    children: [
      { path: "", name: "Main", element: <WorkspaceMain /> },
      { path: "my-page", name: "My Page", element: <MyPage /> },
      { path: "projects", element: <Projects /> },
      { path: "my-timesheet", element: <MyTimesheet /> },
      { path: "my-analysis", element: <ComingSoon /> },
      { path: "calendar", element: <ComingSoon /> },
      { path: "members", element: <Members /> },
      { path: "clients", element: <Clients /> },
      { path: "workspace-timesheet", element: <ManagerTimesheet /> },
      { path: "working-status", element: <ComingSoon /> },
      { path: "workspace-setting", element: <WorkspaceSetting /> },
      { path: "invite-new-teammates", element: <ComingSoon /> },
      { path: "on-chain-dashboard", element: <BlockchainDashboard /> },

      {
        path: "project/:projectName",
        element: <ProjectGuardWrapper />,
        children: [
          { path: "", element: <ProjectMain /> },
          { path: "table", element: <TaskTable /> },
          { path: "calendar", element: <TaskCalendar /> },
          { path: "dashboard", element: <ProjectDashboard /> },
          { path: "information", element: <ProjectInfo /> },
          { path: "storage", element: <ComingSoon /> },
          { path: "setting", element: <ProjectSetting /> },
          { path: "planner", element: <ProjectPlanner /> },
        ],
      },
    ],
  },
  {
    path: "/",
    children: [
      {
        path: "",
        element: (
          <GuestGuard>
            <LandingPage />
          </GuestGuard>
        ),
      },
      {
        path: "sign-in",
        element: (
          <GuestGuard>
            <SignIn />
          </GuestGuard>
        ),
      },
      {
        path: "sign-up",
        element: (
          <GuestGuard>
            <SignUp />
          </GuestGuard>
        ),
      },
      { path: "reset-password", element: <ResetPassword /> },
      {
        path: "verify-email",
        element: (
          <AuthGuard>
            <VerifyEmail />
          </AuthGuard>
        ),
      },
      {
        path: "create-new-workspace",
        element: (
          <AuthGuard>
            <CreateNewWorkspace />
          </AuthGuard>
        ),
      },
      {
        path: "account",
        element: (
          <AuthGuard>
            <Maintenance />
          </AuthGuard>
        ),
      },
      {
        path: "user-workspaces",
        element: (
          <AuthGuard>
            <WorkspaceSelection />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: "*",
    element: (
      <GuestGuard>
        <LogoOnlyLayout />
      </GuestGuard>
    ),
    children: [
      { path: "coming-soon", element: <ComingSoon /> },
      { path: "maintenance", element: <Maintenance /> },
      { path: "500", element: <Page500 /> },
      { path: "404", element: <NotFound /> },
      { path: "*", element: <Navigate to="/404" replace /> },
    ],
  },
  { path: "*", element: <Navigate to="/404" replace /> },
];

export default function Router() {
  return useRoutes(__routes);
}

export type Routes = {
  exact?: boolean;
  path?: string;
  guard?: any;
  layout?: any;
  component?: any;
  routes?: Routes;
}[];

const routes: Routes = [
  {
    path: "/w/:workspace/:option?/:projectName?/:projectOption?",
    guard: WorkspaceGuard,
    layout: WorkspaceLayout,
    routes: [
      {
        exact: true,
        path: "/w/:workspace",
        component: lazy(() => import("src/Pages/WorkspaceMain")),
      },
      {
        path: "/w/:workspace/:option?",
        routes: [
          {
            exact: true,
            path: "/w/:workspace/my-tasks",
            component: lazy(() => import("src/Pages/MyPage/MyPages")),
          },
          {
            exact: true,
            path: "/w/:workspace/projects",
            component: lazy(() => import("src/Pages/Projects")),
          },
          {
            exact: true,
            path: "/w/:workspace/my-timesheet",
            component: lazy(() => import("src/Pages/Timesheet/PersonnelTimesheet")),
          },
          {
            exact: true,
            path: "/w/:workspace/my-analysis",
            component: lazy(() => import("src/Pages/UnderConstruction/underConstruction")),
          },
          {
            exact: true,
            path: "/w/:workspace/calendar",
            component: lazy(() => import("src/Pages/ComingSoon")),
          },
          {
            exact: true,
            path: "/w/:workspace/members",
            component: lazy(() => import("src/Pages/Members/Members")),
          },
          {
            exact: true,
            path: "/w/:workspace/clients",
            component: lazy(() => import("src/Pages/UnderConstruction/underConstruction")),
          },
          {
            exact: true,
            path: "/w/:workspace/workspace-timesheet",
            component: lazy(() => import("src/Pages/Timesheet/ManagerTimesheet")),
          },
          {
            exact: true,
            path: "/w/:workspace/working-status",
            component: lazy(() => import("src/Pages/WorkingStatus/WorkingStatus")),
          },
          {
            exact: true,
            path: "/w/:workspace/workspace-setting",
            component: lazy(() => import("src/Pages/WorkspaceSetting")),
          },
          {
            exact: true,
            path: "/w/:workspace/invite-new-teammates",
            component: lazy(() => import("src/Pages/UnderConstruction/underConstruction")),
          },
          {
            exact: true,
            path: "/w/:workspace/on-chain-dashboard",
            component: lazy(() => import("src/Pages/BlockchainDashboard")),
          },

          {
            path: "/w/:workspace/project/:projectName",
            guard: ProjectGuardWrapper,
            routes: [
              {
                exact: true,
                path: "/w/:workspace/project/:projectName",
                component: lazy(() => import("src/Pages/ProjectMain")),
              },
              {
                exact: true,
                path: "/w/:workspace/project/:projectName/table",
                component: lazy(() => import("src/Pages/TaskTable/TaskTable")),
              },
              {
                exact: true,
                path: "/w/:workspace/project/:projectName/dashboard",
                component: lazy(() => import("src/Pages/ProjectDashboard")),
              },
              {
                exact: true,
                path: "/w/:workspace/project/:projectName/calendar",
                component: lazy(() => import("src/Pages/ProejctCalendar")),
              },
              {
                exact: true,
                path: "/w/:workspace/project/:projectName/information",
                component: lazy(() => import("src/Pages/ProjectInfo")),
              },
              {
                exact: true,
                path: "/w/:workspace/project/:projectName/storage",
                component: lazy(() => import("src/Pages/UnderConstruction/underConstruction")),
              },
              {
                exact: true,
                path: "/w/:workspace/project/:projectName/setting",
                component: lazy(() => import("src/Pages/ProjectSetting")),
              },
              {
                exact: true,
                path: "/w/:workspace/project/:projectName/planner",
                component: lazy(() => import("src/Pages/ProjectPlanner")),
              },
              {
                exact: true,
                path: "/w/:workspace/project/:projectName/milestones",
                component: lazy(() => import("src/Pages/UnderConstruction/underConstruction")),
              },
              {
                exact: true,
                path: "/w/:workspace/project/:projectName/kanban",
                component: lazy(() => import("src/Pages/UnderConstruction/underConstruction")),
              },
              {
                exact: true,
                path: "/w/:workspace/project/:projectName/feed",
                component: lazy(() => import("src/Pages/UnderConstruction/underConstruction")),
              },
              {
                exact: true,
                path: "/w/:workspace/project/:projectName/chat",
                component: lazy(() => import("src/Pages/UnderConstruction/underConstruction")),
              },
            ],
          },
        ],
      },
      {
        component: () => {
          // return <Redirect to="/user-workspaces" />;
        },
      },
    ],
  },
  {
    exact: true,
    path: "/404-not-found",
    component: lazy(() => import("src/Pages/Error/404")),
  },
  {
    exact: true,
    path: "/reset-password",
    component: lazy(() => import("src/Pages/ResetPassword")),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: "/sign-in",
    component: lazy(() => import("src/Pages/Signin")),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: "/sign-up",
    component: lazy(() => import("src/Pages/Signup")),
  },
  {
    exact: true,
    guard: AuthGuard,
    path: "/verify-email",
    component: lazy(() => import("src/Pages/VerifyEmail")),
  },
  {
    exact: true,
    guard: AuthGuard,
    path: "/create-new-workspace",
    layout: DefaultLayout,
    component: lazy(() => import("src/Pages/CreateNewWorkspace")),
  },
  {
    exact: true,
    guard: AuthGuard,
    path: "/account",
    layout: DefaultLayout,
    component: lazy(() => import("src/Pages/UnderConstruction/underConstruction")),
  },
  {
    exact: true,
    guard: AuthGuard,
    path: "/user-workspaces",
    layout: DefaultLayout,
    component: lazy(() => import("src/Pages/WorkspaceSelection/WorkspaceSelection")),
  },
  {
    path: "*",
    routes: [
      {
        exact: true,
        guard: GuestGuard,
        path: "/",
        component: Landing,
      },
      {
        // component: () => <Redirect to="/404-not-found" />,
      },
    ],
  },
];

interface BreadcrumbTreeType {
  [key: number]: BreadcrumbType[];
}

const breadcrumbTree = {};

const createBreadcrumbsTree = (routes: Routes | undefined): BreadcrumbTreeType => {
  if (routes === undefined) return {};

  routes.map((route) => {
    const parsedRoute = route.path?.split("/").slice(2);
    const depth = parsedRoute ? parsedRoute.length : 0;
    const label = parsedRoute && depth ? (parsedRoute[depth - 1] ? parsedRoute[depth - 1] : "/") : "/";
    const isParam = label.startsWith(":") ? true : false;
    const to = route.path;
    if (route.routes) createBreadcrumbsTree(route.routes);

    if (!breadcrumbTree.hasOwnProperty(depth - 1) && route.component && depth !== 0) {
      breadcrumbTree[depth - 1] = [
        {
          to: to,
          depth: depth - 1,
          label: label,
          isParam: isParam,
        },
      ];
    } else if (route.component && depth !== 0) {
      breadcrumbTree[depth - 1].push({
        to: to,
        depth: depth - 1,
        label: label,
        isParam: isParam,
      });
    }
  });

  return breadcrumbTree;
};

export const breadcrumbsTree = createBreadcrumbsTree(routes[0].routes);

import React from "react";
import { Paper, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { BaseCard } from "src/Pages/ProjectDashboard/components";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: 0,
      margin: 0,
    },
    paper: {
      display: "flex",
      flexDirection: "column", // Change this line to adjust the flex direction
      justifyContent: "center",
      alignItems: "center", // Centers the items horizontally
      flexWrap: "wrap",
      height: "33%", // Ensures it takes full height of its container
      width: "100%",
      margin: 0,
      "& > *": {
        margin: theme.spacing(0),
      },
    },
    title: {
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(1),
      margin: 0,
    },
    time: {
      padding: theme.spacing(1),
      margin: 0,
      alignItems: "center",
      alignSelf: "center",
    },
    radioGroup: {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(0),
      margin: 0,
    },
    unit: {
      margin: 0,
      alignSelf: "center",
    },
    hourContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignSelf: "center",
    },
  }),
);

type TotalTimeProps = {
  title: string;
  totalValue: number;
  unit: string;
};

export default function TotalResultCard(props: TotalTimeProps) {
  const classes = useStyles();
  const { title, totalValue, unit } = props;

  return (
    <BaseCard name={title}>
      <div className={classes.hourContainer}>
        <Typography variant="h2" className={classes.time}>
          {totalValue}
        </Typography>
        <div className={classes.unit}>{unit}</div>
      </div>
    </BaseCard>
  );
}

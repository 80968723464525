import type { FC } from "react";
import { Box, BoxProps, Stack, Theme, Divider } from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface RowProps extends BoxProps {
  values: any[];
}

const TimesheetRow: FC<RowProps> = ({ values, ...other }) => {
  const theme = useTheme();
  const maxWidthes = ["250px", "80px", "80px", "80px", "80px", "80px", "80px", "80px", "120px", "400px", "40px"];
  const minWidthes = ["150px", "80px", "80px", "80px", "80px", "80px", "80px", "80px", "100px", "250px", "40px"];

  return (
    <Stack
      direction="row"
      alignItems="center"
      divider={<Divider orientation="vertical" flexItem sx={{ height: "20px" }} variant="middle" />}
      {...other}
    >
      {values.map((v, i) => (
        <Box key={i} sx={{ maxWidth: maxWidthes[i], minWidth: minWidthes[i], width: "100%" }}>
          {v}
        </Box>
      ))}
    </Stack>
  );
};

export default TimesheetRow;

import { useTypedSelector } from "../../../hooks";
import { getById, getListById, getListByIds } from "../../helper/projection";
import { useState } from "react";
import {
  Milestone,
  Project,
  ProjectAssignee,
  ProjectBudget,
  ProjectPhase,
  ProjectTaskCategory,
  ProjectTaskStatus,
  WorkspaceUser,
} from "../../../generated/graphql";

export function useProject(projectId?: string) {
  const { workspace } = useTypedSelector((state) => state.workspace);
  const { projects, currentProjectId } = useTypedSelector((state) => state.projects);
  const { projectMembers } = useTypedSelector((state) => state.projectMembers);
  const { members } = useTypedSelector((state) => state.members);
  const { projectPhases, projectMilestones } = useTypedSelector((state) => state.projectTimeline);
  const { taskCategories } = useTypedSelector((state) => state.projectTaskCategories);
  const { taskStatuses } = useTypedSelector((state) => state.projectTaskStatuses);
  const { projectBudgets } = useTypedSelector((state) => state.projectBudget);

  const [_projectId, setProjectId] = useState(projectId || currentProjectId);

  const _project = getById<Project>(projects, _projectId);
  const _projectMembers = getListByIds<WorkspaceUser>(
    members,
    "byId",
    (getListById<ProjectAssignee>(projectMembers, "byProject", "byId", _projectId) || []).map((v) => v.userId!),
  );
  const _projectAssignees = getListById<ProjectAssignee>(projectMembers, "byProject", "byId", _projectId) || [];
  const _projectPhases = getListById<ProjectPhase>(projectPhases, "byProject", "byId", _projectId) || [];
  const _projectMilestones = getListById<Milestone>(projectMilestones, "byProject", "byId", _projectId) || [];
  const _statuses = getListById<ProjectTaskStatus>(taskStatuses, "byProject", "byId", _projectId) || [];
  const _categories = getListById<ProjectTaskCategory>(taskCategories, "byProject", "byId", _projectId) || [];
  const _projectBudget = getById<ProjectBudget>(projectBudgets, _projectId, "byProject");

  const findUserById = (id: string | undefined | null) => {
    return getById<WorkspaceUser>(members, id);
  };

  const findStatusById = (id: number | undefined | null) => {
    return getById<ProjectTaskStatus>(taskStatuses, id);
  };

  const findCategoryById = (id: number | undefined | null) => {
    return getById<ProjectTaskCategory>(taskCategories, id);
  };

  const findPhaseById = (id: number | undefined | null) => {
    return getById<ProjectPhase>(projectPhases, id);
  };

  return {
    workspace,
    project: _project,
    members: _projectMembers,
    assginees: _projectAssignees,
    phases: _projectPhases,
    milestones: _projectMilestones,
    statuses: _statuses,
    categories: _categories,
    projectBudget: _projectBudget,
    phasesMap: projectPhases.byId,
    findUserById,
    findStatusById,
    findCategoryById,
    findPhaseById,
  };
}

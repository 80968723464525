import { LinearProgress } from "@mui/material";
import { FC, ReactNode, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "src/hooks";
import {
  getProjectMembers,
  getProjectTaskCategoriesByProject,
  getProjectTaskStatusesByProject,
  getProjectBudget,
} from "src/Store/action-creators";
import { getMilestones, getProjectPhases } from "src/Store/action-creators/projectTimeline.actionCreator";

interface ProjectFetchProps {
  children?: ReactNode;
}

const ProjectFetch: FC<ProjectFetchProps> = ({ children }) => {
  const dispatch = useDispatch();
  const { currentProjectId } = useTypedSelector((state) => state.projects);
  const { isLoaded: statusLoad } = useTypedSelector((state) => state.projectTaskStatuses);
  const { isLoaded: categoryLoad } = useTypedSelector((state) => state.projectTaskCategories);
  const { isLoaded: memberLoad } = useTypedSelector((state) => state.projectMembers);
  const { loading } = useTypedSelector((state) => state.projectTimeline);
  const { loading: projectBudgetLoading } = useTypedSelector((state) => state.projectBudget);

  useEffect(() => {
    dispatch(getProjectTaskStatusesByProject(currentProjectId));
    dispatch(getProjectTaskCategoriesByProject(currentProjectId));
    dispatch(getProjectMembers(currentProjectId));
    dispatch(getProjectPhases(currentProjectId));
    dispatch(getMilestones(currentProjectId));
    dispatch(getProjectBudget(currentProjectId!));
  }, []);

  if (
    !currentProjectId ||
    !(currentProjectId in statusLoad.byProject) ||
    !(currentProjectId in categoryLoad.byProject) ||
    !(currentProjectId in memberLoad.byProject) ||
    !statusLoad.byProject[currentProjectId] ||
    !categoryLoad.byProject[currentProjectId] ||
    !memberLoad.byProject[currentProjectId] ||
    loading ||
    projectBudgetLoading
  ) {
    return <LinearProgress />;
  }

  return <>{children}</>;
};
export default ProjectFetch;

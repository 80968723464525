import React from "react";
import { Info as InfoIcon } from "react-feather";

import { Box, Card, Grid, Paper, Theme, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import type { FC } from "react";
import Header from "../header";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    zIndex: 1,
    flexGrow: 1,
    boxShadow: "0 0 2px 0 rgba(145, 158, 171, 0.24), 0 16px 14px -4px rgba(145, 158, 171, 0.24)",
    marginTop: "20px",
  },

  helper: {
    cursor: "pointer",
  },
  helperIcon: {
    backgroundColor: "white",
  },
}));
interface RecordItem {
  user: string;
  project: string;
  time: string;
}

interface BaseCardProps {
  name: string;
  arr: RecordItem[];
  direction?: "row" | "column";
  helperText?: string;
}

const RecordCard: FC<BaseCardProps> = ({ name, arr, direction = "row", helperText, children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down("md"));
  const totalItems = 5; // 총 항목 수를 5로 설정
  let itemsToRender = arr.slice(-5).reverse();
  //block hash
  //block timestamp
  //block number

  // arr.length가 5 미만이면 빈 항목으로 채우기
  while (itemsToRender.length < totalItems) {
    itemsToRender.push({ user: "", project: "", time: "" }); // 빈 객체를 푸시
  }

  //arr를 받아서 뿌려줄것 이때 해당 배열의 순서의 역순으로 보여줄것. 최신화하기 위함
  //
  return (
    <Card className={classes.paper}>
      <Header name={name} />
      {itemsToRender.map((item, index) => (
        <Box key={index} flexGrow={1} my={1}>
          <Grid
            container
            spacing={2}
            style={{
              borderBottom: index === itemsToRender.length - 1 ? "none" : "1px solid",
              borderColor: theme.palette.divider,
              maxHeight: "480px",
              overflow: "auto", // 스크롤바 필요시 나타나도록 설정
            }}
          >
            <Grid item xs={4}>
              <Typography variant="body1" style={{ color: theme.palette.grey[500] }}>
                User:{" "}
              </Typography>
              <Typography variant="body1">{item.user || "N/A"}</Typography>
            </Grid>

            <Grid item xs={8}>
              <Typography variant="body1" style={{ color: theme.palette.grey[500] }}>
                Project:{" "}
              </Typography>
              <Tooltip placement="top" title={item.project} className={classes.helper}>
                <Typography variant="body1">{item.project.slice(0, item.project.length) || "N/A"}</Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" style={{ color: theme.palette.grey[500] }}>
                Date:{" "}
              </Typography>
              <Tooltip
                placement="top"
                title="This date is extracted from the Block Timestamp."
                className={classes.helper}
              >
                <Typography variant="body1" style={{ marginBottom: "10px", color: theme.palette.primary.main }}>
                  {item.time || "N/A"}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </Box>
      ))}
    </Card>
  );
};

export default RecordCard;

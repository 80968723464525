import { Workspace } from "src/types";
import { WorkspaceRole } from "../generated/graphql";

export function getSelectOptionStringId<T extends { id: string | number }>(
  id: string | number | undefined | null,
  options: T[],
): string {
  if (id && options) {
    const _ret = options.find((v) => Number(v.id) === Number(id));

    // all types are NOT permanent.
    // Manager can remove the specific type and it might be a legacy type.
    if (!_ret) {
      return "";
    } else {
      return _ret.id.toString();
    }
  } else {
    return "";
  }
}

export function getSelectOptionObject<T extends { id: string | number }>(
  id: string | number | undefined | null,
  options: T[],
  initializer?: T,
): T {
  if (id) {
    const _ret = options.find((v) => Number(v.id) === Number(id));

    // all types are NOT permanent.
    // Manager can remove the specific type and it might be a legacy type.
    if (!_ret) {
      return initializer ? (initializer as T) : ({} as T);
    } else {
      return _ret;
    }
  } else {
    return initializer ? (initializer as T) : ({} as T);
  }
}

export function validateEmail(email: string) {
  const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function exportTwoChar(name: string) {
  if (name) {
    const arr = name.split(" ", 2);

    if (arr.length >= 2) {
      return (arr[0].substring(0, 1) + arr[1].substring(0, 1)).toUpperCase();
    } else {
      const twoChars = arr[0].substring(0, 2).toLowerCase();
      return twoChars.charAt(0).toUpperCase() + twoChars.slice(1);
    }
  } else {
    return "";
  }
}

/**
 * 확장자가 대문자 또는 소문자로 다를 경우, DB에서는 서로 다른 file 이름으로 인식하므로,
 * 확장자는 모두 소문자로 변경하여 반환한다.
 *
 * @param fileName File 이름(확장자 포함)
 */
export function extensionToLowercase(fileName: string): string {
  const _ext = fileName.split(".").pop();

  if (_ext === undefined) {
    return fileName;
  }

  const _lowerExt = _ext.toLowerCase();

  return fileName.slice(0, fileName.length - _ext.length) + _lowerExt;
}

/**
 * List의 모든 객체를 순회하면서 async 함수를 적용.
 * @param array 순회할 list
 * @param func async 함수
 */
export async function asyncForEachArray<T>(array: T[], func: (value: T, idx?: number) => Promise<any>) {
  // map array to promises
  const promises = array.map(func);
  // wait until all promises are resolved
  await Promise.all(promises);
}

export const objFromArray = (arr: any[], key: string = "id") =>
  arr.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});

/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */

function camelToSnake(str: string) {
  let newKey = "";
  let index = 0;
  let code;
  let wasPrevNumber = true;
  let wasPrevUppercase = true;

  while (index < str.length) {
    code = str.charCodeAt(index);
    if ((!wasPrevUppercase && code >= 65 && code <= 90) || (!wasPrevNumber && code >= 48 && code <= 57)) {
      newKey += "_";
      newKey += str[index].toLowerCase();
    } else {
      newKey += str[index].toLowerCase();
    }
    wasPrevNumber = code >= 48 && code <= 57;
    wasPrevUppercase = code >= 65 && code <= 90;
    index++;
  }

  return newKey;
}

function snakeToCamel(str: string) {
  const parts = str.split("_");
  return parts.reduce((p, c) => p + c.charAt(0).toUpperCase() + c.slice(1), parts.shift()!);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function toSnakeCase(object: any, exceptions: string[] = []): any {
  if (typeof object !== "object" || object === null) {
    return object;
  }

  return Object.keys(object).reduce((p: { [key: string]: any }, key: string) => {
    const newKey = exceptions.indexOf(key) === -1 ? camelToSnake(key) : key;
    p[newKey] = toSnakeCase(object[key]);
    return p;
  }, {});
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function toCamelCase(object: any, exceptions: string[] = []): any {
  if (typeof object !== "object" || object === null) {
    return object;
  }

  return Object.keys(object).reduce((p: { [key: string]: any }, key: string) => {
    const newKey = exceptions.indexOf(key) === -1 ? snakeToCamel(key) : key;
    p[newKey] = toCamelCase(object[key]);
    return p;
  }, {});
}

export function camelToWords(str: string): string {
  let newKey = "";
  let index = 0;
  let code;
  let wasPrevNumber = true;
  let wasPrevUppercase = true;

  while (index < str.length) {
    code = str.charCodeAt(index);
    if (index === 0) {
      newKey += str[index].toUpperCase();
    } else if ((!wasPrevUppercase && code >= 65 && code <= 90) || (!wasPrevNumber && code >= 48 && code <= 57)) {
      newKey += " ";
      newKey += str[index].toUpperCase();
    } else {
      newKey += str[index].toLowerCase();
    }
    wasPrevNumber = code >= 48 && code <= 57;
    wasPrevUppercase = code >= 65 && code <= 90;
    index++;
  }

  return newKey;
}

export const isWorkspaceAdmin = (userId: string, workspace?: Workspace) => {
  if (!workspace) {
    return false;
  }
  return workspace.assignees.some((assignee) => {
    if (assignee.id === userId && assignee.role === WorkspaceRole.Admin) {
      return true;
    } else {
      return false;
    }
  });
};

/**
 * object의 property 중 undefined property는 제거
 * @param obj Any objects
 * @returns
 */
export const removeUndefined = (obj: object) => {
  Object.keys(obj).forEach((key) => obj[key] === undefined && delete obj[key]);
  return obj;
};

export const timespentStringToNumber = (value: string | undefined | null) => {
  const helper = (value: string) => {
    if (+value === 0) {
      throw Error();
    }
    if (!isNaN(s[0] as any)) {
      throw Error();
    } else {
      var withNoDigits = value.replace(/[0-9]/g, "");

      if (withNoDigits === "h") {
        return parseInt(value) * 60;
      } else if (withNoDigits === "m") {
        return parseInt(value);
      } else {
        throw Error();
      }
    }
  };

  if (value === undefined || value === null) return 0;

  const s = value.split(" ");

  if (s.length === 1) {
    // string이 empty 일 경우
    if (+s[0] === 0) {
      return 0;
    }

    // string에 숫자만 있을 경우
    if (!isNaN(s[0] as any)) {
      return Number(s[0]);
    }

    // h, m format 확인
    var withNoDigits = s[0].replace(/[0-9]/g, "");
    if (withNoDigits === "h") {
      return parseInt(s[0]) * 60;
    } else if (withNoDigits === "m") {
      return parseInt(s[0]);
    } else {
      throw Error();
    }
  } else {
    var sum = 0;

    try {
      s.forEach((v: any) => {
        sum += helper(v);
      });
    } catch (error) {
      throw Error();
    }

    return sum;
  }
};

export const timespentNumberToString = (value: number | undefined | null) => {
  if (value) {
    const hours = ~~(value / 60);
    const minutes = value % 60;

    if (hours > 0 && minutes > 0) {
      return hours + "h " + minutes + "m";
    } else if (hours > 0 && minutes === 0) {
      return hours + "h";
    } else if (minutes > 0) {
      return minutes + "m";
    } else {
      return "0m";
    }
  } else {
    return "";
  }
};

import React, { useRef, useState } from "react";
import { MoreHorizontal as MoreHorizontalIcon } from "react-feather";

import { Badge, Box, IconButton, Popover, SvgIcon, Theme, Tooltip, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import ThemeSwtich from "../../../layouts/WorkspaceLayout/TopBar/Setting/ThemeSwitch";

import type { FC } from "react";
const useStyles = makeStyles((theme: Theme) => ({
  badge: {
    height: 10,
    width: 10,
    borderRadius: 5,
    color: theme.palette.grey[50],
  },
  popover: {
    width: 320,
    padding: theme.spacing(2),
  },
}));

const Settings: FC = () => {
  const classes = useStyles();
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Settings">
        <Badge variant="dot" classes={{ badge: classes.badge }}>
          <IconButton onClick={handleOpen} ref={ref} size="large">
            <SvgIcon fontSize="small">
              <MoreHorizontalIcon />
            </SvgIcon>
          </IconButton>
        </Badge>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Typography variant="h6" color="textSecondary">
          Theme
        </Typography>
        <Box mt={1}>
          <ThemeSwtich />
        </Box>
      </Popover>
    </>
  );
};

export default Settings;

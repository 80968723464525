import moment from "moment";

export function compareToday(time: Date | null): boolean {
  if (time) {
    const _d = new Date();
    const _today = new Date(_d.getFullYear(), _d.getMonth(), _d.getDate());
    const _time = new Date(time);
    const __time = new Date(_time.getFullYear(), _time.getMonth(), _time.getDate());
    if (__time.getTime() === _today.getTime()) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

/**
 * date format의 string을 입력받아 Date type 반환.
 * date format이 아니거나 null이면 null 반환
 * @param date
 */
export function DateOrNull(date: Date | string | null | undefined) {
  if (date && isValidDate(date)) {
    return new Date(date);
  } else {
    return null;
  }
}

export function checkDateBetween(from: Date, to: Date, item: Date) {
  let fromCompare = false;
  let toCompare = false;
  if (from.getMonth() < to.getMonth()) {
    if (item.getMonth() === from.getMonth()) {
      fromCompare = item.getDate() >= from.getDate();
      toCompare = true;
    } else {
      fromCompare = true;
      toCompare = item.getDate() <= to.getDate();
    }
  } else if (from.getMonth() === to.getMonth()) {
    if (item.getMonth() === from.getMonth()) {
      fromCompare = item.getDate() >= new Date(from).getDate();
      toCompare = item.getDate() <= new Date(to).getDate();
    }
  }
  return fromCompare && toCompare;
}

export function convertDatetimeForm(rawdate: Date) {
  const year = rawdate.getFullYear();
  const month = rawdate.getMonth() + 1 < 10 ? "0" + (rawdate.getMonth() + 1) : rawdate.getMonth() + 1;
  const date = rawdate.getDate() < 10 ? "0" + rawdate.getDate().toString() : rawdate.getDate();
  const hours = rawdate.getHours() < 10 ? "0" + rawdate.getHours() : rawdate.getHours();
  const minutes = rawdate.getMinutes() < 10 ? "0" + rawdate.getMinutes() : rawdate.getMinutes();

  return `${month}/${date}/${year} ${hours}:${minutes}`;
}

export function formerConvertDatetimeForm(rawdate: Date) {
  const year = rawdate.getFullYear();
  const month = rawdate.getMonth() + 1 < 10 ? "0" + (rawdate.getMonth() + 1) : rawdate.getMonth() + 1;
  const date = rawdate.getDate() < 10 ? "0" + rawdate.getDate().toString() : rawdate.getDate();
  const hours =
    rawdate.getHours() > 12
      ? rawdate.getHours() - 12 < 10
        ? "0" + (rawdate.getHours() - 12)
        : rawdate.getHours() - 12
      : rawdate.getHours() < 10
      ? "0" + rawdate.getHours()
      : rawdate.getHours();
  const minutes = rawdate.getMinutes() < 10 ? "0" + rawdate.getMinutes() : rawdate.getMinutes();
  const ampm = rawdate.getHours() < 12 ? "AM" : "PM";

  return `${month}/${date}/${year} ${hours}:${minutes} ${ampm}`;
}

// export function stringDateToDateType(date: string | null): Date | undefined {
// 	if (date === null) {
// 		return undefined;
// 	}

// 	const _date = new Date(Number(date));
// 	return isValidDate(_date) ? _date : undefined;
// }

export function durationInMinutes(startDate: Date | null, endDate: Date | null): number {
  if (startDate && endDate) {
    if (endDate.getTime() < startDate.getTime()) {
      return 0;
    } else {
      const duration = Math.abs(endDate.getTime() - startDate.getTime());
      return Math.floor(duration / 60000);
    }
  } else {
    return 0;
  }
}

export function compareDateTime(a: Date, b: Date, order: "ASC" | "DESC" = "DESC") {
  var _a = moment(a);
  var _b = moment(b);

  if (_a > _b) {
    return order === "ASC" ? 1 : -1;
  } else if (_a < _b) {
    return order === "ASC" ? -1 : 1;
  } else {
    return 0;
  }
}

export function startOfMonth() {
  return moment().clone().startOf("month").toDate();
}

export function endOfMonth() {
  return moment().clone().endOf("month").toDate();
}

export function isToday(date: Date): boolean {
  const _date = moment(date);

  return _date.isSame(moment().clone().startOf("day"), "d");
}

export function isValidDate(d: any) {
  const _d = new Date(d);
  return _d instanceof Date && !isNaN(_d.getTime());
}

import React, { createContext, useEffect, useReducer } from "react";
import type { FC, ReactNode } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_CURRENT_USER } from "src/Api/User/User.queries";
import client from "src/apollo";
import firebase from "src/Firebase/firebase";
import type { User } from "src/types/UserTypes";
import { LoadingScreen, SplashScreen } from "src/Components/Molecules";
import { GetCurrentUserQuery } from "../generated/graphql";

interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  isEmailVerified: boolean;
  user: User | null;
}

export interface AuthContextValue extends AuthState {
  method: "FirebaseAuth";
  createUserWithEmailAndPassword: (email: string, password: string) => Promise<any>;
  signInWithEmailAndPassword: (email: string, password: string) => Promise<any>;
  updateUserProfile: () => Promise<void>;
  logout: () => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type AuthStateChangedAction = {
  type: "AUTH_STATE_CHANGED";
  payload: {
    isAuthenticated: boolean;
    isEmailVerified: boolean;
    user: User | null;
  };
};

type Action = AuthStateChangedAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  isEmailVerified: false,
  user: null,
};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case "AUTH_STATE_CHANGED": {
      const { isAuthenticated, isEmailVerified, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isEmailVerified,
        isInitialised: true,
        user,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: "FirebaseAuth",
  createUserWithEmailAndPassword: () => Promise.resolve(),
  signInWithEmailAndPassword: () => Promise.resolve(),
  updateUserProfile: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const [userProfile, { loading: checkFileNameLoading }] = useLazyQuery<GetCurrentUserQuery>(GET_CURRENT_USER, {
    fetchPolicy: "no-cache", //반드시 no-cache로 할 것!
    onCompleted: (data) => {
      if (data.GetCurrentUser.user) {
        let user: User = {
          ...data.GetCurrentUser.user,
          profileColor: data.GetCurrentUser.user.profileColor || null,
          profileImage: data.GetCurrentUser.user.profileImage || null,
          lastWorkspaceId: data.GetCurrentUser.user.lastWorkspaceId || null,
          workspaces: data.GetCurrentUser.user.workspaces,
          googleToken: data.GetCurrentUser.user.googleToken || null,
        };
        dispatch({
          type: "AUTH_STATE_CHANGED",
          payload: {
            isAuthenticated: true,
            isEmailVerified: firebase.auth().currentUser!.emailVerified,
            user: user,
          },
        });
      }
    },
  });

  const updateUserProfile = async () => {
    return await userProfile();
  };

  const signInWithEmailAndPassword = (email: string, password: string): Promise<any> => {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  };

  const createUserWithEmailAndPassword = async (email: string, password: string): Promise<any> => {
    return firebase.auth().createUserWithEmailAndPassword(email, password);
  };

  const logout = (): Promise<void> => {
    return firebase
      .auth()
      .signOut()
      .then(() => {
        // 반드시 해줄 것. 로그아웃한 뒤, 다른 사람 ID로 로그인했을 경우,
        // Cache가 충돌되서 다른 사람 정보가 DB에 update 될 수 있음.
        client.cache.reset();

        window.localStorage.clear();
      });
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        console.log(user.getIdToken());
        userProfile();
      } else {
        dispatch({
          type: "AUTH_STATE_CHANGED",
          payload: {
            isAuthenticated: false,
            isEmailVerified: false,
            user: null,
          },
        });
      }
    });

    return unsubscribe;
  }, [dispatch]);

  if (!state.isInitialised) {
    return <LoadingScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "FirebaseAuth",
        createUserWithEmailAndPassword,
        signInWithEmailAndPassword,
        updateUserProfile,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

import { merge } from "lodash";
import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

import { useQuery } from "@apollo/client";
import { Box, Card, CircularProgress, Theme, useMediaQuery, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";

import {
  GET_PROJECT_BUDGET_EXPENSE,
  GET_PROJECT_BUDGET_EXPENSE_BY_USER,
} from "../../../Api/Dashboard/dashborad.queries";
import {
  GetProjectBudgetExpenseByUserQuery,
  GetProjectBudgetExpenseQuery,
  GetProjectBudgetExpenseQueryVariables,
  WorkspaceUserBase,
} from "../../../generated/graphql";
import { useProject, useProperties } from "../../../Store/hooks";
import BaseOptionChart from "../../../Style/BaseOptionChart";
import { fNumber } from "../../../utils/floatNumber";
import BaseCard from "./BaseCard";

import type { FC } from "react";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

const RootStyle = styled(Card)(({ theme }) => ({
  display: "flex",
  position: "relative",
  alignItems: "center",
  padding: theme.spacing(3),
  backgroundColor: theme.palette.primary.dark,
}));

interface ProjectBudgetExpenseByUser {}

const ProjectBudgetExpenseByUser: FC<ProjectBudgetExpenseByUser> = () => {
  const classes = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down("md"));

  const { project, projectBudget } = useProject();

  const { getUsersByIds } = useProperties();
  const [users, setUsers] = useState<WorkspaceUserBase[]>([]);
  const [chartData, setChartData] = useState<any>([]);

  const { data, loading } = useQuery<GetProjectBudgetExpenseByUserQuery, GetProjectBudgetExpenseQueryVariables>(
    GET_PROJECT_BUDGET_EXPENSE_BY_USER,
    {
      variables: { projectId: project!.id },
      onCompleted: (data) => {
        if (data.GetProjectBudgetExpenseByUser) {
          setUsers(getUsersByIds(data.GetProjectBudgetExpenseByUser.map((v) => v.userId)));
          setChartData({ data: data.GetProjectBudgetExpenseByUser.map((v) => v.expense) });
        }
      },
    },
  );

  const chartOptions = merge(BaseOptionChart(), {
    tooltip: {
      marker: { show: false },
      y: {
        formatter: (seriesName: string) => fNumber(seriesName),
        title: {
          formatter: () => "",
        },
      },
    },
    plotOptions: {
      bar: { horizontal: true, barHeight: "28%", borderRadius: 2 },
    },
    xaxis: {
      categories: users.map((v) => v.fullName),
    },
  });

  return (
    <BaseCard name="Expense By User" direction="column">
      {loading && (
        <Box display="flex" flexGrow={1} justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {!loading && data && <ReactApexChart type="bar" series={[chartData]} options={chartOptions} height={350} />}
    </BaseCard>
  );
};

export default ProjectBudgetExpenseByUser;

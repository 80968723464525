import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Page, StyledTab, StyledTabs, TextLabelChip, UserAvatar } from "src/Components/Atoms";
import { timesheetCategoryEnum } from "src/enum/timesheetCategoryEnum";
import { useTypedSelector } from "src/hooks";
import {
  getProjectMembers,
  getProjects,
  getProjectTaskCategories,
  getProjectTaskStatuses,
} from "src/Store/action-creators";
import { resetFilter } from "src/Store/reducers/tableReducer";

import { ScreenLockLandscapeTwoTone } from "@mui/icons-material";
import {
  Box,
  Container,
  Divider,
  LinearProgress,
  Paper,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import Timesheets from "../Timesheet";
import TimesheetsAdmin from "../TimesheetsAdmin";
import { PageHeader } from "../../../Components/Molecules";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

interface ManagerTimesheetProps {}

const ManagerTimesheet: FC<ManagerTimesheetProps> = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [currentTab, setCurrentTab] = useState(0);
  const dispatch = useDispatch();
  const { isLoaded } = useTypedSelector((state) => state.projects);
  const { loading } = useTypedSelector((state) => state.projectMembers);
  const { isAllLoaded: projectTaskStatusedLoaded } = useTypedSelector((state) => state.projectTaskStatuses);
  const { isAllLoaded: projectTaskCategoriesLoaded } = useTypedSelector((state) => state.projectTaskCategories);

  const handleTabsChange = (event: ChangeEvent<{}>, value: number): void => {
    setCurrentTab(value);
  };

  const tabs = [
    { value: 0, label: "This Week" },
    { value: 1, label: "Last Week" },
    { value: 2, label: "Past TimeSheets" },
  ];

  const mobileDevice = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    dispatch(resetFilter());
    dispatch(getProjects());
    dispatch(getProjectMembers());
    dispatch(getProjectTaskStatuses());
    dispatch(getProjectTaskCategories());
  }, []);

  if (!isLoaded || loading || !projectTaskStatusedLoaded || !projectTaskCategoriesLoaded) {
    return <LinearProgress />;
  }

  return (
    <Page title="My Timesheet">
      <Container maxWidth={false}>
        <PageHeader name="Workspace Timesheet" />
        <TimesheetsAdmin />
      </Container>
    </Page>
  );
};

export default ManagerTimesheet;

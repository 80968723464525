import { alpha, InputBase, TextField, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";

const DetailInputBasea = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      "label + &": {
        marginTop: theme.spacing(3),
      },
      "&. Mui-error": {
        borderColor: "red",
      },
      "&:error": {
        borderColor: "red",
      },
    },
    error: {
      borderColor: "red",
    },
    input: {
      borderRadius: 4,
      position: "relative",
      border: "1.2px solid",
      paddingLeft: "5px",
      fontSize: 14,
      fontWeight: 500,
      width: "100%",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.background.default,
      "&:focus": {
        boxShadow: `${alpha(theme.palette.primary.main, 0.75)} 0 0 0 0.1rem`,
        borderColor: theme.palette.primary.main,
      },
      "&:error": {
        borderColor: "red",
      },
      "&:disabled": {
        backgroundColor: theme.palette.background.paper,
      },
    },
  }),
)(InputBase);

export default DetailInputBasea;

import React, { FC, MouseEvent, MouseEventHandler, ReactElement, useCallback, useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { BreadcrumbType } from "src/types";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Paper,
  Popover,
  SvgIcon,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

interface BreadcrumbTypeProps extends BreadcrumbType {
  depth?: number;
  totalBreadcrumbCount: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  breadcrumbItem: {
    borderRadius: 5,
    marginTop: 5,
    marginBottom: 5,
    paddingLeft: 10,
    marginRight: 5,
    cursor: "pointer",
    color: theme.palette.text.secondary,
  },
  linkTitle: {
    color: "inherit",
  },
  linkTitleLast: {
    color: theme.palette.primary.main,
  },
  optionItem: {
    minWidth: 120,
    backgroundColor: theme.palette.background.paper,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}));

export const BreadcrumbItem: FC<BreadcrumbTypeProps> = (props: BreadcrumbTypeProps): ReactElement => {
  const classes = useStyles({});

  const { to, label, options, depth, totalBreadcrumbCount: totalCount } = props;

  const [anchorEl, setAnchorEl] = useState<Element | undefined>(undefined);
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const ref = useRef<any>(null);

  const handleBreadcrumbOptionClick = useCallback(
    (event: MouseEvent) => {
      setAnchorEl(event.currentTarget);
      setIsOpened(true);
    },
    [setAnchorEl, setIsOpened],
  );

  const handleClose = useCallback(() => {
    setIsOpened(false);
    setAnchorEl(undefined);
  }, []);

  return (
    <Box
      display="flex"
      alignItems="center"
      className={classes.breadcrumbItem}
      // @ts-ignore
      ref={ref}
    >
      <Box>
        <Link
          component={RouterLink}
          to={to}
          key={label}
          className={depth === totalCount - 1 ? classes.linkTitleLast : classes.linkTitle}
        >
          {label}
        </Link>
      </Box>
      {options !== undefined && options.length > 1 && depth !== 0 ? (
        <Box>
          <IconButton onClick={handleBreadcrumbOptionClick} style={{ margin: 0, padding: 0 }} size="large">
            <SvgIcon fontSize="small" className={depth === totalCount - 1 ? classes.linkTitleLast : classes.linkTitle}>
              {isOpened ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </SvgIcon>
          </IconButton>
          <Paper>
            <Menu
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              open={isOpened}
              onClose={handleClose}
            >
              {options
                .filter((v) => {
                  return v.label !== label;
                })
                .map((v) => {
                  return (
                    <MenuItem component={RouterLink} key={v.label} to={v.to} className={classes.optionItem}>
                      <Box>
                        <Typography className={classes.linkTitle}>{v.label}</Typography>
                      </Box>
                    </MenuItem>
                  );
                })}
            </Menu>
          </Paper>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};

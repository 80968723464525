import { combineReducers } from "@reduxjs/toolkit";
import languageReducer from "./languageReducer";
import { reducer as workspaceReducer } from "./workspaceReducer";
import { reducer as taskDefaultOptionsReducer } from "./taskDefaultOptions";
import { reducer as projectInfoPropertiesReducer } from "./projectInfoReducer";
import { reducer as projectsReducer } from "./projectReducer";
import { reducer as companiesReducer } from "./companyReducer";
import { reducer as tasksReducer } from "./taskReducer";
import { reducer as workspaceAttendanceReducer } from "./workspaceAttendance";
import { reducer as workspaceMemberReducer } from "./workspaceMemberReducer";
import { reducer as ProjectTaskStatusReducer } from "./projectTaskStatusReducer";
import { reducer as ProjectTaskCategoryReducer } from "./projectTaskCategoryReducer";
import { reducer as ProjectMembersReducer } from "./projectMemberReducer";
import { reducer as UserTitlesReducer } from "./userTitles";
import { reducer as PhasesReducer } from "./phasesReducer";
import { reducer as TableReducer } from "./tableReducer";
import { reducer as ProjectTimelineReducer } from "./projectTimelineReducer";
import { reducer as ProjectBudgetReducer } from "./projectBudgetReducer";

const reducers = combineReducers({
  workspace: workspaceReducer,
  members: workspaceMemberReducer,
  userTitles: UserTitlesReducer,
  attendance: workspaceAttendanceReducer,
  taskDefaultOptions: taskDefaultOptionsReducer,
  phases: PhasesReducer,
  tasks: tasksReducer,
  projects: projectsReducer,
  projectInfoProperties: projectInfoPropertiesReducer,
  projectMembers: ProjectMembersReducer,
  projectTaskStatuses: ProjectTaskStatusReducer,
  projectTaskCategories: ProjectTaskCategoryReducer,
  companies: companiesReducer,
  language: languageReducer,
  table: TableReducer,
  projectTimeline: ProjectTimelineReducer,
  projectBudget: ProjectBudgetReducer,
});

export default reducers;

export { reducer as workspaceReducer } from "./workspaceReducer";
export { reducer as taskDefaultOptionsReducer } from "./taskDefaultOptions";
export { reducer as projectInfoPropertiesReducer } from "./projectInfoReducer";
export { reducer as projectsReducer } from "./projectReducer";
export { reducer as companiesReducer } from "./companyReducer";
export { reducer as tasksReducer } from "./taskReducer";
export { reducer as workspaceAttendanceReducer } from "./workspaceAttendance";
export { reducer as workspaceMemberReducer } from "./workspaceMemberReducer";
export { reducer as ProjectTaskStatusReducer } from "./projectTaskStatusReducer";
export { reducer as ProjectTaskCategoryReducer } from "./projectTaskCategoryReducer";
export { reducer as ProjectMembersReducer } from "./projectMemberReducer";
export { reducer as UserTitlesReducer } from "./userTitles";
export { reducer as PhasesReducer } from "./phasesReducer";
export { reducer as TableReducer } from "./tableReducer";
export { reducer as ProjectTimelineReducer } from "./projectTimelineReducer";
export { reducer as ProjectBudgetReducer } from "./projectBudgetReducer";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { compareDateTime } from "src/utils/date";
import { objFromArray } from "src/utils/utils";
import { UserAttendance } from "../../generated/graphql";

export interface WorkspaceAttendanceState {
  isLoaded: boolean;
  attendance: {
    byId: Record<number, UserAttendance>;
    byUser: Record<string, number[]>; // 항상 최신의 기록이 가장 앞으로 와야 함.
  };
}

const initialState: WorkspaceAttendanceState = {
  isLoaded: false,
  attendance: {
    byId: {},
    byUser: {},
  },
};

export const WorkspaceAttendanceSlice = createSlice({
  name: "workspaceAttendance",
  initialState,
  reducers: {
    reset(state: WorkspaceAttendanceState) {
      state = initialState;
    },
    startToLoad(state: WorkspaceAttendanceState) {
      state.isLoaded = false;
    },
    getAttendance(state: WorkspaceAttendanceState, action: PayloadAction<{ attendance: UserAttendance[] }>) {
      const { attendance } = action.payload;

      state.attendance.byId = _.merge(state.attendance.byId, objFromArray(attendance));

      const _groupByUserId = _.groupBy(attendance, "userId");
      _.forEach(_groupByUserId, (val, userId) => {
        state.attendance.byUser[userId] = (state.attendance.byUser[userId] || []).concat(val.map((v) => v.id));
        state.attendance.byUser[userId] = state.attendance.byUser[userId].sort((a, b) =>
          compareDateTime(state.attendance.byId[a].startTime, state.attendance.byId[b].startTime),
        );
      });

      state.isLoaded = true;
    },
    createAttendance(state: WorkspaceAttendanceState, action: PayloadAction<{ attendance: UserAttendance }>) {
      const { attendance } = action.payload;

      state.attendance.byId[attendance.id] = attendance;

      if (state.attendance.byUser[attendance.userId]) {
        state.attendance.byUser[attendance.userId].unshift(attendance.id);
      } else {
        state.attendance.byUser[attendance.userId] = [attendance.id];
      }
    },
    updateAttendance(state: WorkspaceAttendanceState, action: PayloadAction<{ attendance: UserAttendance }>) {
      const { attendance } = action.payload;

      _.merge(state.attendance.byId[attendance.id], attendance);
    },
    deleteAttendance(state: WorkspaceAttendanceState, action: PayloadAction<{ id: number }>) {
      const { id } = action.payload;

      _.pull(state.attendance.byUser[state.attendance.byId[id].userId], id);
      state.attendance.byId = _.omit(state.attendance.byId, id);
    },
  },
});

export const reducer = WorkspaceAttendanceSlice.reducer;

import clsx from "clsx";
import React from "react";
import { stringToColor } from "src/utils";

import { Avatar, Theme, Tooltip } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

// user or project
type Props = {
  usage: AvartarType;
  name: string;
  color: string | null | undefined;
  imageUrl?: string | null | undefined;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    user: {
      width: theme.spacing(3.3),
      height: theme.spacing(3.3),
    },
    project: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    option: {
      width: theme.spacing(2),
      height: theme.spacing(2),
    },
    big: {
      width: theme.spacing(30),
      height: theme.spacing(30),
    },
    hasImage: (props: Props) => ({
      backgroundColor: props.color || stringToColor(props.name),
      color: theme.palette.getContrastText(props.color || stringToColor(props.name)),
      fontSize: "0.9rem",
    }),
  }),
);

type AvartarType = "User" | "Project" | "Option" | "Big";

function exportTwoChar(name: string) {
  if (name) {
    const arr = name.split(" ", 2);

    if (arr.length >= 2) {
      return (arr[0].substring(0, 1) + arr[1].substring(0, 1)).toUpperCase();
    } else {
      return arr[0].substring(0, 2).toUpperCase();
    }
  } else {
    return "";
  }
}

/*
  TODO: image가 있으면 image로 변경하는 것 추가
*/
export default function CustomAvatar(props: Props) {
  const classes = useStyles(props);
  const { usage, name, imageUrl } = props;
  const twoChars = exportTwoChar(name);

  if (!name) {
    return <></>;
  }
  const usageInfo = (usage: AvartarType) => {
    if (usage === "User") {
      return classes.user;
    } else if (usage === "Project") {
      return classes.project;
    } else if (usage === "Big") {
      return classes.big;
    } else {
      return classes.option;
    }
  };

  return (
    <div>
      <Tooltip title={name}>
        {!imageUrl ? (
          <Avatar variant={usage ? "circular" : "circular"} className={clsx(usageInfo(usage), classes.hasImage)}>
            {usageInfo(usage) === classes.option ? "" : twoChars}
          </Avatar>
        ) : (
          <Avatar variant={usage ? "circular" : "circular"} className={usageInfo(usage)} src={imageUrl || ""}>
            {usageInfo(usage) === classes.option ? "" : twoChars}
          </Avatar>
        )}
      </Tooltip>
    </div>
  );
}

import { ReactNode } from "react";
import type { FC } from "react";
import { Box } from "@mui/material";

interface TableCellProps {
  hCenter?: boolean;
  vCenter?: boolean;
  children?: ReactNode;
}

const TableCellAlign: FC<TableCellProps> = ({ hCenter = false, vCenter = true, children }) => {
  return (
    <Box
      display="flex"
      width="100%"
      height="100%"
      justifyContent={hCenter ? "center" : "flex-end"}
      alignItems={vCenter ? "center" : "flex-start"}
    >
      {children}
    </Box>
  );
};

export default TableCellAlign;

import gql from "graphql-tag";

export const CREATE_TASK = gql`
  mutation CreateTask(
    $name: String!
    $description: String
    $plannedStartDate: DateTime
    $plannedEndDate: DateTime
    $startDate: DateTime
    $endDate: DateTime
    $duration: Int
    $projectId: String!
    $statusId: Int
    $categoryId: Int
    $assigneesIds: [String!]
    $milestoneId: Int
    $phaseId: Int
  ) {
    CreateTask(
      name: $name
      description: $description
      plannedStartDate: $plannedStartDate
      plannedEndDate: $plannedEndDate
      startDate: $startDate
      endDate: $endDate
      duration: $duration
      projectId: $projectId
      statusId: $statusId
      categoryId: $categoryId
      assigneesIds: $assigneesIds
      milestoneId: $milestoneId
      phaseId: $phaseId
    ) {
      ok
      error
      task {
        id
        name
        description
        plannedStartDate
        plannedEndDate
        startDate
        endDate
        duration
        projectId
        statusId
        categoryId
        assigneesIds
        createdById
        createdAt
        updatedAt
      }
    }
  }
`;

export const DELETE_TASK = gql`
  mutation DeleteTask($taskIds: [Int!]!) {
    DeleteTask(taskIds: $taskIds) {
      ok
      error
    }
  }
`;

export const F_TASK_PROPERTIES = gql`
  fragment TaskProperties on Task {
    id
    name
    description
    plannedStartDate
    plannedEndDate
    startDate
    endDate
    duration
    projectId
    statusId
    categoryId
    phaseId
    milestoneId
    assigneesIds
    createdById
    createdAt
    updatedAt
    timesheets {
      id
      status
      weekStartDate
      weekEndDate
      status
      user {
        id
        fullName
      }
    }
  }
`;

export const GET_PROJECT_TASKS = gql`
  query GetProjectTasks(
    $projectId: String!
    $offset: Int
    $limit: Int
    $calendarDate: String
    $filterStartDate: String
    $filterEndDate: String
    $filterAssigneesIds: [String]
  ) {
    GetProjectTasks(
      projectId: $projectId
      offset: $offset
      limit: $limit
      calendarDate: $calendarDate
      filterStartDate: $filterStartDate
      filterEndDate: $filterEndDate
      filterAssigneesIds: $filterAssigneesIds
    ) {
      ok
      error
      tasks {
        ...TaskProperties
      }
    }
  }
  ${F_TASK_PROPERTIES}
`;

export const GET_TIMESHEET_WEEK_TASKS = gql`
  query GetTimesheetWeekTasks(
    $projectId: String
    $date: DateTime!
    $userId: String
    $timesheetId: Int
    $limit: Int
    $offset: Int
  ) {
    GetTimesheetWeekTasks(
      projectId: $projectId
      date: $date
      userId: $userId
      timesheetId: $timesheetId
      limit: $limit
      offset: $offset
    ) {
      ok
      error
      tasks {
        ...TaskProperties
      }
      count
    }
  }
  ${F_TASK_PROPERTIES}
`;

export const GET_PROJECT_TASKS_COUNT = gql`
  query GetProjectTasksCount(
    $projectId: String!
    $filterStartDate: String
    $filterEndDate: String
    $filterAssigneesIds: [String]
  ) {
    GetProjectTasksCount(
      projectId: $projectId
      filterStartDate: $filterStartDate
      filterEndDate: $filterEndDate
      filterAssigneesIds: $filterAssigneesIds
    ) {
      ok
      error
      count
    }
  }
`;
export const GET_USER_TASKS = gql`
  query GetUserTasks(
    $offset: Int
    $limit: Int
    $calendarDate: String
    $filterStartDate: String
    $filterEndDate: String
    $filterAssigneesIds: [String]
  ) {
    GetUserTasks(
      offset: $offset
      limit: $limit
      calendarDate: $calendarDate
      filterStartDate: $filterStartDate
      filterEndDate: $filterEndDate
      filterAssigneesIds: $filterAssigneesIds
    ) {
      ok
      error
      tasks {
        ...TaskProperties
      }
    }
  }
  ${F_TASK_PROPERTIES}
`;
export const GET_USER_TASKS_COUNT = gql`
  query GetUserTasksCount($filterStartDate: String, $filterEndDate: String, $filterAssigneesIds: [String]) {
    GetUserTasksCount(
      filterStartDate: $filterStartDate
      filterEndDate: $filterEndDate
      filterAssigneesIds: $filterAssigneesIds
    ) {
      ok
      error
      count
    }
  }
`;

export const UPDATE_TASK = gql`
  mutation UpdateTask(
    $id: Int!
    $name: String
    $description: String
    $plannedStartDate: DateTime
    $plannedEndDate: DateTime
    $startDate: DateTime
    $endDate: DateTime
    $duration: Int
    $projectId: String
    $statusId: Int
    $categoryId: Int
    $assigneesIds: [String!]
    $phaseId: Int
    $milestoneId: Int
  ) {
    UpdateTask(
      id: $id
      name: $name
      description: $description
      plannedStartDate: $plannedStartDate
      plannedEndDate: $plannedEndDate
      startDate: $startDate
      endDate: $endDate
      duration: $duration
      projectId: $projectId
      statusId: $statusId
      categoryId: $categoryId
      assigneesIds: $assigneesIds
      phaseId: $phaseId
      milestoneId: $milestoneId
    ) {
      ok
      error
      result {
        ...TaskProperties
      }
    }
  }
  ${F_TASK_PROPERTIES}
`;

export const GET_TASKS = gql`
  query GetTasks(
    $projectId: String!
    $phaseId: Int
    $milestoneId: Int
    $taskIds: [Int!]
    $offset: Int
    $limit: Int
    $filter: TaskFilterType
  ) {
    GetTasks(
      projectId: $projectId
      phaseId: $phaseId
      milestoneId: $milestoneId
      taskIds: $taskIds
      offset: $offset
      limit: $limit
      filter: $filter
    ) {
      ok
      error
      tasks {
        ...TaskProperties
      }
    }
  }
  ${F_TASK_PROPERTIES}
`;

export const GET_TASKS_BY_WEEK = gql`
  query GetTasksByWeek($date: DateTime!, $userId: String, $timezone: String!, $offset: Int, $limit: Int) {
    GetTasksByWeek(date: $date, userId: $userId, timezone: $timezone, offset: $offset, limit: $limit) {
      ...TaskProperties
    }
  }
  ${F_TASK_PROPERTIES}
`;

//export filter task 쿼리문
export const GET_FILTER_TASK = gql`
  query ExportFilterTask(
    $projectId: String!
    $filterAssigneesIds: [String!]
    $filterStartDate: String
    $filterEndDate: String
  ) {
    ExportFilterTask(
      projectId: $projectId
      filterAssigneesIds: $filterAssigneesIds
      filterStartDate: $filterStartDate
      filterEndDate: $filterEndDate
    ) {
      header
      rows
    }
  }
`;

import React from "react";

import { Box, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import type { FC } from "react";
const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

interface BodyCardProps {
  title: string;
  [key: string]: any;
}

const BodyCard: FC<BodyCardProps> = ({ title, children, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box {...rest}>
      <Typography variant="subtitle1" color="textPrimary">
        {title}
      </Typography>
      {children}
    </Box>
  );
};

export default BodyCard;

import React, { ReactNode, useRef, useState } from "react";
import type { FC } from "react";
import { useTheme, Box, Typography, SvgIcon, Menu, MenuItem, Paper, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ChevronDown as ChevronDownIcon } from "react-feather";
import { useLocales } from "../../../hooks";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  filterButton: {
    padding: 5,
    marginRight: 8,
    borderRadius: 5,
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: theme.palette.grey[300],
    cursor: "pointer",
    "&:hover": {
      borderColor: theme.palette.primary.main,
    },
  },
  popover: {
    minWidth: 150,
  },
}));

type Option = { id: number; name: string; postfix?: any; prefix?: any };

interface OptionSelectButtonProps {
  icon: ReactNode;
  name: string;
  value: Option;
  options: Option[];
  onChange: (id: number) => void;
}

const OptionSelectButton: FC<OptionSelectButtonProps> = ({ icon, name, value, options, onChange }) => {
  const { translate } = useLocales();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const ref = useRef<any>(null);

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        className={classes.filterButton}
        onClick={() => setOpen(true)}
        // @ts-ignore
        ref={ref}
      >
        {icon}
        <Box>
          <Typography variant="subtitle2" style={{ paddingLeft: 6, paddingRight: 10 }} color="textSecondary">
            {name}
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle2" style={{ paddingRight: 10 }} color="textPrimary">
            {translate(value.name)}
          </Typography>
        </Box>
        {value.postfix}
        <Box>
          <SvgIcon fontSize="small" color="primary">
            <ChevronDownIcon />
          </SvgIcon>
        </Box>
      </Box>
      {open && (
        <Paper>
          <Menu
            onClose={() => setOpen(false)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            PaperProps={{ className: classes.popover }}
            open={open}
            anchorEl={ref.current}
          >
            {options.map((option) => (
              <MenuItem
                key={option.id}
                onClick={() => onChange(option.id)}
                style={{
                  backgroundColor:
                    option.id === value.id ? theme.palette.primary.light : theme.palette.background.paper,
                  color: option.id === value.id ? "white" : undefined,
                }}
              >
                <Box display="flex">
                  <Box>
                    <Typography variant="h6">{translate(option.name)}</Typography>
                  </Box>
                  {option.postfix && (
                    <Box pl={1} color="inherit">
                      {option.postfix}
                    </Box>
                  )}
                </Box>
              </MenuItem>
            ))}
          </Menu>
        </Paper>
      )}
    </>
  );
};

export default OptionSelectButton;

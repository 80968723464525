export const COLOR_CODES = [
  "#D3455B",
  "#E8833A",
  "#F7C325",
  "#DAF7A6",
  "#3AAE9F",
  "#257868",
  "#897A5F",
  "#AC6364",
  "#3188D9",
  "#6495ED",
  "#6558F5",
  "#730EC3",
  "#BD34D1",
  "#C3CFD9",
  "#788896",
  "#4B5C6B",
  "#0350D6",
  "#033CA0",
  "#4E4E4E",
  "#FFFFFF",
  "#000000",
  "#1F3A6B",
  "#F1F1F1",
  "#A8A4AD",
  "#E5E5E5",
  "#D2D2D2",
];

export const getContrastFontColor = (color: string | undefined | null): string => {
  // Default to dark gray
  if (!color) return "#293845";

  let r, g, b;

  // Check if color is in hex format
  if (color.charAt(0) === "#") {
    // Convert hex color to rgb
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
    if (result === null) return "#FFFFFF";
    r = parseInt(result[1], 16);
    g = parseInt(result[2], 16);
    b = parseInt(result[3], 16);
  } else {
    // Assume color is already in 'rgb(r, g, b)' format
    const result = /rgb\((\d+),\s*(\d+),\s*(\d+)\)/.exec(color);
    if (result === null) return "#FFFFFF";
    r = parseInt(result[1]);
    g = parseInt(result[2]);
    b = parseInt(result[3]);
  }

  // Calculate the luminance of the color
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // Return white for dark colors and black for light colors
  return luminance > 0.5 ? "#000000" : "#FFFFFF";
};

export const getRandomColorCodes = (): string => {
  return COLOR_CODES[Math.floor(Math.random() * 16)];
};

export const getDefaultColorCodes = (): string => {
  return COLOR_CODES[13];
};

export const TASK_COLORS = {
  IN_PROGRESS: "#219653",
  DONE: "#828282",
};

import _ from "lodash";
import { CREATE_COMPANY, DELETE_COMPANY, GET_COMPANIES, UPDATE_COMPANY } from "src/Api/Options/Company/Company.queries";
import {
  CREATE_COMPANY_MEMBER,
  GET_COMPANY_MEMBERS,
  UPDATE_COMPANY_MEMBER,
  DELETE_COMPANY_MEMBERS,
} from "src/Api/Options/CompanyMember/CompanyMember.queries";
import client from "src/apollo";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk } from "../";
import {
  Company,
  CompanyMember,
  CreateCompanyMemberMutation,
  CreateCompanyMemberMutationVariables,
  CreateCompanyMutation,
  CreateCompanyMutationVariables,
  DeleteCompanyMembersMutation,
  DeleteCompanyMembersMutationVariables,
  DeleteCompanyMutation,
  DeleteCompanyMutationVariables,
  GetCompaniesQuery,
  GetCompanyMembersQuery,
  UpdateCompanyMemberMutation,
  UpdateCompanyMemberMutationVariables,
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables,
} from "../../generated/graphql";
import { objFromArray } from "../../utils";

export interface CompanyState {
  isLoaded: boolean;
  companies: {
    byId: Record<number, Company>;
    allIds: number[];
  };
  members: {
    byId: Record<number, CompanyMember>;
    byCompany: Record<number, number[]>;
  };
}

const initialState: CompanyState = {
  isLoaded: false,
  companies: {
    byId: {},
    allIds: [],
  },
  members: {
    byId: {},
    byCompany: [],
  },
};

export const slice = createSlice({
  name: "companies",
  initialState,
  reducers: {
    reset(state: CompanyState) {
      state = initialState;
    },
    getCompaniesAndMembers(
      state: CompanyState,
      action: PayloadAction<{
        companies: Company[];
        members: CompanyMember[];
      }>,
    ) {
      const { companies, members } = action.payload;

      state.companies.byId = objFromArray(companies);
      state.companies.allIds = companies.map((v) => v.id);

      state.members.byId = objFromArray(members);
      state.members.byCompany = members.reduce((acc, cur) => {
        if (cur.company!.id in acc) {
          acc[cur.company!.id].push(cur.id);
        } else {
          acc[cur.company!.id] = [cur.id];
        }
        return acc;
      }, {});
      state.isLoaded = true;
    },
    createCompany(state: CompanyState, action: PayloadAction<{ company: Company }>) {
      const { company } = action.payload;

      state.companies.byId[company.id] = company;
      state.companies.allIds.unshift(company.id);
    },
    updateCompany(state: CompanyState, action: PayloadAction<{ company: Company }>) {
      const { company } = action.payload;

      state.companies.byId[company.id] = company;
    },
    deleteCompany(state: CompanyState, action: PayloadAction<{ companyId: number }>) {
      const { companyId } = action.payload;

      state.companies.byId = _.omit(state.companies.byId, companyId);
      _.pull(state.companies.allIds, companyId);
    },
    createCompanyMember(state: CompanyState, action: PayloadAction<{ companyId: number; member: CompanyMember }>) {
      const { companyId, member } = action.payload;
      state.members.byId[member.id] = member;

      if (companyId in state.members.byCompany && state.members.byCompany[companyId].length > 0) {
        state.members.byCompany[companyId].unshift(member.id);
      } else {
        state.members.byCompany[companyId] = [member.id];
      }
    },
    updateCompanyMember(state: CompanyState, action: PayloadAction<{ companyId: number; member: CompanyMember }>) {
      const { companyId, member } = action.payload;
      state.members.byId[member.id] = member;
    },
    deleteCompanyMember(state: CompanyState, action: PayloadAction<{ companyId: number; memberIds: number[] }>) {
      const { companyId, memberIds } = action.payload;

      state.members.byId = _.omit(state.members.byId, memberIds);
      _.pullAll(state.members.byCompany[companyId], memberIds);
    },
  },
});

export const reducer = slice.reducer;

export const getCompaniesAndMembers = (): AppThunk => async (dispatch) => {
  const response_companies = await client.query<GetCompaniesQuery>({
    query: GET_COMPANIES,
  });

  const response_members = await client.query<GetCompanyMembersQuery>({
    query: GET_COMPANY_MEMBERS,
  });

  if (response_companies.data.GetCompanies.ok && response_members.data.GetCompanyMembers.ok) {
    dispatch(
      slice.actions.getCompaniesAndMembers({
        companies: response_companies.data.GetCompanies.companies || [],
        members: response_members.data.GetCompanyMembers.companyMembers || [],
      }),
    );
  } else {
    console.error("getCompaniesAndMembers errors");
  }
};

export const createCompany = (name: string): AppThunk => async (dispatch) => {
  const res = await client.mutate<CreateCompanyMutation, CreateCompanyMutationVariables>({
    mutation: CREATE_COMPANY,
    variables: {
      name,
    },
  });

  if (res.data?.CreateCompany.ok && res.data.CreateCompany.result) {
    dispatch(slice.actions.createCompany({ company: res.data.CreateCompany.result }));
  }
};

export const updateCompany = (company: Company): AppThunk => async (dispatch) => {
  const res = await client.mutate<UpdateCompanyMutation, UpdateCompanyMutationVariables>({
    mutation: UPDATE_COMPANY,
    variables: company,
  });

  if (res.data?.UpdateCompany.ok) {
    dispatch(slice.actions.updateCompany({ company }));
  }
};

export const deleteCompany = (companyId: number): AppThunk => async (dispatch) => {
  const res = await client.mutate<DeleteCompanyMutation, DeleteCompanyMutationVariables>({
    mutation: DELETE_COMPANY,
    variables: {
      id: companyId,
    },
  });

  if (res.data?.DeleteCompany.ok) {
    dispatch(slice.actions.deleteCompany({ companyId }));
  }
};

export const createCompanyMember = (companyId: number, member: CompanyMember): AppThunk => async (dispatch) => {
  const res = await client.mutate<CreateCompanyMemberMutation, CreateCompanyMemberMutationVariables>({
    mutation: CREATE_COMPANY_MEMBER,
    variables: { ...member, companyId },
  });

  if (res.data?.CreateCompanyMember.ok) {
    dispatch(slice.actions.createCompanyMember({ companyId, member }));
  }
};

export const updateCompanyMember = (companyId: number, member: CompanyMember): AppThunk => async (dispatch) => {
  const res = await client.mutate<UpdateCompanyMemberMutation, UpdateCompanyMemberMutationVariables>({
    mutation: UPDATE_COMPANY_MEMBER,
    variables: { ...member },
  });

  if (res.data?.UpdateCompanyMember.ok) {
    dispatch(slice.actions.updateCompanyMember({ companyId, member }));
  }
};

export const deleteCompanyMember = (companyId: number, memberIds: number[]): AppThunk => async (dispatch) => {
  const res = await client.mutate<DeleteCompanyMembersMutation, DeleteCompanyMembersMutationVariables>({
    mutation: DELETE_COMPANY_MEMBERS,
    variables: {
      ids: memberIds,
    },
  });

  if (res.data?.DeleteCompanyMembers.ok) {
    dispatch(slice.actions.deleteCompanyMember({ companyId, memberIds }));
  }
};

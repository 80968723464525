import { useEffect, useState } from "react";
import type { FC } from "react";
import { Box, Breadcrumbs, Theme } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useTypedSelector } from "src/hooks/useTypedSelector";

interface AppBreadcrumbsProps {}

type BreadcrumbType = {
  label: string;
  to: string;
  options?: BreadcrumbType[];
  depth?: number;
  isParam?: boolean;
};

/**
 * Breadcrumb example
 *
 * /w/:WorkspaceName/project/:ProjectName/dashboard
 * :WorkspaceName > Projects > ProjectName > Dashboard
 *
 * /w/:WorkspaceName/projects
 * :WorkspaceName > Projects
 *
 */

const AppBreadcrumbs: FC<AppBreadcrumbsProps> = () => {
  const location = useLocation();

  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbType[]>([]);
  const { workspace } = useTypedSelector((state) => state.workspace);

  const getName = (path: string) => {
    const decoded = decodeURIComponent(path);
    const parsedPath = decoded.split("-");
    const breadcrumbName = parsedPath
      .map((v) => {
        return v.charAt(0).toUpperCase() + v.slice(1);
      })
      .join(" ");
    return breadcrumbName;
  };

  const getTo = (parsedLocation: string[], value: string, index: number) => {
    return value === "project" && index === 1
      ? "/w/" + parsedLocation.slice(0, index).join("/") + "/projects"
      : "/w/" + parsedLocation.slice(0, index + 1).join("/");
  };

  const getLabel = (value: string, index: number) => {
    return index === 0 || index === 2 ? value : value === "project" && index === 1 ? "Projects" : getName(value);
  };

  const generateBreadcrumb = () => {
    // url에서 /w/ 다음부터 slice
    const parsedLocation = decodeURIComponent(location.pathname).split("/").slice(2);
    if (parsedLocation.length === 0) return [];

    return parsedLocation.map((v, i) => ({
      to: getTo(parsedLocation, v, i),
      label: getLabel(v, i),
    }));
  };

  useEffect(() => {
    if (workspace) {
      setBreadcrumbs(generateBreadcrumb());
    }
  }, [location]);

  return (
    <Breadcrumbs separator=">">
      {breadcrumbs.map((v, i) => (
        <Box key={i}>{v.label}</Box>
      ))}
    </Breadcrumbs>
  );
};

export default AppBreadcrumbs;

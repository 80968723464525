import moment from "moment";
import { useSnackbar } from "notistack";
import { FC, useState } from "react";
import { Trash } from "react-feather";
import { useDispatch } from "react-redux";
import * as yup from "yup";

import {
  Autocomplete,
  Box,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  Paper,
  SvgIcon,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { Milestone, ProjectPhase, ProjectTaskCategory, WorkspaceUserMeta } from "../../../generated/graphql";
import { useTaskDetail } from "../../../Store/hooks/taskDetail/useTaskDetail";
import { timespentStringToNumber } from "../../../utils";
import { SelectButton, TextLabelChip, UserAvatar } from "../../Atoms";
import { Description, DetailModal, TitleField, UserAvatarAndName } from "../../Molecules";
import CopyTaskDialog from "../TaskDetailModal/components/CopyTaskDialog";
import AssigneesRow from "./components/AssigneesRow";
import BodyCard from "./components/BodyCard";
import DetailBreadcrumbHeader from "./components/DetailBreadcrumbHeader";
import DetailElement from "./components/DetailElement";
import DetailHeader from "./components/DetailHeader";
import DetailInputBase from "./components/DetailInputBase";
import DetailRow from "./components/DetailRow";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

interface TaskDetailProps {}

//TODO: Update 시 disabled
const TaskDetail: FC<TaskDetailProps> = () => {
  const classes = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const { enqueueSnackbar } = useSnackbar();

  //TODO: path 수정
  const {
    open,
    close,
    focus,
    setFocus,
    unsetFocus,
    error: { nameError, startDateError, dueDateError, timespentError },
    task: {
      id,
      name,
      description,
      project,
      phase,
      milestone,
      status,
      category,
      assignees,
      startDate,
      dueDate,
      completed,
      timespent,
    },
    options: { projects, phases, milestones, statuses, categories, members },
    actions: {
      changeStartDate,
      changeDueDate,
      changeCompleted,
      changeTimespent,
      updateName,
      updateDescription,
      updateProject,
      updatePhase,
      updateMilestone,
      updateCategory,
      updateStatus,
      updateAssignees,
      updateStartDate,
      updateDueDate,
      updateCompleted,
      updateTimespent,
      deleteTask,
    },
  } = useTaskDetail();

  const [copyTaskDialog, setCopyTaskDialog] = useState(false);

  const plannedDateSchema = yup.object().shape({
    startDate: yup.date().max(yup.ref("dueDate"), "Start date can't be after due date."),
    dueDate: yup.date().min(yup.ref("startDate"), "Due date can't be before start date."),
  });

  const handleChangeStartDateError = async (value: Date) => {
    // plannedDateSchema
    //   .validate({ startDate: value, dueDate: moment(dueDate).toDate() })
    //   .then(() => setStartDateError(""))
    //   .catch((err) => {
    //     console.log(err);
    //     setStartDateError(err.errors[0]);
    //   });
  };

  const handleChangeDueDateError = async (value: Date) => {
    // plannedDateSchema
    //   .validate({ startDate: startDate, dueDate: moment(value).toDate() })
    //   .then(() => setDueDateError(""))
    //   .catch((err) => {
    //     console.log(err);
    //     setDueDateError(err.errors[0]);
    //   });
  };

  const showSnackbarUpdated = (content: string) => {
    enqueueSnackbar(content, { variant: "success" });
  };

  const copyTask = () => {
    setCopyTaskDialog(true);
  };
  const closeCopyDialog = () => {
    setCopyTaskDialog(false);
  };

  // if (!task || !status || !statusOptions || !category || !categoryOptions || !project || !projectOptions) {
  //TODO: Error alert!
  // return <></>;
  // }

  return (
    <>
      <DetailModal
        open={open}
        options={[
          { name: "Delete", onSelect: deleteTask },
          { name: "Copy", onSelect: copyTask },
        ]}
        onClose={close}
        fullWidth
        maxWidth="lg"
        header={
          <DetailBreadcrumbHeader projectId={project.id} phaseId={phase?.id} milestoneId={milestone?.id} taskId={id} />
        }
      >
        <Grid container>
          <Grid item sm={7} xs={12}>
            <Box pl={1} pt={1} position="relative" left={-10} top={-10} style={{ maxHeight: "80vh", overflow: "auto" }}>
              <TitleField value={name} onSubmit={updateName} />
            </Box>
            <Box pr={2} display="flex" flexDirection="column" width="100%">
              <BodyCard title="Description">
                <Description description={description} onSubmit={updateDescription} />
              </BodyCard>
              <BodyCard title="Check List"></BodyCard>
              <BodyCard title="Activity"></BodyCard>
            </Box>
          </Grid>
          <Grid item sm={5} xs={12}>
            <Box display="flex" flexDirection="column" width="100%">
              <Box display="flex" mb={1}>
                <SelectButton value={status} options={statuses} onChange={(value: any) => updateStatus(value)} />
                <Box flexGrow={1} />
              </Box>
              <Paper variant="outlined">
                <Box p={2}>
                  <Typography variant="h6">Details</Typography>
                </Box>
                <Divider />
                <Box style={{ backgroundColor: theme.palette.background.paper }}>
                  <Box pl={2} pr={2}>
                    <DetailRow>
                      <DetailHeader name={"Project"} />
                      <DetailElement
                        open={"project" === focus}
                        // onClick={() => setFocus("project")}
                        defaultNode={
                          <TextLabelChip
                            name={project.name}
                            color={project.projectColor}
                            variant="h6"
                            height="30px"
                            width="100%"
                          />
                        }
                        editNode={
                          <Autocomplete
                            fullWidth
                            open={"project" === focus}
                            options={projects}
                            value={project}
                            onClose={unsetFocus}
                            onChange={async (e: any, v: any) => updateProject(v.id)}
                            getOptionLabel={(option) => option.name}
                            isOptionEqualToValue={(option, { id }) => {
                              return option.id === id;
                            }}
                            renderInput={(params) => (
                              <DetailInputBase ref={params.InputProps.ref} inputProps={params.inputProps} autoFocus />
                            )}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <TextLabelChip name={option.name} color={option.projectColor} />
                              </li>
                            )}
                          />
                        }
                      />
                    </DetailRow>
                    <DetailRow>
                      <DetailHeader name={"Phase"} />
                      <DetailElement
                        open={focus === "phase"}
                        onClick={() => setFocus("phase")}
                        defaultNode={
                          (!phase && (
                            <Typography variant="subtitle2" color="textSecondary">
                              None
                            </Typography>
                          )) || <TextLabelChip name={phase!.name} color={phase?.color} variant="h6" height="30px" />
                        }
                        editNode={
                          <Autocomplete
                            fullWidth
                            open={focus === "phase"}
                            options={phases}
                            value={phase}
                            onClose={unsetFocus}
                            onChange={async (e: any, v: ProjectPhase | null) => updatePhase(v?.id || null)}
                            getOptionLabel={(option) => option.name}
                            isOptionEqualToValue={(option, { id }) => {
                              return option.id === id;
                            }}
                            renderInput={(params) => (
                              <DetailInputBase ref={params.InputProps.ref} inputProps={params.inputProps} autoFocus />
                            )}
                            renderOption={(props, option, state) => (
                              <li {...props}>
                                <TextLabelChip name={option.name} color={option.color} />
                              </li>
                            )}
                          />
                        }
                      />
                    </DetailRow>
                    <DetailRow>
                      <DetailHeader name={"Milestone"} />
                      <DetailElement
                        open={focus === "milestone"}
                        onClick={() => setFocus("milestone")}
                        defaultNode={
                          (milestone === null && (
                            <Typography variant="subtitle2" color="textSecondary">
                              None
                            </Typography>
                          )) || (
                            <Box display="flex" flexDirection="column">
                              <TextLabelChip
                                name={milestone!.name}
                                color={milestone?.color}
                                variant="h6"
                                height="30px"
                              />
                              <Typography variant="caption" color="textSecondary">
                                {moment(milestone?.end).diff(moment(), "days").toString() + " days left"}
                              </Typography>
                            </Box>
                          )
                        }
                        editNode={
                          <Autocomplete
                            fullWidth
                            open={focus === "milestone"}
                            options={milestones}
                            value={milestone}
                            onClose={unsetFocus}
                            onChange={async (e: any, v: Milestone | null) => updateMilestone(v?.id || null)}
                            getOptionLabel={(option) => option.name}
                            isOptionEqualToValue={(option, { id }) => {
                              return option.id === id;
                            }}
                            renderInput={(params) => (
                              <DetailInputBase ref={params.InputProps.ref} inputProps={params.inputProps} autoFocus />
                            )}
                            renderOption={(props, option, state) => (
                              <li {...props}>
                                <TextLabelChip name={option.name} color={option.color} />
                              </li>
                            )}
                          />
                        }
                      />
                    </DetailRow>
                  </Box>
                </Box>
                <Divider />
                <Box pl={2} pr={2}>
                  <DetailRow>
                    <DetailHeader name={"Category"} />
                    <DetailElement
                      open={"category" === focus}
                      onClick={() => setFocus("category")}
                      defaultNode={
                        <TextLabelChip name={category.name} color={category.color} variant="h6" height="30px" />
                      }
                      editNode={
                        <Autocomplete
                          fullWidth
                          open={"category" === focus}
                          options={categories}
                          value={category}
                          onClose={unsetFocus}
                          onChange={async (e: any, v: ProjectTaskCategory | null) => {
                            if (v) updateCategory(v.id);
                          }}
                          getOptionLabel={(option) => option.name}
                          isOptionEqualToValue={(option, { id }) => {
                            return option.id === id;
                          }}
                          renderInput={(params) => (
                            <DetailInputBase ref={params.InputProps.ref} inputProps={params.inputProps} autoFocus />
                          )}
                          renderOption={(props, option, state) => (
                            <li {...props}>
                              <TextLabelChip name={option.name} color={option.color} />
                            </li>
                          )}
                        />
                      }
                    />
                  </DetailRow>
                  <DetailRow>
                    <DetailHeader name={"Assignees"} />
                    <DetailElement
                      open={focus === "assignee"}
                      onClick={() => setFocus("assignee")}
                      defaultNode={
                        assignees.length === 0
                          ? undefined
                          : assignees.map((v) => (
                              <Box mr={1}>
                                <UserAvatar key={v.id} user={v} size="small" />
                              </Box>
                            ))
                      }
                      editNode={
                        <AssigneesRow
                          open={focus === "assignee"}
                          options={members}
                          value={assignees}
                          onUpdate={async (v?: WorkspaceUserMeta[]) => updateAssignees((v && v.map((v) => v.id)) || [])}
                        />
                      }
                    />
                  </DetailRow>
                  <DetailRow>
                    <DetailHeader name={"Start Date"} />
                    <DetailElement
                      open={focus === "start-date"}
                      onClick={() => {
                        setFocus("start-date");
                        if (startDate) {
                          handleChangeStartDateError(startDate);
                        } else {
                          // setStartDate(moment().toDate());
                          handleChangeStartDateError(moment().toDate());
                        }
                      }}
                      defaultNode={
                        startDate ? (
                          <Typography variant="subtitle2" color={"textPrimary"}>
                            {moment(startDate).format("yyyy-MM-DD HH:mm").toString()}
                          </Typography>
                        ) : undefined
                      }
                      editNode={
                        <Box display="flex" width="100%">
                          <Box flexGrow={1} pr={1}>
                            <DetailInputBase
                              fullWidth
                              autoFocus
                              error={true}
                              type={"datetime-local"}
                              defaultValue={moment(startDate || new Date())
                                .format("yyyy-MM-DDTHH:mm")
                                .toString()}
                              onChange={(e: any) => {
                                changeStartDate(moment(e.target.value).toDate());
                              }}
                              onBlur={() => updateStartDate()}
                            />
                            <FormHelperText error={Boolean(startDateError)}>{startDateError}</FormHelperText>
                          </Box>
                          <IconButton
                            size="small"
                            onClick={() => {
                              // setStartDate(null);
                              // handleChangeStartDateNull();
                            }}
                          >
                            <SvgIcon fontSize="small">
                              <Trash />
                            </SvgIcon>
                          </IconButton>
                        </Box>
                      }
                    />
                  </DetailRow>
                  <DetailRow>
                    <DetailHeader name={"Due Date"} />
                    <DetailElement
                      open={focus === "due-date"}
                      onClick={() => {
                        setFocus("due-date");
                        if (dueDate) {
                          handleChangeDueDateError(dueDate);
                        } else {
                          // setDueDate(moment().toDate());
                          handleChangeDueDateError(moment().toDate());
                        }
                      }}
                      defaultNode={
                        dueDate ? (
                          <Typography variant="subtitle2" color={"textPrimary"}>
                            {moment(dueDate).format("yyyy-MM-DD HH:mm").toString()}
                          </Typography>
                        ) : undefined
                      }
                      editNode={
                        <Box display="flex" width="100%" alignItems="center">
                          <Box flexGrow={1} pr={1}>
                            <DetailInputBase
                              fullWidth
                              autoFocus
                              type={"datetime-local"}
                              defaultValue={moment(dueDate || new Date())
                                .format("yyyy-MM-DDTHH:mm")
                                .toString()}
                              onChange={(e: any) => {
                                changeDueDate(moment(e.target.value).toDate());
                              }}
                              onBlur={() => updateDueDate()}
                            />
                            <FormHelperText error={Boolean(dueDateError)}>{dueDateError}</FormHelperText>
                          </Box>
                          <IconButton
                            size="small"
                            onClick={() => {
                              // setDueDate(null);
                              // handleChangeDueDateNull();
                            }}
                          >
                            <SvgIcon fontSize="small">
                              <Trash />
                            </SvgIcon>
                          </IconButton>
                        </Box>
                      }
                    />
                  </DetailRow>
                  <DetailRow>
                    <DetailHeader name={"Completed"} />
                    <DetailElement
                      open={focus === "completed"}
                      onClick={() => {
                        setFocus("completed");
                      }}
                      defaultNode={
                        completed ? (
                          <Typography variant="subtitle2" color={"textPrimary"}>
                            {moment(completed).format("yyyy-MM-DD HH:mm").toString()}
                          </Typography>
                        ) : undefined
                      }
                      editNode={
                        <Box display="flex" width="100%">
                          <Box display="flex" flexDirection="column" width="100%">
                            <Box display="flex" width="100%" alignItems="center">
                              <Box flexGrow={1} pr={1}>
                                <DetailInputBase
                                  fullWidth
                                  autoFocus
                                  type={"datetime-local"}
                                  defaultValue={moment(completed || new Date())
                                    .format("yyyy-MM-DDTHH:mm")
                                    .toString()}
                                  onChange={(e: any) => {
                                    changeCompleted(moment(e.target.value).toDate());
                                  }}
                                  onBlur={updateCompleted}
                                />
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      }
                    />
                  </DetailRow>
                  <DetailRow>
                    <DetailHeader name={"Time Spent"} />
                    <DetailElement
                      open={focus === "time-spent"}
                      onClick={() => {
                        setFocus("time-spent");
                      }}
                      defaultNode={
                        timespent ? (
                          <Typography variant="subtitle2" color={"textPrimary"}>
                            {timespent}
                          </Typography>
                        ) : undefined
                      }
                      editNode={
                        <Box display="flex" width="100%" flexDirection="column">
                          <DetailInputBase
                            name="time-spent"
                            autoFocus
                            placeholder="3h 35m"
                            defaultValue={timespent}
                            onChange={(e: any) => {
                              changeTimespent(e.target.value);
                            }}
                            onBlur={updateTimespent}
                          />
                          <FormHelperText error={Boolean(timespentError)}>{timespentError}</FormHelperText>
                        </Box>
                      }
                    />
                  </DetailRow>
                </Box>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </DetailModal>
      {copyTaskDialog && (
        <CopyTaskDialog
          open={copyTaskDialog}
          task={{
            name,
            description,
            project,
            phase,
            milestone,
            category,
            status,
            assignees,
            startDate,
            dueDate,
            completed,
            timespent: timespentStringToNumber(timespent),
          }}
          onClose={closeCopyDialog}
        />
      )}
    </>
  );
};

export default TaskDetail;

import { FC, ReactNode, useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import useAuth from "src/hooks/useAuth";
import { useDispatch } from "react-redux";
import { changeProjectIsNull } from "src/Store/action-creators";
import { LinearProgress } from "@mui/material";
import { WorkspaceFetch } from "..";
import { useTypedSelector } from "src/hooks";
import { changeWorkspace } from "src/Store/reducers/workspaceReducer";
import { LoadingScreen } from "../../Molecules";

interface WorkspaceGuardProps {
  children?: ReactNode;
}

const WorkspaceGuard: FC<WorkspaceGuardProps> = ({ children, ...rest }) => {
  const params = useParams();

  const { isAuthenticated, isEmailVerified, user } = useAuth();
  const dispatch = useDispatch();
  const { workspace, loading } = useTypedSelector((state) => state.workspace);

  useEffect(() => {
    if (isAuthenticated && isEmailVerified && workspace === undefined) {
      dispatch(changeWorkspace(params.workspace));
    }
  }, [workspace]);

  useEffect(() => {
    // 프로젝트 밖에 있을 경우엔 무조건 currentProject 값이 null로 변경
    if (!params.projectName) {
      dispatch(changeProjectIsNull());
    }
  }, [params]);

  if (!isAuthenticated) {
    return <Navigate to="/sign-in" />;
  }

  if (user == null) {
    return <Navigate to="/404-not-found" />;
  }

  if (!isEmailVerified) {
    return <Navigate to="/verify-email" />;
  }

  if (loading) {
    return <LoadingScreen />;
  }

  return <WorkspaceFetch>{children}</WorkspaceFetch>;
};

export default WorkspaceGuard;

import type { FC } from "react";
import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Theme } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useLocales } from "../../../hooks";

interface MultiLanguageProps {}

const MultiLanguage: FC<MultiLanguageProps> = () => {
  const theme = useTheme();
  const { allLang, currentLang, onChangeLang } = useLocales();

  const onChange = (event: SelectChangeEvent) => {
    onChangeLang(event.target.value);
  };

  return (
    <FormControl sx={{ m: 1, minWidth: 150 }}>
      <Select labelId="select-language" value={currentLang.value} onChange={onChange}>
        {allLang.map((v) => (
          <MenuItem key={v.value} value={v.value}>
            {v.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultiLanguage;

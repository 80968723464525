import { Field, Formik } from "formik";
// import { Autocomplete, AutocompleteRenderInputParams } from "formik-material-ui-lab";
import _ from "lodash";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { KeyboardEvent, useState } from "react";
import { Copy as CopyIcon } from "react-feather";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch } from "react-redux";
import { TextLabelChip, UserAvatar } from "src/Components/Atoms";
import { useTypedSelector } from "src/hooks";
import useAuth from "src/hooks/useAuth";
import useIsMountedRef from "src/hooks/useIsMountedRef";
import { getTableFirstPage } from "src/Store/reducers/tableReducer";
import { createTask, deleteTask, updateTask } from "src/Store/reducers/taskReducer";
import { BaseUserProperty, Project, Task, User } from "src/types";
import * as Yup from "yup";

import DesktopDateTimePicker from "@mui/lab/DesktopDateTimePicker";
import {
  Box,
  Button,
  Chip,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Paper,
  SvgIcon,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ProjectState } from "../../../generated/graphql";
import TaskStatusOptions from "../../../Pages/WorkspaceSetting/TaskDefaultOptions/TaskStatusOptions";
import CopyTaskDialog from "./components/CopyTaskDialog";
import Description from "./components/Description";
import Header from "./components/Header";
import TaskOptionAutocomplete from "./components/TaskOptionAutocomplete";
import TaskTitle from "./components/TaskTitle";
import { DateInputShortCut } from "./helper/DateInputShortCut";
import { getLastProjectRecord, setLastProjectRecord } from "./helper/LastProject";

import type { FC } from "react";

interface TaskDetailProps {
  onClose: () => void;
  open: boolean;
  fromMyTasks?: boolean;
  task?: Task;
  projectId?: string;
  range?: { start: Date; end: Date };
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  projectChip: {
    padding: "5px 0px 5px 10px",
  },
}));

const getInitialValues = (
  project: Project,
  user: User,
  task?: Task,
  range?: { start: Date; end: Date },
): Task & {
  submit: boolean | null;
  hours: number | null | undefined;
  minutes: number | null | undefined;
} => {
  if (task) {
    const hours = task.duration && Math.floor(task.duration / 60);
    const minutes = task.duration && task.duration % 60;

    return _.merge(
      {},
      {
        submit: null,
        hours: (hours && hours !== 0 && hours) || null,
        minutes: minutes,
      },
      task,
    );
  }
  return {
    id: -1,
    name: "",
    description: "",
    statusId: [...project.taskStatuses!].sort((a, b) => a.order! - b.order!)[0].id,
    status: [...project.taskStatuses!].sort((a, b) => a.order! - b.order!)[0],
    categoryId: [...project.taskCategories!].sort((a, b) => a.order! - b.order!)[0].id,
    category: [...project.taskCategories!].sort((a, b) => a.order! - b.order!)[0],
    projectId: project.id,
    project: project,
    assigneesIds: [user.id],
    assignees: [user],
    plannedStartDate: (range?.start && moment(range.start).toDate()) || null,
    plannedEndDate: (range?.end && moment(range.end).toDate()) || null,
    startDate: null,
    endDate: null,
    duration: null,
    createdAt: moment().toDate(),
    updatedAt: moment().toDate(),
    hours: null,
    minutes: null,
    createdById: user.id,
    createdBy: user,
    submit: null,
    timesheets: null,
  };
};

const TaskDetailModal: FC<TaskDetailProps> = ({
  className,
  task,
  projectId,
  onClose,
  open,
  range,
  fromMyTasks = false,
  ...rest
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMountedRef = useIsMountedRef();
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const { projects, currentProjectId } = useTypedSelector((state) => state.projects);
  const { members } = useTypedSelector((state) => state.members);
  const { projectMembers } = useTypedSelector((state) => state.projectMembers);
  const { taskStatuses } = useTypedSelector((state) => state.projectTaskStatuses);
  const { taskCategories } = useTypedSelector((state) => state.projectTaskCategories);
  const { pageSize } = useTypedSelector((state) => state.table);

  const [copyDialog, setCopyDialog] = useState(false);

  const isCreating = !task;

  const [project] = useState(
    (projectId && projects.byId[projectId]) ||
      (isCreating && getLastProjectRecord(projects.allIds.map((id) => projects.byId[id]))) ||
      null,
  );

  const [taskStatusOptions, setTaskStatusOptions] = useState(
    (project && taskStatuses.byProject[project.id].map((id) => taskStatuses.byId[id])) || [],
  );
  const [taskCategoryOptions, setTaskCategoryOptions] = useState(
    (project && taskCategories.byProject[project.id].map((id) => taskCategories.byId[id])) || [],
  );
  const [assignees, setAssignees] = useState(
    (project && projectMembers.byProject[project.id].map((id) => projectMembers.byId[id])) || [],
  );

  if (!project) {
    return <div>Error</div>;
  }
  return (
    <Formik
      initialValues={getInitialValues(project, user!, task, range)}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(255).required("Task name is required"),
        description: Yup.string().nullable().max(5000),
        plannedStartDate: Yup.date().nullable(),
        plannedEndDate: Yup.date().nullable(),
        endDate: Yup.date().nullable(),
        duration: Yup.string().nullable(),
        hours: Yup.number().nullable().max(99),
        minutes: Yup.number().nullable().max(59),
      })}
      onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
        try {
          // change duration value into numerical values.
          let duration = values.hours && values.hours * 60;
          duration = duration ? duration + (values.minutes || 0) : values.minutes;

          const data: Task = {
            ...values,
            duration: duration || null,
          };

          if (isCreating && user) {
            dispatch(getTableFirstPage());
            dispatch(
              createTask(
                data,
                values.assigneesIds.some((id) => user.id === id),
                pageSize,
              ),
            );
            setLastProjectRecord(values.projectId);
          } else {
            await dispatch(updateTask(data));
          }

          enqueueSnackbar(isCreating ? "A new task created" : "Task updated", {
            variant: "success",
          });
          if (isMountedRef.current) {
            resetForm();
            setStatus({ success: true });
            setSubmitting(false);
          }

          onClose();
        } catch (err) {
          console.error(err);
          if (isMountedRef.current) {
            setStatus({ success: false });
            setErrors({ submit: (err as Error).message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        submitForm,
        setFieldTouched,
        setFieldValue,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <Dialog
            onClose={() => {
              onClose();
              !isCreating && handleSubmit();
            }}
            open={open}
            maxWidth="md"
            fullWidth
            {...rest}
          ></Dialog>
          {/* {copyDialog && (
            <CopyTaskDialog
              open={copyDialog}
              task={{
                name,
                description,

              }}
              onClose={(copied: boolean) => {
                setCopyDialog(false);

                if (copied) {
                  onClose();
                }
              }}
            />
          )} */}
        </form>
      )}
    </Formik>
  );
};

export default TaskDetailModal;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { objFromArray } from "src/utils/utils";
import _ from "lodash";
import { ProjectAssignee } from "../../generated/graphql";

export interface ProjectMembersState {
  loading: boolean;
  isLoaded: { byProject: Record<string, boolean> };
  projectMembers: {
    byId: Record<number, ProjectAssignee>;
    byProject: Record<string, number[]>;
  };
}

const initialState: ProjectMembersState = {
  loading: true,
  isLoaded: {
    byProject: {},
  },
  projectMembers: {
    byId: {},
    byProject: {},
  },
};

export const ProjectMembersSlice = createSlice({
  name: "projectMembers",
  initialState,
  reducers: {
    reset(state: ProjectMembersState) {
      state = initialState;
    },
    startLoad(state: ProjectMembersState) {
      state.loading = true;
    },
    getProjectMembers(state: ProjectMembersState, action: PayloadAction<{ members: ProjectAssignee[] }>) {
      const { members } = action.payload;

      state.projectMembers.byId = _.merge(state.projectMembers.byId, objFromArray(members));

      const _groupByProjectId = _.groupBy(members, "projectId");
      _.forEach(_groupByProjectId, (val, projectId) => {
        state.projectMembers.byProject[projectId] = val.map((v) => v.id);
        state.isLoaded.byProject[projectId] = true;
      });

      state.loading = false;
    },
    addProjectMember(state: ProjectMembersState, action: PayloadAction<{ member: ProjectAssignee }>) {
      const { member } = action.payload;

      state.projectMembers.byId[member.id] = member;
      state.projectMembers.byProject[member.projectId].push(member.id);
    },
    updateProjectMember(state: ProjectMembersState, action: PayloadAction<{ member: ProjectAssignee }>) {
      const { member } = action.payload;

      _.merge(state.projectMembers.byId[member.id], member);
    },
    deleteProjectMember(state: ProjectMembersState, action: PayloadAction<{ id: number }>) {
      const { id } = action.payload;

      const projectId = state.projectMembers.byId[id].projectId;

      state.projectMembers.byId = _.omit(state.projectMembers.byId, id);
      _.pull(state.projectMembers.byProject[projectId], id);
    },
  },
});

export const reducer = ProjectMembersSlice.reducer;

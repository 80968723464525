import {
  CREATE_PROJECT_PHASE,
  DELETE_PROJECT_PHASE,
  GET_PROJECT_PHASES,
  UPDATE_PROJECT_PHASE,
} from "src/Api/Project/ProjectPhase.queries";
import client from "src/apollo";
import { AppThunk } from "..";
import {
  CREATE_MILESTONE,
  DELETE_MILESTONE,
  GET_MILESTONES,
  UPDATE_MILESTONE,
} from "src/Api/Milestone/Milestone.queries";
import { ProjectTimelineSlice as slice } from "../reducers/projectTimelineReducer";
import {
  CreateMilestoneMutation,
  CreateMilestoneMutationVariables,
  CreateProjectPhaseMutation,
  CreateProjectPhaseMutationVariables,
  DeleteMilestoneMutation,
  DeleteMilestoneMutationVariables,
  DeleteProjectPhaseMutation,
  DeleteProjectPhaseMutationVariables,
  GetMilestonesQuery,
  GetMilestonesQueryVariables,
  GetProjectPhasesQuery,
  GetProjectPhasesQueryVariables,
  Milestone,
  UpdateMilestoneMutation,
  UpdateMilestoneMutationVariables,
  UpdateProjectPhaseInput,
  UpdateProjectPhaseMutation,
  UpdateProjectPhaseMutationVariables,
} from "../../generated/graphql";

export const getProjectPhases = (projectId?: string | null): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setLoadingState({ loading: true }));

  const res = await client.query<GetProjectPhasesQuery, GetProjectPhasesQueryVariables>({
    query: GET_PROJECT_PHASES,
    variables: { projectId },
    fetchPolicy: "cache-first",
  });

  if (res.data.GetProjectPhases.ok) {
    dispatch(slice.actions.getProjectPhases({ phase: res.data.GetProjectPhases.phases }));
  } else {
    console.error(res.data.GetProjectPhases.error);
    throw new Error(`Get Project Phases Error : ${res.data?.GetProjectPhases.error}`);
  }

  dispatch(slice.actions.setLoadingState({ loading: false }));
};

export const createProjectPhase = (projectId: string, phaseId: number, start: Date, end: Date): AppThunk => async (
  dispatch,
) => {
  const res = await client.mutate<CreateProjectPhaseMutation, CreateProjectPhaseMutationVariables>({
    mutation: CREATE_PROJECT_PHASE,
    variables: {
      projectId,
      phaseId,
      start,
      end,
    },
  });

  if (res.data?.CreateProjectPhase.ok && res.data.CreateProjectPhase.phase) {
    dispatch(slice.actions.addProjectPhase({ phase: res.data.CreateProjectPhase.phase }));
  } else {
    console.error(res.data?.CreateProjectPhase.error);
    throw new Error(`Create Project Phase Error : ${res.data?.CreateProjectPhase.error}`);
  }
};

export const updateProjectPhase = (id: number, input: UpdateProjectPhaseInput): AppThunk => async (dispatch) => {
  const res = await client.mutate<UpdateProjectPhaseMutation, UpdateProjectPhaseMutationVariables>({
    mutation: UPDATE_PROJECT_PHASE,
    variables: { id, input },
  });

  if (res.data?.UpdateProjectPhase.ok) {
    dispatch(slice.actions.updateProjectPhase({ id, input }));
  } else {
    console.error(res.data?.UpdateProjectPhase.error);
    throw new Error(`Update Project Phase Error : ${res.data?.UpdateProjectPhase.error}`);
  }
};

export const deleteProjectPhase = (id: number, removeChilds: boolean): AppThunk => async (dispatch) => {
  const res = await client.mutate<DeleteProjectPhaseMutation, DeleteProjectPhaseMutationVariables>({
    mutation: DELETE_PROJECT_PHASE,
    variables: { id, removeChilds },
  });

  if (res.data?.DeleteProjectPhase.ok) {
    dispatch(slice.actions.deleteProjectPhase({ id }));
  } else {
    console.error(res.data?.DeleteProjectPhase.error);
    throw new Error(`Delete Project Phase Error : ${res.data?.DeleteProjectPhase.error}`);
  }
};

export const getMilestones = (projectId?: string | null, phaseId?: number | null): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setLoadingState({ loading: true }));

  const res = await client.query<GetMilestonesQuery, GetMilestonesQueryVariables>({
    query: GET_MILESTONES,
    variables: { projectId, phaseId },
  });

  if (res.data.GetMilestones.ok) {
    dispatch(slice.actions.getMilestones({ milestones: res.data.GetMilestones.milestones }));
  } else {
    console.error(res.data.GetMilestones.error);
    throw new Error(`Get Milestones Error : ${res.data?.GetMilestones.error}`);
  }

  dispatch(slice.actions.setLoadingState({ loading: false }));
};

export const createMilestone = (
  name: string,
  end: Date,
  projectId: string,
  start?: Date | null,
  phaseId?: number,
): AppThunk => async (dispatch) => {
  const res = await client.mutate<CreateMilestoneMutation, CreateMilestoneMutationVariables>({
    mutation: CREATE_MILESTONE,
    variables: {
      name,
      projectId,
      start,
      end,
      phaseId,
    },
  });

  if (res.data?.CreateMilestone.ok && res.data.CreateMilestone.milestone) {
    dispatch(slice.actions.addMilestone({ milestone: res.data.CreateMilestone.milestone }));
  } else {
    console.error(res.data?.CreateMilestone.error);
    throw new Error(`Create Milestone Error : ${res.data?.CreateMilestone.error}`);
  }
};

export const updateMilestone = (milestone: Milestone): AppThunk => async (dispatch) => {
  const res = await client.mutate<UpdateMilestoneMutation, UpdateMilestoneMutationVariables>({
    mutation: UPDATE_MILESTONE,
    variables: {
      id: milestone.id,
      start: milestone.start,
      end: milestone.end,
      name: milestone.name,
      description: milestone.description,
      phaseId: milestone.phaseId,
      color: milestone.color,
    },
  });

  if (res.data?.UpdateMilestone.ok) {
    dispatch(slice.actions.updateMilestones({ milestone }));
  } else {
    console.error(res.data?.UpdateMilestone.error);
    throw new Error(`Update Milestone Error : ${res.data?.UpdateMilestone.error}`);
  }
};

export const deleteMilestone = (id: number, removeChilds: boolean): AppThunk => async (dispatch) => {
  const res = await client.mutate<DeleteMilestoneMutation, DeleteMilestoneMutationVariables>({
    mutation: DELETE_MILESTONE,
    variables: { id, removeChilds },
  });

  if (res.data?.DeleteMilestone.ok) {
    dispatch(slice.actions.deleteMilestone({ id }));
  } else {
    console.error(res.data?.DeleteMilestone.error);
    throw new Error(`Delete Milestone Error : ${res.data?.DeleteMilestone.error}`);
  }
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UPDATE_CURRENT_USER } from "src/Api/User/User.queries";
import { GET_CURRENT_WORKSPACE } from "src/Api/Workspace/Workspace.queries";
import client from "src/apollo";
import { Workspace } from "src/types/WorkspaceTypes";
import { getStoredLastWorkspace } from "src/utils/local";
import {
  GetCurrentWorkspaceQuery,
  GetCurrentWorkspaceQueryVariables,
  UpdateCurrentUserMutation,
  UpdateCurrentUserMutationVariables,
} from "../../generated/graphql";
import { AppThunk } from "../store";
import { resetStoresWhenWorkspaceIsChanged } from "./resetReducer";

export interface WorkspaceState {
  loading: boolean;
  error: string | null;
  workspace?: Workspace;
}
type WorkspaceConvertingTypes = {
  context: WorkspaceConvertingContext;
  workspaceId?: string;
};

enum WorkspaceConvertingContext {
  USER_ACCESS_FROM_CONVERTING_ACTION,
  USER_ACCESS_AFTER_LOGIN,
  USER_ACCESS_FROM_URL,
  USER_URL_DIFFERENT_WITH_STOREDDATA,
}

const initialState: WorkspaceState = {
  loading: true,
  error: null,
  workspace: undefined,
};

export const slice = createSlice({
  name: "workspace",
  initialState,
  reducers: {
    reset(state: WorkspaceState) {
      state.workspace = undefined;
    },
    changeWorkspace(state: WorkspaceState) {
      state.loading = true;
      state.error = null;
    },
    changeWorkspaceSuccess(state: WorkspaceState, action: PayloadAction<{ workspace: Workspace }>) {
      const { workspace } = action.payload;
      state.loading = false;
      state.error = null;
      state.workspace = workspace;
    },
    changeWorkspaceError(state: WorkspaceState, action: PayloadAction<{ error: string | null }>) {
      const { error } = action.payload;

      state.loading = false;
      state.error = error;
      state.workspace = undefined;
    },
    updateWorkspace(state: WorkspaceState, action: PayloadAction<{ workspace: Workspace }>) {
      const { workspace } = action.payload;

      state.workspace = workspace;
    },
    deleteWorkspace(state: WorkspaceState, action: PayloadAction<{ id: string }>) {
      const { id } = action.payload;

      state.loading = false;
      state.workspace = undefined;
    },
  },
});

export const reducer = slice.reducer;

const resetClientAndStore = async (dispatch: any) => {
  await Promise.all([client.resetStore(), dispatch(resetStoresWhenWorkspaceIsChanged(false))]);
};

export const changeWorkspace = (workspaceParam?: string, workspaceId?: string): AppThunk => async (
  dispatch,
  _: any,
  { history }: any,
) => {
  dispatch(slice.actions.changeWorkspace());

  try {
    await resetClientAndStore(dispatch);
    const { context, workspaceId: nextWorkspaceId } = ProcessGetNextStep(workspaceId, workspaceParam);

    if (context === WorkspaceConvertingContext.USER_URL_DIFFERENT_WITH_STOREDDATA) {
      throw new Error("User's local history is different with url.");
    }

    const request = await client.query<GetCurrentWorkspaceQuery, GetCurrentWorkspaceQueryVariables>({
      query: GET_CURRENT_WORKSPACE,
      variables: {
        id: nextWorkspaceId,
      },
    });

    if (request.data.GetCurrentWorkspace.ok && request.data.GetCurrentWorkspace.workspace) {
      updateLastWorkspace(request.data.GetCurrentWorkspace.workspace);
      dispatch(slice.actions.changeWorkspaceSuccess({ workspace: request.data.GetCurrentWorkspace.workspace }));

      if (!workspaceParam) {
        history.push(`/w/${request.data.GetCurrentWorkspace.workspace.name}`);
      }
    } else {
      throw new Error(request.data.GetCurrentWorkspace.error || "Fail to get GetCurrentWorkspace result");
    }
  } catch (err) {
    handleWorkspaceChangeError(dispatch, history, "error");
  }
};

const ProcessGetNextStep = (
  workspaceId: string | undefined,
  workspaceParams: string | undefined,
): WorkspaceConvertingTypes => {
  if (workspaceId) {
    return {
      context: WorkspaceConvertingContext.USER_ACCESS_FROM_CONVERTING_ACTION,
      workspaceId: workspaceId,
    };
  }

  if (!workspaceId && !workspaceParams) {
    return {
      context: WorkspaceConvertingContext.USER_ACCESS_AFTER_LOGIN,
    };
  }

  const storedWorkspace = getStoredLastWorkspace();
  if (workspaceParams && storedWorkspace && workspaceParams !== storedWorkspace.name) {
    return {
      context: WorkspaceConvertingContext.USER_URL_DIFFERENT_WITH_STOREDDATA,
    };
  } else {
    return {
      context: WorkspaceConvertingContext.USER_ACCESS_FROM_URL,
      workspaceId: storedWorkspace?.id,
    };
  }
};

const handleWorkspaceChangeError = (dispatch: any, history: any, errorMessage: string) => {
  dispatch(slice.actions.changeWorkspaceError({ error: errorMessage }));
  cleanupOnWorkspaceChangeError(history);
};

const cleanupOnWorkspaceChangeError = (history: any) => {
  ChagneUserLastworkspaceId(undefined);
  window.localStorage.removeItem("lastWorkspace");
  history.push("/user-workspaces");
};

const updateLastWorkspace = (workspace: Workspace) => {
  window.localStorage.setItem("lastWorkspace", JSON.stringify({ id: workspace.id, name: workspace.name }));
  ChagneUserLastworkspaceId(workspace.id);
};

// const ProcessWhenWorkspaceChangingIsError = (history: any) => {
//   ChagneUserLastworkspaceId(undefined);
//   window.localStorage.removeItem("lastWorkspace");
//   history.push("/user-workspaces");
// };

// const ProcessWhenWorkspaceChangingIsSuccess = (workspace: Workspace) => {
//   window.localStorage.setItem("lastWorkspace", JSON.stringify({ id: workspace.id, name: workspace.name }));
//   ChagneUserLastworkspaceId(workspace.id);
// };

const ChagneUserLastworkspaceId = (lastWorkspaceId: string | undefined) => {
  client.mutate<UpdateCurrentUserMutation, UpdateCurrentUserMutationVariables>({
    mutation: UPDATE_CURRENT_USER,
    variables: {
      lastWorkspaceId: lastWorkspaceId,
    },
  });
};

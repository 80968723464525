import type { FC } from "react";

interface EmojiProps {
  symbol: string;
  label?: string;
  grey?: boolean;
}

const Emoji: FC<EmojiProps> = ({ symbol, label, grey }) => (
  <span
    className="emoji"
    style={{ color: grey ? "grey" : "", filter: grey ? "grayscale(100%)" : "" }}
    role="img"
    aria-label={label ? label : ""}
    aria-hidden={label ? "false" : "true"}
  >
    {symbol}
  </span>
);

export default Emoji;

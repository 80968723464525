import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

export default makeStyles((theme: Theme) => ({
  pageTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(0),
    marginLeft: theme.spacing(3.5),
  },
  typo: {
    color: theme.palette.text.secondary,
    fontFamily: "Roboto Mono",
  },
  button: {
    color: "white",
    textTransform: "none",
    paddingTop: 2,
    paddingBottom: 2,
    "&:active": {
      boxShadow: theme.shadows,
    },
  },
}));

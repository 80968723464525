import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { ProjectBudget } from "../../generated/graphql";

export interface ProjectBudgetState {
  loading: boolean;
  projectBudgets: {
    byProject: Record<string, ProjectBudget>;
  };
}

const initialState: ProjectBudgetState = {
  loading: true,
  projectBudgets: {
    byProject: {},
  },
};

export const ProjectBudgetSlice = createSlice({
  name: "projectBudget",
  initialState,
  reducers: {
    reset(state: ProjectBudgetState) {
      state = initialState;
    },
    setLoadingState(state: ProjectBudgetState, action: PayloadAction<{ loading: boolean }>) {
      state.loading = action.payload.loading;
    },
    addProjectBudget(state: ProjectBudgetState, action: PayloadAction<{ projectBudget: ProjectBudget }>) {
      const { projectBudget } = action.payload;

      state.projectBudgets.byProject[projectBudget.projectId] = projectBudget;
    },
    updateProjectBudget(state: ProjectBudgetState, action: PayloadAction<{ projectBudget: ProjectBudget }>) {
      const { projectBudget } = action.payload;

      if (projectBudget.projectId in state.projectBudgets.byProject)
        state.projectBudgets.byProject[projectBudget.projectId] = _.merge(
          state.projectBudgets.byProject[projectBudget.projectId],
          projectBudget,
        );
      else {
        state.projectBudgets.byProject[projectBudget.projectId] = projectBudget;
      }
    },
  },
});

export const reducer = ProjectBudgetSlice.reducer;

import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import menu2Fill from "@iconify/icons-eva/menu-2-fill";
import { alpha, AppBar, Box, IconButton, Stack, styled, Toolbar } from "@mui/material";

// import Logo from 'src/components/Logo';
import Account from "./Account";
import Contacts from "./Contacts";
import Notifications from "./Notifications";
import Search from "./Search";
import Settings from "./Settings";

import type { FC } from "react";
import useCollapseDrawer from "../../../hooks/useCollapseDrawer";
import { MHidden } from "../../../Components/@material-extend";
import AppBreadcrumbs from "./AppBreadcrumbs";
import MultiLanguage from "./MultiLanguage";

interface TopBarProps {
  className?: string;
  onMobileNavOpen?: () => void;
  onOpenSidebar: VoidFunction;
}

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const APPBAR_MOBILE = 50;
const APPBAR_DESKTOP = 50;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.paper, 0.72),
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

const TopBar: FC<TopBarProps> = ({ className, onMobileNavOpen, onOpenSidebar, ...rest }) => {
  const { isCollapse } = useCollapseDrawer();

  return (
    <RootStyle
      sx={{
        ...(isCollapse && {
          width: { lg: `calc(100% - ${COLLAPSE_WIDTH}px)` },
        }),
      }}
    >
      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: "text.primary" }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>
        <AppBreadcrumbs />
        <Box sx={{ flexGrow: 1 }} />
        {/* <Search />
        <Contacts />
        <Notifications /> */}
        {/* <Settings /> */}
        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <Account />
          <Settings />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;

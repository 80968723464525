import gql from "graphql-tag";

export const F_COMPANY_PROPERTIES = gql`
  fragment CompanyProperties on Company {
    id
    name
  }
`;

export const F_OPTIONS = gql`
  fragment Options on ProjectInfo {
    complexityLevel {
      id
      name
    }
    paymentTerm {
      id
      name
    }
    program {
      id
      name
    }
    risk {
      id
      name
    }
    serviceType {
      id
      name
    }
    mainTool {
      id
      name
    }
    subTool {
      id
      name
    }
  }
`;

export const GET_PROJECTS_BY_USER = gql`
  query GetProjectsByUser {
    GetProjectsByUser {
      ok
      error
      projects {
        id
        name
        description
        projectColor
        projectImage
        state
        currency
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_PROJECT_LIST_BY_USER = gql`
  query GetProjectListByUser {
    GetProjectsByUser {
      ok
      error
      projects {
        id
        name
        description
        projectColor
        projectImage
        state
        createdAt
        updatedAt
      }
    }
  }
`;

export const CHECK_PROJECT_NAME = gql`
  query CheckProjectName($projectName: String!) {
    CheckProjectName(projectName: $projectName) {
      ok
      error
      result
    }
  }
`;

export const UPDATE_PROJECT = gql`
  mutation UpdateProject($input: UpdateProjectInput!) {
    UpdateProject(input: $input) {
      ok
      error
    }
  }
`;

export const CREATE_PROJECT = gql`
  mutation CreateProject(
    $name: String!
    $description: String
    $projectColor: String
    $projectImage: String
    $state: String!
    $arch_projectName: String
    $arch_startDate: DateTime
    $arch_endDate: DateTime
    $arch_size: Float
    $arch_publishedCost: Price
    $arch_website: String
    $arch_location: Location
    $proj_startDate: DateTime
    $proj_endDate: DateTime
    $proj_publishedDate: DateTime!
    $proj_contractValue: Price
    $strategy: String
    $remark: String
    $riskDescription: String
    $otherComments: String
    $clientMembers: [String!]
    $complexityLevelId: Int
    $paymentTermId: Int
    $programId: Int
    $riskId: Int
    $serviceTypeId: Int
    $mainToolId: Int
    $subToolId: Int
    $architectId: Int
    $clientId: Int
    $generalContractorId: Int
    $ownerId: Int
  ) {
    CreateProject(
      name: $name
      description: $description
      projectColor: $projectColor
      projectImage: $projectImage
      state: $state
      arch_projectName: $arch_projectName
      arch_startDate: $arch_startDate
      arch_endDate: $arch_endDate
      arch_size: $arch_size
      arch_publishedCost: $arch_publishedCost
      arch_website: $arch_website
      arch_location: $arch_location
      proj_startDate: $proj_startDate
      proj_endDate: $proj_endDate
      proj_publishedDate: $proj_publishedDate
      proj_contractValue: $proj_contractValue
      strategy: $strategy
      remark: $remark
      riskDescription: $riskDescription
      otherComments: $otherComments
      clientMembers: $clientMembers
      complexityLevelId: $complexityLevelId
      paymentTermId: $paymentTermId
      programId: $programId
      riskId: $riskId
      serviceTypeId: $serviceTypeId
      mainToolId: $mainToolId
      subToolId: $subToolId
      architectId: $architectId
      clientId: $clientId
      generalContractorId: $generalContractorId
      ownerId: $ownerId
    ) {
      ok
      error
      result {
        id
        name
        description
        projectColor
        projectImage
        state
        createdAt
        updatedAt
        assignees {
          id
          lead
          userId
          projectId
          position {
            id
            name
          }
          createdAt
          updatedAt
        }
        projectInfo {
          id
          arch_projectName
          arch_startDate
          arch_endDate
          arch_size
          arch_publishedCost
          arch_website
          arch_location
          proj_startDate
          proj_endDate
          proj_publishedDate
          proj_contractValue
          strategy
          remark
          riskDescription
          otherComments
          clientMembers
          ...Options
          architect {
            ...CompanyProperties
          }
          client {
            ...CompanyProperties
          }
          generalContractor {
            ...CompanyProperties
          }
          owner {
            ...CompanyProperties
          }
        }
        taskStatuses {
          id
          name
          order
          keyStatus
          color
          isHidden
          isHiddenFromCalendar
          projectId
        }
        taskCategories {
          id
          name
          order
          color
          projectId
        }
      }
    }
  }
  ${F_COMPANY_PROPERTIES}
  ${F_OPTIONS}
`;

export const UPDATE_PROJECT_INFO = gql`
  mutation UpdateProjectInfo(
    $projectId: String!
    $name: String
    $description: String
    $projectColor: String
    $projectImage: String
    $state: String
    $arch_projectName: String
    $arch_startDate: DateTime
    $arch_endDate: DateTime
    $arch_size: Float
    $arch_publishedCost: Price
    $arch_website: String
    $arch_location: Location
    $proj_startDate: DateTime
    $proj_endDate: DateTime
    $proj_publishedDate: DateTime
    $proj_contractValue: Price
    $strategy: String
    $remark: String
    $riskDescription: String
    $otherComments: String
    $clientMembers: [String!]
    $complexityLevelId: Int
    $paymentTermId: Int
    $programId: Int
    $riskId: Int
    $serviceTypeId: Int
    $mainToolId: Int
    $subToolId: Int
    $architectId: Int
    $clientId: Int
    $generalContractorId: Int
    $ownerId: Int
  ) {
    UpdateProjectInfo(
      projectId: $projectId
      name: $name
      description: $description
      projectColor: $projectColor
      projectImage: $projectImage
      state: $state
      arch_projectName: $arch_projectName
      arch_startDate: $arch_startDate
      arch_endDate: $arch_endDate
      arch_size: $arch_size
      arch_publishedCost: $arch_publishedCost
      arch_website: $arch_website
      arch_location: $arch_location
      proj_startDate: $proj_startDate
      proj_endDate: $proj_endDate
      proj_publishedDate: $proj_publishedDate
      proj_contractValue: $proj_contractValue
      strategy: $strategy
      remark: $remark
      riskDescription: $riskDescription
      otherComments: $otherComments
      clientMembers: $clientMembers
      complexityLevelId: $complexityLevelId
      paymentTermId: $paymentTermId
      programId: $programId
      riskId: $riskId
      serviceTypeId: $serviceTypeId
      mainToolId: $mainToolId
      subToolId: $subToolId
      architectId: $architectId
      clientId: $clientId
      generalContractorId: $generalContractorId
      ownerId: $ownerId
    ) {
      ok
      error
    }
  }
`;

export const DELETE_PROJECT = gql`
  mutation DeleteProject($projectId: String!) {
    DeleteProject(projectId: $projectId) {
      ok
      error
    }
  }
`;

import moment from "moment";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Milestone,
  ProjectPhase,
  ProjectTaskCategory,
  ProjectTaskStatus,
  WorkspaceUserBase,
} from "../../../../generated/graphql";
import { useTypedSelector } from "../../../../hooks";
import useAuth from "../../../../hooks/useAuth";
import { getListByIds } from "../../../../Store/helper/projection";
import { useProperties } from "../../../../Store/hooks";
import { closeCreateTaskDialog, createTask as createTaskDispatch } from "../../../../Store/reducers/taskReducer";

type FocusField =
  | "project"
  | "category"
  | "phase"
  | "milestone"
  | "assignee"
  | "start-date"
  | "due-date"
  | "completed"
  | "time-spent"
  | "none";

export function useCreateTaskDialog(projectId?: string) {
  const [loading, setLoading] = useState(false);
  const [focus, setFocus] = useState<FocusField>("none");
  const { user } = useAuth();

  const dispatch = useDispatch();
  const { selectedRange } = useTypedSelector((state) => state.tasks);
  const {
    getUsersByIds,
    getProject,
    getProjects,
    getProjectPhases,
    getProjectMilestones,
    getProjectMembers,
    getProjectTaskCategories,
    getProjectTaskStatuses,
    getProjectTaskCategoryDefault,
    getProjectTaskStatusDefault,
  } = useProperties();

  /**
   * Variables
   */
  // Project Id가 있으면 해당 Project를 할당하고, 아니면 projects 중 아무거나 할당한다.
  // TODO: cache를 사용해서 이전에 입력한 project가 default로 지정될 수 있도록 추가
  const [project, setProject] = useState(getProject(projectId, true));

  const projects = getProjects();

  const [phases, setPhases] = useState(getProjectPhases(project?.id));
  const [milestones, setMilestones] = useState(getProjectMilestones({ projectId: project?.id }));
  const [statuses, setStatuses] = useState(getProjectTaskStatuses(project?.id));
  const [categories, setCategories] = useState(getProjectTaskCategories(project?.id));
  const [members, setMembers] = useState(getProjectMembers(project?.id));

  const [category, setCategory] = useState(getProjectTaskCategoryDefault(project?.id));
  const [status, setStatus] = useState(getProjectTaskStatusDefault(project?.id));

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [phase, setPhase] = useState<ProjectPhase | null>(null);
  const [milestone, setMilestone] = useState<Milestone | null>(null);

  const [assignees, setAssignees] = useState<WorkspaceUserBase[]>(
    (!projectId && members.some((v) => v.id === user!.id) && [members.find((v) => v.id === user!.id)!]) || [],
  );
  const [startDate, setStartDate] = useState<Date | null>(
    (selectedRange?.start && moment(selectedRange.start).toDate()) || null,
  );
  const [dueDate, setDueDate] = useState<Date | null>(
    (selectedRange?.end && moment(selectedRange.end).toDate()) || null,
  );

  const changeProject = (projectId: string) => {
    try {
      const _members = getProjectMembers(projectId);
      setProject(getProject(projectId));
      setPhases(getProjectPhases(projectId));
      setMilestones(getProjectMilestones({ projectId }));
      setStatuses(getProjectTaskStatuses(projectId));
      setCategories(getProjectTaskCategories(projectId));
      setMembers(getProjectMembers(projectId));

      setPhase(null);
      setMilestone(null);
      setStatus(getProjectTaskStatusDefault(projectId));
      setCategory(getProjectTaskCategoryDefault(projectId));
      setAssignees((_members.some((v) => v.id === user!.id) && [_members.find((v) => v.id === user!.id)!]) || []);
    } catch (error) {}
  };

  const changeCategory = (value: ProjectTaskCategory) => {
    setCategory(value);
  };

  const changeStatus = (value: ProjectTaskStatus) => {
    setStatus(value);
  };

  const changeName = (value: string) => {
    setName(value);
  };

  const changeDescription = (value: string) => {
    setDescription(value);
  };

  const changePhase = (value: ProjectPhase) => {
    setPhase(value);
    setMilestone(null);

    setMilestones(getProjectMilestones({ projectId: value.projectId, phaseId: value.id }));
  };

  const changeMilestone = (value: Milestone) => {
    setMilestone(value);
  };

  const changeStartDate = (value: Date) => {
    setStartDate(value);
  };

  const changeDueDate = (value: Date) => {
    setDueDate(value);
  };

  const changeAssignees = (ids: string[]) => {
    setAssignees(getUsersByIds(ids));
  };

  const createTask = async () => {
    setTimeout(() => setLoading(true), 0);
    if (project && status && category) {
      dispatch(
        createTaskDispatch(
          {
            id: -1,
            name,
            description,
            projectId: project.id,
            statusId: status.id,
            categoryId: category.id,
            assigneesIds: assignees.map((v) => v.id) || [],
            phaseId: phase?.id || null,
            milestoneId: milestone?.id || null,
            plannedStartDate: startDate || null,
            plannedEndDate: dueDate || null,
          },
          assignees.some((v) => v.id === user!.id),
        ),
      );

      setName("");
      setDescription("");
      setStartDate(null);
      setDueDate(null);
    }

    setLoading(false);
    dispatch(closeCreateTaskDialog());
  };

  return {
    loading,
    focus,
    setFocus: (value: FocusField) => setFocus(value),
    unsetFocus: () => setTimeout(() => setFocus("none"), 0),
    options: {
      projects,
      phases,
      milestones,
      statuses,
      categories,
      members,
    },
    state: {
      project,
      category,
      status,
      name,
      description,
      phase,
      milestone,
      assignees,
      startDate,
      dueDate,
    },
    action: {
      changeProject,
      changeCategory,
      changeStatus,
      changeName,
      changeDescription,
      changeStartDate,
      changeDueDate,
      changePhase,
      changeMilestone,
      changeAssignees,
      createTask,
    },
  };
}

import {
  FINISH_USER_ATTENDANCE,
  GET_ATTENDANCE_ALL_USERS,
  GET_ATTENDANCE_BY_USER,
  START_USER_ATTENDANCE,
  UPDATE_USER_ATTENDANCE,
} from "src/Api/User/UserAttendance.queries";
import client from "src/apollo";
import { AppThunk } from "../store";
import { WorkspaceAttendanceSlice as slice } from "../reducers/workspaceAttendance";
import {
  FinishUserAttendanceMutation,
  GetAttendanceAllUsersQuery,
  GetAttendanceAllUsersQueryVariables,
  GetAttendanceByUserQuery,
  GetAttendanceByUserQueryVariables,
  StartUserAttendanceMutation,
  UpdateUserAttendanceMutation,
  UpdateUserAttendanceMutationVariables,
  UserAttendance,
} from "../../generated/graphql";

export const getAttendanceByUser = (userId: string, start: Date, end: Date): AppThunk => async (dispatch) => {
  dispatch(slice.actions.startToLoad());

  const res = await client.query<GetAttendanceByUserQuery, GetAttendanceByUserQueryVariables>({
    query: GET_ATTENDANCE_BY_USER,
    variables: {
      userId,
      start,
      end,
    },
  });

  if (res.data.GetAttendanceByUser.ok && res.data.GetAttendanceByUser.results) {
    dispatch(slice.actions.getAttendance({ attendance: res.data.GetAttendanceByUser.results }));
  }
};

export const getAttendanceAllUsers = (start: Date, end: Date): AppThunk => async (dispatch) => {
  dispatch(slice.actions.startToLoad());

  const res = await client.query<GetAttendanceAllUsersQuery, GetAttendanceAllUsersQueryVariables>({
    query: GET_ATTENDANCE_ALL_USERS,
    variables: {
      start,
      end,
    },
  });

  if (res.data.GetAttendanceAllUsers.ok && res.data.GetAttendanceAllUsers.results) {
    dispatch(slice.actions.getAttendance({ attendance: res.data.GetAttendanceAllUsers.results }));
  }
};

export const startWork = (): AppThunk => async (dispatch) => {
  const res = await client.mutate<StartUserAttendanceMutation>({
    mutation: START_USER_ATTENDANCE,
  });

  if (res.data?.StartUserAttendance.ok && res.data.StartUserAttendance.result) {
    dispatch(slice.actions.createAttendance({ attendance: res.data.StartUserAttendance.result }));
  }
};

export const endWork = (): AppThunk => async (dispatch) => {
  const res = await client.mutate<FinishUserAttendanceMutation>({
    mutation: FINISH_USER_ATTENDANCE,
  });

  if (res.data?.FinishUserAttendance.ok && res.data.FinishUserAttendance.result) {
    dispatch(slice.actions.updateAttendance({ attendance: res.data.FinishUserAttendance.result }));
  }
};

export const updateAttendance = (attendance: UserAttendance): AppThunk => async (dispatch) => {
  const res = await client.mutate<UpdateUserAttendanceMutation, UpdateUserAttendanceMutationVariables>({
    mutation: UPDATE_USER_ATTENDANCE,
    variables: {
      id: attendance.id,
      startTime: attendance.startTime,
      endTime: attendance.endTime,
    },
  });

  if (res.data?.UpdateUserAttendance.ok && res.data.UpdateUserAttendance.result) {
    dispatch(slice.actions.updateAttendance({ attendance: res.data.UpdateUserAttendance.result }));
  }
};

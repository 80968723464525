import React from "react";
import type { FC } from "react";
import { useTheme, useMediaQuery, makeStyles, Theme, Stack } from "@mui/material";

interface DateRangeProps {
  value: Date;
}

const DateRange: FC<DateRangeProps> = ({ value }) => {
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));

  return <Stack></Stack>;
};

export default DateRange;

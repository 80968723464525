import { merge } from "lodash";
import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

import { useQuery } from "@apollo/client";
import { Box, Card, Theme, useMediaQuery, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";

import { GET_TEAM_WORKLOAD } from "../../../Api/Dashboard/dashborad.queries";
import { GetTeamWorkloadQuery, GetTeamWorkloadQueryVariables, WorkspaceUserBase } from "../../../generated/graphql";
import { useProject, useProperties } from "../../../Store/hooks";
import BaseOptionChart from "../../../Style/BaseOptionChart";
import BaseCard from "./BaseCard";

import type { FC } from "react";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

const RootStyle = styled(Card)(({ theme }) => ({
  display: "flex",
  position: "relative",
  alignItems: "center",
  padding: theme.spacing(3),
  backgroundColor: theme.palette.primary.dark,
}));

interface TeamWorkloadProps {}

const TeamWorkload: FC<TeamWorkloadProps> = () => {
  const classes = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down("md"));

  const { project, statuses } = useProject();
  const { getUsersByIds } = useProperties();
  const [users, setUsers] = useState<WorkspaceUserBase[]>([]);
  const [chartDate, setChartData] = useState({ data: [] as number[] });

  const { data, loading } = useQuery<GetTeamWorkloadQuery, GetTeamWorkloadQueryVariables>(GET_TEAM_WORKLOAD, {
    variables: {
      projectId: project!.id,
      input: {
        timezone: new Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    },
  });

  const chartOptions = merge(BaseOptionChart(), {
    chart: {
      id: "area-datetime",
      type: "area",
      height: 350,
      zoom: {
        autoScaleYaxis: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
      style: "hollow",
    },
    xaxis: {
      type: "datetime",
      min:
        data && data.GetTeamWorkload.length > 0
          ? new Date(Number(data.GetTeamWorkload[0].date)).getTime()
          : new Date("01 Mar 2021").getTime(),
      tickAmount: 6,
    },
    tooltip: {
      x: {
        label: "workload",
        format: "dd MMM yyyy",
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100],
      },
    },
  });

  return (
    <BaseCard name="Team Workload" direction="column">
      {loading && (
        <Box display="flex" flexGrow={1} justifyContent="center">
          {/* <CircularProgress /> */}
        </Box>
      )}
      {!loading && data && (
        <ReactApexChart
          type="area"
          series={[
            {
              name: "Total timespent",
              data: data.GetTeamWorkload.map((v) => [Number(v.date), Number(v.hours.toFixed(1))]),
            },
          ]}
          options={chartOptions}
          height={320}
        />
      )}
    </BaseCard>
  );
};

export default TeamWorkload;

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { objFromArray } from "src/utils/utils";
import { AppThunk } from "../store";
import { BaseUserProperty } from "src/types";

export interface TableState {
  pageSize: number;
  pageIndex: number;
  sortBy?: any;
  filters?: any;
  columnResizing?: any;
  hiddenColumns?: any;
  refresh?: boolean;
  filter: {
    byAssignees: string[] | null;
    byDate: {
      startDate: string;
      endDate: string;
    };
  };
}

const __ = window.localStorage.getItem(`tableState:taskTable`);
const ___ = __ ? JSON.parse(__) : {};

const initialState: TableState = {
  pageSize: ___.pageSize ? Number(___.pageSize) : 10,
  pageIndex: 0,
  refresh: false,
  filter: {
    byAssignees: null,
    byDate: {
      startDate: "",
      endDate: "",
    },
  },
};

export const slice = createSlice({
  name: "table",
  initialState,
  reducers: {
    resetFilter(state: TableState) {
      state.filter.byAssignees = null;
      state.filter.byDate.startDate = "";
      state.filter.byDate.endDate = "";
    },
    getFirstPage(state: TableState) {
      const __ = window.localStorage.getItem(`tableState:taskTable`);
      const ___ = __ ? JSON.parse(__) : {};

      state.pageSize = ___.pageSize || 10;
      state.pageIndex = 0;
    },
    setCurrentPage(state: TableState, action: PayloadAction<{ pageIndex: number; pageSize: number }>) {
      const { pageSize, pageIndex } = action.payload;
      state.pageSize = pageSize;
      state.pageIndex = pageIndex;
    },
    setAssigneeFilter(state: TableState, action: PayloadAction<{ assignees: string[] | null }>) {
      const { assignees } = action.payload;
      state.filter.byAssignees = assignees !== null ? (assignees.length !== 0 ? assignees.map((v) => v) : null) : null;
    },
    setDateFilter(
      state: TableState,
      action: PayloadAction<{ filterStartDate: string | null; filterEndDate: string | null }>,
    ) {
      const { filterStartDate, filterEndDate } = action.payload;
      state.filter.byDate.startDate = filterStartDate || "";
      state.filter.byDate.endDate = filterEndDate || "";
    },
    refresh(state: TableState, action: PayloadAction<{ refresh: boolean }>) {
      const { refresh } = action.payload;
      state.refresh = refresh;
    },
  },
});

export const reducer = slice.reducer;

export const getTableFirstPage = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.getFirstPage());
};

export const setTableCurrentPage = (pageIndex: number, pageSize: number): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setCurrentPage({ pageIndex, pageSize }));
};

export const refreshTable = (refresh: boolean): AppThunk => async (dispatch) => {
  dispatch(slice.actions.refresh({ refresh }));
};

export const setTableAssigneeFilter = (assignees: string[] | null): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setAssigneeFilter({ assignees }));
};

export const setTableDateFilter = (filterStartDate: string | null, filterEndDate: string | null): AppThunk => async (
  dispatch,
) => {
  dispatch(slice.actions.setDateFilter({ filterStartDate, filterEndDate }));
};

export const resetFilter = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.resetFilter());
};

import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import Dialog from "@mui/material/Dialog";
import { Box, IconButton, MenuItem, MenuList, Popover, SvgIcon, Typography, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { MoreHorizontal } from "react-feather";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  paper: {
    maxHeight: "80vh",
    minWidth: "30vh",
  },
  iconBtnSelected: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  item: {
    minWidth: 100,
    padding: 0,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

type DetailModalOptionItem = {
  name: String;
  onSelect: (value?: any) => void;
};

interface DetailModalProps<T> {
  open: boolean;
  header: string | React.ReactNode;
  children: React.ReactNode;
  value?: T;
  options?: DetailModalOptionItem[];
  onClose: () => void;
  onUpdate?: () => void;
  [key: string]: any;
}

export default function DetailModal<T extends { id: string | number }>(props: DetailModalProps<T>) {
  const { open, options, onClose, onUpdate, children, header, ...rest } = props;
  const classes = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));

  const [openSetting, setOpenSetting] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenSetting(true);
  };

  const handleClose = () => {
    onUpdate && onUpdate();

    setAnchorEl(null);
    setOpenSetting(false);
  };

  const id = open ? "simple-popover" : undefined;

  return (
    <Dialog
      fullScreen={mobileDevice ? true : false}
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      {...rest}
    >
      <Box p={3} pl={4} pr={4}>
        <Box display="flex" alignItems="center">
          <Box>{header}</Box>
          <Box flexGrow={1} />
          {options && (
            <IconButton
              aria-label="close"
              onClick={handleClick}
              size="small"
              className={clsx(openSetting && classes.iconBtnSelected)}
            >
              <SvgIcon fontSize="small">
                <MoreHorizontal fontSize="small" />
              </SvgIcon>
            </IconButton>
          )}
          <Box p={0.5} />
          <IconButton aria-label="close" onClick={onClose} size="small" color={"inherit"}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <Box mt={1}>{children}</Box>
      </Box>
      {options && options.length > 0 && (
        <Popover
          id={id}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: -10,
            horizontal: "center",
          }}
        >
          <MenuList>
            {options?.map((v) => (
              <MenuItem onClick={v.onSelect} className={classes.item}>
                {v.name}
              </MenuItem>
            ))}
          </MenuList>
        </Popover>
      )}
    </Dialog>
  );
}

import moment from "moment";
import React, { useState } from "react";
import { XCircle as CloseIcon } from "react-feather";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "src/hooks";
import useAuth from "src/hooks/useAuth";
import { createTask } from "src/Store/reducers/taskReducer";
import { Task } from "src/types";
import { TextField } from "@mui/material";

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  SvgIcon,
  Typography,
} from "@mui/material";
import DesktopDateTimePicker from "@mui/lab/DesktopDateTimePicker";
import {
  Milestone,
  Project,
  ProjectPhase,
  ProjectTaskCategory,
  ProjectTaskStatus,
  WorkspaceUserBase,
} from "../../../../generated/graphql";

import type { FC } from "react";
import { useProject } from "../../../../Store/hooks";
interface CopyTaskDialogProps {
  task: {
    name: string;
    description: string | undefined | null;
    project: Project;
    phase: ProjectPhase | null;
    milestone: Milestone | null;
    category: ProjectTaskCategory;
    status: ProjectTaskStatus;
    assignees: WorkspaceUserBase[];
    startDate: Date | null;
    dueDate: Date | null;
    completed: Date | null;
    timespent: number | null;
  };
  open: boolean;
  onClose: (copied: boolean) => void;
}

const CopyTaskDialog: FC<CopyTaskDialogProps> = ({ task, open, onClose }) => {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const [newPlannedStartDate, setNewPlannedStartDate] = useState<Date | null>(moment().toDate());
  const { pageSize } = useTypedSelector((state) => state.table);
  const { project: currentProject, statuses, categories } = useProject(task.project.id);
  const [properties, setProperties] = useState({
    name: true,
    description: true,
    assignees: true,
    status: true,
    category: true,
    project: true,
    duration: false,
    endDate: false,
  });

  const handleCopyTask = () => {
    dispatch(
      createTask(
        {
          id: -1,
          name: properties.name ? task.name : "New Task",
          description: properties.description ? task.description : null,
          assigneesIds: properties.assignees ? task.assignees.map((v) => v.id) : [user!.id],
          statusId: properties.status ? task.status.id : statuses[0].id,
          categoryId: properties.category ? task.category.id : categories[0].id,
          projectId: task.project.id,
          duration: properties.duration ? task.timespent : null,
          endDate: properties.endDate ? task.completed : null,
          plannedStartDate: newPlannedStartDate,
          plannedEndDate: task.dueDate
            ? moment(newPlannedStartDate)
                .add(moment(task.dueDate).diff(moment(task.dueDate), "minutes"), "minute")
                .toDate()
            : moment(newPlannedStartDate).add(30, "minute").toDate(),
        },
        properties.assignees ? task.assignees.some((v) => v.id === user!.id) : true,
        pageSize,
        true,
      ),
    );

    onClose(true);
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProperties({ ...properties, [event.target.name]: event.target.checked });
  };

  const { name, description, assignees, status, category, project, duration, endDate } = properties;

  const error =
    [name, description, assignees, status, category, project, duration, endDate].filter((v) => v).length === 1;

  return (
    <Dialog maxWidth="sm" onClose={() => onClose(false)} open={open}>
      <Box height="100%" display="flex" flexDirection="column" p={3}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography variant="h5">Copy this task</Typography>
          </Box>
          <IconButton onClick={() => onClose(false)} size="large">
            <SvgIcon fontSize="small">
              <CloseIcon />
            </SvgIcon>
          </IconButton>
        </Box>
        <Box>
          <DesktopDateTimePicker
            label="Planned Start"
            onChange={(date, value) => {
              setNewPlannedStartDate(moment(date).toDate());
            }}
            value={newPlannedStartDate}
            renderInput={(props) => <TextField {...props} />}
          />
        </Box>
        <Box pt={1}>
          <FormControl component="fieldset" variant="standard">
            <FormLabel component="legend">Select fields to copy</FormLabel>
            <FormGroup>
              <Grid container>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={<Checkbox checked={name} onChange={handleChange} name="name" />}
                    label="Task Title"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={<Checkbox checked={description} onChange={handleChange} name="description" />}
                    label="Description"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={<Checkbox checked={assignees} onChange={handleChange} name="assignees" />}
                    label="Assignees"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    disabled
                    control={<Checkbox checked={project} onChange={handleChange} name="project" />}
                    label="Project"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={<Checkbox checked={status} onChange={handleChange} name="status" />}
                    label="Status"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={<Checkbox checked={category} onChange={handleChange} name="category" />}
                    label="Category"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={<Checkbox checked={endDate} onChange={handleChange} name="endDate" />}
                    label="Complete Time"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={<Checkbox checked={duration} onChange={handleChange} name="duration" />}
                    label="Time Spent"
                  />
                </Grid>
              </Grid>
            </FormGroup>
            <FormHelperText> Default value will be copied for unchecked fields. </FormHelperText>
          </FormControl>
        </Box>
        <Box display="flex" pt={1}>
          <Box flexGrow={1} />
          <Box>
            <Button variant="contained" color="primary" onClick={handleCopyTask} disabled={error}>
              Copy
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default CopyTaskDialog;

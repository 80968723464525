import React, { useRef, useState } from "react";
import { alpha, Box, InputBase, Theme, useMediaQuery, useTheme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import type { FC } from "react";

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    backgroundColor: theme.palette.grey[200],
  },
}));

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: "relative",
      border: "none",
      paddingLeft: "5px",
      left: "-5px",
      fontSize: 18,
      fontWeight: 500,
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      "&:hover": {
        backgroundColor: theme.palette.background.neutral,
      },
      "&:focus": {
        backgroundColor: theme.palette.background.neutral,
        boxShadow: `${alpha(theme.palette.primary.main, 0.75)} 0 0 0 0.1rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }),
)(InputBase);

interface TitleFieldProps {
  value: string;
  placeHolder?: string;
  onSubmit: (title: string) => void;
  helperText?: string | false | undefined;
  error?: boolean;
}

const TitleField: FC<TitleFieldProps> = ({ value, placeHolder, onSubmit, helperText, error }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down("md"));
  const [text, setText] = useState(value);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (e: any) => {
    setText(e.target.value);
  };

  const handleEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      _submit();
      inputRef.current?.blur();
    }
  };

  const _submit = () => {
    onSubmit(text);
  };

  return (
    <Box pr={1} pb={2}>
      <Box width="100%" position="relative">
        <BootstrapInput
          fullWidth
          inputRef={inputRef}
          placeholder={placeHolder}
          value={text}
          onChange={handleChange}
          onKeyPress={handleEnter}
        />
      </Box>
    </Box>
  );
};

export default TitleField;

import type { FC } from "react";
import { useImage } from "react-image";

type ImageProps = {
  src: string;
  className?: string;
};

const Image: FC<ImageProps> = ({ src, className }) => {
  //TODO:
  // const { src: imgSrc } = useImage({
  //   srcList: src,
  // });

  return <img src={src} className={className} />;
};

export default Image;

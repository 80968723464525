import React, { useState, useRef, useContext } from "react";
import { Theme, Button, Dialog, DialogTitle, DialogActions, DialogContent, Menu, MenuItem } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useSnackbar } from "notistack";
import { usePopupState, bindTrigger, bindMenu } from "material-ui-popup-state/hooks";
import { StringId, BaseProject } from "src/types";
import { ProfileContext } from "src/Context/ProfileContext";
import { ProfileUploadCrop } from "src/Components/Molecules";
import { useProfileQueries, useProjectQueries } from "src/Store/hooks";
import { uploadProjectAvatarImage, uploadUserAvatarImage } from "src/Firebase/uploadFile";
import { deleteFile } from "src/Firebase/deleteFile";
import { StorageRefPath } from "src/Firebase/storagePath";
import CustomAvatar from "src/Components/ShouldBeDeleted/CustomAvatar";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textOver: () => ({
      position: "absolute",
      textAlign: "center",
      bottom: "10px",
      left: "10px",
    }),
    editBox: {
      backgroundColor: theme.palette.grey[800],
      color: "white",
      borderRadius: "4px",
      padding: "3px 6px",
      display: "flex",
      alignItems: "center",
      height: "25px",
    },
    input: {
      display: "none",
    },
  }),
);

type ProjectAppearance = {
  projectColor: string | null;
  projectImage: string | null;
};

type ProjectAvatarUploadViewProps = {
  workspaceName: string;
  project: BaseProject;
  appearance: ProjectAppearance;
  setAppear: React.Dispatch<React.SetStateAction<ProjectAppearance>>;
};

/**
 * Project의 avatar 변경 component
 * @param props ProjectAvatarUploadViewProps
 */
export function ProjectAvatarUploadView(props: ProjectAvatarUploadViewProps) {
  const { updateProjectMutation } = useProjectQueries();
  const AVATAR_IMAGE_FILE_NAME = `project_avatar_${props.project.id}`;

  /**
   * Cropped 된 이미지를 Firebase에 업로드
   * @param dataUrl Cropped image url
   */
  async function uploadAvatarImageToFirebase(dataUrl: string): Promise<string> {
    // Uploadg project avatar image to firebase
    return await uploadProjectAvatarImage(dataUrl, AVATAR_IMAGE_FILE_NAME, props.project.id, props.workspaceName)
      .catch((err) => {
        return err;
      })
      .then((url) => {
        // Appearance state update
        props.setAppear((v) => ({
          projectColor: v.projectColor,
          projectImage: v.projectImage,
        }));

        // Update project information
        updateProjectMutation({
          variables: {
            projectId: props.project.id,
            projectColor: props.project.projectColor,
            projectImage: props.project.projectImage,
          },
        });

        return "";
      });
  }

  /**
   * Project의 avatar에 등록된 이미지 삭제
   */
  async function revertAvatarImage() {
    await deleteFile(
      StorageRefPath.getProjectAvatarPath(AVATAR_IMAGE_FILE_NAME, props.project.id, props.workspaceName),
    ).then(() => {
      // Appearance state update
      props.setAppear((v) => ({
        projectColor: v.projectColor,
        projectImage: null,
      }));

      // update project information
      updateProjectMutation({
        variables: {
          projectId: props.project.id,
          projectColor: props.project.projectColor,
          projectImage: undefined,
        },
      });
    });
  }

  return (
    <CroppedImageUploadView
      name={props.project.name}
      appear={{
        color: props.project.projectColor || null,
        image: props.project.projectImage || null,
      }}
      uploadImageAsync={uploadAvatarImageToFirebase}
      revertAvatarImage={revertAvatarImage}
    />
  );
}

type UserAppearance = {
  profileColor: string | null;
  profileImage: string | null;
};

type UserAvatarUploadViewProps = {
  appear: UserAppearance;
  setAppear: React.Dispatch<React.SetStateAction<UserAppearance>>;
};

/**
 * Current user의 avatar 이미지 변경 component
 * @param props UserAvatarUploadViewProps
 */
export function UserAvatarUploadView(props: UserAvatarUploadViewProps) {
  const currentUser = useContext(ProfileContext);
  const { updateCurrentUser } = useProfileQueries();

  const AVATAR_IMAGE_FILE_NAME = (fullName: string, id: StringId) => `${fullName}_${id}`;

  async function uploadAvatarImageToFirebase(dataUrl: string): Promise<string> {
    // Upload USER avatar image to firebase
    return await uploadUserAvatarImage(dataUrl, AVATAR_IMAGE_FILE_NAME(currentUser.fullName, currentUser.id))
      .catch((err) => {
        return err;
      })
      .then((url) => {
        // Appearance state update
        props.setAppear((v) => ({
          profileColor: v.profileColor,
          profileImage: v.profileImage,
        }));

        // Update current user information
        updateCurrentUser({
          variables: {
            profileColor: props.appear.profileColor,
            profileImage: url,
          },
        });

        return "";
      });
  }

  async function revertAvatarImage() {
    await deleteFile(
      StorageRefPath.getUserAvatarPath(AVATAR_IMAGE_FILE_NAME(currentUser.fullName, currentUser.id)),
    ).then(() => {
      // Appearance state update
      props.setAppear((v) => ({
        profileColor: v.profileColor,
        profileImage: null,
      }));

      // update current user information
      updateCurrentUser({
        variables: {
          profileColor: props.appear.profileColor,
          profileImage: "",
        },
      });
    });
  }

  return (
    <CroppedImageUploadView
      name={currentUser.fullName}
      appear={{
        color: props.appear.profileColor,
        image: props.appear.profileImage,
      }}
      uploadImageAsync={uploadAvatarImageToFirebase}
      revertAvatarImage={revertAvatarImage}
    />
  );
}

type Appearance = {
  color: string | null;
  image: string | null;
};

type CroppedImageUploadViewProps = {
  name: string;
  appear: Appearance;
  uploadImageAsync: (dataUrl: string) => Promise<string>;
  revertAvatarImage: () => void;
};

function CroppedImageUploadView(props: CroppedImageUploadViewProps) {
  const { name, appear, uploadImageAsync, revertAvatarImage } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const inputFile = useRef<HTMLInputElement>(null);

  const [open, setOpen] = React.useState(false);
  const [src, setSrc] = useState<string | ArrayBuffer | null>(null);

  const [croppedImageUrl, setCroppedImageUrl] = useState<any>();

  //https://jcoreio.github.io/material-ui-popup-state/
  const popupState = usePopupState({
    variant: "popover",
    popupId: "changeProfileImage",
  });

  const handleUpload = () => {
    if (croppedImageUrl) {
      uploadImageAsync(croppedImageUrl).catch((error) => {
        enqueueSnackbar(error || "Server error", { variant: "error" });
      });
    }

    setOpen(false);
  };

  const handleRevert = () => {
    revertAvatarImage();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSelectFile = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        setSrc(reader.result);
        setCroppedImageUrl(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        minWidth: 0,
        overflow: "hidden",
        width: "300px",
        height: "300px",
        borderRadius: "6px",
        position: "relative",
      }}
    >
      <CustomAvatar usage="Big" name={name} color={appear.color} imageUrl={appear.image} />

      <div className={classes.textOver}>
        <div className={classes.editBox} {...bindTrigger(popupState)}>
          <EditOutlinedIcon style={{ width: "15px", height: "15px", lineHeight: "15px" }} />
          <Button style={{ color: "white", alignItems: "center", height: "25px" }}>Edit</Button>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Upload Profile Image"}</DialogTitle>
        <input
          type="file"
          ref={inputFile}
          accept="image/*"
          className={classes.input}
          id="contained-button-file"
          onChange={onSelectFile}
        />
        <Button
          component="span"
          fullWidth
          onClick={() => {
            inputFile.current?.click();
          }}
        >
          Choose File
        </Button>
        <DialogContent>
          <ProfileUploadCrop
            imageUrl={appear.image}
            croppedImageUrl={croppedImageUrl}
            setCroppedImageUrl={setCroppedImageUrl}
            src={src}
            setSrc={setSrc}
          />
        </DialogContent>
        <DialogActions>
          {croppedImageUrl && (
            <Button onClick={handleUpload} color="primary" autoFocus>
              Upload
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <MenuItem
          onClick={() => {
            popupState.close();
            setOpen(true);
          }}
        >
          Upload a photo...
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleRevert();
            popupState.close();
          }}
        >
          Revert to default
        </MenuItem>
      </Menu>
    </div>
  );
}

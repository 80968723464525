import React from "react";
import type { FC } from "react";
import { Box, Typography, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { getContrastFontColor, getDefaultColorCodes } from "src/theme/colors";
import { exportTwoChar } from "src/utils/utils";
import VisibilitySensor from "react-visibility-sensor";
import Image from "../Image";

type info = {
  name: string;
  color?: string;
  image?: string;
  workspaceColor?: string | null;
  workspaceImage?: string | null;
  projectColor?: string | null;
  projectImage?: string | null;
};

type WorkspaceAvatarAdapterProps = {
  info: info;
  className?: string;
};

const WorkspaceAvatar: FC<WorkspaceAvatarAdapterProps> = ({ info, className }) => {
  const { name, color, workspaceColor, projectColor, image, workspaceImage, projectImage } = info;
  return (
    <WorkspaceAvatarBox
      name={name}
      color={color || workspaceColor || projectColor || undefined}
      image={image || workspaceImage || projectImage || undefined}
      className={className}
    />
  );
};

export default WorkspaceAvatar;

const useStyles = makeStyles((theme: Theme) => ({
  default: (props: WorkspaceAvatarBoxProps) => {
    return {
      width: 42,
      height: 42,
      borderRadius: 10,
      backgroundColor: props.color || getDefaultColorCodes(),
      color: getContrastFontColor(props.color || getDefaultColorCodes()),
    };
  },
  image: {
    objectFit: "cover",
  },
}));

interface WorkspaceAvatarBoxProps {
  name: string;
  color?: string;
  image?: string;
  className?: string;
}

const WorkspaceAvatarBox: FC<WorkspaceAvatarBoxProps> = ({ name, color, image, className, ...rest }) => {
  const classes = useStyles({ name, color, image });

  return (
    <Box display="flex" justifyContent="center" alignItems="center" className={clsx(classes.default, className)}>
      {/* <VisibilitySensor> */}
      {image && <Image src={image} className={clsx(classes.default, classes.image, className)} />}
      {/* </VisibilitySensor> */}
      {!image && <Typography variant={"h4"}>{exportTwoChar(name)}</Typography>}
    </Box>
  );
};

import gql from "graphql-tag";

export const GET_PHASES = gql`
  query GetPhases {
    GetPhases {
      ok
      error
      phases {
        id
        name
        code
        color
        order
        deleted
      }
    }
  }
`;

export const CREATE_PHASE = gql`
  mutation CreatePhase($name: String!, $code: String, $color: String) {
    CreatePhase(name: $name, code: $code, color: $color) {
      ok
      error
      result {
        id
        name
        code
        color
        order
        deleted
      }
    }
  }
`;

export const DELETE_PHASE = gql`
  mutation DeletePhase($id: Int!) {
    DeletePhase(id: $id) {
      ok
      error
    }
  }
`;

export const UPDATE_PHASE = gql`
  mutation UpdatePhase($id: Int!, $name: String, $code: String, $color: String) {
    UpdatePhase(id: $id, name: $name, code: $code, color: $color) {
      ok
      error
    }
  }
`;

export const UPDATE_PHASE_ORDER = gql`
  mutation UpdatePhaseOrder($id: Int!, $position: Int!) {
    UpdatePhaseOrder(id: $id, position: $position) {
      ok
      error
    }
  }
`;

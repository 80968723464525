import React from "react";
import type { FC } from "react";
import { useTheme, useMediaQuery, Box, Typography, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Task } from "../../../types";
import { useTypedSelector } from "../../../hooks";
import TextLabelChip from "../TextLabelChip";
import UserAvatar from "../UserAvatar";
import { useProject } from "../../../Store/hooks";

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.grey[300],
    },
  },
}));

interface TaskListRowProps {
  task: Task;
  [key: string]: any;
}

const TaskListRow: FC<TaskListRowProps> = ({ task, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down("md"));

  const { projectMembers } = useTypedSelector((state) => state.projectMembers);
  const { taskStatuses } = useTypedSelector((state) => state.projectTaskStatuses);
  const { taskCategories } = useTypedSelector((state) => state.projectTaskCategories);
  const { members } = useTypedSelector((state) => state.members);

  // const assignees = (task.assigneesIds || []).map((id) => members.byId[projectMembers.byId[id].userId]);
  const status = taskStatuses.byId[task.statusId];
  const category = taskCategories.byId[task.categoryId];

  return (
    <Box
      display="flex"
      p={1}
      alignItems="center"
      border="1px solid"
      borderColor={theme.palette.grey[400]}
      borderRadius="5px"
      mb={0.3}
      className={classes.row}
      {...rest}
    >
      <Box width="30px">
        <Typography variant="caption" color="textSecondary">
          {task.id}
        </Typography>
      </Box>
      <Box width="100%">
        <Typography variant="subtitle2">{task.name}</Typography>
      </Box>
      <Box flexGrow={1} />
      {/* <Box>{assignees.length > 0 && <UserAvatar user={assignees[0]} />}</Box> */}
      <Box
        maxWidth="150px"
        mr={1}
        onClick={(e: any) => {
          e.stopPropagation();
        }}
      >
        <TextLabelChip name={status.name} color={status.color} />
      </Box>
      <Box maxWidth="150px">
        <TextLabelChip name={category.name} color={category.color} />
      </Box>
    </Box>
  );
};

export default TaskListRow;

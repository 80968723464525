export enum TaskInfoEnum {
  projectId = "Project",
  id = "Id",
  name = "Task",
  createdBy = "Created by",
  createdAt = "Created at",
  statusId = "Status",
  categoryId = "Category",
  assignees = "Assignee",
  startDate = "Started",
  endDate = "Completed",
  description = "Description",
  plannedStartDate = "Planned Start",
  plannedEndDate = "Planned Complete",
  duration = "Time Spent",
}

import React, { useState } from "react";
import type { FC } from "react";
import { useTheme, useMediaQuery, Box, Button, Typography, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ReactQuill from "react-quill";

const useStyles = makeStyles((theme: Theme) => ({
  quill: {
    "& .ql-toolbar": {
      borderLeft: `1px solid ${theme.palette.divider}`,
      borderTop: `1px solid ${theme.palette.divider}`,
      borderRight: `1px solid ${theme.palette.divider}`,
      borderBottom: `1px solid ${theme.palette.divider}`,
      "& .ql-picker-label:hover": {
        color: theme.palette.secondary.main,
      },
      "& .ql-picker-label.ql-active": {
        color: theme.palette.secondary.main,
      },
      "& .ql-picker-item:hover": {
        color: theme.palette.secondary.main,
      },
      "& .ql-picker-item.ql-selected": {
        color: theme.palette.secondary.main,
      },
      "& button:hover": {
        color: theme.palette.secondary.main,
        "& .ql-stroke": {
          stroke: theme.palette.secondary.main,
        },
      },
      "& button:focus": {
        color: theme.palette.secondary.main,
        "& .ql-stroke": {
          stroke: theme.palette.secondary.main,
        },
      },
      "& button.ql-active": {
        "& .ql-stroke": {
          stroke: theme.palette.secondary.main,
        },
      },
      "& .ql-stroke": {
        stroke: theme.palette.text.primary,
      },
      "& .ql-picker": {
        color: theme.palette.text.primary,
      },
      "& .ql-picker-options": {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.default,
        boxShadow: theme.shadows[10],
        borderRadius: theme.shape.borderRadius,
      },
    },
    "& .ql-container": {
      "& .ql-editor": {
        fontFamily: theme.typography.fontFamily,
        fontSize: 16,
        color: theme.palette.text.primary,
        "&.ql-blank::before": {
          color: theme.palette.text.secondary,
        },
      },
    },
  },
  inactive: {
    padding: "1px",
    "&:hover": {
      backgroundColor: theme.palette.background.neutral,
    },
  },
}));

interface DescriptionProps {
  description?: string | null;
  onSubmit: (text: string) => void;
}

const Description: FC<DescriptionProps> = ({ description, onSubmit }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(false);
  const [text, setText] = useState(description || "");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    await onSubmit(text === "<p><br></p>" ? "" : text);
    setOpen(false);
    setLoading(false);
  };

  const handleCancel = () => {
    setText(description || "");
    setOpen(false);
  };

  return (
    <Box width="100%">
      {!open && (
        <Box onClick={() => setOpen(true)} className={classes.inactive} pt={1}>
          {text && text.length > 0 ? (
            <ReactQuill value={text} readOnly={true} theme={"bubble"} />
          ) : (
            <Typography color="textSecondary" variant="body2">
              Add description...
            </Typography>
          )}
        </Box>
      )}
      {open && (
        <Box pb={1} pt={1}>
          <ReactQuill className={classes.quill} value={text} onChange={setText} />
          <Box display="flex" pt={1}>
            <Button size="small" onClick={handleCancel} disabled={loading}>
              Cancel
            </Button>
            <Button color="primary" size="small" onClick={handleSubmit} disabled={loading}>
              Submit
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Description;

import React from "react";
import type { FC } from "react";
import { Box, Typography, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { getContrastFontColor, getDefaultColorCodes } from "src/theme/colors";
import { WorkspaceAvatar } from "src/Components/Atoms";
import { WorkspaceBaseProperty } from "../../../../../generated/graphql";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  workspaceProfile: (props: WorkspaceListItemProps) => {
    const size = props.size === "small" ? 36 : 42;
    const corner = props.size === "small" ? 8 : 10;
    return {
      width: size,
      height: size,
      borderRadius: corner,
      backgroundColor: props.workspaceItem.workspaceColor || getDefaultColorCodes(),
      color: getContrastFontColor(props.workspaceItem.workspaceColor || getDefaultColorCodes()),
    };
  },
}));

type Size = "small" | "default";

type WorkspaceListItemProps = {
  workspaceItem: Omit<WorkspaceBaseProperty, "__typename">;
  size?: Size;
  className?: string;
};

const WorkspaceListItem: FC<WorkspaceListItemProps> = ({ workspaceItem, size, className, ...rest }) => {
  const classes = useStyles({ workspaceItem, size });

  const handleClickWorkspaceListItem = () => {};

  return (
    <Box
      display={"flex"}
      alignItems="center"
      onClick={() => handleClickWorkspaceListItem()}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <WorkspaceAvatar info={workspaceItem} className={classes.workspaceProfile} />
      <Typography variant="body1" color="textSecondary" style={{ marginLeft: 8 }}>
        {" "}
        {workspaceItem.name}{" "}
      </Typography>
    </Box>
  );
};

export default WorkspaceListItem;

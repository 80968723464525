import {
  CREATE_PROJECT,
  DELETE_PROJECT,
  GET_PROJECTS_BY_USER,
  UPDATE_PROJECT,
  UPDATE_PROJECT_INFO,
} from "src/Api/Project/Project/Project.queries";
import client from "src/apollo";
import { Project } from "src/types";
import {
  CreateProjectMutation,
  CreateProjectMutationVariables,
  DeleteProjectMutation,
  DeleteProjectMutationVariables,
  GetProjectsByUserQuery,
  ProjectInfo,
  UpdateProjectInfoMutation,
  UpdateProjectInfoMutationVariables,
  UpdateProjectInput,
  UpdateProjectMutation,
  UpdateProjectMutationVariables,
} from "../../generated/graphql";
import { slice } from "../reducers/projectReducer";
import { AppThunk } from "../store";

export const getProjects = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.startLoad());

  const response = await client.query<GetProjectsByUserQuery>({
    query: GET_PROJECTS_BY_USER,
    fetchPolicy: "network-only",
  });

  dispatch(
    slice.actions.getProjects({
      projects: response.data.GetProjectsByUser.projects || [],
    }),
  );
};

export const createProject = (name: string, description?: string): AppThunk => async (dispatch) => {
  const response = await client.mutate<CreateProjectMutation, CreateProjectMutationVariables>({
    mutation: CREATE_PROJECT,
    variables: {
      name: name,
      description: description,
      state: "running",
      proj_publishedDate: new Date(),
    },
  });

  if (response.data?.CreateProject.ok && response.data.CreateProject.result) {
    dispatch(
      slice.actions.createProject({
        project: response.data.CreateProject.result,
      }),
    );
  }
};

export const openProjectModal = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.openModal());
};

export const closeProjectModal = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.closeModal());
};

export const changeProject = (
  workspaceName: string,
  projectName: string,
  pushHistory: boolean = true,
  route?: string,
  changeRoute: boolean = true,
): AppThunk => (dispatch, _: any, { history }: any) => {
  dispatch(slice.actions.selectProjectByName({ projectName }));
  if (changeRoute) {
    if (pushHistory) {
      if (route) {
        history.push(`/w/${workspaceName}/project/${projectName}/${route}`);
      } else {
        history.push(`/w/${workspaceName}/project/${projectName}`);
      }
    }
  }
};

export const changeProjectIsNull = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.deselectProject());
};

export const updateProjectInfo = (projectId: string, projectInfo: ProjectInfo): AppThunk => async (dispatch) => {
  const response = await client.mutate<UpdateProjectInfoMutation, UpdateProjectInfoMutationVariables>({
    mutation: UPDATE_PROJECT_INFO,
    variables: {
      projectId: projectId,
      ...projectInfo,
      complexityLevelId: projectInfo.complexityLevel?.id,
      paymentTermId: projectInfo.complexityLevel?.id,
      programId: projectInfo.program?.id,
      riskId: projectInfo.risk?.id,
      serviceTypeId: projectInfo.serviceType?.id,
      mainToolId: projectInfo.mainTool?.id,
      subToolId: projectInfo.subTool?.id,
      architectId: projectInfo.architect?.id,
      clientId: projectInfo.client?.id,
      generalContractorId: projectInfo.generalContractor?.id,
      ownerId: projectInfo.owner?.id,
    },
  });

  if (response.data?.UpdateProjectInfo.ok) {
    dispatch(slice.actions.updateProjectInfo({ projectId, projectInfo }));
  }
};

export const updateProject = (updateProjectInput: UpdateProjectInput): AppThunk => async (dispatch) => {
  const res = await client.mutate<UpdateProjectMutation, UpdateProjectMutationVariables>({
    mutation: UPDATE_PROJECT,
    variables: {
      input: updateProjectInput,
    },
  });

  if (res.data?.UpdateProject.ok) {
    dispatch(
      slice.actions.updateProject({
        project: updateProjectInput as Project,
      }),
    );
  }
};

export const updateProjectBasic = (project: Project): AppThunk => async (dispatch) => {
  const response = await client.mutate<UpdateProjectInfoMutation, UpdateProjectInfoMutationVariables>({
    mutation: UPDATE_PROJECT_INFO,
    variables: {
      projectId: project.id,
      name: project.name,
      description: project.description,
      state: project.state,
    },
  });

  if (response.data?.UpdateProjectInfo.ok) {
    dispatch(
      slice.actions.updateProject({
        project: {
          name: project.name,
          description: project.description,
          state: project.state,
        } as Project,
      }),
    );
  }
};

export const updateProjectSimple = (id: string, projectName: string, description?: string | null): AppThunk => async (
  dispatch,
) => {
  const res = await client.mutate<UpdateProjectInfoMutation, UpdateProjectInfoMutationVariables>({
    mutation: UPDATE_PROJECT_INFO,
    refetchQueries: [{ query: GET_PROJECTS_BY_USER }],
    variables: {
      projectId: id,
      name: projectName,
      description: description,
    },
  });

  if (res.data?.UpdateProjectInfo.ok) {
    dispatch(
      slice.actions.updateProject({ project: { id: id, name: projectName, description: description } as Project }),
    );
  }
};

export const updateProjectColorImage = (project: Project): AppThunk => async (dispatch) => {
  const res = await client.mutate<UpdateProjectInfoMutation, UpdateProjectInfoMutationVariables>({
    mutation: UPDATE_PROJECT_INFO,
    refetchQueries: [{ query: GET_PROJECTS_BY_USER }],
    variables: {
      projectId: project.id,
      projectColor: project.projectColor,
      projectImage: project.projectImage,
    },
  });

  if (res.data?.UpdateProjectInfo.ok) {
    dispatch(
      slice.actions.updateProject({
        project: {
          id: project.id,
          projectColor: project.projectColor,
          projectImage: project.projectImage,
        } as Project,
      }),
    );
  }
};

export const deleteProject = (id: string): AppThunk => async (dispatch) => {
  const res = await client.mutate<DeleteProjectMutation, DeleteProjectMutationVariables>({
    mutation: DELETE_PROJECT,
    variables: {
      projectId: id,
    },
  });

  if (res.data?.DeleteProject.ok) {
    dispatch(slice.actions.deselectProject());
    dispatch(
      slice.actions.deleteProject({
        projectId: id,
      }),
    );
  }
};

import React, { useEffect, useState } from "react";
import { Breadcrumbs } from "@mui/material";
import { useLocation } from "react-router-dom";
import { Location } from "history";
import type { FC } from "react";
import { breadcrumbsTree } from "src/Routes/Routes";
import { useTypedSelector } from "src/hooks/useTypedSelector";
import {
  projectSectionsForAdmin,
  projectSectionsForAll,
  workspaceSectionsForAdmin,
  workspaceSectionsForAll,
} from "src/Shared/FeatureList";
import { Project, User, Workspace, BreadcrumbType } from "src/types";
import useAuth from "src/hooks/useAuth";
import { BreadcrumbItem } from "./BreadcrumbItem";
import { WorkspaceBaseProperty, WorkspaceRole } from "../../../../generated/graphql";

const HeaderBreadcrumbs: FC = () => {
  const location = useLocation();
  const { user } = useAuth();
  const { workspace } = useTypedSelector((state) => state.workspace);
  const { projects, currentProjectId } = useTypedSelector((state) => state.projects);
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbType[]>([]);

  useEffect(() => {
    if (workspace && user) {
      setBreadcrumbs(
        createBreadcrumb(location, user?.workspaces || [], workspace, projects, currentProjectId || "", user),
      );
    }
  }, [location]);

  return (
    <Breadcrumbs separator="/">
      {breadcrumbs.map((v, i) => {
        return (
          <BreadcrumbItem
            to={v.to}
            label={v.label}
            options={v.options}
            key={v.label}
            depth={i}
            totalBreadcrumbCount={breadcrumbs.length}
          />
        );
      })}
    </Breadcrumbs>
  );
};

const getName = (path: string) => {
  const parsedPath = path.split("-");
  const breadcrumbName = parsedPath
    .map((v) => {
      return v.charAt(0).toUpperCase() + v.slice(1);
    })
    .join(" ");
  return breadcrumbName;
};

const createBreadcrumb = (
  location: Location<any>,
  workspaces: WorkspaceBaseProperty[],
  workspace: Workspace,
  projects: {
    byId: Record<string, Project>;
    allIds: string[];
  },
  projectId: string,
  user: User,
): BreadcrumbType[] => {
  const parsedLocation = location.pathname.split("/").slice(2);
  if (parsedLocation.length === 0) return [];

  return parsedLocation.map((v, i) => {
    const to =
      v === "project" && i === 1
        ? "/w/" + parsedLocation.slice(0, i).join("/") + "/projects"
        : "/w/" + parsedLocation.slice(0, i + 1).join("/");
    const label = i === 0 || i === 2 ? v : v === "project" && i === 1 ? "Projects" : getName(v);
    const options = breadcrumbOptionGenerator(
      parsedLocation,
      i,
      breadcrumbsTree[i],
      user,
      workspace,
      workspaces,
      projects,
      projectId,
    );
    return {
      to: to,
      label: label,
      options: options,
    };
  });
};

const breadcrumbOptionGenerator = (
  parsedLocation: string[],
  depth: number,
  breadcrumbsTreeItem: BreadcrumbType[],
  user: User,
  workspace: Workspace,
  workspaces?: WorkspaceBaseProperty[],
  projects?: {
    byId: Record<string, Project>;
    allIds: string[];
  },
  projectId?: string,
): BreadcrumbType[] => {
  const isAdmin = workspace.assignees.some((v) => v.id === user.id && v.role === WorkspaceRole.Admin);
  if (breadcrumbsTreeItem[0]["isParam"]) {
    switch (breadcrumbsTreeItem[0].label) {
      case ":workspace":
        return workspaces
          ? workspaces.map((v) => {
              return {
                to: `/w/${v.name}`,
                label: v.name,
              };
            })
          : [];
      case ":projectName":
        return projects
          ? projects.allIds.map((id) => {
              const project = projects.byId[id];
              return {
                label: project.name,
                to: `/w/` + parsedLocation.slice(0, depth).join("/") + "/" + project.name,
              };
            })
          : [];
      default:
        return [];
    }
  } else {
    return isAdmin
      ? breadcrumbsTreeItem
          .filter((v) => {
            return (
              workspaceSectionsForAdmin(":workspace")
                .reduce((a, c) => ({
                  ...a,
                  items: a.items.concat(c.items),
                }))
                .items.map((k: any) => {
                  return k.href;
                })
                .includes(v.to) ||
              projectSectionsForAdmin(":workspace", ":projectName")
                .reduce((a, c) => ({
                  ...a,
                  items: a.items.concat(c.items),
                }))
                .items.map((k: any) => {
                  return k.href;
                })
                .includes(v.to)
            );
          })
          .map((v: any) => {
            return { label: getName(v.label), to: "/w/" + parsedLocation.slice(0, depth).join("/") + "/" + v.label };
          })
      : breadcrumbsTreeItem
          .filter((v) => {
            return (
              workspaceSectionsForAll(":workspace")
                .reduce((a, c) => ({
                  ...a,
                  items: a.items.concat(c.items),
                }))
                .items.map((k: any) => {
                  return k.href;
                })
                .includes(v.to) ||
              projectSectionsForAll(":workspace", ":projectName")
                .reduce((a, c) => ({
                  ...a,
                  items: a.items.concat(c.items),
                }))
                .items.map((k: any) => {
                  return k.href;
                })
                .includes(v.to)
            );
          })
          .map((v: any) => {
            return { label: getName(v.label), to: "/w/" + parsedLocation.slice(0, depth).join("/") + "/" + v.label };
          });
  }
};

export default HeaderBreadcrumbs;

// routes
import { PATH_DASHBOARD, PATH_PROJECT } from "../../../Routes/paths";
import { Icon, IconifyIcon } from "@iconify/react";

// components
import { Label } from "../../../Components/Atoms";
import { SvgIconStyle } from "../../../Components/Atoms";
import { useTypedSelector } from "../../../hooks";

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: "100%", height: "100%" }} />
);

const getIconifyIcon = (icon: string | IconifyIcon) => <Icon icon={icon} width={24} height={24} />;

const ICONS = {
  myTask: getIconifyIcon("uim:house-user"),
  myTimesheet: getIconifyIcon("icons8:todo-list"),
  project: getIconifyIcon("uim:rocket"),
  members: getIconifyIcon("ph:users-three-duotone"),
  clients: getIconifyIcon("fa-solid:address-card"),
  adminTimesheet: getIconifyIcon("fluent:task-list-square-rtl-20-filled"),
  setting: getIconifyIcon("ant-design:setting-twotone"),
  blog: getIcon("ic_blog"),
  cart: getIcon("ic_cart"),
  chat: getIcon("ic_chat"),
  mail: getIcon("ic_mail"),
  user: getIcon("ic_user"),
  kanban: getIcon("ic_kanban"),
  banking: getIcon("ic_banking"),
  calendar: getIcon("ic_calendar"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_analytics"),
  dashboard: getIcon("ic_dashboard"),
  booking: getIcon("ic_booking"),
  back: getIconifyIcon("fluent:arrow-circle-left-28-regular"),
  planner: getIconifyIcon("mdi:chart-timeline"),
  information: getIconifyIcon("clarity:building-outline-badged"),
  storage: getIconifyIcon("ant-design:cloud-server-outlined"),
  table: getIconifyIcon("fa-regular:list-alt"),
};

export const sidebarConfig = (workspaceName: string, isAdmin: boolean) => {
  const pathDashboard = PATH_DASHBOARD(workspaceName);

  const general = [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      subheader: "side-bar.private",
      items: [
        {
          title: "side-bar.my-tasks",
          path: pathDashboard.private.myTasks,
          icon: ICONS.myTask,
        },
        { title: "side-bar.my-analysis", path: pathDashboard.private.myAnalysis, icon: ICONS.analytics },
        {
          title: "side-bar.my-timesheet",
          path: pathDashboard.private.myTimesheet,
          icon: ICONS.myTimesheet,
        },
      ],
    },

    // MANAGEMENT
    // ----------------------------------------------------------------------
    {
      subheader: "side-bar.team",
      items: [
        // MANAGEMENT : USER
        {
          title: "side-bar.projects",
          path: pathDashboard.team.projects,
          icon: ICONS.project,
        },
        {
          title: "side-bar.calendar",
          path: pathDashboard.team.calendar,
          icon: ICONS.calendar,
        },
        {
          title: "side-bar.members",
          path: pathDashboard.team.members,
          icon: ICONS.members,
        },
        {
          title: "side-bar.clients",
          path: pathDashboard.team.clients,
          icon: ICONS.clients,
        },
      ],
    },
    {
      subheader: "Blockchain",
      items: [
        // MANAGEMENT : USER
        {
          title: "on-chain Dashboard",
          path: pathDashboard.blockchain.onChainDashboard,
          icon: ICONS.table,
        },
      ],
    },
  ];

  const admin = [
    // APP
    // ----------------------------------------------------------------------
    {
      subheader: "side-bar.admin",
      items: [
        {
          title: "side-bar.workspace-setting",
          path: pathDashboard.admin.workspaceSetting,
          icon: ICONS.setting,
          // info: <Label color="error">2</Label>,
        },
        {
          title: "side-bar.workspace-timesheet",
          path: pathDashboard.admin.workspaceTimesheet,
          icon: ICONS.adminTimesheet,
        },
      ],
    },
  ];

  return isAdmin ? general.concat(admin) : general;
};

export const projectSidebarConfig = (workspaceName: string, projectName: string, isAdmin: boolean) => {
  const pathDashboard = PATH_DASHBOARD(workspaceName);
  const pathProject = PATH_PROJECT(workspaceName, projectName);

  const general = [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      subheader: "",
      items: [
        {
          title: "side-bar.back-to-workspace",
          path: pathDashboard.team.projects,
          icon: ICONS.back,
        },
      ],
    },
    {
      subheader: "common.project",
      items: [
        {
          title: "project.common.dashboard",
          path: pathProject.project.dashboard,
          icon: ICONS.dashboard,
        },
        {
          title: "project.common.planner",
          path: pathProject.project.planner,
          icon: ICONS.planner,
        },
        {
          title: "project.common.table",
          path: pathProject.project.table,
          icon: ICONS.table,
        },
        {
          title: "project.common.calendar",
          path: pathProject.project.calendar,
          icon: ICONS.calendar,
        },
        {
          title: "project.common.information",
          path: pathProject.project.information,
          icon: ICONS.information,
        },
        {
          title: "project.common.storage",
          path: pathProject.project.storage,
          icon: ICONS.storage,
        },
        {
          title: "project.common.setting",
          path: pathProject.project.setting,
          icon: ICONS.setting,
        },
      ],
    },
  ];

  return general;
};

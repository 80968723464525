import gql from "graphql-tag";

export const GET_WORKSPACE_USERS = gql`
  query GetWorkspaceUsers {
    GetWorkspaceUsers {
      ok
      error
      users {
        id
        email
        firstName
        lastName
        fullName
        profileColor
        profileImage
        activate
        role
        titleId
        customRate
        customCurrency
      }
    }
  }
`;

export const UPDATE_WORKSPACE_USER = gql`
  mutation UpdateWorkspaceUser(
    $id: String!
    $activate: Boolean
    $role: WorkspaceRole
    $customSetting: JSON
    $titleId: Int
    $customRate: Float
    $customCurrency: String
  ) {
    UpdateWorkspaceUser(
      id: $id
      activate: $activate
      role: $role
      customSetting: $customSetting
      titleId: $titleId
      customRate: $customRate
      customCurrency: $customCurrency
    ) {
      ok
      error
    }
  }
`;

import {
  CREATE_PROJECT_ASSIGNEE,
  DELETE_PROJECT_ASSIGNEE,
  GET_PROJECT_ASSIGNEE,
  UPDATE_PROJECT_ASSIGNEE,
} from "src/Api/Project/ProjectAssignee/ProjectAssignee.queries";
import client from "src/apollo";
import {
  CreateProjectAssigneeMutation,
  CreateProjectAssigneeMutationVariables,
  DeleteProjectAssigneeMutation,
  DeleteProjectAssigneeMutationVariables,
  GetProjectAssigneesQuery,
  GetProjectAssigneesQueryVariables,
  ProjectAssignee,
  UpdateProjectAssigneeMutation,
  UpdateProjectAssigneeMutationVariables,
} from "../../generated/graphql";
import { ProjectMembersSlice as slice } from "../reducers/projectMemberReducer";
import { AppThunk } from "../store";

export const getProjectMembers = (projectId?: string | null): AppThunk => async (dispatch) => {
  dispatch(slice.actions.startLoad());

  const res = await client.query<GetProjectAssigneesQuery, GetProjectAssigneesQueryVariables>({
    query: GET_PROJECT_ASSIGNEE,
    variables: {
      projectId,
    },
  });

  if (res.data.GetProjectAssignees.ok && res.data.GetProjectAssignees.projectAssignees) {
    dispatch(slice.actions.getProjectMembers({ members: res.data.GetProjectAssignees.projectAssignees }));
  } else {
    console.error(`Fail to fetch project members : ${projectId}`);
  }
};

export const addProjectMember = (userId: string, projectId: string): AppThunk => async (dispatch) => {
  const res = await client.mutate<CreateProjectAssigneeMutation, CreateProjectAssigneeMutationVariables>({
    mutation: CREATE_PROJECT_ASSIGNEE,
    refetchQueries: [{ query: GET_PROJECT_ASSIGNEE, variables: { projectId: projectId } }],
    variables: {
      workspaceUserId: userId,
      projectId: projectId,
    },
  });

  if (res.data?.CreateProjectAssignee.ok && res.data.CreateProjectAssignee.result) {
    dispatch(slice.actions.addProjectMember({ member: res.data.CreateProjectAssignee.result }));
  } else {
    console.error(`Fail to add project member: ${userId}`);
  }
};

export const updateProjectMember = (member: ProjectAssignee): AppThunk => async (dispatch) => {
  const res = await client.mutate<UpdateProjectAssigneeMutation, UpdateProjectAssigneeMutationVariables>({
    mutation: UPDATE_PROJECT_ASSIGNEE,
    refetchQueries: [{ query: GET_PROJECT_ASSIGNEE, variables: { projectId: member.projectId } }],
    variables: {
      id: member.id,
      lead: member.lead,
    },
  });

  if (res.data?.UpdateProjectAssignee.ok) {
    dispatch(slice.actions.updateProjectMember({ member }));
  } else {
    console.error(`Fail to update project member : ${member.id}`);
  }
};

export const deleteProjectMember = (member: ProjectAssignee): AppThunk => async (dispatch) => {
  const res = await client.mutate<DeleteProjectAssigneeMutation, DeleteProjectAssigneeMutationVariables>({
    mutation: DELETE_PROJECT_ASSIGNEE,
    refetchQueries: [{ query: GET_PROJECT_ASSIGNEE, variables: { projectId: member.projectId } }],
    variables: {
      id: member.id,
    },
  });

  if (res.data?.DeleteProjectAssignee.ok) {
    dispatch(slice.actions.deleteProjectMember({ id: member.id }));
  } else {
    console.error(`Fail to remove project member: ${member.id}`);
  }
};

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

type Props = {
  message: string;
  open: boolean;
  activateFn?: () => void;
  handleClose: () => void;
};

export default function CustomDialog(props: Props) {
  const { message, open, activateFn, handleClose } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{message}</DialogTitle>
        {activateFn ? (
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              No
            </Button>
            <Button onClick={activateFn} color="primary" autoFocus>
              Yes
            </Button>
          </DialogActions>
        ) : (
          <>
            <DialogActions>
              <Button onClick={handleClose} color="primary" autoFocus>
                Ok
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
}

import React, { useEffect } from "react";
import type { FC, ReactNode } from "react";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import useAuth from "src/hooks/useAuth";
import { useTypedSelector } from "src/hooks/useTypedSelector";
import { useDispatch } from "react-redux";
import { changeWorkspace } from "src/Store/reducers/workspaceReducer";

interface GuestGuardProps {
  children?: ReactNode;
}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  const { isAuthenticated, isEmailVerified, user } = useAuth();
  const { workspace } = useTypedSelector((state) => state.workspace);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated && isEmailVerified && workspace === undefined) {
      dispatch(changeWorkspace());
    }
  }, [workspace]);

  if (isAuthenticated) {
    if (user == null) {
      return <Navigate to="/404-not-found" />;
    }

    if (!isEmailVerified) {
      return <Navigate to="/verify-email" />;
    }

    if (workspace) {
      return <Navigate to={`/w/${workspace.name}/my-page`} />;
    }

    return <Navigate to="/user-workspaces" />;
  }

  return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default GuestGuard;

import {
  CREATE_PHASE,
  DELETE_PHASE,
  GET_PHASES,
  UPDATE_PHASE,
  UPDATE_PHASE_ORDER,
} from "src/Api/Phases/Phases.queries";
import client from "src/apollo";
import { AppThunk } from "..";
import {
  CreatePhaseMutation,
  CreatePhaseMutationVariables,
  DeletePhaseMutation,
  DeletePhaseMutationVariables,
  GetPhasesQuery,
  UpdatePhaseMutation,
  UpdatePhaseMutationVariables,
  UpdatePhaseOrderMutation,
  UpdatePhaseOrderMutationVariables,
} from "../../generated/graphql";
import { PhaseType } from "../../types";
import { PhaseSlice as slice } from "../reducers/phasesReducer";

export const getPhases = (): AppThunk => async (dispatch) => {
  const res = await client.query<GetPhasesQuery>({
    query: GET_PHASES,
  });

  if (res.data.GetPhases.phases.length > 0) {
    dispatch(slice.actions.getPhases({ phases: res.data.GetPhases.phases }));
  } else {
    dispatch(slice.actions.changeLoadState({ isLoaded: true }));
  }
};

export const createPhase = (name: string): AppThunk => async (dispatch) => {
  const res = await client.mutate<CreatePhaseMutation, CreatePhaseMutationVariables>({
    mutation: CREATE_PHASE,
    variables: {
      name,
    },
  });

  if (res.data?.CreatePhase.ok && res.data.CreatePhase.result) {
    dispatch(slice.actions.createPhase({ phase: res.data.CreatePhase.result }));
  } else {
    console.error(res.data?.CreatePhase.error);
  }
};

export const updatePhase = (phase: PhaseType): AppThunk => async (dispatch) => {
  const res = await client.mutate<UpdatePhaseMutation, UpdatePhaseMutationVariables>({
    mutation: UPDATE_PHASE,
    variables: {
      id: phase.id,
      name: phase.name,
      code: phase.code,
      color: phase.color,
    },
  });

  if (res.data?.UpdatePhase.ok) {
    dispatch(slice.actions.updatePhase({ phase }));
  } else {
    console.error(res.data?.UpdatePhase.error);
  }
};

export const deletePhase = (id: number): AppThunk => async (dispatch) => {
  const res = await client.mutate<DeletePhaseMutation, DeletePhaseMutationVariables>({
    mutation: DELETE_PHASE,
    variables: {
      id: id,
    },
  });

  if (res.data?.DeletePhase.ok) {
    dispatch(slice.actions.deletePhase({ id }));
  } else {
    console.error(res.data?.DeletePhase.error);
  }
};

export const movePhase = (id: number, position: number): AppThunk => async (dispatch) => {
  dispatch(slice.actions.movePhase({ id, position }));
  client.mutate<UpdatePhaseOrderMutation, UpdatePhaseOrderMutationVariables>({
    mutation: UPDATE_PHASE_ORDER,
    refetchQueries: [{ query: GET_PHASES }],
    variables: {
      id,
      position,
    },
  });
};

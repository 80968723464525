import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { AppThunk } from "..";
import client from "src/apollo";
import { GET_PROJECT_INFO_PROPERTIES } from "src/Api/ProjectInfoProperties/ProjectInfoProperties.queries";
import {
  BaseOption,
  GetProjectInfoQuery,
  GetProjectInfoQueryVariables,
  ProjectInfo,
  ProjectInfoPropertiesQuery,
} from "../../generated/graphql";
import { GET_PROJECT_INFO } from "../../Api/Project/ProjectInfo.queries";

export interface ProjectInformationPropertiesState {
  isLoaded: boolean;
  complexityLevels: BaseOption[];
  paymentTerms: BaseOption[];
  programs: BaseOption[];
  risks: BaseOption[];
  serviceTypes: BaseOption[];
  tools: BaseOption[];
  projectInformations: {
    byId: Record<string, ProjectInfo>;
  };
}

const initialState: ProjectInformationPropertiesState = {
  isLoaded: false,
  complexityLevels: [],
  paymentTerms: [],
  programs: [],
  risks: [],
  serviceTypes: [],
  tools: [],
  projectInformations: {
    byId: {},
  },
};

type ProjectInfoPropType = "complexityLevels" | "paymentTerms" | "programs" | "risks" | "serviceTypes" | "tools";

export const slice = createSlice({
  name: "projectInfoProps",
  initialState,
  reducers: {
    reset(state: ProjectInformationPropertiesState) {
      state = initialState;
    },
    getProjectInfoProps(
      state: ProjectInformationPropertiesState,
      action: PayloadAction<{
        complexityLevels: BaseOption[];
        paymentTerms: BaseOption[];
        programs: BaseOption[];
        risks: BaseOption[];
        serviceTypes: BaseOption[];
        tools: BaseOption[];
      }>,
    ) {
      const { complexityLevels, paymentTerms, programs, risks, serviceTypes, tools } = action.payload;

      state.complexityLevels = complexityLevels;
      state.paymentTerms = paymentTerms;
      state.programs = programs;
      state.risks = risks;
      state.serviceTypes = serviceTypes;
      state.tools = tools;
      state.isLoaded = true;
    },
    createProjectProp(
      state: ProjectInformationPropertiesState,
      action: PayloadAction<{
        option: { id: number; name: string };
        type: ProjectInfoPropType;
      }>,
    ) {
      const { option, type } = action.payload;

      state[type] = [...state[type], option];
      //   switch (type) {
      //     case "complexityLevels":
      //       state.complexityLevels = [...state.complexityLevels, option];
      //       break;
      //     case "paymentTerms":
      //       state.paymentTerms = [...state.paymentTerms, option];
      //       break;
      //     case "programs":
      //       state.programs = [...state.programs, option];
      //       break;
      //     case "risks":
      //       state.risks = [...state.risks, option];
      //       break;
      //     case "serviceTypes":
      //       state.serviceTypes = [...state.serviceTypes, option];
      //       break;
      //     case "mainTools":
      //       state.mainTools = [...state.mainTools, option];
      //       break;
      //     case "subTools":
      //       state.subTools = [...state.subTools, option];
      //       break;
      //     default:
      //       break;
      //   }
    },
    updateProjectProp(
      state: Omit<ProjectInformationPropertiesState, "isLoaded">,
      action: PayloadAction<{
        option: BaseOption;
        type: ProjectInfoPropType;
      }>,
    ) {
      const { option, type } = action.payload;

      state[type] = _.map(state[type], (_option) => {
        if (_option.id === option.id) {
          return option;
        }
        return _option;
      });
    },
    deleteProjectProp(
      state: ProjectInformationPropertiesState,
      action: PayloadAction<{
        optionId: number;
        type: ProjectInfoPropType;
      }>,
    ) {
      const { optionId, type } = action.payload;
      state[type] = _.reject(state[type], { id: optionId });
    },
    getProjectInfo(
      state: ProjectInformationPropertiesState,
      action: PayloadAction<{ projectId: string; projectInfo: ProjectInfo}>,
    ) {
      const { projectId, projectInfo } = action.payload;
      state.projectInformations.byId[projectId] = projectInfo;
    },
  },
});

export const reducer = slice.reducer;

export const getProjectInfoProps = (): AppThunk => async (dispatch) => {
  const response = await client.query<ProjectInfoPropertiesQuery>({
    query: GET_PROJECT_INFO_PROPERTIES,
  });

  if (response.data.ProjectInfoProperties.ok) {
    dispatch(slice.actions.getProjectInfoProps(response.data.ProjectInfoProperties));
  }
};

import React, { useState } from "react";

import { Box, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import type { FC } from "react";
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    cursor: "pointer",
    width: "100%",
    "&:hover": {
      backgroundColor: theme.palette.background.paper,
      transition: "0.3s",
    },
  },
}));

interface DetailElementProps {
  open: boolean;
  defaultNode: React.ReactNode;
  editNode: React.ReactNode;
  [key: string]: any;
}

const DetailElement: FC<DetailElementProps> = ({ open, defaultNode, editNode, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box display="flex" className={classes.root} alignItems="center" p={mobileDevice ? 0 : 1} overflow="auto" {...rest}>
      {!open &&
        (!defaultNode ? (
          <Typography variant="subtitle2" color="textSecondary">
            None
          </Typography>
        ) : (
          defaultNode
        ))}
      {open && editNode}
    </Box>
  );
};

export default DetailElement;

import gql from "graphql-tag";

export const CREATE_COMPANY = gql`
  mutation CreateCompany($name: String!) {
    CreateCompany(name: $name) {
      ok
      error
      result {
        id
        name
      }
    }
  }
`;

export const DELETE_COMPANY = gql`
  mutation DeleteCompany($id: Int!) {
    DeleteCompany(id: $id) {
      ok
      error
    }
  }
`;

export const GET_COMPANIES = gql`
  query GetCompanies {
    GetCompanies {
      ok
      error
      companies {
        id
        name
        members {
          id
          name
          title
          email
          phone
        }
      }
    }
  }
`;

export const UPDATE_COMPANY = gql`
  mutation UpdateCompany($id: Int!, $name: String!) {
    UpdateCompany(id: $id, name: $name) {
      ok
      error
    }
  }
`;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Emoji } from "src/Components/Atoms";
import useFirebaseAuth from "src/hooks/useAuth";
import { getAttendanceAllUsers } from "src/Store/action-creators/attendance.actionCreator";
import { endOfMonth, startOfMonth } from "src/utils/date";

import { Box, Theme, Typography, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { UserStatusDialog } from "../";

import type { FC } from "react";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    width: "100%",
    height: "100%",
    cursor: "pointer",
    backgroundColor: "white",
    lineHeight: "30px",

    border: "1px solid",
    borderRadius: "4px",
    borderColor: theme.palette.grey[300],
    "&:hover": {
      borderColor: theme.palette.grey[400],
    },
  },
  emojiBox: {
    padding: "2px 10px 0px 7px",
    borderRight: "1px solid",
    borderRightColor: theme.palette.grey[300],
    "&:hover": {
      borderRightColor: theme.palette.grey[400],
    },
  },
}));

interface UserStatusSelectionProps {
  onClose: () => void;
}

const UserStatusSelection: FC<UserStatusSelectionProps> = ({ onClose }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { user } = useFirebaseAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAttendanceAllUsers(startOfMonth(), endOfMonth()));
  }, []);

  const [open, setOpen] = useState(false);
  return (
    <>
      <Box
        display="flex"
        className={classes.paper}
        alignItems="center"
        onClick={() => {
          setOpen(true);
        }}
      >
        <Box className={classes.emojiBox}>
          <Emoji symbol="🤗" />
        </Box>
        <Box pl={2}>
          <Typography variant="body1">Set your status!</Typography>
        </Box>
      </Box>
      {open && (
        <UserStatusDialog
          open={open}
          onClose={() => {
            setOpen(false);
            onClose();
          }}
        />
      )}
    </>
  );
};

export default UserStatusSelection;

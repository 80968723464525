import React from "react";
import { Info as InfoIcon } from "react-feather";

import { Box, Card, Paper, Theme, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import type { FC } from "react";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    zIndex: 1,
    flexGrow: 1,
    boxShadow: "0 0 2px 0 rgba(145, 158, 171, 0.24), 0 16px 14px -4px rgba(145, 158, 171, 0.24)",
  },
  helper: {
    marginLeft: "8px",
  },
  helperIcon: {
    backgroundColor: "white",
  },
}));

interface BaseCardProps {
  name: string;
  direction?: "row" | "column";
  helperText?: string;
}

const BaseCard: FC<BaseCardProps> = ({ name, direction = "row", helperText, children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Card className={classes.paper}>
      <Box
        flexGrow={1}
        display="flex"
        alignItems={direction === "row" ? "center" : "none"}
        // justifyContent={direction === "row" ? "none" : "center"}
        flexDirection={direction}
      >
        <Box display="flex" alignItems="center">
          <Typography variant="h5">{name}</Typography>
          {helperText && (
            <Tooltip title={helperText} className={classes.helper}>
              <InfoIcon size="12px" strokeWidth="2px" />
            </Tooltip>
          )}
        </Box>
        {direction === "row" && <Box flexGrow={1} />}
        <Box>{children}</Box>
      </Box>
    </Card>
  );
};

export default BaseCard;

import React from "react";
import type { FC } from "react";
import { Button, Grid, SvgIcon, Typography } from "@mui/material";
import clsx from "clsx";
import { Plus as PlusIcon } from "react-feather";
import { useLocales } from "src/hooks";

interface HeaderProps {
  name: string;
  className?: string;
  admin?: boolean;
}

const Header: FC<HeaderProps> = ({ name, className, admin, ...rest }) => {
  const { translate } = useLocales();
  return (
    <Grid
      alignItems="center"
      container
      justifyContent="space-between"
      spacing={3}
      className={clsx(className)}
      style={{ marginBottom: 5 }}
      {...rest}
    >
      <Grid item>
        <Typography variant="h4" color="textPrimary">
          {name}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Header;

import moment from "moment";
import React, { FC, useCallback, useEffect, useState } from "react";

import { Box, Button, Container, Divider, Grid, LinearProgress, Paper, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { getLastBlocks, getRecordTimesheets } from "src/utils/avaxBlockchainWyzrs";
import RecordCard from "../Components/recordCard";
import BlockCard from "../Components/blockCard";
import Header from "../Components/header";
import { CircularProgress } from "@mui/material";

interface RecordItem {
  user: string;
  project: string;
  time: string;
}
interface BlockItem {
  parentHash: string;
  hash: string;
  timestamp: number;
  number: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    width: "30px",
    height: "30px",
  },
}));

const TxTable: FC = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<RecordItem[]>([]);
  const [blockData, setBlockData] = useState<BlockItem[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // 로딩 시작
      try {
        const rawData = await getRecordTimesheets();
        const blocks = await getLastBlocks(5);
        const formattedBlocks = blocks.map((block) => ({
          parentHash: block.parentHash,
          hash: block.hash,
          timestamp: block.timestamp,
          number: block.number,
        }));
        setBlockData(formattedBlocks);
        const refinedData = rawData.map(([bigNumber, workerName, description]) => ({
          user: workerName,
          project: description,
          time: bigNumber._isBigNumber ? moment.unix(bigNumber.toNumber()).format("MM/DD/YYYY HH:mm") : bigNumber,
        }));
        setData(refinedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoading(false); // 로딩 완료
    };

    fetchData();
  }, []);

  //task  불러오는 data hooks
  //아래 _data 함수를 통해 현재 프로젝트의 task를 불러온다.
  //FilterMenu를 통해 필터링된 task를 불러온다.
  //FilterMenu에서 필터링된 task를 불러오기 위해 필요한 변수들을 선언한다.

  return (
    <>
      <Header name="On-Chain Timesheet Record" />
      {data.length !== 0 && blockData.length !== 0 ? (
        <>
          {" "}
          <Typography variant="body1">
            In this table, the records of timesheets issued are logged onto the blockchain via smart contracts, and
            integrity is verified based on the time when the blocks are connected to the blockchain.
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              {<RecordCard name="Last 5 Records" arr={data} />}
            </Grid>

            <Grid item xs={12} md={6}>
              {<BlockCard name="Last 5 Blocks" arr={blockData} />}
            </Grid>
          </Grid>
          <Box mt={2} />
        </>
      ) : (
        <div>
          <LinearProgress />
        </div>
      )}
    </>
  );
};

export default TxTable;

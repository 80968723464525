import React from "react";
import type { FC } from "react";
import { Box, ImageList, ImageListItem, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { COLOR_CODES } from "src/theme/colors";
import { Check as CheckIcon } from "react-feather";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
    marginTop: 10,
  },
  gridList: {
    width: 210,
    height: 140,
  },
  circle: {
    height: "30px",
    width: "30px",
    borderRadius: "50%",
    display: "inlineBlock",
  },
}));

interface ColorCodePickerProps {
  selectedColor?: string;
  handleSelect: (color: string) => void;
}

const ColorCodesPicker: FC<ColorCodePickerProps> = ({ selectedColor, handleSelect }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ImageList rowHeight={40} cols={6} className={classes.gridList}>
        {COLOR_CODES.map((v) => (
          <ImageListItem key={v} cols={1}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <IconButton
                size="small"
                className={classes.circle}
                style={{ backgroundColor: v }}
                onClick={() => handleSelect(v)}
              >
                {selectedColor === v && <CheckIcon style={{ color: "white" }} />}
              </IconButton>
            </Box>
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  );
};

export default ColorCodesPicker;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { objFromArray, removeUndefined } from "src/utils/utils";
import _ from "lodash";
import { Milestone, ProjectPhase, UpdateProjectPhaseInput } from "../../generated/graphql";

export interface ProjectTimelineState {
  loading: boolean;
  isLoaded: { byProject: Record<string, boolean> };
  projectPhases: {
    byId: Record<number, ProjectPhase>;
    byProject: Record<string, number[]>;
  };
  projectMilestones: {
    byId: Record<number, Milestone>;
    byProject: Record<string, number[]>;
    byPhase: Record<number, number[]>;
  };
}

const initialState: ProjectTimelineState = {
  loading: true,
  isLoaded: {
    byProject: {},
  },
  projectPhases: {
    byId: {},
    byProject: {},
  },
  projectMilestones: {
    byId: {},
    byProject: {},
    byPhase: {},
  },
};

export const ProjectTimelineSlice = createSlice({
  name: "projectTimeline",
  initialState,
  reducers: {
    reset(state: ProjectTimelineState) {
      state = initialState;
    },
    setLoadingState(state: ProjectTimelineState, action: PayloadAction<{ loading: boolean }>) {
      state.loading = action.payload.loading;
    },
    getProjectPhases(state: ProjectTimelineState, action: PayloadAction<{ phase: ProjectPhase[] }>) {
      const { phase } = action.payload;

      state.projectPhases.byId = _.merge(state.projectPhases.byId, objFromArray(phase));

      const _groupByProjectId = _.groupBy(phase, "projectId");
      _.forEach(_groupByProjectId, (val, projectId) => {
        state.projectPhases.byProject[projectId] = val.map((v) => v.id);
        state.isLoaded.byProject[projectId] = true;
      });
    },
    addProjectPhase(state: ProjectTimelineState, action: PayloadAction<{ phase: ProjectPhase }>) {
      const { phase } = action.payload;

      state.projectPhases.byId[phase.id] = phase;

      if (state.projectPhases.byProject[phase.projectId]) state.projectPhases.byProject[phase.projectId].push(phase.id);
      else {
        state.projectPhases.byProject[phase.projectId] = [phase.id];
      }
    },
    updateProjectPhase(
      state: ProjectTimelineState,
      action: PayloadAction<{ id: number; input: UpdateProjectPhaseInput }>,
    ) {
      const { id, input } = action.payload;

      removeUndefined(input);

      state.projectPhases.byId[id] = { ...state.projectPhases.byId[id], ...input };
    },
    deleteProjectPhase(state: ProjectTimelineState, action: PayloadAction<{ id: number }>) {
      const { id } = action.payload;

      const projectId = state.projectPhases.byId[id].projectId;

      state.projectPhases.byId = _.omit(state.projectPhases.byId, id);
      _.pull(state.projectPhases.byProject[projectId], id);
    },
    getMilestones(state: ProjectTimelineState, action: PayloadAction<{ milestones: Milestone[] }>) {
      const { milestones } = action.payload;

      state.projectMilestones.byId = _.merge(state.projectMilestones.byId, objFromArray(milestones));

      const _groupByMilestoneId = _.groupBy(milestones, "projectId");
      _.forEach(_groupByMilestoneId, (val, projectId) => {
        state.projectMilestones.byProject[projectId] = val.map((v) => v.id);
        state.isLoaded.byProject[projectId] = true;
      });

      const _groupByPhaseId = _.groupBy(milestones, "phaseId");
      _.forEach(_groupByPhaseId, (val, phaseId) => {
        state.projectMilestones.byPhase[phaseId] = val.map((v) => v.id);
      });
    },
    addMilestone(state: ProjectTimelineState, action: PayloadAction<{ milestone: Milestone }>) {
      const { milestone } = action.payload;

      state.projectMilestones.byId[milestone.id] = milestone;

      if (milestone.projectId in state.projectMilestones.byProject) {
        state.projectMilestones.byProject[milestone.projectId].push(milestone.id);
      } else {
        state.projectMilestones.byProject[milestone.projectId] = [milestone.id];
      }

      if (milestone.phaseId) {
        if (
          milestone.phaseId in state.projectMilestones.byPhase &&
          state.projectMilestones.byPhase[milestone.phaseId].length > 0
        ) {
          state.projectMilestones.byPhase[milestone.phaseId].push(milestone.id);
        } else {
          state.projectMilestones.byPhase[milestone.phaseId] = [milestone.id];
        }
      }
    },
    updateMilestones(state: ProjectTimelineState, action: PayloadAction<{ milestone: Milestone }>) {
      const { milestone } = action.payload;

      // phase를 바꾸었을 경우
      const _before = state.projectMilestones.byId[milestone.id];
      if (_before.phaseId !== milestone.phaseId) {
        // 원래 속한 phaes에서 제외
        if (_before.phaseId && _before.phaseId in state.projectMilestones.byPhase) {
          _.pull(state.projectMilestones.byPhase[_before.phaseId], milestone.id);
        }

        if (milestone.phaseId) {
          if (
            milestone.phaseId in state.projectMilestones.byPhase &&
            state.projectMilestones.byPhase[milestone.phaseId].length > 0
          ) {
            state.projectMilestones.byPhase[milestone.phaseId].push(milestone.id);
          } else {
            state.projectMilestones.byPhase[milestone.phaseId] = [milestone.id];
          }
        }
      }

      state.projectMilestones.byId[milestone.id] = milestone;
    },
    deleteMilestone(state: ProjectTimelineState, action: PayloadAction<{ id: number }>) {
      const { id } = action.payload;
      const projectId = state.projectMilestones.byId[id].projectId;
      const phaseId = state.projectMilestones.byId[id].phaseId;

      state.projectMilestones.byId = _.omit(state.projectMilestones.byId, id);
      _.pull(state.projectMilestones.byProject[projectId], id);

      if (phaseId) {
        _.pull(state.projectMilestones.byPhase[phaseId], id);
      }
    },
  },
});

export const reducer = ProjectTimelineSlice.reducer;

import gql from "graphql-tag";

export const CREATE_WORKSPACE = gql`
  mutation CreateWorkspace($name: String!) {
    CreateWorkspace(name: $name, pricing: "free") {
      ok
      error
      workspaceId
    }
  }
`;

export const GET_CURRENT_WORKSPACE = gql`
  query GetCurrentWorkspace($id: ID) {
    GetCurrentWorkspace(id: $id) {
      ok
      error
      workspace {
        id
        name
        database
        pricing
        workspaceColor
        workspaceImage
        defaultCurrency
        createdBy
        createdAt
        updatedAt
    
        assignees {
          id
          email
          firstName
          lastName
          fullName
          profileColor
          profileImage
          activate
          role
        }
      }
    }
  }
`;

export const INVITE_NEW_USER = gql`
  mutation InviteNewUser($workspaceId: String!, $userId: String!) {
    InviteNewUser(workspaceId: $workspaceId, userId: $userId) {
      ok
      error
    }
  }
`;

export const UPDATE_WORKSPACE = gql`
  mutation UpdateWorkspace(
    $id: String!
    $pricing: String
    $workspaceColor: String
    $workspaceImage: String
    $defaultCurrency: String
  ) {
    UpdateWorkspace(
      id: $id
      pricing: $pricing
      workspaceColor: $workspaceColor
      workspaceImage: $workspaceImage
      defaultCurrency: $defaultCurrency
    ) {
      ok
      error
    }
  }
`;

export const DELETE_WORKSPACE = gql`
  mutation DeleteWorkspace($id: String!) {
    DeleteWorkspace(id: $id) {
      ok
      error
    }
  }
`;

import gql from "graphql-tag";
import { F_TASK_PROPERTIES } from "../Task/Task.queries";

export const CREATE_TIMESHEET = gql`
  mutation CreateTimesheet(
    $weekStartDate: DateTime!
    $weekEndDate: DateTime!
    $monday: Int
    $tuesday: Int
    $wednesday: Int
    $thursday: Int
    $friday: Int
    $saturday: Int
    $sunday: Int
    $userId: String
    $taskIds: [Int!]
    $status: String!
    $totalTimeSpent: Int
    $timezone: String!
  ) {
    CreateTimesheet(
      weekStartDate: $weekStartDate
      weekEndDate: $weekEndDate
      monday: $monday
      tuesday: $tuesday
      wednesday: $wednesday
      thursday: $thursday
      friday: $friday
      saturday: $saturday
      sunday: $sunday
      userId: $userId
      taskIds: $taskIds
      status: $status
      totalTimeSpent: $totalTimeSpent
      timezone: $timezone
    ) {
      ok
      error
      id
      startDate
    }
  }
`;

export const UPDATE_TIMESHEET = gql`
  mutation UpdateTimesheet($id: Int!, $status: String, $comment: String) {
    UpdateTimesheet(id: $id, status: $status, comment: $comment) {
      ok
      error
      id
    }
  }
`;

export const GET_WEEK_TIMESHEET = gql`
  query GetWeekTimesheet($weekStartDate: DateTime!, $userIds: [String!], $limit: Int) {
    GetWeekTimesheet(weekStartDate: $weekStartDate, userIds: $userIds, limit: $limit) {
      ok
      error
      timesheets {
        id
        weekStartDate
        weekEndDate
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        status
        totalTimeSpent
        comment
        tasks {
          ...TaskProperties
        }
        user {
          id
          firstName
          lastName
          fullName
          profileColor
          profileImage
        }
      }
    }
  }
  ${F_TASK_PROPERTIES}
`;

export const GET_TIMESHEET_CARDS_BY_USER = gql`
  query GetTimesheetCardsByUser($date: DateTime!, $timezone: String!) {
    GetTimesheetCardsByUser(date: $date, timezone: $timezone) {
      projectId
      timespentsByCategory {
        categoryId
        timespent {
          day
          hours
        }
      }
      timespentsByDay {
        day
        hours
      }
      totalTimespents
    }
  }
`;

import { AppThunk } from "../";
import { slice as CompanySlice } from "./companyReducer";
import { PhaseSlice } from "./phasesReducer";
import { ProjectBudgetSlice } from "./projectBudgetReducer";
import { slice as ProjectInfoSlice } from "./projectInfoReducer";
import { ProjectMembersSlice } from "./projectMemberReducer";
import { slice as ProjectSlice } from "./projectReducer";
import { projectTaskCategorySlice } from "./projectTaskCategoryReducer";
import { projectTaskStatusSlice } from "./projectTaskStatusReducer";
import { ProjectTimelineSlice } from "./projectTimelineReducer";
import { slice as TableSlice } from "./tableReducer";
import { slice as TaskDefaultOptionsSlice } from "./taskDefaultOptions";
import { slice as TaskSlice } from "./taskReducer";
import { UserTitleSlice } from "./userTitles";
import { WorkspaceAttendanceSlice } from "./workspaceAttendance";
import { WorkspaceMemberSlice } from "./workspaceMemberReducer";
import { slice as WorkspaceSlice } from "./workspaceReducer";

export const resetStoresWhenWorkspaceIsChanged = (resetWorkspace: boolean = true): AppThunk => async (dispatch) => {
  await dispatch(CompanySlice.actions.reset());
  await dispatch(TaskDefaultOptionsSlice.actions.reset());
  await dispatch(ProjectSlice.actions.reset());
  await dispatch(ProjectMembersSlice.actions.reset());
  await dispatch(ProjectInfoSlice.actions.reset());
  await dispatch(WorkspaceAttendanceSlice.actions.reset());
  await dispatch(UserTitleSlice.actions.reset());
  await dispatch(WorkspaceMemberSlice.actions.reset());
  await dispatch(TaskSlice.actions.reset());
  await dispatch(TableSlice.actions.resetFilter());
  await dispatch(ProjectTimelineSlice.actions.reset());
  await dispatch(projectTaskStatusSlice.actions.reset());
  await dispatch(projectTaskCategorySlice.actions.reset());
  await dispatch(ProjectBudgetSlice.actions.reset());
  await dispatch(PhaseSlice.actions.reset());
  if (resetWorkspace) {
    await dispatch(WorkspaceSlice.actions.reset());
  }
};

import React, { useState } from "react";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import { currencyMap } from "src/types/CurrencyTypes";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SelectChangeEvent,
  TextField,
  Theme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import CurrencySelect from "../Currency";

import type { FC } from "react";
import { useLocales } from "../../../hooks";

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
    minWidth: 40,
    maxHeight: 40,
    paddingLeft: 5,
    paddingRight: 5,
  },
}));

interface RateProps {
  rate: number | null;
  currency: string;
  onRateChange: (value: number | null) => void;
  onCurrencyChange: (value: string) => void;
}

const RateInput: FC<RateProps> = ({ rate, currency, onRateChange, onCurrencyChange }) => {
  const { translate } = useLocales();
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleInputChange = (values: NumberFormatValues): void => {
    onRateChange(values.floatValue !== undefined ? values.floatValue : null);
  };

  const handleCurrencyChange = (event: SelectChangeEvent) => {
    onCurrencyChange(event.target.value as string);
  };

  return (
    <Box display="flex" alignItems="center">
      <NumberFormat
        customInput={TextField}
        variant="outlined"
        placeholder={translate("component.rate-input.placeholder")}
        margin="dense"
        prefix={currencyMap[currency].symbol + " "}
        onValueChange={handleInputChange}
        value={rate !== null ? rate : ""}
        thousandSeparator
        isNumericString
      />
      <Button variant="outlined" size="large" className={classes.button} onClick={handleOpen}>
        {currencyMap[currency].symbol}
      </Button>
      <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
        <DialogTitle>{translate("component.rate-input.set-curreny")}</DialogTitle>
        <DialogContent>
          <CurrencySelect currency={currency} onChange={handleCurrencyChange} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {translate("common.submit")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default RateInput;

import { TextField } from "@mui/material";
import { ErrorType } from "src/types";

type TextProps = {
  label: string;
  value: string | number;
  onChange: (e: any) => void;
  onBlur?: (e: any) => void;
  isDisabled?: boolean;
  width?: number;
  defaultValue?: string | undefined;
  isRequired?: boolean;
  startAdrnmnt?: string;
  endAdrnmnt?: string;
  error?: ErrorType;
  type?: string;
  name?: string;
  fullWidth?: boolean;
  className?: any;
};

export default function CustomTextField(props: TextProps) {
  const {
    label,
    value,
    onChange,
    isDisabled = false,
    isRequired = false,
    error,
    width,
    defaultValue = undefined,
    type,
    name,
    fullWidth,
    className,
  } = props;

  const id = label.toLowerCase().replace(/\s+/g, "-");

  return (
    <TextField
      id={id}
      name={name}
      disabled={isDisabled}
      variant="outlined"
      defaultValue={defaultValue}
      autoComplete="off"
      required={isRequired}
      size="small"
      label={label}
      style={{ width: width }}
      fullWidth={fullWidth}
      value={value}
      error={error && error.isError ? true : false}
      helperText={error ? error.message : ""}
      onChange={onChange}
      // InputProps={{
      //     startAdornment: <InputAdornment position="start">{startAdrnmnt}</InputAdornment>,
      // }}
      type={type}
      className={className}
    />
  );
}

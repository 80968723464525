import { merge } from "lodash";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

import { useQuery } from "@apollo/client";
import { Box, Card, CircularProgress, Theme, useMediaQuery, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";

import { GET_PROJECT_BUDGET_EXPENSE } from "../../../Api/Dashboard/dashborad.queries";
import {
  GetProjectBudgetExpenseQuery,
  GetProjectBudgetExpenseQueryVariables,
  WorkspaceUserBase,
} from "../../../generated/graphql";
import { useProject, useProperties } from "../../../Store/hooks";
import BaseOptionChart from "../../../Style/BaseOptionChart";
import { fNumber } from "../../../utils/floatNumber";
import BaseCard from "./BaseCard";

import type { FC } from "react";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

const RootStyle = styled(Card)(({ theme }) => ({
  display: "flex",
  position: "relative",
  alignItems: "center",
  padding: theme.spacing(3),
  backgroundColor: theme.palette.primary.dark,
}));

interface ProjectBudgetActivityProps {}

const ProjectBudgetActivity: FC<ProjectBudgetActivityProps> = () => {
  const classes = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down("md"));

  const { project, projectBudget } = useProject();

  const { getUsersByIds } = useProperties();
  const [users, setUsers] = useState<WorkspaceUserBase[]>([]);
  const [chartData, setChartData] = useState([{ name: "Budget", data: [projectBudget?.budget || 0] }]);

  const { data, loading } = useQuery<GetProjectBudgetExpenseQuery, GetProjectBudgetExpenseQueryVariables>(
    GET_PROJECT_BUDGET_EXPENSE,
    {
      variables: { projectId: project!.id },
      onCompleted: (data) => {
        if (data.GetProjectBudgetExpense) {
          setChartData((v) => v.concat([{ name: "Expense", data: [data.GetProjectBudgetExpense] }]));
        }
      },
    },
  );

  const chartOptions = merge(BaseOptionChart(), {
    colors: [theme.palette.common.black, theme.palette.primary.main], // 여기에 원하는 색상 코드를 추가하십시오.
    tooltip: {
      marker: { show: false },
      y: {
        formatter: (seriesName: string) => fNumber(seriesName),
        title: {
          formatter: () => "",
        },
      },
    },
    plotOptions: {
      bar: { horizontal: true, barHeight: "28%", borderRadius: 2 },
    },
    xaxis: {
      categories: [project?.name || ""],
    },
  });

  useEffect(() => {
    console.log(chartData, "charData");
  });
  return (
    <BaseCard name="Budget and Expense" direction="column">
      {loading && (
        <Box display="flex" flexGrow={1} justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {!loading && data && <ReactApexChart type="bar" series={chartData} options={chartOptions} height={150} />}
    </BaseCard>
  );
};

export default ProjectBudgetActivity;

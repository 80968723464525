import React, { useState, useEffect } from "react";
import { AppBar, Toolbar, ThemeProvider, Theme, StyledEngineProvider, IconButton } from "@mui/material";
import useStyles from "./styles";
import Locale from "./Components/Locale";
import HomeHeader from "./Components/HomeHeader";
import FeatureBand from "./Components/FeatureBand";
import FeatureBand2 from "./Components/FeatureBand2";
import FeatureBand3 from "./Components/FeatureBand3";
import taskTableImage from "./Images/taskTable.png";
import calendarImage from "./Images/calendar_2.png";
import analysisImage from "./Images/analysis_2.png";
import { theme } from "./Theme/theme";
import Footer from "./Components/Footer";
import MenuIcon from "@mui/icons-material/Menu";

import { MultiLang } from "./Util/type";
import DialogPaper from "./Components/DialogPaper";
import RequestDemo from "./Components/RequestDemo";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export function Landing() {
  const classes = useStyles();

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <iframe
          src="https://d3dfbm4awnyfi1.cloudfront.net"
          title="landing"
          style={{ width: "100%", height: "100%", border: "none" }}
        />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

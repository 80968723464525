import { PropsWithChildren, ReactElement } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Draggable } from "react-beautiful-dnd";
import { DraggableOption } from "src/Components/Molecules";
import { Theme } from "@mui/material";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

interface DragDropListProps<T extends { id: string }> {
  option: T;
  index: number;
}

function DragDropList<T extends { id: string }>(props: PropsWithChildren<DragDropListProps<T>>): ReactElement {
  const { option, index } = props;

  return (
    <Draggable draggableId={option.id} key={option.id} index={index}>
      {(provided, snapshot) => (
        <DraggableOption
          dragging={snapshot.isDragging}
          index={index}
          //@ts-ignore
          ref={provided.innerRef}
          style={{ ...provided.draggableProps.style }}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {props.children}
        </DraggableOption>
      )}
    </Draggable>
  );
}

export default DragDropList;

import React from "react";

import { Box, CircularProgress, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

import type { FC } from "react";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    alignItems: "center",
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    left: 0,
    padding: theme.spacing(3),
    position: "fixed",
    top: 0,
    width: "100%",
    zIndex: 2000,
  },
}));

const useStylesFacebook = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
    },
    bottom: {
      color: theme.palette.grey[200],
    },
    top: {
      color: theme.palette.primary.main,
      animationDuration: "550ms",
      position: "absolute",
      left: 0,
    },
    circle: {
      strokeLinecap: "round",
    },
  }),
);

const SlashScreen: FC = () => {
  const classes = useStyles();
  const progressClasses = useStylesFacebook();

  return (
    <div className={classes.root}>
      <Box width={200}>
        <div className={progressClasses.root}>
          {/* <CircularProgress
            variant="determinate"
            className={progressClasses.bottom}
            size={40}
            thickness={4}
            value={100}
          />
          <CircularProgress
            variant="indeterminate"
            disableShrink
            className={progressClasses.top}
            classes={{
              circle: progressClasses.circle,
            }}
            size={40}
            thickness={4}
          /> */}
        </div>
      </Box>
    </div>
  );
};

export default SlashScreen;

import {
  CREATE_USER_TITLE,
  DELETE_USER_TITLE,
  GET_USER_TITLES,
  UPDATE_USER_TITLE,
} from "src/Api/User/UserTitles.queries";
import client from "src/apollo";
import { AppThunk } from "../store";
import { UserTitleSlice as slice } from "../reducers/userTitles";
import { UserTitle } from "src/types/UserTitleType";
import {
  CreateUserTitleMutation,
  CreateUserTitleMutationVariables,
  DeleteUserTitleMutation,
  DeleteUserTitleMutationVariables,
  GetUserTitlesQuery,
  UpdateUserTitleMutation,
  UpdateUserTitleMutationVariables,
} from "../../generated/graphql";

export const getUserTitles = (): AppThunk => async (dispatch) => {
  const res = await client.query<GetUserTitlesQuery>({
    query: GET_USER_TITLES,
  });

  if (res.data.GetUserTitles.length > 0) {
    dispatch(slice.actions.getUserTitles({ userTitles: res.data.GetUserTitles }));
  } else {
    dispatch(slice.actions.changeLoadState({ isLoaded: true }));
  }
};

export const createUserTitle = (userTitle: UserTitle): AppThunk => async (dispatch) => {
  const res = await client.mutate<CreateUserTitleMutation, CreateUserTitleMutationVariables>({
    mutation: CREATE_USER_TITLE,
    variables: {
      title: userTitle.title,
      rate: userTitle.rate,
      currency: userTitle.currency,
    },
  });

  if (res.data?.CreateUserTitle.ok && res.data.CreateUserTitle.result) {
    dispatch(slice.actions.createUserTitle({ userTitle: res.data.CreateUserTitle.result }));
  } else {
    console.error(res.data?.CreateUserTitle.error);
  }
};

export const updateUserTitle = (userTitle: UserTitle): AppThunk => async (dispatch) => {
  const res = await client.mutate<UpdateUserTitleMutation, UpdateUserTitleMutationVariables>({
    mutation: UPDATE_USER_TITLE,
    variables: {
      id: userTitle.id,
      title: userTitle.title,
      rate: userTitle.rate,
      currency: userTitle.currency,
    },
  });

  if (res.data?.UpdateUserTitle.ok) {
    dispatch(slice.actions.updateUserTitle({ userTitle }));
  } else {
    console.error(res.data?.UpdateUserTitle.error);
  }
};

export const deleteUserTitle = (id: number): AppThunk => async (dispatch) => {
  const res = await client.mutate<DeleteUserTitleMutation, DeleteUserTitleMutationVariables>({
    mutation: DELETE_USER_TITLE,
    variables: {
      id: id,
    },
  });

  if (res.data?.DeleteUserTitle.ok) {
    dispatch(slice.actions.deleteUserTitle({ id }));
  } else {
    console.error(res.data?.DeleteUserTitle.error);
  }
};

import { WorkspaceRole, WorkspaceUser } from "../../generated/graphql";
import { useTypedSelector } from "../../hooks";
import useAuth from "../../hooks/useAuth";
import { getListById, getListByIds } from "../helper/projection";

export function useUser() {
  const { user } = useAuth();
  const { members } = useTypedSelector((state) => state.members);

  const _members = getListById<WorkspaceUser>(members, "allIds", "byId");

  const userInfoInWorkspace = _members?.find((v) => v.id === user!.id);

  return {
    userInfo: user,
    userInfoInWorkspace: _members!.find((v) => v.id === user!.id),
    isAdmin: userInfoInWorkspace?.role === WorkspaceRole.Admin,
  };
}

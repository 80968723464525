import React from "react";
import type { FC } from "react";
import { Box, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import type { Theme } from "@mui/material";
import { getContrastFontColor, getDefaultColorCodes } from "src/theme/colors";
import clsx from "clsx";
import tinycolor from "tinycolor2";
import { TypographyVariant } from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      borderRadius: 3,
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    fontColor: (props: TextLabelChipProps) => ({
      color: getContrastFontColor(props.color),
    }),
  }),
);

interface TextLabelChipProps {
  name: string;
  variant?: TypographyVariant | "inherit";
  color?: string | null;
  className?: string;
  [key: string]: any;
}

const TextLabelChip: FC<TextLabelChipProps> = ({ name, color, className, variant = "subtitle2", ...rest }) => {
  const classes = useStyles({ color, name });

  return (
    <Box
      className={clsx(classes.root, className)}
      display="flex"
      alignItems="center"
      justifyContent="center"
      style={{
        backgroundColor:
          (color && tinycolor(color).lighten(11).toHexString()) ||
          tinycolor(getDefaultColorCodes()).lighten(11).toHexString(),
      }}
      {...rest}
    >
      <Typography variant={variant} align="center" className={classes.fontColor} noWrap>
        {name}
      </Typography>
    </Box>
  );
};

export default TextLabelChip;

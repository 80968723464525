import { SnackbarProvider } from "notistack";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

import ThemePrimaryColor from "./Components/ThemePrimaryColor";
import { AuthProvider } from "./Context/FirebaseAuthContext";
import Router from "./Routes/Routes";
import ThemeConfig from "./theme";
import { GlobalThemeStyles } from "./theme/globalThemeStyles";
import { config, library } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";

import "./locales/i18n";

config.autoAddCss = false;
export default function App() {
  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SnackbarProvider dense maxSnack={2} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
            <GlobalThemeStyles />
            <AuthProvider>
              <Router />
            </AuthProvider>
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}

import { ReactNode } from "react";
import type { FC } from "react";
import { Button, ButtonProps, Grid, Typography, useTheme } from "@mui/material";
import clsx from "clsx";

interface HeaderProps {
  name: string;
  className?: string;
  buttonText?: string;
  buttonProps?: ButtonProps;
  onButtonEvent?: () => void;
  button?: ReactNode;
}

const PageHeader: FC<HeaderProps> = ({ name, className, buttonText, onButtonEvent, buttonProps, button, ...rest }) => {
  const theme = useTheme();
  return (
    <Grid
      alignItems="center"
      container
      justifyContent="space-between"
      spacing={3}
      className={clsx(className)}
      {...rest}
    >
      <Grid item>
        <Typography variant="h4" sx={{ color: theme.palette.primary.main }}>
          {name}
        </Typography>
      </Grid>
      <Grid item>
        {button && button}
        {!button && buttonText && (
          <Button color="primary" variant="contained" onClick={onButtonEvent} {...buttonProps}>
            {buttonText}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default PageHeader;

import { Box, Skeleton, Theme, Typography, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { FC, useMemo } from "react";
import { useQuery } from "@apollo/client";
import { GetTasksByWeekQuery, GetTasksByWeekQueryVariables, Task, WorkspaceUserBase } from "../../generated/graphql";
import { GET_TASKS_BY_WEEK } from "../../Api/Task/Task.queries";
import TableCellAlign from "./TableCellAlign";
import { TextLabelChip, UserAvatar } from "../../Components/Atoms";
import moment from "moment";
import { useProperties } from "../../Store/hooks";
import { DataGrid, GridColDef, GridColumnHeaderParams, GridRenderCellParams } from "@mui/x-data-grid";

interface TimesheetUserDatailProps {
  date: Date;
  userId: string;
}

const TimesheetUserDatail: FC<TimesheetUserDatailProps> = ({ date, userId }) => {
  const {
    getProjectTaskCategory,
    getProjectTaskStatus,
    getUsersByIds,
    getProject,
    getProjectPhase,
    getProjectMilestone,
  } = useProperties();

  const { data, loading } = useQuery<GetTasksByWeekQuery, GetTasksByWeekQueryVariables>(GET_TASKS_BY_WEEK, {
    fetchPolicy: "no-cache",
    variables: {
      date: date,
      userId: userId,
      timezone: new Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  });

  const theme = useTheme();

  const columns: GridColDef[] = [
    {
      field: "id",
      width: 80,
      headerName: "ID",
      headerAlign: "center",

      renderCell: (params: GridRenderCellParams) => {
        return <TableCellAlign hCenter>{params.value}</TableCellAlign>;
      },
    },
    {
      field: "statusId",
      minWidth: 60,
      headerAlign: "center",
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography variant="subtitle2" color="textPrimary">
          Status
        </Typography>
      ),
      renderCell: (params: GridRenderCellParams) => {
        const _value = getProjectTaskStatus(params.value as any);
        return (
          <TableCellAlign hCenter>
            {(_value && <TextLabelChip name={_value.name} color={_value.color} />) || <div>Error</div>}
          </TableCellAlign>
        );
      },
    },
    {
      field: "plannedStartDate",
      width: 150,
      minWidth: 150,
      headerAlign: "center",
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography variant="subtitle2" color="textPrimary">
          Planned Start
        </Typography>
      ),
      renderCell: (params: GridRenderCellParams) => {
        return (
          (params.value && (
            <TableCellAlign hCenter>
              <Typography variant="body2">
                {moment(params.value as Date)
                  .format("MM/DD/YYYY HH:mm")
                  .toString()}
              </Typography>
            </TableCellAlign>
          )) ||
          ""
        );
      },
    },
    {
      field: "plannedEndDate",
      width: 150,
      minWidth: 150,
      headerAlign: "center",

      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography variant="subtitle2" color="textPrimary">
          Planned End
        </Typography>
      ),
      renderCell: (params: GridRenderCellParams) => {
        return (
          (params.value && (
            <TableCellAlign hCenter>
              <Typography variant="body2">
                {moment(params.value as Date)
                  .format("MM/DD/YYYY HH:mm")
                  .toString()}
              </Typography>
            </TableCellAlign>
          )) ||
          ""
        );
      },
    },
    {
      field: "name",
      minWidth: 150,
      width: 300,
      headerAlign: "center",

      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography variant="subtitle2" color="textPrimary">
          Task
        </Typography>
      ),
      renderCell: (params: GridRenderCellParams) => {
        return (
          <TableCellAlign hCenter>
            <Typography variant="subtitle1">{params.value}</Typography>
          </TableCellAlign>
        );
      },
    },
    {
      field: "projectId",
      minWidth: 150,
      width: 200,
      headerAlign: "center",

      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography variant="subtitle2" color="textPrimary">
          Project
        </Typography>
      ),
      renderCell: (params: GridRenderCellParams) => {
        const _value = getProject(params.value as any);

        return (
          <TableCellAlign hCenter>
            {(_value && <TextLabelChip name={_value.name} color={_value.projectColor} />) || <div>Error</div>}
          </TableCellAlign>
        );
      },
    },
    {
      field: "categoryId",
      minWidth: 80,
      headerAlign: "center",

      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography variant="subtitle2" color="textPrimary">
          Category
        </Typography>
      ),
      renderCell: (params: GridRenderCellParams) => {
        const _value = getProjectTaskCategory(params.value as any);
        return (
          <TableCellAlign hCenter>
            {(_value && <TextLabelChip name={_value.name} color={_value.color} />) || <div>Error</div>}
          </TableCellAlign>
        );
      },
    },
    {
      field: "assigneesIds",
      minWidth: 80,
      headerAlign: "center",

      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography variant="subtitle2" color="textPrimary">
          Assignee
        </Typography>
      ),
      renderCell: (params: GridRenderCellParams) => {
        const _values = getUsersByIds(params.value as any);
        return (
          <TableCellAlign hCenter>
            {_values.map((v: WorkspaceUserBase) => (
              <UserAvatar key={v.id} user={v} size="small" />
            ))}
          </TableCellAlign>
        );
      },
    },
    {
      field: "phaseId",
      minWidth: 100,
      headerAlign: "center",

      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography variant="subtitle2" color="textPrimary">
          Phase
        </Typography>
      ),
      renderCell: (params: GridRenderCellParams) => {
        const _value = getProjectPhase(params.value as any);
        return (
          <TableCellAlign hCenter>{_value && <TextLabelChip name={_value.name} color={_value.color} />}</TableCellAlign>
        );
      },
    },
    {
      field: "milestoneId",
      minWidth: 100,
      headerAlign: "center",

      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography variant="subtitle2" color="textPrimary">
          Milestone
        </Typography>
      ),
      renderCell: (params: GridRenderCellParams) => {
        const _value = getProjectMilestone(params.value as any);
        return (
          <TableCellAlign hCenter>{_value && <TextLabelChip name={_value.name} color={_value.color} />}</TableCellAlign>
        );
      },
    },
    {
      field: "endDate",
      minWidth: 150,
      width: 150,
      headerAlign: "center",

      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography variant="subtitle2" color="textPrimary">
          Completed
        </Typography>
      ),
      renderCell: (params: GridRenderCellParams) => {
        return (
          (params.value && (
            <TableCellAlign hCenter>
              <Typography variant="body2">
                {moment(params.value as Date)
                  .format("MM/DD/YYYY HH:mm")
                  .toString()}
              </Typography>
            </TableCellAlign>
          )) ||
          ""
        );
      },
    },
    {
      field: "duration",
      minWidth: 120,
      width: 120,
      headerAlign: "center",

      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography variant="subtitle2" color="textPrimary">
          Time Spent
        </Typography>
      ),
      renderCell: (params: GridRenderCellParams) => {
        const hours = params.value && Math.floor((params.value as any) / 60);
        const minutes = params.value && (params.value as any) % 60;

        return (
          (params.value && (
            <TableCellAlign hCenter>
              <Typography variant="body2" align="center">
                {(hours && hours !== 0 && hours) || ""}
                {(hours && hours !== 0 && "h ") || ""}
                {(minutes && minutes !== 0 && minutes) || ""}
                {(minutes && minutes !== 0 && "m") || ""}
              </Typography>
            </TableCellAlign>
          )) ||
          ""
        );
      },
    },
  ];

  return (
    <>
      {loading ? (
        <>
          <Skeleton sx={{ m: 1 }} animation="wave" height={50} />
          <Skeleton sx={{ m: 1 }} animation="wave" height={50} />
          <Skeleton sx={{ m: 1 }} animation="wave" height={50} />
          <Skeleton sx={{ m: 1 }} animation="wave" height={50} />
          <Skeleton sx={{ m: 1 }} animation="wave" height={50} />
        </>
      ) : (
        <Box sx={{ width: "100%", height: "70vh" }}>
          <DataGrid rows={data?.GetTasksByWeek || []} columns={columns} />
        </Box>
      )}
    </>
  );
};

export default TimesheetUserDatail;

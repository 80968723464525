import { useEffect, useRef, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
// material
import { alpha, styled } from "@mui/material/styles";
import {
  Box,
  Link,
  Stack,
  Button,
  Drawer,
  Tooltip,
  Typography,
  CardActionArea,
  IconButton,
  SvgIcon,
  Popover,
  Theme,
  Modal,
  Paper,
  List,
  ListItem,
  Divider,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
// hooks

// routes
import { PATH_DASHBOARD, PATH_DOCS } from "../../../Routes/paths";
// components
// import Logo from "../../components/Logo";
// import MyAvatar from "../../components/MyAvatar";
import { Scrollbar } from "../../../Components/Atoms";
import { NavSection } from "../../../Components/Atoms";
import { MHidden } from "../../../Components/@material-extend";
//
import { sidebarConfig, projectSidebarConfig } from "./SidebarConfig";
import useFirebaseAuth from "../../../hooks/useAuth";
import { useLocales, useTypedSelector } from "../../../hooks";
import useCollapseDrawer from "../../../hooks/useCollapseDrawer";
import { WorkspaceRole } from "../../../generated/graphql";
import { workspaceSectionsForAdmin, workspaceSectionsForAll } from "../../../Shared/FeatureList";

import { ArrowLeft as ArrowLeftIcon, ArrowLeftCircle as ArrowLeftCircleIcon, Eye as EyeIcon } from "react-feather";
import WorkspaceConvertingItem from "../NavBar/components/Workspace/WorkspaceConvertingItem";
import { useUser } from "../../../Store/hooks/useUser";
import { useProperties } from "../../../Store/hooks";
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    transition: theme.transitions.create("width", {
      duration: theme.transitions.duration.complex,
    }),
  },
  overflow: "visible",
}));

const AccountStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12],
}));

// ----------------------------------------------------------------------

type IconCollapseProps = {
  onToggleCollapse: VoidFunction;
  collapseClick: boolean;
};
const Eye = (props: any) => (
  <SvgIcon {...props}>
    <EyeIcon />
  </SvgIcon>
);
function IconCollapse({ onToggleCollapse, collapseClick }: IconCollapseProps) {
  return (
    <Tooltip title="Mini Menu">
      <CardActionArea
        onClick={onToggleCollapse}
        sx={{
          width: 18,
          height: 18,
          display: "flex",
          cursor: "pointer",
          borderRadius: "50%",
          alignItems: "center",
          color: "text.primary",
          justifyContent: "center",
          border: "solid 1px currentColor",
          ...(collapseClick && {
            borderWidth: 2,
          }),
        }}
      >
        <Box
          sx={{
            width: 8,
            height: 8,
            borderRadius: "50%",
            bgcolor: "currentColor",
            transition: (theme) => theme.transitions.create("all"),
            ...(collapseClick && {
              width: 0,
              height: 0,
            }),
          }}
        />
      </CardActionArea>
    </Tooltip>
  );
}

type DashboardSidebarProps = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
};
interface IUpdateTranslations {
  version: string;
  feat: string;
  feat_list: string[];
  fix: string;
  fix_list: string[];
}
// const useStyles = makeStyles((theme: Theme) => ({
//   popover: {
//     width: 320,
//   },
//   icon: {
//     backgroundColor: theme.palette.secondary.main,
//     color: theme.palette.secondary.contrastText,
//   },
// }));

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }: DashboardSidebarProps) {
  const { translate } = useLocales();
  const featList: string[] = translate("update.feat-list", { returnObjects: true }) as string[];
  const fixList: string[] = translate("update.fix-list", { returnObjects: true }) as string[];
  const updateTitle: string = translate("update.title");
  // const classes = useStyles();
  const { pathname } = useLocation();
  const { user } = useFirebaseAuth();
  const { isAdmin } = useUser();
  const { workspace } = useTypedSelector((state) => state.workspace);
  const { currentProjectId } = useTypedSelector((state) => state.projects);
  const { getProject } = useProperties();
  const [open, setOpen] = useState<boolean>(false);
  const anchorRef = useRef<any>(null);

  const handleModalToggle = () => {
    setOpen((prev) => !prev); // Modal의 상태를 토글합니다.
  };

  const {
    isCollapse,
    collapseClick,
    collapseHover,
    onToggleCollapse,
    onHoverEnter,
    onHoverLeave,
  } = useCollapseDrawer();

  const workspaceName = workspace?.name || "";
  const project = getProject(currentProjectId);
  const onProjectSide = Boolean(currentProjectId);
  const navConfig = !onProjectSide
    ? sidebarConfig(workspaceName, isAdmin)
    : projectSidebarConfig(workspaceName, project?.name || "", isAdmin);

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          px: 2.5,
          pt: 3,
          pb: 2,
          ...(isCollapse && {
            alignItems: "center",
          }),
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box component={RouterLink} to="/" sx={{ display: "inline-flex" }}>
            {/* <Logo /> */}
          </Box>

          <MHidden width="lgDown">
            {!isCollapse && <IconCollapse onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />}
          </MHidden>
        </Stack>

        {!isCollapse && !onProjectSide && <WorkspaceConvertingItem />}
      </Stack>

      <NavSection navConfig={navConfig} isShow={!isCollapse} />

      <Box sx={{ flexGrow: 1 }} />

      {!isCollapse && (
        <Stack spacing={3} alignItems="center" sx={{ px: 5, pb: 5, mt: 10, width: 1, textAlign: "center" }}>
          <div>
            <Typography gutterBottom variant="subtitle2">
              {translate("side-bar.hi")}, {user?.fullName}
            </Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {translate("side-bar.need-help")}
              <br /> Please check our docs
            </Typography>
          </div>
          <Box display="flex" alignItems="center" flexDirection="row">
            <Typography variant="body2" sx={{ color: "text.secondary", marginRight: 1 }}>
              {"Wyzrstask" + " " + translate("update.version")}
            </Typography>
            <Tooltip title={updateTitle}>
              <Box
                ref={anchorRef}
                onClick={handleModalToggle}
                sx={{
                  cursor: "pointer",
                  transition: "background-color 0.3s",
                  display: "flex", // Flexbox 사용
                  justifyContent: "center", // 가로 정렬
                  alignItems: "center", // 세로 정렬
                  width: "30px", // 균일한 양옆 너비
                  height: "30px", // 동일한 상하 높이
                  marginLeft: "-4px",
                  borderRadius: "50%", // 동그랗게 만들기
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                  },
                }}
              >
                <Eye sx={{ color: "text.secondary", fontSize: "1rem" }} />
              </Box>
            </Tooltip>
          </Box>

          <Button href={PATH_DOCS} sx={{ fontSize: 0.78 + "rem" }} target="_blank" variant="contained">
            Documentation
          </Button>
        </Stack>
      )}
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH,
        },
        ...(collapseClick && {
          position: "absolute",
        }),
      }}
    >
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH, bgcolor: "background.default", overflow: "visible" },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              overflow: "visible",
              width: DRAWER_WIDTH,
              bgcolor: "background.default",
              ...(isCollapse && {
                width: COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                borderRight: 0,
                backdropFilter: "blur(6px)",
                WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
                boxShadow: (theme) => theme.customShadows.z20,
                bgcolor: (theme) => alpha(theme.palette.background.default, 0.88),
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <Modal
        open={open}
        onClose={handleModalToggle}
        aria-labelledby="update-modal-title"
        aria-describedby="update-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          elevation={6}
          sx={{
            width: "80%",
            maxWidth: 600,
            p: 4,
            bgcolor: "background.paper",
            borderRadius: 2,
          }}
        >
          <Typography variant="h5" gutterBottom fontWeight="bold" mb={2} color="primary">
            {translate("update.version")}
          </Typography>
          <Divider sx={{ mb: 2 }} />

          <Typography variant="h6" fontWeight="bold" mb={2}>
            {translate("update.feat")}
          </Typography>
          <List>
            {featList?.map((text, index) => (
              <ListItem key={text} disablePadding>
                <Typography variant="body1">- {text}</Typography>
              </ListItem>
            ))}
          </List>

          <Typography variant="h6" fontWeight="bold" mt={3} mb={2}>
            {translate("update.fix")}
          </Typography>
          <List>
            {fixList?.map((text, index) => (
              <ListItem key={text} disablePadding>
                <Typography variant="body1">- {text}</Typography>
              </ListItem>
            ))}
          </List>
        </Paper>
      </Modal>
    </RootStyle>
  );
}

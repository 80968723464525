import {
  CREATE_PROJECT_TASK_STATUS,
  DELETE_PROJECT_TASK_STATUS,
  GET_PROJECT_TASK_STATUSES,
  UPDATE_PROJECT_TASK_STATUS,
  UPDATE_PROJECT_TASK_STATUS_ORDER,
} from "src/Api/Project/ProjectTaskStatus.queries";
import client from "src/apollo";
import {
  CreateProjectTaskStatusMutation,
  CreateProjectTaskStatusMutationVariables,
  DeleteProjectTaskPropertyInput,
  DeleteProjectTaskStatusMutation,
  DeleteProjectTaskStatusMutationVariables,
  GetProjectTaskStatusesQuery,
  GetProjectTaskStatusesQueryVariables,
  ProjectTaskStatus,
  UpdateProjectTaskStatusMutation,
  UpdateProjectTaskStatusMutationVariables,
  UpdateProjectTaskStatusOrderMutation,
  UpdateProjectTaskStatusOrderMutationVariables,
} from "../../generated/graphql";
import { projectTaskStatusSlice as slice } from "../reducers/projectTaskStatusReducer";
import { AppThunk } from "../store";

export const getProjectTaskStatuses = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setLoaded({ loaded: false }));

  const res = await client.query<GetProjectTaskStatusesQuery, GetProjectTaskStatusesQueryVariables>({
    query: GET_PROJECT_TASK_STATUSES,
    fetchPolicy: "network-only",
    variables: { projectId: undefined },
  });

  if (res.data.GetProjectTaskStatuses.ok) {
    dispatch(slice.actions.getTaskStatuses({ taskStatuses: res.data.GetProjectTaskStatuses.statuses }));
  } else {
    console.error(res.data.GetProjectTaskStatuses.error);
  }

  dispatch(slice.actions.setLoaded({ loaded: true }));
};

export const getProjectTaskStatusesByProject = (projectId: string | null): AppThunk => async (dispatch) => {
  if (projectId) {
    const res = await client.query<GetProjectTaskStatusesQuery, GetProjectTaskStatusesQueryVariables>({
      query: GET_PROJECT_TASK_STATUSES,
      variables: {
        projectId,
      },
    });

    if (res.data.GetProjectTaskStatuses.ok) {
      dispatch(slice.actions.getTaskStatuses({ taskStatuses: res.data.GetProjectTaskStatuses.statuses }));
    }
  }
};

export const createProjectTaskStatus = (projectId: string, name: string, color?: string): AppThunk => async (
  dispatch,
) => {
  const res = await client.mutate<CreateProjectTaskStatusMutation, CreateProjectTaskStatusMutationVariables>({
    mutation: CREATE_PROJECT_TASK_STATUS,
    refetchQueries: [{ query: GET_PROJECT_TASK_STATUSES, variables: { projectId } }],
    variables: {
      name,
      projectId,
      color,
    },
  });

  if (res.data?.CreateProjectTaskStatus.ok && res.data.CreateProjectTaskStatus.result) {
    dispatch(slice.actions.createProjectTaskStatus({ taskStatus: res.data.CreateProjectTaskStatus.result }));
  }
};

export const updateProjectTaskStatus = (taskStatus: ProjectTaskStatus): AppThunk => async (dispatch) => {
  const res = await client.mutate<UpdateProjectTaskStatusMutation, UpdateProjectTaskStatusMutationVariables>({
    mutation: UPDATE_PROJECT_TASK_STATUS,
    refetchQueries: [{ query: GET_PROJECT_TASK_STATUSES, variables: { projectId: taskStatus.projectId } }],
    variables: { ...taskStatus },
  });

  if (res.data?.UpdateProjectTaskStatus.ok) {
    dispatch(slice.actions.updateProjectTaskStatus({ taskStatus }));
  }
};

export const updateProjectTaskStatusOrder = (id: number, position: number, projectId: string): AppThunk => async (
  dispatch,
) => {
  dispatch(slice.actions.updateProjectTaskStatusOrder({ id, position }));
  client.mutate<UpdateProjectTaskStatusOrderMutation, UpdateProjectTaskStatusOrderMutationVariables>({
    mutation: UPDATE_PROJECT_TASK_STATUS_ORDER,
    refetchQueries: [{ query: GET_PROJECT_TASK_STATUSES, variables: { projectId } }],
    variables: {
      id,
      position,
    },
  });
};

// TODO: Key Status 외에 다른 status를 지울 경우,
// 해당 status로 할당된 task들을 다른 status로 변경해야함.
export const deleteProjectTaskStatus = (input: DeleteProjectTaskPropertyInput): AppThunk => async (dispatch) => {
  const res = await client.mutate<DeleteProjectTaskStatusMutation, DeleteProjectTaskStatusMutationVariables>({
    mutation: DELETE_PROJECT_TASK_STATUS,
    variables: {
      input,
    },
  });

  if (res.data?.DeleteProjectTaskStatus.ok) {
    dispatch(slice.actions.deleteProjectTaskStatus({ id: input.id }));
  }
};

import { FC, ReactNode, useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LinearProgress } from "@mui/material";
import { changeProject, getProjects } from "src/Store/action-creators";
import ProjectFetch from "../ProjectFetch";
import { useTypedSelector } from "src/hooks";

interface ProjectGuardProps {
  children?: ReactNode;
}

const ProjectGuard: FC<ProjectGuardProps> = ({ children, ...rest }) => {
  const { projectName = "", workspace = "" } = useParams();
  const dispatch = useDispatch();
  const { workspace: workspaceState } = useTypedSelector((state) => state.workspace);
  const { isLoaded, projects, currentProjectId } = useTypedSelector((state) => state.projects);

  useEffect(() => {
    if (!isLoaded) {
      dispatch(getProjects());
    }
  }, [dispatch]);

  useEffect(() => {
    if (isLoaded && !currentProjectId) {
      if (projects.allIds.map((v) => projects.byId[v].name).includes(projectName)) {
        dispatch(changeProject(workspace, projectName, false));
      }
    }
  }, [isLoaded]);

  if (isLoaded && !currentProjectId && !projects.allIds.map((v) => projects.byId[v].name).includes(projectName)) {
    return <Navigate to={`/w/${workspaceState?.name}/projects`} />;
  }

  if (!isLoaded || !currentProjectId) {
    return <LinearProgress />;
  }

  return <ProjectFetch>{children}</ProjectFetch>;
};

export default ProjectGuard;

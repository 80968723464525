import { AppThunk } from "..";
import {
  GET_PROJECT_BUDGET,
  CREATE_PROJECT_BUDGET,
  UPDATE_PROJECT_BUDGET,
} from "../../Api/Project/ProjectBudget.queries";
import client from "../../apollo";
import {
  CreateProjectBudgetMutation,
  CreateProjectBudgetMutationVariables,
  GetProjectBudgetQuery,
  GetProjectBudgetQueryVariables,
  ProjectBudgetInput,
  UpdateProjectBudgetMutation,
  UpdateProjectBudgetMutationVariables,
} from "../../generated/graphql";
import { ProjectBudgetSlice as slice } from "../reducers/projectBudgetReducer";

export const getProjectBudget = (projectId: string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setLoadingState({ loading: true }));

  const {
    data: { GetProjectBudget },
  } = await client.query<GetProjectBudgetQuery, GetProjectBudgetQueryVariables>({
    query: GET_PROJECT_BUDGET,
    variables: {
      projectId,
    },
  });

  if (GetProjectBudget.ok && GetProjectBudget.projectBudget) {
    dispatch(slice.actions.addProjectBudget({ projectBudget: GetProjectBudget.projectBudget }));
  } else if (GetProjectBudget.ok && GetProjectBudget.projectBudget === null) {
    const res = await client.mutate<CreateProjectBudgetMutation, CreateProjectBudgetMutationVariables>({
      mutation: CREATE_PROJECT_BUDGET,
      variables: {
        projectId,
      },
    });

    if (res.data?.CreateProjectBudget.ok && res.data.CreateProjectBudget.projectBudget) {
      dispatch(slice.actions.addProjectBudget({ projectBudget: res.data?.CreateProjectBudget.projectBudget }));
    }
  }

  dispatch(slice.actions.setLoadingState({ loading: false }));
};

export const updateProjectBudget = (projectId: string, values: ProjectBudgetInput): AppThunk => async (dispatch) => {
  // dispatch(slice.actions.setLoadingState({ loading: true }));

  const { data } = await client.mutate<UpdateProjectBudgetMutation, UpdateProjectBudgetMutationVariables>({
    mutation: UPDATE_PROJECT_BUDGET,
    variables: {
      projectId,
      values: {
        ...values,
      },
    },
  });

  if (data?.UpdateProjectBudget.ok && data.UpdateProjectBudget.projectBudget) {
    dispatch(slice.actions.updateProjectBudget({ projectBudget: data.UpdateProjectBudget.projectBudget }));
  }

  // dispatch(slice.actions.setLoadingState({ loading: false }));
};

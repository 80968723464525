import { COLOR_CODES } from "src/theme/colors";
import tinycolor from "tinycolor2";

export function getColorName(color: string): string {
  const newColor = Color_Codes.find((c) => c.value === color);
  if (newColor) {
    return newColor.name;
  } else {
    console.error(`There isn't color in color codes : ${color}`);
    return Color_Codes[0].name;
  }
}

export type ColorCodesType = {
  name: string;
  value: string;
};

export const Color_Codes: ColorCodesType[] = [
  { name: "Terra Cotta", value: COLOR_CODES[0] },
  { name: "Burnt Sienna", value: COLOR_CODES[1] },
  { name: "Mandarin", value: COLOR_CODES[2] },
  { name: "Sandy Brown", value: COLOR_CODES[3] },
  { name: "Sunray", value: COLOR_CODES[4] },
  { name: "Orange Yellow", value: COLOR_CODES[5] },
  { name: "Asparagus", value: COLOR_CODES[6] },
  { name: "Persian Green", value: COLOR_CODES[7] },
  { name: "Charcoal", value: COLOR_CODES[8] },
  { name: "default", value: COLOR_CODES[9] },
];

export function invertColor(hex: string, bw: boolean = true) {
  if (hex.indexOf("#") === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error("Invalid HEX color.");
  }
  var r = parseInt(hex.slice(0, 2), 16),
    g = parseInt(hex.slice(2, 4), 16),
    b = parseInt(hex.slice(4, 6), 16);
  if (bw) {
    // http://stackoverflow.com/a/3943023/112731
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#FFFFFF";
  }
  // invert color components
  var _r = (255 - r).toString(16),
    _g = (255 - g).toString(16),
    _b = (255 - b).toString(16);
  // pad each with zeros and return
  return "#" + padZero(_r) + padZero(_g) + padZero(_b);
}

function padZero(str: string, len?: number) {
  len = len || 2;
  var zeros = new Array(len).join("0");
  return (zeros + str).slice(-len);
}

export function stringToColor(name: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < name.length; i += 1) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }

  var hsl = tinycolor(color).toHsl();
  hsl.s = 0.5;

  return tinycolor(hsl).toString("hex");
}

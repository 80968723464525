import React from "react";
import type { FC } from "react";
import { useTheme, useMediaQuery, Theme } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) => ({
  footerStyle: {
    position: "absolute",
    left: "0",
    bottom: "0",
    width: "100%",
  },
}));

interface FooterProps {}

const Footer: FC<FooterProps> = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down("md"));

  return <div className={classes.footerStyle}>{children}</div>;
};

export default Footer;

import { languageEnum } from "../../enum/languageEnum";

// action type
const CHANGE_LANGUAGE = "appState/CHANGE_LANGUAGE" as const;

// action function
export const changeLanguage = (language: languageEnum) => ({
  type: CHANGE_LANGUAGE,
  language,
});

// initial state
export type LanguageState = {
  language: languageEnum;
};

const userLanguage = navigator.language.slice(0, 2);
const initialState: LanguageState = {
  language: languageEnum[userLanguage] || languageEnum.en,
};

// action function type
type LanguageStateAction = ReturnType<typeof changeLanguage>;

export default function LanguageReducer(
  state: LanguageState = initialState,
  action: LanguageStateAction,
) {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return {
        ...state,
        language: action.language,
      };

    default:
      return state;
  }
}
